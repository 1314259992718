import { FC, useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { createInviteProfileSchema } from './inviteProfile.schema';
import {
  AutocompleteField,
  TextInputField,
  RadioButtonField,
  Button,
  ProgressSpinner,
  Card,
  CheckboxField,
  GridItem,
  GridWrapper,
} from '@jsluna/react';
import {
  createCountryCodeOptions,
  createTitleOptions,
  DropDownOptions,
  getDefaultOption,
} from 'src/utils/createOptions';
import { IRequestProfile } from '@interfaces/requestProfile.interface';
import { gql } from '@apollo/client';
import * as React from 'react';
import {
  InviteProfileForm_TagsByAccountTypeFragment,
  useInviteProfileForm_AccountTypesQuery,
  useInviteProfileForm_RolesByAccountTypeLazyQuery,
  useInviteProfileForm_TagsByAccountTypeLazyQuery,
} from 'src/operations/generated/graphql';
import { colleagueDomains } from 'src/constants/colleagueEmailDoamins';
import { AuthContext } from 'src/providers/AuthProvider';
import { IOption, IOptionWithDefault } from '@interfaces/option.interface';
import { envConfiguration } from 'src/utils/loadEnvConfig';

gql`
  fragment InviteProfileForm_RolesByAccountType on RoleProjection {
    id
    name
    hiddenForRequests
  }
`;

gql`
  fragment InviteProfileForm_AccountTypes on AccountTypeProjection {
    id
    name
    checkType
  }
`;

gql`
  fragment InviteProfileForm_TagsByAccountType on TagProjection {
    id
    name
  }
`;

gql`
  query InviteProfileForm_RolesByAccountType($accountTypeId: String!) {
    rolesByAccountType(accountTypeId: $accountTypeId) {
      id
      name
      description
      hiddenForRequests
    }
  }
`;

gql`
  query InviteProfileForm_AccountTypes {
    accountTypes {
      ...InviteProfileForm_AccountTypes
    }
  }
`;

gql`
  query InviteProfileForm_TagsByAccountType($accountTypeId: String!) {
    tagsByAccountType(accountTypeId: $accountTypeId) {
      ...InviteProfileForm_TagsByAccountType
    }
  }
`;

const TITLE_PLACEHOLDER = 'Select title';
export const COUNTRY_CODE_PLACEHOLDER = 'Select country code';

const COLLEAGUE_ID = 'AT_colleague';

interface ICreateProfileForm {
  initialValue: Partial<IRequestProfile>;
  onSubmit: (data: IRequestProfile) => void;
  loading: boolean;
  buttonText: string;
  autoInvite: boolean;
}

export const InviteProfileForm: FC<ICreateProfileForm> = React.forwardRef(
  ({ initialValue, onSubmit, loading, buttonText, autoInvite }, ref) => {
    const [accountTypesOptions, setAccountTypesOptions] = useState(
      [] as DropDownOptions[],
    );

    const [checkedRoles, setCheckedRoles] = useState([] as string[]);
    const [checkedTags, setCheckedTags] = useState([] as string[]);
    const [checkType, setCheckType] = useState<null | string>(null);

    const [schema, setSchema] = useState(createInviteProfileSchema([]));

    // @ts-ignore
    const { isAccountTypeAdminOf } = useContext(AuthContext);
    const [allTags, setAllTags] = useState<
      InviteProfileForm_TagsByAccountTypeFragment[]
    >([]);

    const [userEmail, setUserEmail] = useState('');

    const { data: { accountTypes } = { accountTypes: [] } } =
      useInviteProfileForm_AccountTypesQuery();

    const [getRolesByAccountType, { data: { rolesByAccountType = [] } = {} }] =
      useInviteProfileForm_RolesByAccountTypeLazyQuery();

    const [geTagsAccountType, { data: { tagsByAccountType = null } = {} }] =
      useInviteProfileForm_TagsByAccountTypeLazyQuery({
        fetchPolicy: 'no-cache',
      });

    const titleOptions = createTitleOptions();
    const countryCodeOptions = createCountryCodeOptions();

    const defaultCountryCodeOption =
      initialValue?.countryCode &&
      getDefaultOption(countryCodeOptions, initialValue.countryCode);

    const defaultTitleOption =
      initialValue?.title && getDefaultOption(titleOptions, initialValue.title);

    const defaultAccountTypeOption =
      initialValue?.accountTypeId &&
      getDefaultOption(accountTypesOptions, initialValue.accountTypeId);

    const getRoleOptions = () => {
      return rolesByAccountType.reduce((acc: IOptionWithDefault[], role) => {
        if (
          !(role.hiddenForRequests && !isAccountTypeAdminOf(watchAccountTypeId))
        ) {
          acc.push({
            label: role.name || '',
            value: role.id,
            defaultChecked: initialValue?.roles?.includes(role.id) || false,
          });
        }
        return acc;
      }, [] as IOptionWithDefault[]);
    };
    const getTagOptions = () => {
      return (
        tagsByAccountType &&
        tagsByAccountType.map((tag: any) => ({
          label: tag.name,
          value: tag.id,
          defaultChecked: initialValue?.tags?.includes(tag.id) || false,
        }))
      );
    };

    const handleRolesChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;

      if (checked) {
        const updatedRoles = [...checkedRoles, value];
        setCheckedRoles(updatedRoles);
        setValue('roles', updatedRoles);
      } else {
        const updatedRoles = checkedRoles.filter(item => item !== value);
        setCheckedRoles(updatedRoles);
        setValue('roles', updatedRoles);
      }
    };
    const handleTagsChecked = async (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const { value, checked } = e.target;

      if (checked) {
        const updatedTags = [...checkedTags, value];
        setCheckedTags(updatedTags);
        setValue('tags', updatedTags);
      } else {
        const updatedTags = checkedTags.filter(item => item !== value);
        setCheckedTags(updatedTags);
        setValue('tags', updatedTags);
      }
      await trigger();
    };

    const {
      handleSubmit,
      formState: { errors, isValid, isDirty },
      control,
      setValue,
      trigger,
      watch,
    } = useForm({
      resolver: yupResolver(schema),
      mode: 'onChange',
    });

    const watchAccountTypeId = watch('accountTypeId');
    const watchIsPartner = watch('isPartner');

    const isColleagueEmail = (email: string) => {
      const domains = [...colleagueDomains];
      if (envConfiguration.env !== 'production') {
        domains.push('jstest3.onmicrosoft.com');
      }
      return domains.some(domain => email.endsWith(domain));
    };

    useEffect(() => {
      if (initialValue?.title) {
        setValue('title', initialValue?.title);
      }
      if (initialValue?.countryCode) {
        setValue('countryCode', initialValue?.countryCode);
      }
      if (initialValue?.isPartner) {
        setValue('isPartner', initialValue?.isPartner);
      }

      if (initialValue?.accountTypeId) {
        setValue('accountTypeId', initialValue?.accountTypeId);
      }

      if (initialValue?.roles) {
        setCheckedRoles(initialValue?.roles);
        setValue('roles', initialValue?.roles);
      }
    }, []);

    const handleInputBlur = (
      event: React.FocusEvent<HTMLInputElement>,
    ): void => {
      setUserEmail(event.target.value);
    };

    useEffect(() => {
      const options = accountTypes.reduce<{ label: string; value: string }[]>(
        (acc, type) => {
          if (
            userEmail !== '' &&
            !isColleagueEmail(userEmail) &&
            type.id === COLLEAGUE_ID
          ) {
            return acc;
          }

          return [
            ...acc,
            {
              label: type.name,
              value: type.id,
            },
          ];
        },
        [],
      );

      setAccountTypesOptions(options);
    }, [accountTypes, userEmail]);

    useEffect(() => {
      if (tagsByAccountType) {
        setAllTags(tagsByAccountType);
      }
    }, [tagsByAccountType, userEmail]);

    useEffect(() => {
      const options = allTags?.map((tag: any) => tag.id);
      if (options?.length) {
        const updatedSchema = createInviteProfileSchema(options);
        setSchema(updatedSchema);
      } else {
        const updatedSchema = createInviteProfileSchema([]);
        setSchema(updatedSchema);
      }
      isDirty && trigger();
    }, [allTags]);

    useEffect(() => {
      if (watchAccountTypeId) {
        setCheckType(
          accountTypes.find(
            accountType => accountType.id === watchAccountTypeId,
          )?.checkType || null,
        );
        getRolesByAccountType({
          variables: { accountTypeId: watchAccountTypeId },
        });
        geTagsAccountType({
          variables: { accountTypeId: watchAccountTypeId },
        });
      } else {
        setCheckType(null);
        setAllTags([]);
      }
      setValue('tags', []);
      setValue('roles', []);
      isDirty && trigger();
    }, [watchAccountTypeId]);

    const submitHandler = (formData: any) => {
      if (isValid) {
        onSubmit({
          ...formData,
          autoInvite,
          roles: checkedRoles,
        });
      }
    };

    return (
      <>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Card>
            <h4>Profile Details</h4>
            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='title'
                  control={control}
                  render={({ field }) => (
                    <AutocompleteField
                      {...field}
                      ref={ref}
                      error={errors?.title?.message}
                      label='Title'
                      options={titleOptions}
                      defaultSelectedOption={defaultTitleOption}
                      placeholder={TITLE_PLACEHOLDER}
                      onSelect={async (selected: any) => {
                        setValue('title', selected?.value || '');
                        selected && (await trigger('title'));
                      }}
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>
            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='firstName'
                  control={control}
                  defaultValue={initialValue?.firstName}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      label='First name'
                      placeholder='Please enter'
                      error={errors?.firstName?.message}
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>

            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='lastName'
                  control={control}
                  defaultValue={initialValue?.lastName}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      label='Last name'
                      placeholder='Please enter'
                      error={errors?.lastName?.message}
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>

            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='preferredName'
                  control={control}
                  defaultValue={initialValue?.preferredName}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      label='Preferred name'
                      placeholder='Please enter if applicable'
                      error={errors?.preferredName?.message}
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>

            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='emailAddress'
                  control={control}
                  defaultValue={initialValue?.emailAddress}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      label='Email address'
                      placeholder='Please enter'
                      error={errors?.emailAddress?.message}
                      onBlur={handleInputBlur}
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>

            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='confirmEmailAddress'
                  control={control}
                  defaultValue={initialValue?.confirmEmailAddress}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      label='Confirm email address'
                      placeholder='Please enter'
                      error={errors?.confirmEmailAddress?.message}
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>

            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='countryCode'
                  control={control}
                  render={({ field }) => (
                    <AutocompleteField
                      {...field}
                      error={errors?.countryCode?.message}
                      label='Country code'
                      options={countryCodeOptions}
                      defaultSelectedOption={defaultCountryCodeOption}
                      placeholder={COUNTRY_CODE_PLACEHOLDER}
                      onSelect={async (selected: any) => {
                        setValue('countryCode', selected?.value || '');
                        selected && (await trigger('countryCode'));
                      }}
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>

            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='contactNumber'
                  control={control}
                  defaultValue={initialValue?.contactNumber}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      error={errors?.contactNumber?.message}
                      placeholder='Please enter'
                      label='Telephone number'
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>
          </Card>

          <Card className='ln-u-margin-top*2'>
            <h4>Account Details</h4>

            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='name'
                  control={control}
                  defaultValue={initialValue?.name}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      error={errors?.name?.message}
                      placeholder='Add account name here'
                      label='What would you like to name the account?'
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>

            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='jobTitle'
                  control={control}
                  defaultValue={initialValue?.jobTitle}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      error={errors?.jobTitle?.message}
                      placeholder='Add job title name here'
                      label='What is your job title?'
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>

            {accountTypesOptions.length > 0 && (
              <GridWrapper>
                <GridItem size={{ md: '1/3' }}>
                  <Controller
                    name='accountTypeId'
                    control={control}
                    render={({ field }) => (
                      <AutocompleteField
                        {...field}
                        ref={ref}
                        error={errors?.accountTypeId?.message}
                        label='Please select an account type'
                        options={accountTypesOptions}
                        defaultSelectedOption={defaultAccountTypeOption}
                        placeholder='Enter'
                        info="If you are a Sainsbury's colleague please select Colleague. If you are a Sainsbury's supplier please select Supplier."
                        onSelect={async (selected: any) => {
                          setValue('accountTypeId', selected?.value || '');
                          (await isDirty) && trigger();
                          if (selected?.value !== initialValue.accountTypeId) {
                            setCheckedRoles([]);
                            setCheckedTags([]);
                            setValue('tags', []);
                          }
                        }}
                      />
                    )}
                  />
                </GridItem>
              </GridWrapper>
            )}

            {checkType && (
              <>
                {rolesByAccountType.length > 0 && (
                  <GridWrapper>
                    <GridItem size={{ md: '1/3' }}>
                      <Controller
                        name='roles'
                        control={control}
                        render={({ field }) => (
                          <CheckboxField
                            info={
                              'Note: Roles are solely for the Supplier Onboarding Application. Do not select a role unless applicable'
                            }
                            label='Roles'
                            options={getRoleOptions()}
                            error={errors?.roles?.message}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleRolesChecked(e);
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </GridItem>
                  </GridWrapper>
                )}

                {tagsByAccountType && tagsByAccountType?.length > 0 && (
                  <GridWrapper>
                    <GridItem size={{ md: '1/3' }}>
                      <Controller
                        name='tags'
                        control={control}
                        render={({ field }) => (
                          <CheckboxField
                            label='Tags'
                            options={getTagOptions()}
                            error={errors?.tags?.message}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleTagsChecked(e);
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </GridItem>
                  </GridWrapper>
                )}

                <GridWrapper>
                  <GridItem size={{ md: '1/3' }}>
                    <Controller
                      name='isPartner'
                      control={control}
                      render={({ field }) => (
                        <RadioButtonField
                          label={
                            checkType === 'ORGANISATION'
                              ? 'Are you an Agent?'
                              : 'Are you a partner?'
                          }
                          info={
                            checkType === 'ORGANISATION'
                              ? 'Do you work for an agency/distributor on behalf of one of our suppliers?'
                              : "Do you work for a partner of Sainsbury's?"
                          }
                          {...field}
                          onChange={field.onChange}
                          error={errors?.isPartner?.message}
                          name='radio-button-field-1'
                          fullWidth
                          options={[
                            { value: 'yes', label: 'Yes' },
                            { value: 'no', label: 'No' },
                          ]}
                        />
                      )}
                    />
                  </GridItem>
                </GridWrapper>

                {watchIsPartner === 'yes' && (
                  <>
                    <GridWrapper>
                      <GridItem size={{ md: '1/3' }}>
                        <Controller
                          name='partnerName'
                          control={control}
                          defaultValue={initialValue?.partnerName}
                          render={({ field }) => (
                            <TextInputField
                              {...field}
                              onChange={field.onChange}
                              error={errors?.partnerName?.message}
                              label='What’s the name of your organisation?'
                            />
                          )}
                        />
                      </GridItem>
                    </GridWrapper>

                    <GridWrapper>
                      <GridItem size={{ md: '1/3' }}>
                        <Controller
                          name='partnerContact'
                          control={control}
                          defaultValue={initialValue?.partnerContact}
                          render={({ field }) => (
                            <TextInputField
                              {...field}
                              onChange={field.onChange}
                              error={errors?.partnerContact?.message}
                              label='What’s the name of your Sainsbury’s contact?'
                            />
                          )}
                        />
                      </GridItem>
                    </GridWrapper>
                  </>
                )}

                {checkType === 'ORGANISATION' && (
                  <GridWrapper>
                    <GridItem size={{ md: '1/3' }}>
                      <Controller
                        name='siteId'
                        control={control}
                        defaultValue={initialValue?.siteId}
                        render={({ field }) => (
                          <TextInputField
                            {...field}
                            onChange={field.onChange}
                            error={errors?.name?.message}
                            placeholder='Please enter'
                            label='Supplier ID number'
                            info="Please provide one of your Sainsbury's supplier code below, so we can link your account to your company. You can list either the alphanumeric version (S1234) or the numeric-only version (191234) of your supplier code. If you have more than one code, you can add those to your account later."
                          />
                        )}
                      />
                    </GridItem>
                  </GridWrapper>
                )}
              </>
            )}

            <Button
              disabled={loading || !isValid}
              type='submit'
              variant='filled'
              className='add-profile'
            >
              {loading && (
                <ProgressSpinner size='icon' className='ln-u-push-right-sm' />
              )}
              {buttonText}
            </Button>
          </Card>
        </form>
      </>
    );
  },
);
