import React, { FC, useEffect, useState } from 'react';
import { AddTagsContainer, PanelActions } from './addTagsComponent.styles';
import { Button, MultiAutocompleteField, TextInputField } from '@jsluna/react';
import { gql } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import {
  SelectedAccountFragment,
  useAddTagsComponent_TagsByAccountTypeQuery,
  useCompleteAccountMutation,
} from 'src/operations/generated/graphql';

interface IAddTagsComponent {
  account: SelectedAccountFragment;
  handleClose: () => void;
}

interface ITagOption {
  label: string;
  value: string;
}

gql`
  query AddTagsComponent_TagsByAccountType($accountTypeId: String!) {
    tagsByAccountType(accountTypeId: $accountTypeId) {
      ...TagSelection_Tags
    }
  }
`;

export const AddTagsComponent: FC<IAddTagsComponent> = ({
  account,
  handleClose,
}) => {
  const { data: { tagsByAccountType: fullTagOptions = [] } = {}, loading } =
    useAddTagsComponent_TagsByAccountTypeQuery({
      variables: { accountTypeId: account.accountTypeId || '' },
    });

  const [selectedTags, setSelectedTags] = useState<ITagOption[]>([]);
  const [tagOptions, setTagOptions] = useState<ITagOption[]>([]);
  const [accountName, setAccountName] = useState<string>(account.name || '');
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const handleSelect = (selected: ITagOption[]) => {
    setSelectedTags(selected);
  };

  const [completeAccount, { loading: completeAccountLoading }] =
    useCompleteAccountMutation();

  useEffect(() => {
    if (fullTagOptions.length) {
      const tagIds = account.tags.map(tag => tag.value.id);

      const tagOptions = fullTagOptions.reduce((acc, tag) => {
        if (tagIds.includes(tag.id)) return acc;
        return [...acc, { label: tag.name || '', value: tag.id }];
      }, [] as ITagOption[]);

      setTagOptions(tagOptions);
    }
  }, [fullTagOptions]);

  const handleAddTag = async () => {
    const tagIds = selectedTags.map(tag => tag.value);

    try {
      const result = await completeAccount({
        variables: {
          tagIds,
          name: accountName,
        },
      });

      if (result.data?.completeAccount.id) {
        setFeedBackMessage(NotificationType.SUCCESS);
      } else {
        setFeedBackMessage(NotificationType.FAILURE);
      }
    } catch (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  };

  return (
    <>
      <AddTagsContainer>
        {feedBackMessage ? (
          <>
            {feedBackMessage === NotificationType.SUCCESS ? (
              <>
                <div className='panel-heading'>It's all set.</div>
                <p>All done, click the close button to dismiss this window</p>
              </>
            ) : (
              <>
                <div className='panel-heading'>Something went wrong</div>
                <p>
                  We were unable to add tags to your account. Please try again.
                </p>
              </>
            )}
          </>
        ) : (
          <>
            <div className='panel-heading'>Welcome to your new account</div>
            <p className='ln-u-margin-bottom*2'>
              Please take a moment to check your Account name and account Tags
              are up to date.
            </p>
            <p className='ln-u-margin-bottom*2'>
              You can edit them now or via your profile later, but you must have
              at least one Tag attached to your account to continue (either in
              Existing tags or via Add tags).
            </p>
            <p className='ln-u-margin-bottom*2'>
              Once you are happy click <b>Get Started</b>.
            </p>

            <TextInputField
              name='accountName'
              defaultValue={account.name}
              placeholder={account.name}
              label='Account name'
              error={!accountName ? 'Account name cannot be empty' : false}
              onChange={(e: any) => {
                setAccountName(e.target.value);
              }}
            />

            {account.tags?.length > 0 && (
              <div className='existing-tags-container'>
                <h5 className='ln-u-margin-bottom*2'>Existing tags</h5>
                <div className='existing-tags ln-u-margin-bottom*2'>
                  {account.tags.map(tag => (
                    <div className='tag ln-u-margin-right*2' key={tag.value.id}>
                      {tag.value.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <>
              {tagOptions.length > 0 && (
                <MultiAutocompleteField
                  name='multi-autocomplete-field-1'
                  label={'Add tags'}
                  options={tagOptions}
                  onSelect={handleSelect}
                />
              )}
            </>

            {tagOptions.length == 0 && (
              <div className='no-tags-to-select'>
                There are no tags left on this account type to be added to your
                account.
              </div>
            )}
          </>
        )}
      </AddTagsContainer>
      <PanelActions>
        {!feedBackMessage ? (
          <Button
            onClick={handleAddTag}
            variant='filled'
            disabled={
              loading ||
              completeAccountLoading ||
              (selectedTags.length === 0 && account.tags.length === 0) ||
              accountName === ''
            }
          >
            Get started
          </Button>
        ) : (
          <>
            {feedBackMessage === NotificationType.SUCCESS ? (
              <Button onClick={handleClose} variant='filled'>
                Close
              </Button>
            ) : (
              <Button
                onClick={() => {
                  window.location.reload();
                }}
                variant='filled'
              >
                Try again
              </Button>
            )}
          </>
        )}
      </PanelActions>
    </>
  );
};
