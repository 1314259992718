import { routes } from '../constants/routes';
import { APP_ACCESS } from '../enums/permissions.enum';

export interface INav {
  name: string;
  path: string;
  label: string;
  navigateFunction?: () => void;
  scope?: string;
  subNav?: INav[];
  isPublic?: boolean;
}

export enum ClaimsEnum {
  APP_ADMIN = 'appAdmin',
  ACCOUNT_TYPE = 'accountType',
}

export const navList: INav[] = [
  {
    name: 'home',
    path: routes.root,
    label: 'Home',
    isPublic: true,
  },
  {
    name: 'apps',
    path: routes.applications,
    label: 'Apps',
  },
  {
    name: 'news',
    path: routes.newsAndArticles,
    label: 'News',
    isPublic: true,
  },
  {
    name: 'journals',
    path: routes.supplierJournals,
    label: 'Journals',
    isPublic: true,
  },
  {
    name: 'help',
    path: routes.supplierSupport,
    label: 'Help',
  },
  {
    name: 'admin',
    label: 'Admin',
    path: '/admin',
    subNav: [
      {
        name: 'profiles',
        path: routes.profiles,
        label: 'Profiles',
        scope: `permissions:${APP_ACCESS.UPDATE_USER_DETAILS}`,
      },
      {
        name: 'manageTags',
        label: 'Manage tags',
        path: routes.tags,
        scope: `permissions:${APP_ACCESS.TAG_MANAGEMENT}`,
      },
      {
        name: 'manageRegistrations',
        label: 'Registrations',
        path: routes.registrations,
        scope: `${ClaimsEnum.ACCOUNT_TYPE}`,
      },
      {
        name: 'manageAccountTypes',
        label: 'Manage account types',
        path: routes.manageAccountType,
        scope: `${ClaimsEnum.ACCOUNT_TYPE}`,
      },
      {
        name: 'viewSites',
        label: 'View sites',
        path: routes.sites,
        scope: `${ClaimsEnum.ACCOUNT_TYPE}`,
      },
      {
        name: 'serviceDashboard',
        label: 'Service Dashboard',
        path: routes.serviceDashboard,
        scope: `permissions:${APP_ACCESS.SERVICE_DASHBOARD}`,
      },
    ],
  },
];
