import React, { FC, useEffect, useState } from 'react';
import { ContentWrapper } from './components/contentWrapper/contentWrapper';
import { GridItem, GridWrapper } from '@jsluna/react';
import { BrexitUploadConnected } from './components/brexitUploadConnected';
import { UploadHistoryTableConnected } from './components/uploadHistoryTableConnected';
import { useMsal, MsalProvider } from '@azure/msal-react';
import { loginRequest } from 'src/constants/msal';

export const BrexitUpload: FC = () => {
  const [token, setToken] = useState<string | null>(null);

  const { instance, accounts } = useMsal();

  const getToken = async () => {
    const token = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

    setToken(token.accessToken);
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <ContentWrapper noMargin>
      {token && <BrexitUploadConnected token={token} />}
      <div className='ln-u-bg-color-grey-v-light'>
        <GridWrapper horizontalAlign='center' className='ln-u-soft'>
          <GridItem size={{ default: '1/1', md: '12/12', xl: '10/12' }}>
            <GridWrapper horizontalAlign='center'>
              <GridItem size='1/1'>
                <h2
                  className='ln-u-push-bottom ln-u-display-1'
                  data-testid='brexit-page-user-history'
                >
                  Your upload history
                </h2>
                <div className='ln-u-soft ln-u-bg-color-white'>
                  {token && <UploadHistoryTableConnected token={token} />}
                </div>
              </GridItem>
            </GridWrapper>
          </GridItem>
        </GridWrapper>
      </div>
    </ContentWrapper>
  );
};
