import { TopOfPage } from '@sainsburys-tech/suppex-top-of-page';
import { generateBreadcrumbs } from 'src/utils/getBreadcrumbs';
import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { JournalError } from 'src/components/JournalError';
import { JournalLoading } from 'src/components/JournalLoading';
import { useArticleForNews } from 'src/operations/queries/getArticleForNews';
import { useGetSupplierArticleGroupings } from 'src/operations/queries/getSupplierArticleGroupings';
import { GridWrapper, GridItem, Container } from '@jsluna/react';
import { TagGroup } from '@sainsburys-tech/suppex-tag-group';
import { TileGroup } from '@sainsburys-tech/suppex-tile-group';
import { ArticleFooter } from '@sainsburys-tech/article-footer';
import { TileData } from '@sainsburys-tech/suppex-tile';
import {
  generateJournalTags,
  generateNewsArticleTags,
} from 'src/utils/generateJournalTags';
import { journalClickHandler } from 'src/utils/journalClickHandler';
import DOMPurify from 'dompurify';

export const NewsArticlePage: FC = () => {
  const { articleId } = useParams();
  const navigate = useNavigate();

  const [filteredNews, setFilteredNews] = useState<any[]>();

  const {
    data: { article = null, news = null } = {},
    error: newsError,
    loading: newsLoading,
  } = useArticleForNews(articleId || '');
  const {
    data: { articles: journals } = {},
    error: supplierJournalsError,
    loading: supplierJournalsLoading,
  } = useGetSupplierArticleGroupings('Journal');

  useEffect(() => {
    if (article && news.list?.length) {
      const filtered = news.list.filter((n: any) => n.uid !== article.uid);
      setFilteredNews(filtered);
    }
  }, [news, article]);

  return (
    <>
      {newsLoading || supplierJournalsLoading ? (
        <JournalLoading />
      ) : newsError || supplierJournalsError ? (
        <JournalError />
      ) : (
        <>
          {article && (
            <>
              <TopOfPage
                title={article.title}
                subtitle={article.summary}
                breadcrumbs={generateBreadcrumbs({ article })}
                LinkType={Link}
              />

              <GridWrapper className='ln-u-bg-color-white'>
                <Container
                  soft
                  className='ln-u-soft-top ln-u-soft-bottom news-and-articles__article-page'
                >
                  <GridItem size={{ xs: '1/1', md: '8/12' }}>
                    <TagGroup tags={generateNewsArticleTags(article)} />
                    {article.intro && (
                      <div className='ln-u-soft-top'>
                        <b>{article.intro}</b>
                      </div>
                    )}
                    {article.image && (
                      <div className='ln-u-soft-top'>
                        <img
                          style={{ borderRadius: '0.5rem' }}
                          src={article.image}
                          alt={article.image_caption}
                        />
                      </div>
                    )}
                    <article
                      className='ln-u-soft-top'
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(article.body, {
                          ADD_TAGS: ['iframe'],
                          ADD_ATTR: [
                            'allow',
                            'allowfullscreen',
                            'frameborder',
                            'scrolling',
                          ],
                        }),
                      }}
                    />
                    <div className='ln-u-soft-top'>
                      <ArticleFooter text='' />
                    </div>
                  </GridItem>

                  <GridItem
                    size={{ xs: '1/1', md: '4/12' }}
                    role='complementary'
                  >
                    {filteredNews && filteredNews.length > 0 && (
                      <TileGroup
                        tileClickHandler={(tile: TileData) => {
                          journalClickHandler(tile, navigate);
                        }}
                        title='Latest News'
                        variant='text-only'
                        style='light'
                        tiles={filteredNews.map(news => ({
                          ...news,
                          summary: null,
                          tags: generateNewsArticleTags(news),
                        }))}
                        seeMoreHandler={() =>
                          navigate('/news-and-articles/news')
                        }
                        seeMoreButtonPosition='bottom'
                      />
                    )}

                    {journals.length > 0 && (
                      <TileGroup
                        tileClickHandler={(tile: TileData) => {
                          journalClickHandler(tile, navigate);
                        }}
                        title='Supplier Journal'
                        variant='card'
                        style='light'
                        tiles={[
                          {
                            ...journals[0],
                            image: { url: journals[0].teaser_image },
                            tags: generateJournalTags(journals[0]),
                          },
                        ]}
                        seeMoreHandler={() =>
                          navigate('/news-and-articles/journals')
                        }
                        seeMoreButtonPosition='bottom'
                      />
                    )}
                  </GridItem>
                </Container>
              </GridWrapper>
            </>
          )}
        </>
      )}
    </>
  );
};
