import React, { FC, useState } from 'react';
import { Table, IconButton, Button } from '@jsluna/react';
import { Minus, Plus } from '@jsluna/icons';
import { ApolloError, gql } from '@apollo/client';
import { Section } from '../section.styles';
import { Drawer } from '@mui/material';
import { AddAccountByProfileScreen } from '../../../screens/addAccountByProfile';
import { AccountSelectorSection_AccountsFragment } from 'src/operations/generated/graphql';

interface IAccountSelectorSection {
  loading: boolean;
  error: ApolloError | undefined;
  existingAccounts: AccountSelectorSection_AccountsFragment[];
  target: { id: string; displayName: string; type: string; origin: string };
  handleConfirmation: (
    accounts: AccountSelectorSection_AccountsFragment[],
  ) => Promise<void>;
  handleRemoval: (accountId: string) => Promise<void>;
  disabled?: boolean;
}

const MinusIcon = Minus as unknown as React.FC;
const PlusIcon = Plus as unknown as React.FC;

enum PanelAction {
  ACCOUNT_SELECTOR = 'ACCOUNT_SELECTOR',
}

gql`
  fragment AccountSelectorSection_accounts on AccountProjection {
    id
    name
    profile {
      value {
        id
        firstName
        lastName
        emailAddress
      }
    }
  }
`;

export const AccountSelectorSection: FC<IAccountSelectorSection> = ({
  loading,
  error,
  existingAccounts,
  target,
  handleConfirmation,
  handleRemoval,
  disabled = true,
}) => {
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);

  const handlePanelClose = () => {
    setShowSidePanel(null);
  };

  const renderPanel = () => {
    switch (showSidePanel) {
      case PanelAction.ACCOUNT_SELECTOR:
        return (
          <AddAccountByProfileScreen
            handleClose={handlePanelClose}
            existingSelectedAccounts={existingAccounts}
            loading={loading}
            error={error}
            handleConfirmation={handleConfirmation}
            target={target}
          />
        );
    }
  };

  return (
    <Section>
      <h4>{target.type}s</h4>
      {existingAccounts.length > 0 ? (
        <>
          <Table
            rowKey='id'
            data={existingAccounts}
            caption='Captions that have no significance to sighted users, can be toggled off and still be readable by screen readers.'
            visuallyHiddenCaption
            columns={[
              {
                name: 'Account I.D.',
                accessor: (
                  rowData: AccountSelectorSection_AccountsFragment,
                ) => ({
                  value: rowData.id,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'User name',
                accessor: (
                  rowData: AccountSelectorSection_AccountsFragment,
                ) => ({
                  value: `${rowData.profile?.value.firstName} ${rowData.profile?.value.lastName}`,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'Email',
                accessor: (
                  rowData: AccountSelectorSection_AccountsFragment,
                ) => ({
                  value: rowData.profile?.value.emailAddress,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'Account name',
                accessor: (
                  rowData: AccountSelectorSection_AccountsFragment,
                ) => ({
                  value: rowData.name,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'Manage',
                hideLabel: true,
                className: 'customCol',
                render: (rowData: AccountSelectorSection_AccountsFragment) => (
                  <>
                    <IconButton
                      variant='text'
                      circle={false}
                      disabled={disabled}
                      hard={false}
                      element='button'
                      label='Remove'
                      color='dark'
                      onClick={() => {
                        handleRemoval(rowData.id);
                      }}
                    >
                      <MinusIcon />
                    </IconButton>
                  </>
                ),
              },
            ]}
          />

          <div className='action-button'>
            <IconButton
              variant='text'
              circle={false}
              disabled={disabled}
              hard={false}
              element='button'
              label={`Add ${target.type}s`}
              color='dark'
              onClick={() => setShowSidePanel(PanelAction.ACCOUNT_SELECTOR)}
            >
              <PlusIcon />
            </IconButton>
          </div>
        </>
      ) : (
        <>
          <div className='ln-u-margin-bottom*3'>
            There are no {target.type.toLowerCase()}s currently associated with
            this {target.origin}.
          </div>
          <Button
            circle={false}
            disabled={disabled}
            hard={false}
            element='button'
            variant='filled'
            onClick={() => setShowSidePanel(PanelAction.ACCOUNT_SELECTOR)}
          >
            Add {target.type}s
          </Button>
        </>
      )}

      <Drawer
        open={showSidePanel !== null}
        onClose={handlePanelClose}
        anchor='right'
        className='add-application'
      >
        {renderPanel()}
      </Drawer>
    </Section>
  );
};
