import { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import requestAccountSchema from './requestAccount.schema';
import {
  AutocompleteField,
  TextInputField,
  RadioButtonField,
  Button,
  ProgressSpinner,
  Card,
  CheckboxField,
  GridItem,
  GridWrapper,
} from '@jsluna/react';
import { DropDownOptions, getDefaultOption } from 'src/utils/createOptions';
import { IRequestAccount } from '@interfaces/requestAccount.interface';
import { gql } from '@apollo/client';
import * as React from 'react';
import { InviteProfileMessages } from 'src/constants/inviteProfileMessages';

import {
  useRequestAccountForm_RolesByAccountTypeLazyQuery,
  useRequestAccountForm_AccountTypesQuery,
  useRequestAccountForm_TagsByAccountTypeLazyQuery,
  RequestAccountForm_TagsByAccountTypeFragment,
} from 'src/operations/generated/graphql';

gql`
  fragment RequestAccountForm_GetAccountType on AccountTypeProjection {
    id
    name
    checkType
  }
`;

gql`
  fragment RequestAccountForm_GetRolesByAccountType on RoleProjection {
    id
    name
    description
  }
`;
gql`
  fragment RequestAccountForm_TagsByAccountType on TagProjection {
    id
    name
  }
`;

gql`
  query RequestAccountForm_RolesByAccountType($accountTypeId: String!) {
    rolesByAccountType(accountTypeId: $accountTypeId) {
      id
      name
      description
    }
  }
`;

gql`
  query RequestAccountForm_AccountTypes {
    accountTypes {
      ...RequestAccountForm_GetAccountType
    }
  }
`;

gql`
  query RequestAccountForm_TagsByAccountType($accountTypeId: String!) {
    tagsByAccountType(accountTypeId: $accountTypeId) {
      ...RequestAccountForm_TagsByAccountType
    }
  }
`;

interface ICreateProfileForm {
  initialValue: Partial<IRequestAccount>;
  onSubmit: (data: IRequestAccount) => void;
  loading: boolean;
}

export const RequestAccountForm: FC<ICreateProfileForm> = React.forwardRef(
  ({ initialValue, onSubmit, loading }, ref) => {
    const [accountTypesOptions, setAccountTypesOptions] = useState(
      [] as DropDownOptions[],
    );
    const [checkedRoles, setCheckedRoles] = useState([] as string[]);
    const [checkType, setCheckType] = useState<null | string>(null);
    const [checkedTags, setCheckedTags] = useState([] as string[]);

    const [allTags, setAllTags] = useState<
      RequestAccountForm_TagsByAccountTypeFragment[]
    >([]);

    const { data: { accountTypes } = { accountTypes: [] } } =
      useRequestAccountForm_AccountTypesQuery();

    const [getRolesByAccountType, { data: { rolesByAccountType = [] } = {} }] =
      useRequestAccountForm_RolesByAccountTypeLazyQuery();

    const [geTagsAccountType, { data: { tagsByAccountType = null } = {} }] =
      useRequestAccountForm_TagsByAccountTypeLazyQuery();

    const getTagOptions = () => {
      return allTags.map((tag: any) => ({
        label: tag.name,
        value: tag.id,
        defaultChecked: false,
      }));
    };
    const handleTagsChecked = async (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const { value, checked } = e.target;

      if (checked) {
        const updatedTags = [...checkedTags, value];
        setCheckedTags(updatedTags);
        setValue('tags', updatedTags);
      } else {
        const updatedTags = checkedTags.filter(item => item !== value);
        setCheckedTags(updatedTags);
        setValue('tags', updatedTags);
      }

      await trigger();
    };

    const defaultAccountTypeOption =
      initialValue?.accountTypeId &&
      getDefaultOption(accountTypesOptions, initialValue.accountTypeId);

    const getRoleOptions = () => {
      return rolesByAccountType.map((role: any) => ({
        label: role.name,
        value: role.id,
        defaultChecked: initialValue?.roles?.includes(role.id) || false,
      }));
    };

    const handleRolesChecked = async (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const { value, checked } = e.target;

      if (checked) {
        const updatedRoles = [...checkedRoles, value];
        setCheckedRoles(updatedRoles);
        setValue('roles', updatedRoles);
      } else {
        const updatedRoles = checkedRoles.filter(item => item !== value);
        setCheckedRoles(updatedRoles);
        setValue('roles', updatedRoles);
      }

      await trigger();
    };

    const {
      handleSubmit,
      formState: { errors, isValid, isDirty },
      control,
      setValue,
      trigger,
      watch,
    } = useForm({
      resolver: yupResolver(requestAccountSchema),
      mode: 'onChange',
    });

    const watchAccountTypeId = watch('accountTypeId');

    const watchIsPartner = watch('isPartner');

    useEffect(() => {
      if (initialValue?.isPartner) {
        setValue('isPartner', initialValue?.isPartner);
      }

      if (initialValue?.accountTypeId) {
        setValue('accountTypeId', initialValue?.accountTypeId);
      }

      if (initialValue?.roles) {
        setCheckedRoles(initialValue?.roles);
        setValue('roles', initialValue?.roles);
      }
    }, []);

    useEffect(() => {
      if (tagsByAccountType) {
        setAllTags(tagsByAccountType);
      }
    }, [tagsByAccountType]);

    useEffect(() => {
      const options = accountTypes.map(type => ({
        label: type.name,
        value: type.id,
      }));
      setAccountTypesOptions(options);
    }, [accountTypes]);

    useEffect(() => {
      if (watchAccountTypeId) {
        setCheckType(
          accountTypes.find(
            accountType => accountType.id === watchAccountTypeId,
          )?.checkType || null,
        );
        getRolesByAccountType({
          variables: { accountTypeId: watchAccountTypeId },
        });
        geTagsAccountType({
          variables: { accountTypeId: watchAccountTypeId },
        });
      } else {
        setCheckType(null);
        setAllTags([]);
        setCheckedTags([]);
      }
      setValue('tags', []);
      setValue('roles', []);
      isDirty && trigger();
    }, [watchAccountTypeId]);

    const submitHandler = (formData: any) => {
      onSubmit({
        ...formData,
        roles: checkedRoles,
        tags: checkedTags,
      });
    };

    return (
      <>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Card>
            <h4>Account Details</h4>

            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='name'
                  control={control}
                  defaultValue={initialValue?.name}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      error={errors?.name?.message}
                      placeholder='Add account name here'
                      label='What would you like to name the account?'
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>

            <GridWrapper>
              <GridItem size={{ md: '1/3' }}>
                <Controller
                  name='jobTitle'
                  control={control}
                  defaultValue={initialValue?.jobTitle}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      error={errors?.jobTitle?.message}
                      placeholder='Add job title name here'
                      label='What is your job title?'
                    />
                  )}
                />
              </GridItem>
            </GridWrapper>

            {accountTypesOptions.length > 0 && (
              <GridWrapper>
                <GridItem size={{ md: '1/3' }}>
                  <Controller
                    name='accountTypeId'
                    control={control}
                    render={({ field }) => (
                      <AutocompleteField
                        {...field}
                        ref={ref}
                        error={errors?.accountTypeId?.message}
                        label='Please select an account type'
                        options={accountTypesOptions}
                        defaultSelectedOption={defaultAccountTypeOption}
                        placeholder='Enter'
                        onSelect={async (selected: any) => {
                          setValue('accountTypeId', selected?.value || '');
                          selected && (await trigger('accountTypeId'));
                          if (selected?.value !== initialValue.accountTypeId) {
                            setCheckedRoles([]);
                            setCheckedTags([]);
                            setValue('tags', []);
                          }
                        }}
                      />
                    )}
                  />
                </GridItem>
              </GridWrapper>
            )}

            {checkType && (
              <>
                {rolesByAccountType.length > 0 && (
                  <GridWrapper>
                    <GridItem size={{ md: '1/3' }}>
                      <Controller
                        name='roles'
                        control={control}
                        render={({ field }) => (
                          <CheckboxField
                            label='Roles'
                            options={getRoleOptions()}
                            error={errors?.roles?.message}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleRolesChecked(e);
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </GridItem>
                  </GridWrapper>
                )}

                {allTags.length > 0 && (
                  <GridWrapper>
                    <GridItem size={{ md: '1/3' }}>
                      <Controller
                        name='tags'
                        control={control}
                        render={({ field }) => (
                          <CheckboxField
                            label='Tags'
                            options={getTagOptions()}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleTagsChecked(e);
                            }}
                            fullWidth
                          />
                        )}
                      />
                      {checkedTags.length === 0 && (
                        <div
                          className='ln-c-field-info ln-c-field-info--error'
                          role='alert'
                          aria-live='assertive'
                        >
                          {InviteProfileMessages.tagIds.required}
                        </div>
                      )}
                    </GridItem>
                  </GridWrapper>
                )}
                <GridWrapper>
                  <GridItem size={{ md: '1/3' }}>
                    <Controller
                      name='isPartner'
                      control={control}
                      render={({ field }) => (
                        <RadioButtonField
                          label={
                            checkType === 'ORGANISATION'
                              ? 'Are you an Agent?'
                              : 'Are you a partner?'
                          }
                          info={
                            checkType === 'ORGANISATION'
                              ? 'Do you work for an agency/distributor on behalf of one of our suppliers?'
                              : "Do you work for a partner of Sainsbury's?"
                          }
                          {...field}
                          onChange={field.onChange}
                          error={errors?.isPartner?.message}
                          name='radio-button-field-1'
                          fullWidth
                          options={[
                            { value: 'yes', label: 'Yes' },
                            { value: 'no', label: 'No' },
                          ]}
                        />
                      )}
                    />
                  </GridItem>
                </GridWrapper>

                {watchIsPartner === 'yes' && (
                  <>
                    <GridWrapper>
                      <GridItem size={{ md: '1/3' }}>
                        <Controller
                          name='partnerName'
                          control={control}
                          defaultValue={initialValue?.partnerName}
                          render={({ field }) => (
                            <TextInputField
                              {...field}
                              onChange={field.onChange}
                              error={errors?.partnerName?.message}
                              label='What’s the name of your organisation?'
                            />
                          )}
                        />
                      </GridItem>
                    </GridWrapper>

                    <GridWrapper>
                      <GridItem size={{ md: '1/3' }}>
                        <Controller
                          name='partnerContact'
                          control={control}
                          defaultValue={initialValue?.partnerContact}
                          render={({ field }) => (
                            <TextInputField
                              {...field}
                              onChange={field.onChange}
                              error={errors?.partnerContact?.message}
                              label='What’s the name of your Sainsbury’s contact?'
                            />
                          )}
                        />
                      </GridItem>
                    </GridWrapper>
                  </>
                )}

                {checkType === 'ORGANISATION' && (
                  <GridWrapper>
                    <GridItem size={{ md: '1/3' }}>
                      <Controller
                        name='siteId'
                        control={control}
                        defaultValue={initialValue?.siteId}
                        render={({ field }) => (
                          <TextInputField
                            {...field}
                            onChange={field.onChange}
                            error={errors?.name?.message}
                            placeholder='Please enter'
                            label='Supplier ID number'
                            info="Please provide one of your Sainsbury's supplier code below, so we can link your account to your company. You can list either the alphanumeric version (S1234) or the numeric-only version (191234) of your supplier code. If you have more than one code, you can add those to your account later."
                          />
                        )}
                      />
                    </GridItem>
                  </GridWrapper>
                )}
              </>
            )}
            <Button
              disabled={
                !isValid ||
                loading ||
                (allTags.length > 0 && checkedTags.length === 0)
              }
              type='submit'
              variant='filled'
              className='add-profile'
            >
              {loading && (
                <ProgressSpinner size='icon' className='ln-u-push-right-sm' />
              )}
              Request Account
            </Button>
          </Card>
        </form>
      </>
    );
  },
);
