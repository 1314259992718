import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  link__Import: { input: any; output: any };
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID']['output'];
};

export type AccountApplicationGroupProjection = {
  __typename?: 'AccountApplicationGroupProjection';
  applications: Array<AccountGroupToApplicationRelationship>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AccountApplicationsGroups = {
  applicationIds: Array<Scalars['String']['input']>;
  groupName: Scalars['String']['input'];
};

export type AccountDetails = {
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AccountGroupToApplicationRelationship = {
  __typename?: 'AccountGroupToApplicationRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: ApplicationProjection;
};

export type AccountProfileGroupProjection = {
  __typename?: 'AccountProfileGroupProjection';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profiles: Array<AccountToProfileRelationship>;
};

export type AccountProfilesGroup = {
  groupName: Scalars['String']['input'];
  profileIds: Array<Scalars['String']['input']>;
};

export type AccountProjection = {
  __typename?: 'AccountProjection';
  accountSetup?: Maybe<Scalars['Boolean']['output']>;
  accountType: AccountTypeProjection;
  accountTypeId?: Maybe<Scalars['String']['output']>;
  applicationGroups: Array<AccountApplicationGroupProjection>;
  id: Scalars['ID']['output'];
  isPartner?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organisationGroups: Array<OrganisationGroupProjection>;
  partnerContact?: Maybe<Scalars['String']['output']>;
  partnerName?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<AccountToProfileRelationship>;
  profileGroups: Array<AccountProfileGroupProjection>;
  profileId?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  roles: Array<AccountToRoleRelationship>;
  sites: Array<AccountToSiteRelationship>;
  status?: Maybe<Scalars['String']['output']>;
  statusChangedAt?: Maybe<Scalars['String']['output']>;
  tags: Array<AccountToTagRelationship>;
};

export type AccountRequest = {
  accountTypeId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  isPartner: Scalars['Boolean']['input'];
  jobTitle: Scalars['String']['input'];
  name: Scalars['String']['input'];
  partnerContact?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  profileId: Scalars['String']['input'];
  roleIds: Array<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  tagIds: Array<Scalars['String']['input']>;
};

export type AccountToProfileRelationship = {
  __typename?: 'AccountToProfileRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: ProfileProjection;
};

export type AccountToRoleRelationship = {
  __typename?: 'AccountToRoleRelationship';
  label?: Maybe<Scalars['String']['output']>;
  status: RoleStatusEnum;
  value: RoleProjection;
};

export type AccountToSiteRelationship = {
  __typename?: 'AccountToSiteRelationship';
  label?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AssociationStatus>;
  value: SiteProjection;
};

export type AccountToTagRelationship = {
  __typename?: 'AccountToTagRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: TagProjection;
};

export type AccountType = {
  __typename?: 'AccountType';
  id: Scalars['ID']['output'];
};

export enum AccountTypeCheckEnum {
  Domain = 'DOMAIN',
  Organisation = 'ORGANISATION',
}

export type AccountTypeCreation = {
  admins?: InputMaybe<Array<Scalars['String']['input']>>;
  approvedDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  approvers?: InputMaybe<Array<Scalars['String']['input']>>;
  audiences?: InputMaybe<Array<Scalars['String']['input']>>;
  checkType: AccountTypeCheckEnum;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rejectedDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AccountTypeDomainInput = {
  domain: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type AccountTypeProjection = {
  __typename?: 'AccountTypeProjection';
  admins: Array<AccountTypeToAccountRelationship>;
  approvedDomains: Array<Scalars['String']['output']>;
  approvers: Array<AccountTypeToAccountRelationship>;
  checkType: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isApprover: Scalars['Boolean']['output'];
  linkedApplications?: Maybe<Array<AccountTypeToApplicationRelationship>>;
  name: Scalars['String']['output'];
  rejectedDomains: Array<Scalars['String']['output']>;
  tags: Array<AccountTypeToTagRelationship>;
};

export type AccountTypeToAccountRelationship = {
  __typename?: 'AccountTypeToAccountRelationship';
  label?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AssociationStatus>;
  value: AccountProjection;
};

export type AccountTypeToApplicationRelationship = {
  __typename?: 'AccountTypeToApplicationRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: ApplicationProjection;
};

export type AccountTypeToTagRelationship = {
  __typename?: 'AccountTypeToTagRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: TagProjection;
};

export type Application = {
  __typename?: 'Application';
  id?: Maybe<Scalars['String']['output']>;
};

export type ApplicationCreation = {
  acronym: Scalars['String']['input'];
  admins?: InputMaybe<Array<Scalars['String']['input']>>;
  approvers?: InputMaybe<Array<Scalars['String']['input']>>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isRoot?: InputMaybe<Scalars['Boolean']['input']>;
  linkedApplications?: InputMaybe<Array<Scalars['String']['input']>>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  prospectiveAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  proxyLocation?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  type: ApplicationType;
};

export enum ApplicationDeletedOptions {
  AlreadyExists = 'ALREADY_EXISTS',
  UserRemoved = 'USER_REMOVED',
}

export type ApplicationProjection = {
  __typename?: 'ApplicationProjection';
  acronym?: Maybe<Scalars['String']['output']>;
  admins: Array<ApplicationToAccountRelationship>;
  approvers: Array<ApplicationToAccountRelationship>;
  id: Scalars['ID']['output'];
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isRoot?: Maybe<Scalars['Boolean']['output']>;
  linkedApplications?: Maybe<Array<LinkedApplicationsRelationship>>;
  longDescription?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  prospectiveAllowed?: Maybe<Scalars['Boolean']['output']>;
  proxyLocation?: Maybe<Scalars['String']['output']>;
  restricted?: Maybe<Scalars['Boolean']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  tags: Array<ApplicationToTagRelationship>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ApplicationToAccountRelationship = {
  __typename?: 'ApplicationToAccountRelationship';
  label?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AssociationStatus>;
  value: AccountProjection;
};

export type ApplicationToTagRelationship = {
  __typename?: 'ApplicationToTagRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: TagProjection;
};

export enum ApplicationType {
  Ext = 'EXT',
  Service = 'SERVICE',
  Ui = 'UI',
}

export type AssociateAccountToPermissionGroupsInput = {
  accountId: Scalars['ID']['input'];
  groupIds: Array<Scalars['ID']['input']>;
};

export type AssociateAccountToPermissionGroupsPayload = {
  __typename?: 'AssociateAccountToPermissionGroupsPayload';
  accountId: Scalars['ID']['output'];
  associatedGroups: Array<Permission>;
};

export type AssociateAccountToPermissionsInput = {
  accountId: Scalars['ID']['input'];
  permissionIds: Array<Scalars['ID']['input']>;
};

export type AssociateAccountToPermissionsPayload = {
  __typename?: 'AssociateAccountToPermissionsPayload';
  accountId: Scalars['ID']['output'];
  associatedPermissions: Array<Permission>;
};

export type AssociateGroupsToRoleInput = {
  groupIds: Array<Scalars['String']['input']>;
  roleId: Scalars['String']['input'];
};

export type AssociatePermissionToRolesInput = {
  permissionId: Scalars['ID']['input'];
  roleIds: Array<Scalars['ID']['input']>;
};

export type AssociatePermissionsToGroupInput = {
  applicationId: Scalars['String']['input'];
  /** Group ID. Required. */
  groupId: Scalars['ID']['input'];
  permissionIds: Array<Scalars['ID']['input']>;
};

export type AssociatePermissionsToGroupPayload = {
  __typename?: 'AssociatePermissionsToGroupPayload';
  associatedPermissions: Array<Scalars['String']['output']>;
  groupId: Scalars['ID']['output'];
};

export type AssociatePermissionsToRoleInput = {
  permissionIds: Array<Scalars['ID']['input']>;
  roleId: Scalars['ID']['input'];
};

export enum AssociationStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED',
  Revoked = 'REVOKED',
}

export type DeleteApplicationReason = {
  reason: ApplicationDeletedOptions;
};

export type DissociateAccountFromPermissionGroupsInput = {
  accountId: Scalars['ID']['input'];
  groupIds: Array<Scalars['ID']['input']>;
};

export type DissociateAccountFromPermissionGroupsPayload = {
  __typename?: 'DissociateAccountFromPermissionGroupsPayload';
  accountId: Scalars['ID']['output'];
  dissociatedGroups: Array<Permission>;
};

export type DissociateAccountFromPermissionsInput = {
  accountId: Scalars['ID']['input'];
  permissionIds: Array<Scalars['ID']['input']>;
};

export type DissociateAccountFromPermissionsPayload = {
  __typename?: 'DissociateAccountFromPermissionsPayload';
  accountId: Scalars['ID']['output'];
  dissociatedPermissions: Array<Permission>;
};

export type DissociateGroupsFromRoleInput = {
  groupIds: Array<Scalars['String']['input']>;
  roleId: Scalars['String']['input'];
};

export type DissociatePermissionFromRolesInput = {
  permissionId: Scalars['ID']['input'];
  roleIds: Array<Scalars['ID']['input']>;
};

export type DissociatePermissionsFromGroupInput = {
  applicationId: Scalars['String']['input'];
  /** Group ID. Required. */
  groupId: Scalars['ID']['input'];
  permissionIds: Array<Scalars['ID']['input']>;
};

export type DissociatePermissionsFromGroupPayload = {
  __typename?: 'DissociatePermissionsFromGroupPayload';
  dissociatedPermissions: Array<Scalars['String']['output']>;
  groupId: Scalars['ID']['output'];
};

export type DissociatePermissionsFromRoleInput = {
  permissionIds: Array<Scalars['ID']['input']>;
  roleId: Scalars['ID']['input'];
};

export type EditApplicationDetails = {
  acronym?: InputMaybe<Scalars['String']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
};

export type FilteredAccountProjection = {
  __typename?: 'FilteredAccountProjection';
  accounts: Array<AccountProjection>;
  count: Scalars['Float']['output'];
  page: Scalars['Float']['output'];
  perPage: Scalars['Float']['output'];
};

export type FilteredGroupsProjection = {
  __typename?: 'FilteredGroupsProjection';
  count: Scalars['Float']['output'];
  groups: Array<OrganisationGroupProjection>;
  page: Scalars['Float']['output'];
  perPage: Scalars['Float']['output'];
};

export type FilteredHierarchyProjection = {
  __typename?: 'FilteredHierarchyProjection';
  count: Scalars['Float']['output'];
  hierarchies: Array<HierarchyProjection>;
  page: Scalars['Float']['output'];
  perPage: Scalars['Float']['output'];
};

export type FilteredProfileProjection = {
  __typename?: 'FilteredProfileProjection';
  count: Scalars['Float']['output'];
  page: Scalars['Float']['output'];
  perPage: Scalars['Float']['output'];
  profiles: Array<ProfileProjection>;
};

export type FilteredSitesProjection = {
  __typename?: 'FilteredSitesProjection';
  count: Scalars['Float']['output'];
  page: Scalars['Float']['output'];
  perPage: Scalars['Float']['output'];
  sites: Array<SiteProjection>;
};

export type GetPermissionsIdsByApplication = {
  __typename?: 'GetPermissionsIdsByApplication';
  permissions: Array<Scalars['String']['output']>;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID']['output'];
};

export type GroupCreation = {
  applicationId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GroupUpdate = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type HierarchyProjection = {
  __typename?: 'HierarchyProjection';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  parentLevel?: Maybe<Scalars['String']['output']>;
};

export type Indirect = {
  __typename?: 'Indirect';
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type InvitationRequestArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LinkedApplicationsRelationship = {
  __typename?: 'LinkedApplicationsRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: ApplicationProjection;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTAndC: Scalars['String']['output'];
  accountUpdateDetails: Account;
  accountUpdateName: Account;
  addApplicationAudienceForAccountType: AccountType;
  addApplicationsToMyGroup: Account;
  addApprovedDomainToAccountType: AccountType;
  addApprovedDomainToSite: Organisation;
  addProfilesToMyGroup: Account;
  addRejectedDomainToAccountType: AccountType;
  addRejectedDomainToSite: Organisation;
  approveAccessToApplication: Application;
  approveAccountTypeForAccount: AccountType;
  /** Approve an account's request for Permission Group association. Only usable by authorized users. */
  approvePermissionGroupRequest: PermissionGroupsRequestPayload;
  /** Approve permission requests for an account. Only usable by authorised users */
  approvePermissionRequest?: Maybe<PermissionsRequestPayload>;
  approveRolesRequest: Account;
  /** Creates an association between a list of group ids and a specific account id */
  associateAccountToPermissionGroups?: Maybe<AssociateAccountToPermissionGroupsPayload>;
  /** Creates an association between a list of permission ids and a specific account id */
  associateAccountToPermissions?: Maybe<AssociateAccountToPermissionsPayload>;
  associateAccountToSites: Account;
  associateAdminsToAccountType: AccountType;
  associateAdminsToApplication: Application;
  associateApproversToAccountType: AccountType;
  associateApproversToApplication: Application;
  associateDependentApplications: Application;
  /** Creates an association between a list of role ids and a specific group id */
  associateGroupsToRole?: Maybe<Role>;
  /** Creates an association between a list of role ids and a specific permission id */
  associatePermissionToRoles?: Maybe<Array<Scalars['String']['output']>>;
  /** Add permissions to a permission group */
  associatePermissionsToGroup?: Maybe<AssociatePermissionsToGroupPayload>;
  /** Creates an association between a list of permission ids and a specific role id */
  associatePermissionsToRole?: Maybe<Role>;
  associateTagsToAccount: Account;
  associateTagsToAccountType: AccountType;
  associateTagsToApplication: Application;
  completeAccount: Account;
  completeProfileInvitation: Profile;
  createAccountType: AccountType;
  createApplication: Application;
  /** Creates a Group */
  createGroup?: Maybe<Group>;
  /** Creates a Permission */
  createPermission?: Maybe<Permission>;
  /** Creates a Role */
  createRole?: Maybe<Role>;
  createTag: TagReturnType;
  /** Decline an account's request for Permission Group association. Only usable by authorized users. */
  declinePermissionGroupRequest: PermissionGroupsRequestPayload;
  /** Decline permission requests for an account. Only usable by authorised users */
  declinePermissionRequest?: Maybe<PermissionsRequestPayload>;
  declineRolesRequest: Account;
  deleteAccount: Account;
  deleteAccountType: AccountType;
  deleteApplication: Application;
  deleteApplicationGroup: Account;
  /** Delete a Group */
  deleteGroup?: Maybe<Group>;
  /** Delete a Permission */
  deletePermission?: Maybe<Permission>;
  /** Delete a Role */
  deleteRoles?: Maybe<Array<Scalars['String']['output']>>;
  deleteTag: TagReturnType;
  /** Removed an association between a list of group ids and a specific account id */
  dissociateAccountFromPermissionGroups?: Maybe<DissociateAccountFromPermissionGroupsPayload>;
  /** Removed an association between a list of permission ids and a specific account id */
  dissociateAccountFromPermissions?: Maybe<DissociateAccountFromPermissionsPayload>;
  dissociateAccountFromRoles: Account;
  dissociateAccountFromSites: Account;
  dissociateAdminFromAccountType: AccountType;
  dissociateAdminsFromApplication: Application;
  dissociateApproverFromAccountType: AccountType;
  dissociateApproversFromApplication: Application;
  dissociateDependentApplications: Application;
  /** Removed an association between a list of permission ids and a specific group id */
  dissociateGroupsFromRole?: Maybe<Role>;
  /** Remove an association between a list of role ids and a specific permission id */
  dissociatePermissionFromRoles?: Maybe<Array<Scalars['String']['output']>>;
  /** Removes permissions from a permission group */
  dissociatePermissionsFromGroup?: Maybe<DissociatePermissionsFromGroupPayload>;
  /** Removed an association between a list of permission ids and a specific role id */
  dissociatePermissionsFromRole?: Maybe<Role>;
  dissociateTagFromAccountType: AccountType;
  dissociateTagsFromAccount: Account;
  dissociateTagsFromApplication: Application;
  inviteProfile: Profile;
  markAllNotificationsAsRead: Scalars['Boolean']['output'];
  markNotificationAsRead: UnreadNotificationCountProjection;
  rejectAccessToApplication: Application;
  rejectAccountTypeForAccount: AccountType;
  removeApplicationAudienceForAccountType: AccountType;
  removeApplicationsFromMyGroup: Account;
  removeApprovedDomainFromAccountType: AccountType;
  removeApprovedDomainsFromSite: Organisation;
  removeProfileAccess: Profile;
  removeProfilesFromMyGroup: Account;
  removeRejectedDomainFromAccountType: AccountType;
  removeRejectedDomainsFromSite: Organisation;
  requestAccessToApplication: Application;
  requestAccount: Account;
  /** Request a set of permission groups for an account */
  requestPermissionGroups?: Maybe<PermissionGroupsRequestPayload>;
  /** Request a set of permissions for an account */
  requestPermissions?: Maybe<PermissionsRequestPayload>;
  requestProfile: Profile;
  requestRoles: Account;
  resyncProfileEmail: Profile;
  revokeAccessToApplication: Application;
  revokeProfileInvitation: Profile;
  roleAddApplicationsAudience: Role;
  roleAddTags: Role;
  roleRemoveApplicationsAudience: Role;
  roleRemoveTags: Role;
  roleUpdateHiddenForRequests: Role;
  siteAddApprovedAccounts: Organisation;
  siteRejectAccountRequest: Organisation;
  siteRemoveApprovedAccount: Organisation;
  takeOver: UnreadNotificationCountProjection;
  unassignNotification: UnreadNotificationCountProjection;
  updateAccountTypeDescription: AccountType;
  updateApplicationDetails: Application;
  updateApplicationIsLive: Application;
  updateApplicationProspectiveAllowed: Application;
  updateApplicationProxyLocation: Application;
  updateApplicationRestricted: Application;
  /** Update a Group */
  updateGroup?: Maybe<Group>;
  /** Update a Permission */
  updatePermission?: Maybe<Permission>;
  updateProfile: Profile;
  updateProfileEmailAddress: Profile;
  /** Update a Role */
  updateRole?: Maybe<Role>;
  /** Update a Tag */
  updateTagDescription: TagReturnType;
};

export type MutationAcceptTAndCArgs = {
  supplierCode: Scalars['String']['input'];
};

export type MutationAccountUpdateDetailsArgs = {
  id: Scalars['String']['input'];
  input: AccountDetails;
};

export type MutationAccountUpdateNameArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationAddApplicationAudienceForAccountTypeArgs = {
  accountTypeId: Scalars['String']['input'];
  applicationIds: Array<Scalars['String']['input']>;
};

export type MutationAddApplicationsToMyGroupArgs = {
  input: AccountApplicationsGroups;
};

export type MutationAddApprovedDomainToAccountTypeArgs = {
  domain: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationAddApprovedDomainToSiteArgs = {
  domain: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationAddProfilesToMyGroupArgs = {
  input: AccountProfilesGroup;
};

export type MutationAddRejectedDomainToAccountTypeArgs = {
  domain: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationAddRejectedDomainToSiteArgs = {
  domain: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationApproveAccessToApplicationArgs = {
  accountId: Scalars['String']['input'];
  applicationId: Scalars['String']['input'];
};

export type MutationApproveAccountTypeForAccountArgs = {
  accountId: Scalars['String']['input'];
  accountTypeId: Scalars['String']['input'];
};

export type MutationApprovePermissionGroupRequestArgs = {
  input: PermissionGroupRequestInput;
};

export type MutationApprovePermissionRequestArgs = {
  input: PermissionRequestInput;
};

export type MutationApproveRolesRequestArgs = {
  input: RolesRequestInput;
};

export type MutationAssociateAccountToPermissionGroupsArgs = {
  input: AssociateAccountToPermissionGroupsInput;
};

export type MutationAssociateAccountToPermissionsArgs = {
  input: AssociateAccountToPermissionsInput;
};

export type MutationAssociateAccountToSitesArgs = {
  id: Scalars['String']['input'];
  siteIds: Array<Scalars['String']['input']>;
};

export type MutationAssociateAdminsToAccountTypeArgs = {
  accountIds: Array<Scalars['String']['input']>;
  accountTypeId: Scalars['String']['input'];
};

export type MutationAssociateAdminsToApplicationArgs = {
  accountIds: Array<Scalars['String']['input']>;
  applicationId: Scalars['String']['input'];
};

export type MutationAssociateApproversToAccountTypeArgs = {
  accountIds: Array<Scalars['String']['input']>;
  accountTypeId: Scalars['String']['input'];
};

export type MutationAssociateApproversToApplicationArgs = {
  accountIds: Array<Scalars['String']['input']>;
  applicationId: Scalars['String']['input'];
};

export type MutationAssociateDependentApplicationsArgs = {
  applicationId: Scalars['String']['input'];
  dependentApplicationIds: Array<Scalars['String']['input']>;
};

export type MutationAssociateGroupsToRoleArgs = {
  input: AssociateGroupsToRoleInput;
};

export type MutationAssociatePermissionToRolesArgs = {
  input: AssociatePermissionToRolesInput;
};

export type MutationAssociatePermissionsToGroupArgs = {
  input: AssociatePermissionsToGroupInput;
};

export type MutationAssociatePermissionsToRoleArgs = {
  input: AssociatePermissionsToRoleInput;
};

export type MutationAssociateTagsToAccountArgs = {
  accountId: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
};

export type MutationAssociateTagsToAccountTypeArgs = {
  accountTypeId: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
};

export type MutationAssociateTagsToApplicationArgs = {
  id: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
};

export type MutationCompleteAccountArgs = {
  name: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
};

export type MutationCompleteProfileInvitationArgs = {
  id: Scalars['String']['input'];
  profile: ProfileUpdate;
};

export type MutationCreateAccountTypeArgs = {
  type: AccountTypeCreation;
};

export type MutationCreateApplicationArgs = {
  application: ApplicationCreation;
};

export type MutationCreateGroupArgs = {
  group: GroupCreation;
};

export type MutationCreatePermissionArgs = {
  permission: PermissionCreation;
};

export type MutationCreateRoleArgs = {
  role: RoleCreation;
};

export type MutationCreateTagArgs = {
  tag: TagCreation;
};

export type MutationDeclinePermissionGroupRequestArgs = {
  input: PermissionGroupRequestInput;
};

export type MutationDeclinePermissionRequestArgs = {
  input: PermissionRequestInput;
};

export type MutationDeclineRolesRequestArgs = {
  input: RolesRequestInput;
};

export type MutationDeleteAccountArgs = {
  accountId: Scalars['String']['input'];
};

export type MutationDeleteAccountTypeArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteApplicationArgs = {
  id: Scalars['String']['input'];
  input: DeleteApplicationReason;
};

export type MutationDeleteApplicationGroupArgs = {
  applicationGroupId: Scalars['String']['input'];
};

export type MutationDeleteGroupArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeletePermissionArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteRolesArgs = {
  roleIds: Array<Scalars['String']['input']>;
};

export type MutationDeleteTagArgs = {
  id: Scalars['String']['input'];
};

export type MutationDissociateAccountFromPermissionGroupsArgs = {
  input: DissociateAccountFromPermissionGroupsInput;
};

export type MutationDissociateAccountFromPermissionsArgs = {
  input: DissociateAccountFromPermissionsInput;
};

export type MutationDissociateAccountFromRolesArgs = {
  id: Scalars['String']['input'];
  roleIds: Array<Scalars['String']['input']>;
};

export type MutationDissociateAccountFromSitesArgs = {
  id: Scalars['String']['input'];
  siteIds: Array<Scalars['String']['input']>;
};

export type MutationDissociateAdminFromAccountTypeArgs = {
  accountId: Scalars['String']['input'];
  accountTypeId: Scalars['String']['input'];
};

export type MutationDissociateAdminsFromApplicationArgs = {
  accountIds: Array<Scalars['String']['input']>;
  applicationId: Scalars['String']['input'];
};

export type MutationDissociateApproverFromAccountTypeArgs = {
  accountId: Scalars['String']['input'];
  accountTypeId: Scalars['String']['input'];
};

export type MutationDissociateApproversFromApplicationArgs = {
  accountIds: Array<Scalars['String']['input']>;
  applicationId: Scalars['String']['input'];
};

export type MutationDissociateDependentApplicationsArgs = {
  applicationId: Scalars['String']['input'];
  dependentApplicationIds: Array<Scalars['String']['input']>;
};

export type MutationDissociateGroupsFromRoleArgs = {
  input: DissociateGroupsFromRoleInput;
};

export type MutationDissociatePermissionFromRolesArgs = {
  input: DissociatePermissionFromRolesInput;
};

export type MutationDissociatePermissionsFromGroupArgs = {
  input: DissociatePermissionsFromGroupInput;
};

export type MutationDissociatePermissionsFromRoleArgs = {
  input: DissociatePermissionsFromRoleInput;
};

export type MutationDissociateTagFromAccountTypeArgs = {
  accountTypeId: Scalars['String']['input'];
  tagId: Scalars['String']['input'];
};

export type MutationDissociateTagsFromAccountArgs = {
  accountId: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
};

export type MutationDissociateTagsFromApplicationArgs = {
  id: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
};

export type MutationInviteProfileArgs = {
  id: Scalars['String']['input'];
  options: InvitationRequestArgs;
};

export type MutationMarkNotificationAsReadArgs = {
  entityId: Scalars['String']['input'];
};

export type MutationRejectAccessToApplicationArgs = {
  accountId: Scalars['String']['input'];
  applicationId: Scalars['String']['input'];
};

export type MutationRejectAccountTypeForAccountArgs = {
  reject: ReasonInput;
};

export type MutationRemoveApplicationAudienceForAccountTypeArgs = {
  accountTypeId: Scalars['String']['input'];
  applicationId: Scalars['String']['input'];
};

export type MutationRemoveApplicationsFromMyGroupArgs = {
  input: AccountApplicationsGroups;
};

export type MutationRemoveApprovedDomainFromAccountTypeArgs = {
  domains: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type MutationRemoveApprovedDomainsFromSiteArgs = {
  domains: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type MutationRemoveProfileAccessArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveProfilesFromMyGroupArgs = {
  input: AccountProfilesGroup;
};

export type MutationRemoveRejectedDomainFromAccountTypeArgs = {
  domains: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type MutationRemoveRejectedDomainsFromSiteArgs = {
  domains: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type MutationRequestAccessToApplicationArgs = {
  applicationId: Scalars['String']['input'];
};

export type MutationRequestAccountArgs = {
  account: AccountRequest;
};

export type MutationRequestPermissionGroupsArgs = {
  input: PermissionGroupRequestInput;
};

export type MutationRequestPermissionsArgs = {
  input: PermissionRequestInput;
};

export type MutationRequestProfileArgs = {
  profile: ProfileRequest;
};

export type MutationRequestRolesArgs = {
  input: RolesRequestInput;
};

export type MutationResyncProfileEmailArgs = {
  id: Scalars['String']['input'];
};

export type MutationRevokeAccessToApplicationArgs = {
  accountId: Scalars['String']['input'];
  applicationId: Scalars['String']['input'];
};

export type MutationRevokeProfileInvitationArgs = {
  data: RevokeInvitation;
  id: Scalars['String']['input'];
};

export type MutationRoleAddApplicationsAudienceArgs = {
  applicationIds: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type MutationRoleAddTagsArgs = {
  id: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
};

export type MutationRoleRemoveApplicationsAudienceArgs = {
  applicationIds: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type MutationRoleRemoveTagsArgs = {
  id: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
};

export type MutationRoleUpdateHiddenForRequestsArgs = {
  hiddenForRequests: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};

export type MutationSiteAddApprovedAccountsArgs = {
  accountIds: Array<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
};

export type MutationSiteRejectAccountRequestArgs = {
  reject: RejectInput;
};

export type MutationSiteRemoveApprovedAccountArgs = {
  accountId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type MutationTakeOverArgs = {
  entityId: Scalars['String']['input'];
};

export type MutationUnassignNotificationArgs = {
  entityId: Scalars['String']['input'];
};

export type MutationUpdateAccountTypeDescriptionArgs = {
  accountTypeId: Scalars['String']['input'];
  description: Scalars['String']['input'];
};

export type MutationUpdateApplicationDetailsArgs = {
  id: Scalars['String']['input'];
  input: EditApplicationDetails;
};

export type MutationUpdateApplicationIsLiveArgs = {
  id: Scalars['String']['input'];
  isLive: Scalars['Boolean']['input'];
};

export type MutationUpdateApplicationProspectiveAllowedArgs = {
  id: Scalars['String']['input'];
  prospectiveAllowed: Scalars['Boolean']['input'];
};

export type MutationUpdateApplicationProxyLocationArgs = {
  id: Scalars['String']['input'];
  proxyLocation: Scalars['String']['input'];
};

export type MutationUpdateApplicationRestrictedArgs = {
  id: Scalars['String']['input'];
  restricted: Scalars['Boolean']['input'];
};

export type MutationUpdateGroupArgs = {
  group: GroupUpdate;
  id: Scalars['String']['input'];
};

export type MutationUpdatePermissionArgs = {
  id: Scalars['String']['input'];
  permission: PermissionUpdate;
};

export type MutationUpdateProfileArgs = {
  id: Scalars['String']['input'];
  profile: ProfileUpdate;
};

export type MutationUpdateProfileEmailAddressArgs = {
  id: Scalars['String']['input'];
  input: ProfileUpdateEmailAddress;
};

export type MutationUpdateRoleArgs = {
  id: Scalars['String']['input'];
  role: RoleUpdate;
};

export type MutationUpdateTagDescriptionArgs = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type NotificationFilters = {
  targetId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationListProjection = {
  __typename?: 'NotificationListProjection';
  data: Array<NotificationProjection>;
  page: Scalars['Float']['output'];
  unreadCount: Scalars['Float']['output'];
};

export type NotificationProjection = {
  __typename?: 'NotificationProjection';
  assignedTo?: Maybe<AccountProjection>;
  assignedToId?: Maybe<Scalars['String']['output']>;
  audience: Scalars['String']['output'];
  audienceId: Scalars['String']['output'];
  author?: Maybe<ApplicationProjection>;
  authorId: Scalars['String']['output'];
  category: Scalars['String']['output'];
  context: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  entityId: Scalars['ID']['output'];
  heading: Scalars['String']['output'];
  message: Scalars['String']['output'];
  readIds?: Maybe<Array<Scalars['String']['output']>>;
  sourceId: Scalars['String']['output'];
  targetApplication?: Maybe<ApplicationProjection>;
  targetApplicationId?: Maybe<Scalars['String']['output']>;
  targetId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type Organisation = {
  __typename?: 'Organisation';
  id: Scalars['ID']['output'];
};

export type OrganisationGroupProjection = {
  __typename?: 'OrganisationGroupProjection';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sites: Array<OrganisationGroupToSiteRelationship>;
};

export type OrganisationGroupToSiteRelationship = {
  __typename?: 'OrganisationGroupToSiteRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: SiteProjection;
};

export type PaginatedGroupsInput = {
  identifier: Scalars['String']['input'];
  page: Scalars['Float']['input'];
  pageSize: Scalars['Float']['input'];
  value: Scalars['String']['input'];
};

export type PaginatedSitesInput = {
  identifier: Scalars['String']['input'];
  page: Scalars['Float']['input'];
  pageSize: Scalars['Float']['input'];
  value: Scalars['String']['input'];
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID']['output'];
};

export type PermissionCreation = {
  applicationId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type PermissionGroupProjection = {
  __typename?: 'PermissionGroupProjection';
  applicationId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissions: Array<PermissionGroupToPermissionRelationship>;
};

export type PermissionGroupRequestInput = {
  /** Account ID. Required. */
  accountId: Scalars['ID']['input'];
  ids: Array<Scalars['ID']['input']>;
};

export type PermissionGroupToPermissionRelationship = {
  __typename?: 'PermissionGroupToPermissionRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: PermissionProjection;
};

export type PermissionGroupsRequestPayload = {
  __typename?: 'PermissionGroupsRequestPayload';
  accountId: Scalars['ID']['output'];
  groups: Array<Group>;
};

export type PermissionProjection = {
  __typename?: 'PermissionProjection';
  applicationId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PermissionRequestInput = {
  /** Account ID. Required */
  accountId: Scalars['ID']['input'];
  ids: Array<Scalars['ID']['input']>;
};

export type PermissionUpdate = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PermissionsRequestPayload = {
  __typename?: 'PermissionsRequestPayload';
  accountId: Scalars['ID']['output'];
  permissions: Array<Permission>;
};

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['ID']['output'];
};

export enum ProfileInvitation {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export enum ProfileInvitationRevokedReason {
  InvitationExpired = 'INVITATION_EXPIRED',
  ManuallyRevoked = 'MANUALLY_REVOKED',
}

export type ProfileProjection = {
  __typename?: 'ProfileProjection';
  TAndCAgreed?: Maybe<Scalars['Boolean']['output']>;
  accounts: Array<ProfileToAccountRelationship>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitationStatus?: Maybe<ProfileInvitation>;
  lastName?: Maybe<Scalars['String']['output']>;
  preferredName?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  selectedAccountId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ProfileStatus>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ProfileRequest = {
  autoInvite: Scalars['Boolean']['input'];
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  preferredName?: InputMaybe<Scalars['String']['input']>;
  title: ProfileTitleOptions;
};

export enum ProfileStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED',
}

export enum ProfileTitleOptions {
  Dr = 'Dr',
  Miss = 'Miss',
  Mr = 'Mr',
  Mrs = 'Mrs',
  Ms = 'Ms',
  Mx = 'Mx',
}

export type ProfileToAccountRelationship = {
  __typename?: 'ProfileToAccountRelationship';
  label?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AssociationStatus>;
  value: AccountProjection;
};

export type ProfileUpdate = {
  TAndCAgreed?: InputMaybe<Scalars['Boolean']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<ProfileTitleOptions>;
};

export type ProfileUpdateEmailAddress = {
  emailAddress: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<AccountProjection>;
  accountPermissions?: Maybe<Array<PermissionProjection>>;
  accountType: AccountTypeProjection;
  accountTypes: Array<AccountTypeProjection>;
  accounts?: Maybe<FilteredAccountProjection>;
  accountsByPermissionId: Array<AccountProjection>;
  application?: Maybe<ApplicationProjection>;
  applicationPermissionGroups?: Maybe<Array<PermissionGroupProjection>>;
  applicationPermissions?: Maybe<Array<PermissionProjection>>;
  applications?: Maybe<Array<ApplicationProjection>>;
  getNotification?: Maybe<NotificationProjection>;
  getNotifications: NotificationListProjection;
  getPermissionsIdsByApplication?: Maybe<GetPermissionsIdsByApplication>;
  groupSites?: Maybe<Array<SiteProjection>>;
  myAccount: AccountProjection;
  myAdminApplications: Array<ApplicationProjection>;
  myApplications?: Maybe<Array<ApplicationProjection>>;
  myApplicationsByAccountTypeAndRole?: Maybe<Array<ApplicationProjection>>;
  myProfile?: Maybe<ProfileProjection>;
  myProfileAccounts: Array<AccountProjection>;
  mySites: Array<SiteProjection>;
  myTags: Array<TagProjection>;
  organisation?: Maybe<OrganisationGroupProjection>;
  paginatedGroups: FilteredGroupsProjection;
  paginatedHierarchies: FilteredHierarchyProjection;
  paginatedProfiles: FilteredProfileProjection;
  paginatedSites: FilteredSitesProjection;
  permission?: Maybe<PermissionProjection>;
  profile?: Maybe<ProfileProjection>;
  profileAccounts: Array<AccountProjection>;
  role: RoleProjection;
  roles?: Maybe<Array<RoleProjection>>;
  rolesByAccountType: Array<RoleProjection>;
  site?: Maybe<SiteProjection>;
  tag?: Maybe<TagProjection>;
  tags: Array<TagProjection>;
  tagsByAccountType: Array<TagProjection>;
};

export type QueryAccountArgs = {
  id: Scalars['String']['input'];
};

export type QueryAccountTypeArgs = {
  id: Scalars['String']['input'];
};

export type QueryAccountsArgs = {
  page: Scalars['Float']['input'];
  pageSize: Scalars['Float']['input'];
};

export type QueryAccountsByPermissionIdArgs = {
  permissionId: Scalars['String']['input'];
};

export type QueryApplicationArgs = {
  id: Scalars['String']['input'];
};

export type QueryApplicationPermissionGroupsArgs = {
  applicationId: Scalars['String']['input'];
};

export type QueryApplicationPermissionsArgs = {
  applicationId: Scalars['String']['input'];
};

export type QueryGetNotificationArgs = {
  entityId: Scalars['String']['input'];
};

export type QueryGetNotificationsArgs = {
  filters?: InputMaybe<NotificationFilters>;
  page: Scalars['Float']['input'];
};

export type QueryGroupSitesArgs = {
  groupId: Scalars['String']['input'];
};

export type QueryMyApplicationsArgs = {
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryOrganisationArgs = {
  id: Scalars['String']['input'];
};

export type QueryPaginatedGroupsArgs = {
  input: PaginatedGroupsInput;
};

export type QueryPaginatedHierarchiesArgs = {
  levels?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  searchTerms?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPaginatedProfilesArgs = {
  accountTypeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hierarchyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  roleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchTerms?: InputMaybe<Scalars['String']['input']>;
  siteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<ProfileStatus>>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryPaginatedSitesArgs = {
  input: PaginatedSitesInput;
};

export type QueryPermissionArgs = {
  id: Scalars['String']['input'];
};

export type QueryProfileArgs = {
  id: Scalars['String']['input'];
};

export type QueryProfileAccountsArgs = {
  profileId: Scalars['String']['input'];
};

export type QueryRoleArgs = {
  id: Scalars['String']['input'];
};

export type QueryRolesByAccountTypeArgs = {
  accountTypeId: Scalars['String']['input'];
};

export type QuerySiteArgs = {
  id: Scalars['String']['input'];
};

export type QueryTagArgs = {
  id: Scalars['String']['input'];
};

export type QueryTagsByAccountTypeArgs = {
  accountTypeId: Scalars['String']['input'];
};

export type ReasonInput = {
  accountId: Scalars['String']['input'];
  accountTypeId: Scalars['String']['input'];
  reason: RejectReasonOptions;
  reasonComment: Scalars['String']['input'];
};

export type RejectInput = {
  accountId: Scalars['String']['input'];
  reason: RejectReasonOptions;
  reasonComment: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export enum RejectReasonOptions {
  DomainRejected = 'DOMAIN_REJECTED',
  EmailConflict = 'EMAIL_CONFLICT',
  GenericEmailAddress = 'GENERIC_EMAIL_ADDRESS',
  NoActiveAccount = 'NO_ACTIVE_ACCOUNT',
  Other = 'OTHER',
  ProfileRejected = 'PROFILE_REJECTED',
  SupplierCodeNotRecognised = 'SUPPLIER_CODE_NOT_RECOGNISED',
  SupplierCodeRejected = 'SUPPLIER_CODE_REJECTED',
  SupplierNameNotMatch = 'SUPPLIER_NAME_NOT_MATCH',
}

export type RevokeInvitation = {
  comment?: InputMaybe<Scalars['String']['input']>;
  reason: ProfileInvitationRevokedReason;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
};

export type RoleCreation = {
  accountTypeId: Scalars['String']['input'];
  applicationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description: Scalars['String']['input'];
  hiddenForRequests?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  permissionGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  permissionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RoleProjection = {
  __typename?: 'RoleProjection';
  accountType: RoleToAccountTypeRelationship;
  description?: Maybe<Scalars['String']['output']>;
  hiddenForRequests?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  roleApplications: Array<RoleToApplicationRelationship>;
  rolePermissionGroups: Array<RoleToPermissionGroupRelationship>;
  rolePermissions: Array<RoleToPermissionRelationship>;
  tags?: Maybe<Array<RoleToTagRelationship>>;
};

export enum RoleStatusEnum {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED',
  Revoked = 'REVOKED',
}

export type RoleToAccountTypeRelationship = {
  __typename?: 'RoleToAccountTypeRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: AccountTypeProjection;
};

export type RoleToApplicationRelationship = {
  __typename?: 'RoleToApplicationRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: ApplicationProjection;
};

export type RoleToPermissionGroupRelationship = {
  __typename?: 'RoleToPermissionGroupRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: PermissionGroupProjection;
};

export type RoleToPermissionRelationship = {
  __typename?: 'RoleToPermissionRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: PermissionProjection;
};

export type RoleToTagRelationship = {
  __typename?: 'RoleToTagRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: TagProjection;
};

export type RoleUpdate = {
  description?: InputMaybe<Scalars['String']['input']>;
};

export type RolesRequestInput = {
  accountId: Scalars['ID']['input'];
  roleIds: Array<Scalars['String']['input']>;
};

export type SiteProjection = {
  __typename?: 'SiteProjection';
  account?: Maybe<SiteToAccountRelationship>;
  accounts: Array<SiteToAccountRelationship>;
  activeStatus?: Maybe<Scalars['Boolean']['output']>;
  approvedDomains: Array<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parentGroup?: Maybe<SiteToOrganisationGroupRelationship>;
  rejectedDomains: Array<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  supplierCode?: Maybe<Scalars['String']['output']>;
  supplierNumber?: Maybe<Scalars['String']['output']>;
  tncStatus?: Maybe<Scalars['String']['output']>;
};

export type SiteProjectionAccountArgs = {
  accountId: Scalars['String']['input'];
};

export type SiteToAccountAssociation = {
  __typename?: 'SiteToAccountAssociation';
  label?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AssociationStatus>;
  value: AccountProjection;
};

export type SiteToAccountRelationship = {
  __typename?: 'SiteToAccountRelationship';
  label?: Maybe<Scalars['String']['output']>;
  requestedId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AssociationStatus>;
  value: AccountProjection;
};

export type SiteToOrganisationGroupRelationship = {
  __typename?: 'SiteToOrganisationGroupRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: OrganisationGroupProjection;
};

export type TagCreation = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type TagProjection = {
  __typename?: 'TagProjection';
  applications: Array<TagToApplicationRelationship>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  myApplications: Array<TagToApplicationRelationship>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TagReturnType = {
  __typename?: 'TagReturnType';
  id: Scalars['ID']['output'];
};

export type TagToApplicationRelationship = {
  __typename?: 'TagToApplicationRelationship';
  label?: Maybe<Scalars['String']['output']>;
  value: ApplicationProjection;
};

export type UnreadNotificationCountProjection = {
  __typename?: 'UnreadNotificationCountProjection';
  unreadCount: Scalars['Float']['output'];
};

export type SelectedAccountFragment = {
  __typename?: 'AccountProjection';
  id: string;
  name?: string | null;
  accountTypeId?: string | null;
  accountSetup?: boolean | null;
  tags: Array<{
    __typename?: 'AccountToTagRelationship';
    value: { __typename?: 'TagProjection'; id: string; name?: string | null };
  }>;
};

export type ProtectedLayout_MyProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProtectedLayout_MyProfileQuery = {
  __typename?: 'Query';
  myProfile?: {
    __typename?: 'ProfileProjection';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    emailAddress?: string | null;
    title?: string | null;
    contactNumber?: string | null;
    countryCode?: string | null;
    selectedAccountId?: string | null;
    status?: ProfileStatus | null;
    accounts: Array<{
      __typename?: 'ProfileToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        accountTypeId?: string | null;
        accountSetup?: boolean | null;
        tags: Array<{
          __typename?: 'AccountToTagRelationship';
          value: {
            __typename?: 'TagProjection';
            id: string;
            name?: string | null;
          };
        }>;
      };
    }>;
  } | null;
};

export type AccountSelectorSection_AccountsFragment = {
  __typename?: 'AccountProjection';
  id: string;
  name?: string | null;
  profile?: {
    __typename?: 'AccountToProfileRelationship';
    value: {
      __typename?: 'ProfileProjection';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      emailAddress?: string | null;
    };
  } | null;
};

export type LinkAppsSection_LinkAppInfoFragment = {
  __typename?: 'ApplicationProjection';
  id: string;
  name?: string | null;
  acronym?: string | null;
  shortDescription?: string | null;
  longDescription?: string | null;
  slug?: string | null;
  proxyLocation?: string | null;
  type?: string | null;
};

export type PermissionGroupSection_PermissionGroupInfoFragment = {
  __typename?: 'PermissionGroupProjection';
  id: string;
  name?: string | null;
  applicationId?: string | null;
  permissions: Array<{
    __typename?: 'PermissionGroupToPermissionRelationship';
    value: {
      __typename?: 'PermissionProjection';
      id: string;
      name?: string | null;
    };
  }>;
};

export type PermissionsSection_PermissionInfoFragment = {
  __typename?: 'PermissionProjection';
  id: string;
  name?: string | null;
};

export type RoleSection_RoleInfoFragment = {
  __typename?: 'RoleProjection';
  id: string;
  name?: string | null;
};

export type RoleSelection_AccountToRoleRelationshipFragment = {
  __typename?: 'AccountToRoleRelationship';
  status: RoleStatusEnum;
  value: { __typename?: 'RoleProjection'; id: string; name?: string | null };
};

export type TagSelection_TagsFragment = {
  __typename?: 'TagProjection';
  id: string;
  name?: string | null;
};

export type AccountCoreFragment = {
  __typename?: 'AccountProjection';
  id: string;
  name?: string | null;
  accountTypeId?: string | null;
  profileId?: string | null;
  isPartner?: boolean | null;
  partnerName?: string | null;
  partnerContact?: string | null;
  status?: string | null;
  statusChangedAt?: string | null;
  reason?: string | null;
};

export type AccountTypeCoreFragment = {
  __typename?: 'AccountTypeProjection';
  id: string;
  name: string;
  description: string;
  checkType: string;
  approvedDomains: Array<string>;
  rejectedDomains: Array<string>;
  isAdmin: boolean;
  isApprover: boolean;
};

export type ApplicationCoreFragment = {
  __typename?: 'ApplicationProjection';
  id: string;
  name?: string | null;
  slug?: string | null;
  shortDescription?: string | null;
  longDescription?: string | null;
  isLive?: boolean | null;
  proxyLocation?: string | null;
  prospectiveAllowed?: boolean | null;
  isRoot?: boolean | null;
  type?: string | null;
  acronym?: string | null;
};

export type NotificationCoreFragment = {
  __typename?: 'NotificationProjection';
  entityId: string;
  uid: string;
  context: string;
  message: string;
  readIds?: Array<string> | null;
  heading: string;
  type: string;
  createdAt: string;
  audience: string;
  audienceId: string;
  authorId: string;
  category: string;
  targetId?: string | null;
  sourceId: string;
};

export type OrganisationGroupCoreFragment = {
  __typename?: 'OrganisationGroupProjection';
  id: string;
  name?: string | null;
};

export type PermissionCoreFragment = {
  __typename?: 'PermissionProjection';
  id: string;
  name?: string | null;
  description?: string | null;
  applicationId?: string | null;
  isActive?: boolean | null;
};

export type PermissionGroupCoreFragment = {
  __typename?: 'PermissionGroupProjection';
  id: string;
  name?: string | null;
  description?: string | null;
  applicationId?: string | null;
};

export type ProfileCoreFragment = {
  __typename?: 'ProfileProjection';
  id: string;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  preferredName?: string | null;
  emailAddress?: string | null;
  countryCode?: string | null;
  contactNumber?: string | null;
  invitationStatus?: ProfileInvitation | null;
  providerId?: string | null;
  selectedAccountId?: string | null;
  status?: ProfileStatus | null;
  TAndCAgreed?: boolean | null;
};

export type RoleCoreFragment = {
  __typename?: 'RoleProjection';
  id: string;
  name?: string | null;
  description?: string | null;
  hiddenForRequests?: boolean | null;
};

export type SiteCoreFragment = {
  __typename?: 'SiteProjection';
  id: string;
  name?: string | null;
  supplierCode?: string | null;
  supplierNumber?: string | null;
  approvedDomains: Array<string>;
  rejectedDomains: Array<string>;
  status?: string | null;
  activeStatus?: boolean | null;
  groupId?: string | null;
};

export type TagCoreFragment = {
  __typename?: 'TagProjection';
  id: string;
  name?: string | null;
  description?: string | null;
};

export type AcceptTAndCMutationVariables = Exact<{
  supplierCode: Scalars['String']['input'];
}>;

export type AcceptTAndCMutation = {
  __typename?: 'Mutation';
  acceptTAndC: string;
};

export type AccountUpdateDetailsMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: AccountDetails;
}>;

export type AccountUpdateDetailsMutation = {
  __typename?: 'Mutation';
  accountUpdateDetails: { __typename?: 'Account'; id: string };
};

export type AddApplicationAudienceForAccountTypeMutationVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
  applicationIds:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input'];
}>;

export type AddApplicationAudienceForAccountTypeMutation = {
  __typename?: 'Mutation';
  addApplicationAudienceForAccountType: {
    __typename?: 'AccountType';
    id: string;
  };
};

export type AddApprovedDomainToAccountTypeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  domain: Scalars['String']['input'];
}>;

export type AddApprovedDomainToAccountTypeMutation = {
  __typename?: 'Mutation';
  addApprovedDomainToAccountType: { __typename?: 'AccountType'; id: string };
};

export type AddApprovedDomainToSiteMutationVariables = Exact<{
  id: Scalars['String']['input'];
  domain: Scalars['String']['input'];
}>;

export type AddApprovedDomainToSiteMutation = {
  __typename?: 'Mutation';
  addApprovedDomainToSite: { __typename?: 'Organisation'; id: string };
};

export type AddRejectedDomainToAccountTypeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  domain: Scalars['String']['input'];
}>;

export type AddRejectedDomainToAccountTypeMutation = {
  __typename?: 'Mutation';
  addRejectedDomainToAccountType: { __typename?: 'AccountType'; id: string };
};

export type AddRejectedDomainToSiteMutationVariables = Exact<{
  id: Scalars['String']['input'];
  domain: Scalars['String']['input'];
}>;

export type AddRejectedDomainToSiteMutation = {
  __typename?: 'Mutation';
  addRejectedDomainToSite: { __typename?: 'Organisation'; id: string };
};

export type Add_Tags_To_RoleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type Add_Tags_To_RoleMutation = {
  __typename?: 'Mutation';
  roleAddTags: { __typename?: 'Role'; id: string };
};

export type ApproveAccountTypeForAccountMutationVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
  accountId: Scalars['String']['input'];
}>;

export type ApproveAccountTypeForAccountMutation = {
  __typename?: 'Mutation';
  approveAccountTypeForAccount: { __typename?: 'AccountType'; id: string };
};

export type ApproveRolesRequestMutationVariables = Exact<{
  input: RolesRequestInput;
}>;

export type ApproveRolesRequestMutation = {
  __typename?: 'Mutation';
  approveRolesRequest: { __typename?: 'Account'; id: string };
};

export type SiteAddApprovedAccountsMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  accountIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type SiteAddApprovedAccountsMutation = {
  __typename?: 'Mutation';
  siteAddApprovedAccounts: { __typename?: 'Organisation'; id: string };
};

export type AddApplicationsToMyGroupMutationVariables = Exact<{
  input: AccountApplicationsGroups;
}>;

export type AddApplicationsToMyGroupMutation = {
  __typename?: 'Mutation';
  addApplicationsToMyGroup: { __typename?: 'Account'; id: string };
};

export type AssociateAccountToSitesMutationVariables = Exact<{
  id: Scalars['String']['input'];
  siteIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AssociateAccountToSitesMutation = {
  __typename?: 'Mutation';
  associateAccountToSites: { __typename?: 'Account'; id: string };
};

export type AssociateAdminsToAccountTypeMutationVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
  accountIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AssociateAdminsToAccountTypeMutation = {
  __typename?: 'Mutation';
  associateAdminsToAccountType: { __typename?: 'AccountType'; id: string };
};

export type AssociateAdminsToApplicationMutationVariables = Exact<{
  applicationId: Scalars['String']['input'];
  accountIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AssociateAdminsToApplicationMutation = {
  __typename?: 'Mutation';
  associateAdminsToApplication: {
    __typename?: 'Application';
    id?: string | null;
  };
};

export type AssociateApproversToAccountTypeMutationVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
  accountIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AssociateApproversToAccountTypeMutation = {
  __typename?: 'Mutation';
  associateApproversToAccountType: { __typename?: 'AccountType'; id: string };
};

export type AssociateApproversToApplicationMutationVariables = Exact<{
  applicationId: Scalars['String']['input'];
  accountIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AssociateApproversToApplicationMutation = {
  __typename?: 'Mutation';
  associateApproversToApplication: {
    __typename?: 'Application';
    id?: string | null;
  };
};

export type AssociateDependentApplicationsMutationVariables = Exact<{
  applicationId: Scalars['String']['input'];
  dependentApplicationIds:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input'];
}>;

export type AssociateDependentApplicationsMutation = {
  __typename?: 'Mutation';
  associateDependentApplications: {
    __typename?: 'Application';
    id?: string | null;
  };
};

export type AssociateGroupsToRoleMutationVariables = Exact<{
  input: AssociateGroupsToRoleInput;
}>;

export type AssociateGroupsToRoleMutation = {
  __typename?: 'Mutation';
  associateGroupsToRole?: { __typename?: 'Role'; id: string } | null;
};

export type AssociatePermissionsToGroupMutationVariables = Exact<{
  input: AssociatePermissionsToGroupInput;
}>;

export type AssociatePermissionsToGroupMutation = {
  __typename?: 'Mutation';
  associatePermissionsToGroup?: {
    __typename?: 'AssociatePermissionsToGroupPayload';
    groupId: string;
  } | null;
};

export type AssociatePermissionsToRoleMutationVariables = Exact<{
  input: AssociatePermissionsToRoleInput;
}>;

export type AssociatePermissionsToRoleMutation = {
  __typename?: 'Mutation';
  associatePermissionsToRole?: { __typename?: 'Role'; id: string } | null;
};

export type AssociateTagsToAccountMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AssociateTagsToAccountMutation = {
  __typename?: 'Mutation';
  associateTagsToAccount: { __typename?: 'Account'; id: string };
};

export type AssociateTagsToAccountTypeMutationVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AssociateTagsToAccountTypeMutation = {
  __typename?: 'Mutation';
  associateTagsToAccountType: { __typename?: 'AccountType'; id: string };
};

export type AssociateTagsToApplicationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AssociateTagsToApplicationMutation = {
  __typename?: 'Mutation';
  associateTagsToApplication: {
    __typename?: 'Application';
    id?: string | null;
  };
};

export type CompleteAccountMutationVariables = Exact<{
  name: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type CompleteAccountMutation = {
  __typename?: 'Mutation';
  completeAccount: { __typename?: 'Account'; id: string };
};

export type CompleteProfileInvitationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  profile: ProfileUpdate;
}>;

export type CompleteProfileInvitationMutation = {
  __typename?: 'Mutation';
  completeProfileInvitation: { __typename?: 'Profile'; id: string };
};

export type CreateAccountTypeMutationVariables = Exact<{
  type: AccountTypeCreation;
}>;

export type CreateAccountTypeMutation = {
  __typename?: 'Mutation';
  createAccountType: { __typename?: 'AccountType'; id: string };
};

export type CreateApplicationMutationVariables = Exact<{
  application: ApplicationCreation;
}>;

export type CreateApplicationMutation = {
  __typename?: 'Mutation';
  createApplication: { __typename?: 'Application'; id?: string | null };
};

export type CreatePermissionMutationVariables = Exact<{
  permission: PermissionCreation;
}>;

export type CreatePermissionMutation = {
  __typename?: 'Mutation';
  createPermission?: { __typename?: 'Permission'; id: string } | null;
};

export type CreatePermissionGroupMutationVariables = Exact<{
  group: GroupCreation;
}>;

export type CreatePermissionGroupMutation = {
  __typename?: 'Mutation';
  createGroup?: { __typename?: 'Group'; id: string } | null;
};

export type CreateRoleMutationVariables = Exact<{
  role: RoleCreation;
}>;

export type CreateRoleMutation = {
  __typename?: 'Mutation';
  createRole?: { __typename?: 'Role'; id: string } | null;
};

export type CreateTagMutationVariables = Exact<{
  tag: TagCreation;
}>;

export type CreateTagMutation = {
  __typename?: 'Mutation';
  createTag: { __typename?: 'TagReturnType'; id: string };
};

export type DeclineRolesRequestMutationVariables = Exact<{
  input: RolesRequestInput;
}>;

export type DeclineRolesRequestMutation = {
  __typename?: 'Mutation';
  declineRolesRequest: { __typename?: 'Account'; id: string };
};

export type DeleteAccountMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type DeleteAccountMutation = {
  __typename?: 'Mutation';
  deleteAccount: { __typename?: 'Account'; id: string };
};

export type DeleteApplicationGroupMutationVariables = Exact<{
  applicationGroupId: Scalars['String']['input'];
}>;

export type DeleteApplicationGroupMutation = {
  __typename?: 'Mutation';
  deleteApplicationGroup: { __typename?: 'Account'; id: string };
};

export type DeletePermissionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeletePermissionMutation = {
  __typename?: 'Mutation';
  deletePermission?: { __typename?: 'Permission'; id: string } | null;
};

export type DeletePermissionGroupMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeletePermissionGroupMutation = {
  __typename?: 'Mutation';
  deleteGroup?: { __typename?: 'Group'; id: string } | null;
};

export type DeleteRolesMutationVariables = Exact<{
  roleIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type DeleteRolesMutation = {
  __typename?: 'Mutation';
  deleteRoles?: Array<string> | null;
};

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteTagMutation = {
  __typename?: 'Mutation';
  deleteTag: { __typename?: 'TagReturnType'; id: string };
};

export type DissociateAccountFromRolesMutationVariables = Exact<{
  id: Scalars['String']['input'];
  roleIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type DissociateAccountFromRolesMutation = {
  __typename?: 'Mutation';
  dissociateAccountFromRoles: { __typename?: 'Account'; id: string };
};

export type DissociateAdminFromAccountTypeMutationVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
  accountId: Scalars['String']['input'];
}>;

export type DissociateAdminFromAccountTypeMutation = {
  __typename?: 'Mutation';
  dissociateAdminFromAccountType: { __typename?: 'AccountType'; id: string };
};

export type DissociateAdminsFromApplicationMutationVariables = Exact<{
  applicationId: Scalars['String']['input'];
  accountIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type DissociateAdminsFromApplicationMutation = {
  __typename?: 'Mutation';
  dissociateAdminsFromApplication: {
    __typename?: 'Application';
    id?: string | null;
  };
};

export type DissociateApproverFromAccountTypeMutationVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
  accountId: Scalars['String']['input'];
}>;

export type DissociateApproverFromAccountTypeMutation = {
  __typename?: 'Mutation';
  dissociateApproverFromAccountType: { __typename?: 'AccountType'; id: string };
};

export type DissociateApproversFromApplicationMutationVariables = Exact<{
  applicationId: Scalars['String']['input'];
  accountIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type DissociateApproversFromApplicationMutation = {
  __typename?: 'Mutation';
  dissociateApproversFromApplication: {
    __typename?: 'Application';
    id?: string | null;
  };
};

export type DissociateDependentApplicationsMutationVariables = Exact<{
  applicationId: Scalars['String']['input'];
  dependentApplicationIds:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input'];
}>;

export type DissociateDependentApplicationsMutation = {
  __typename?: 'Mutation';
  dissociateDependentApplications: {
    __typename?: 'Application';
    id?: string | null;
  };
};

export type DissociateGroupsFromRoleMutationVariables = Exact<{
  input: DissociateGroupsFromRoleInput;
}>;

export type DissociateGroupsFromRoleMutation = {
  __typename?: 'Mutation';
  dissociateGroupsFromRole?: { __typename?: 'Role'; id: string } | null;
};

export type DissociatePermissionsFromGroupMutationVariables = Exact<{
  input: DissociatePermissionsFromGroupInput;
}>;

export type DissociatePermissionsFromGroupMutation = {
  __typename?: 'Mutation';
  dissociatePermissionsFromGroup?: {
    __typename?: 'DissociatePermissionsFromGroupPayload';
    dissociatedPermissions: Array<string>;
    groupId: string;
  } | null;
};

export type DissociatePermissionsFromRoleMutationVariables = Exact<{
  input: DissociatePermissionsFromRoleInput;
}>;

export type DissociatePermissionsFromRoleMutation = {
  __typename?: 'Mutation';
  dissociatePermissionsFromRole?: { __typename?: 'Role'; id: string } | null;
};

export type DissociateAccountFromSitesMutationVariables = Exact<{
  id: Scalars['String']['input'];
  siteIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type DissociateAccountFromSitesMutation = {
  __typename?: 'Mutation';
  dissociateAccountFromSites: { __typename?: 'Account'; id: string };
};

export type DissociateTagFromAccountTypeMutationVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
  tagId: Scalars['String']['input'];
}>;

export type DissociateTagFromAccountTypeMutation = {
  __typename?: 'Mutation';
  dissociateTagFromAccountType: { __typename?: 'AccountType'; id: string };
};

export type DissociateTagsFromAccountMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type DissociateTagsFromAccountMutation = {
  __typename?: 'Mutation';
  dissociateTagsFromAccount: { __typename?: 'Account'; id: string };
};

export type DissociateTagsFromApplicationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type DissociateTagsFromApplicationMutation = {
  __typename?: 'Mutation';
  dissociateTagsFromApplication: {
    __typename?: 'Application';
    id?: string | null;
  };
};

export type InviteProfileMutationVariables = Exact<{
  id: Scalars['String']['input'];
  options: InvitationRequestArgs;
}>;

export type InviteProfileMutation = {
  __typename?: 'Mutation';
  inviteProfile: { __typename?: 'Profile'; id: string };
};

export type RejectAccountTypeForAccountMutationVariables = Exact<{
  reject: ReasonInput;
}>;

export type RejectAccountTypeForAccountMutation = {
  __typename?: 'Mutation';
  rejectAccountTypeForAccount: { __typename?: 'AccountType'; id: string };
};

export type SiteRejectAccountRequestMutationVariables = Exact<{
  reject: RejectInput;
}>;

export type SiteRejectAccountRequestMutation = {
  __typename?: 'Mutation';
  siteRejectAccountRequest: { __typename?: 'Organisation'; id: string };
};

export type RemoveApplicationAudienceForAccountTypeMutationVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
  applicationId: Scalars['String']['input'];
}>;

export type RemoveApplicationAudienceForAccountTypeMutation = {
  __typename?: 'Mutation';
  removeApplicationAudienceForAccountType: {
    __typename?: 'AccountType';
    id: string;
  };
};

export type RemoveApplicationsFromMyGroupMutationVariables = Exact<{
  input: AccountApplicationsGroups;
}>;

export type RemoveApplicationsFromMyGroupMutation = {
  __typename?: 'Mutation';
  removeApplicationsFromMyGroup: { __typename?: 'Account'; id: string };
};

export type RemoveApprovedDomainFromAccountTypeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  domains: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RemoveApprovedDomainFromAccountTypeMutation = {
  __typename?: 'Mutation';
  removeApprovedDomainFromAccountType: {
    __typename?: 'AccountType';
    id: string;
  };
};

export type RemoveApprovedDomainsFromSiteMutationVariables = Exact<{
  id: Scalars['String']['input'];
  domains: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RemoveApprovedDomainsFromSiteMutation = {
  __typename?: 'Mutation';
  removeApprovedDomainsFromSite: { __typename?: 'Organisation'; id: string };
};

export type RemoveProfileAccessMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveProfileAccessMutation = {
  __typename?: 'Mutation';
  removeProfileAccess: { __typename?: 'Profile'; id: string };
};

export type RemoveRejectedDomainFromAccountTypeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  domains: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RemoveRejectedDomainFromAccountTypeMutation = {
  __typename?: 'Mutation';
  removeRejectedDomainFromAccountType: {
    __typename?: 'AccountType';
    id: string;
  };
};

export type RemoveRejectedDomainsFromSiteMutationVariables = Exact<{
  id: Scalars['String']['input'];
  domains: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RemoveRejectedDomainsFromSiteMutation = {
  __typename?: 'Mutation';
  removeRejectedDomainsFromSite: { __typename?: 'Organisation'; id: string };
};

export type Remove_Tags_From_RoleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type Remove_Tags_From_RoleMutation = {
  __typename?: 'Mutation';
  roleRemoveTags: { __typename?: 'Role'; id: string };
};

export type RequestAccountMutationVariables = Exact<{
  account: AccountRequest;
}>;

export type RequestAccountMutation = {
  __typename?: 'Mutation';
  requestAccount: { __typename?: 'Account'; id: string };
};

export type RequestPermissionsMutationVariables = Exact<{
  input: PermissionRequestInput;
}>;

export type RequestPermissionsMutation = {
  __typename?: 'Mutation';
  requestPermissions?: {
    __typename?: 'PermissionsRequestPayload';
    accountId: string;
  } | null;
};

export type RequestPermissionGroupsMutationVariables = Exact<{
  input: PermissionGroupRequestInput;
}>;

export type RequestPermissionGroupsMutation = {
  __typename?: 'Mutation';
  requestPermissionGroups?: {
    __typename?: 'PermissionGroupsRequestPayload';
    accountId: string;
  } | null;
};

export type RequestProfileMutationVariables = Exact<{
  profile: ProfileRequest;
}>;

export type RequestProfileMutation = {
  __typename?: 'Mutation';
  requestProfile: { __typename?: 'Profile'; id: string };
};

export type RequestRoleMutationVariables = Exact<{
  input: RolesRequestInput;
}>;

export type RequestRoleMutation = {
  __typename?: 'Mutation';
  requestRoles: { __typename?: 'Account'; id: string };
};

export type ResyncProfileEmailMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ResyncProfileEmailMutation = {
  __typename?: 'Mutation';
  resyncProfileEmail: { __typename?: 'Profile'; id: string };
};

export type RoleAddApplicationsAudienceMutationVariables = Exact<{
  id: Scalars['String']['input'];
  applicationIds:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input'];
}>;

export type RoleAddApplicationsAudienceMutation = {
  __typename?: 'Mutation';
  roleAddApplicationsAudience: { __typename?: 'Role'; id: string };
};

export type RoleRemoveApplicationsAudienceMutationVariables = Exact<{
  id: Scalars['String']['input'];
  applicationIds:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input'];
}>;

export type RoleRemoveApplicationsAudienceMutation = {
  __typename?: 'Mutation';
  roleRemoveApplicationsAudience: { __typename?: 'Role'; id: string };
};

export type SiteRemoveApprovedAccountMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  accountId: Scalars['String']['input'];
}>;

export type SiteRemoveApprovedAccountMutation = {
  __typename?: 'Mutation';
  siteRemoveApprovedAccount: { __typename?: 'Organisation'; id: string };
};

export type TakeOverMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
}>;

export type TakeOverMutation = {
  __typename?: 'Mutation';
  takeOver: {
    __typename?: 'UnreadNotificationCountProjection';
    unreadCount: number;
  };
};

export type UnassignNotificationMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
}>;

export type UnassignNotificationMutation = {
  __typename?: 'Mutation';
  unassignNotification: {
    __typename?: 'UnreadNotificationCountProjection';
    unreadCount: number;
  };
};

export type AccountUpdateNameMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;

export type AccountUpdateNameMutation = {
  __typename?: 'Mutation';
  accountUpdateName: { __typename?: 'Account'; id: string };
};

export type UpdateAccountTypeDescriptionMutationVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
  description: Scalars['String']['input'];
}>;

export type UpdateAccountTypeDescriptionMutation = {
  __typename?: 'Mutation';
  updateAccountTypeDescription: { __typename?: 'AccountType'; id: string };
};

export type UpdateApplicationDetailsMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: EditApplicationDetails;
}>;

export type UpdateApplicationDetailsMutation = {
  __typename?: 'Mutation';
  updateApplicationDetails: { __typename?: 'Application'; id?: string | null };
};

export type UpdateApplicationIsLiveMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isLive: Scalars['Boolean']['input'];
}>;

export type UpdateApplicationIsLiveMutation = {
  __typename?: 'Mutation';
  updateApplicationIsLive: { __typename?: 'Application'; id?: string | null };
};

export type UpdateApplicationProspectiveAllowedMutationVariables = Exact<{
  id: Scalars['String']['input'];
  prospectiveAllowed: Scalars['Boolean']['input'];
}>;

export type UpdateApplicationProspectiveAllowedMutation = {
  __typename?: 'Mutation';
  updateApplicationProspectiveAllowed: {
    __typename?: 'Application';
    id?: string | null;
  };
};

export type UpdateApplicationProxyLocationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  proxyLocation: Scalars['String']['input'];
}>;

export type UpdateApplicationProxyLocationMutation = {
  __typename?: 'Mutation';
  updateApplicationProxyLocation: {
    __typename?: 'Application';
    id?: string | null;
  };
};

export type UpdateProfileMutationVariables = Exact<{
  id: Scalars['String']['input'];
  profile: ProfileUpdate;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  updateProfile: { __typename?: 'Profile'; id: string };
};

export type UpdateProfileEmailAddressMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: ProfileUpdateEmailAddress;
}>;

export type UpdateProfileEmailAddressMutation = {
  __typename?: 'Mutation';
  updateProfileEmailAddress: { __typename?: 'Profile'; id: string };
};

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  role: RoleUpdate;
}>;

export type UpdateRoleMutation = {
  __typename?: 'Mutation';
  updateRole?: { __typename?: 'Role'; id: string } | null;
};

export type UpdateTagDescriptionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  description: Scalars['String']['input'];
}>;

export type UpdateTagDescriptionMutation = {
  __typename?: 'Mutation';
  updateTagDescription: { __typename?: 'TagReturnType'; id: string };
};

export type RoleUpdateHiddenForRequestsMutationVariables = Exact<{
  id: Scalars['String']['input'];
  hiddenForRequests: Scalars['Boolean']['input'];
}>;

export type RoleUpdateHiddenForRequestsMutation = {
  __typename?: 'Mutation';
  roleUpdateHiddenForRequests: { __typename?: 'Role'; id: string };
};

export type AccountQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AccountQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'AccountProjection';
    id: string;
    name?: string | null;
    accountTypeId?: string | null;
    profileId?: string | null;
    isPartner?: boolean | null;
    partnerName?: string | null;
    partnerContact?: string | null;
    status?: string | null;
    statusChangedAt?: string | null;
    reason?: string | null;
    accountType: {
      __typename?: 'AccountTypeProjection';
      id: string;
      checkType: string;
    };
    profile?: {
      __typename?: 'AccountToProfileRelationship';
      value: {
        __typename?: 'ProfileProjection';
        firstName?: string | null;
        lastName?: string | null;
        emailAddress?: string | null;
      };
    } | null;
    tags: Array<{
      __typename?: 'AccountToTagRelationship';
      value: {
        __typename?: 'TagProjection';
        name?: string | null;
        id: string;
        description?: string | null;
      };
    }>;
    organisationGroups: Array<{
      __typename?: 'OrganisationGroupProjection';
      name?: string | null;
      id: string;
    }>;
    roles: Array<{
      __typename?: 'AccountToRoleRelationship';
      value: {
        __typename?: 'RoleProjection';
        id: string;
        name?: string | null;
        description?: string | null;
      };
    }>;
  } | null;
};

export type AccountTypeQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AccountTypeQuery = {
  __typename?: 'Query';
  accountType: {
    __typename?: 'AccountTypeProjection';
    id: string;
    name: string;
    description: string;
    checkType: string;
    approvedDomains: Array<string>;
    rejectedDomains: Array<string>;
    isAdmin: boolean;
    isApprover: boolean;
    admins: Array<{
      __typename?: 'AccountTypeToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        accountTypeId?: string | null;
        profileId?: string | null;
        isPartner?: boolean | null;
        partnerName?: string | null;
        partnerContact?: string | null;
        status?: string | null;
        statusChangedAt?: string | null;
        reason?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            title?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            preferredName?: string | null;
            emailAddress?: string | null;
            countryCode?: string | null;
            contactNumber?: string | null;
            invitationStatus?: ProfileInvitation | null;
            providerId?: string | null;
            selectedAccountId?: string | null;
            status?: ProfileStatus | null;
            TAndCAgreed?: boolean | null;
          };
        } | null;
      };
    }>;
    approvers: Array<{
      __typename?: 'AccountTypeToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        accountTypeId?: string | null;
        profileId?: string | null;
        isPartner?: boolean | null;
        partnerName?: string | null;
        partnerContact?: string | null;
        status?: string | null;
        statusChangedAt?: string | null;
        reason?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            title?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            preferredName?: string | null;
            emailAddress?: string | null;
            countryCode?: string | null;
            contactNumber?: string | null;
            invitationStatus?: ProfileInvitation | null;
            providerId?: string | null;
            selectedAccountId?: string | null;
            status?: ProfileStatus | null;
            TAndCAgreed?: boolean | null;
          };
        } | null;
      };
    }>;
    linkedApplications?: Array<{
      __typename?: 'AccountTypeToApplicationRelationship';
      value: {
        __typename?: 'ApplicationProjection';
        id: string;
        name?: string | null;
        slug?: string | null;
        shortDescription?: string | null;
        longDescription?: string | null;
        isLive?: boolean | null;
        proxyLocation?: string | null;
        prospectiveAllowed?: boolean | null;
        isRoot?: boolean | null;
        type?: string | null;
        acronym?: string | null;
      };
    }> | null;
    tags: Array<{
      __typename?: 'AccountTypeToTagRelationship';
      value: {
        __typename?: 'TagProjection';
        id: string;
        name?: string | null;
        description?: string | null;
      };
    }>;
  };
};

export type AccountTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AccountTypesQuery = {
  __typename?: 'Query';
  accountTypes: Array<{
    __typename?: 'AccountTypeProjection';
    id: string;
    name: string;
    description: string;
    checkType: string;
    approvedDomains: Array<string>;
    rejectedDomains: Array<string>;
    isAdmin: boolean;
    isApprover: boolean;
  }>;
};

export type GetAccountsByProfileIdQueryVariables = Exact<{
  profileId: Scalars['String']['input'];
}>;

export type GetAccountsByProfileIdQuery = {
  __typename?: 'Query';
  profileAccounts: Array<{
    __typename?: 'AccountProjection';
    id: string;
    name?: string | null;
    accountTypeId?: string | null;
    profileId?: string | null;
    isPartner?: boolean | null;
    partnerName?: string | null;
    partnerContact?: string | null;
    status?: string | null;
    statusChangedAt?: string | null;
    reason?: string | null;
  }>;
};

export type AccountsQueryVariables = Exact<{
  page: Scalars['Float']['input'];
  pageSize: Scalars['Float']['input'];
}>;

export type AccountsQuery = {
  __typename?: 'Query';
  accounts?: {
    __typename?: 'FilteredAccountProjection';
    count: number;
    page: number;
    perPage: number;
    accounts: Array<{
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      accountTypeId?: string | null;
      profileId?: string | null;
      isPartner?: boolean | null;
      partnerName?: string | null;
      partnerContact?: string | null;
      status?: string | null;
      statusChangedAt?: string | null;
      reason?: string | null;
      organisationGroups: Array<{
        __typename?: 'OrganisationGroupProjection';
        name?: string | null;
      }>;
      profile?: {
        __typename?: 'AccountToProfileRelationship';
        value: {
          __typename?: 'ProfileProjection';
          firstName?: string | null;
          lastName?: string | null;
          emailAddress?: string | null;
        };
      } | null;
      sites: Array<{
        __typename?: 'AccountToSiteRelationship';
        value: { __typename?: 'SiteProjection'; supplierCode?: string | null };
      }>;
    }>;
  } | null;
};

export type TagsQueryVariables = Exact<{ [key: string]: never }>;

export type TagsQuery = {
  __typename?: 'Query';
  tags: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
    description?: string | null;
  }>;
};

export type ApplicationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ApplicationQuery = {
  __typename?: 'Query';
  application?: {
    __typename?: 'ApplicationProjection';
    id: string;
    name?: string | null;
    slug?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    isLive?: boolean | null;
    proxyLocation?: string | null;
    prospectiveAllowed?: boolean | null;
    isRoot?: boolean | null;
    type?: string | null;
    acronym?: string | null;
    tags: Array<{
      __typename?: 'ApplicationToTagRelationship';
      value: {
        __typename?: 'TagProjection';
        id: string;
        name?: string | null;
        description?: string | null;
      };
    }>;
    admins: Array<{
      __typename?: 'ApplicationToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        accountTypeId?: string | null;
        profileId?: string | null;
        isPartner?: boolean | null;
        partnerName?: string | null;
        partnerContact?: string | null;
        status?: string | null;
        statusChangedAt?: string | null;
        reason?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            title?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            preferredName?: string | null;
            emailAddress?: string | null;
            countryCode?: string | null;
            contactNumber?: string | null;
            invitationStatus?: ProfileInvitation | null;
            providerId?: string | null;
            selectedAccountId?: string | null;
            status?: ProfileStatus | null;
            TAndCAgreed?: boolean | null;
          };
        } | null;
      };
    }>;
    approvers: Array<{
      __typename?: 'ApplicationToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        accountTypeId?: string | null;
        profileId?: string | null;
        isPartner?: boolean | null;
        partnerName?: string | null;
        partnerContact?: string | null;
        status?: string | null;
        statusChangedAt?: string | null;
        reason?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            title?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            preferredName?: string | null;
            emailAddress?: string | null;
            countryCode?: string | null;
            contactNumber?: string | null;
            invitationStatus?: ProfileInvitation | null;
            providerId?: string | null;
            selectedAccountId?: string | null;
            status?: ProfileStatus | null;
            TAndCAgreed?: boolean | null;
          };
        } | null;
      };
    }>;
    linkedApplications?: Array<{
      __typename?: 'LinkedApplicationsRelationship';
      value: {
        __typename?: 'ApplicationProjection';
        id: string;
        name?: string | null;
        slug?: string | null;
        shortDescription?: string | null;
        longDescription?: string | null;
        isLive?: boolean | null;
        proxyLocation?: string | null;
        prospectiveAllowed?: boolean | null;
        isRoot?: boolean | null;
        type?: string | null;
        acronym?: string | null;
      };
    }> | null;
  } | null;
};

export type AccountApplicationGroupsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AccountApplicationGroupsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'AccountProjection';
    applicationGroups: Array<{
      __typename?: 'AccountApplicationGroupProjection';
      id: string;
      name: string;
      applications: Array<{
        __typename?: 'AccountGroupToApplicationRelationship';
        value: {
          __typename?: 'ApplicationProjection';
          id: string;
          name?: string | null;
          slug?: string | null;
          shortDescription?: string | null;
          longDescription?: string | null;
          isLive?: boolean | null;
          proxyLocation?: string | null;
          prospectiveAllowed?: boolean | null;
          isRoot?: boolean | null;
          type?: string | null;
          acronym?: string | null;
          tags: Array<{
            __typename?: 'ApplicationToTagRelationship';
            value: {
              __typename?: 'TagProjection';
              id: string;
              name?: string | null;
              description?: string | null;
            };
          }>;
        };
      }>;
    }>;
  } | null;
};

export type ApplicationPermissionsQueryVariables = Exact<{
  applicationId: Scalars['String']['input'];
}>;

export type ApplicationPermissionsQuery = {
  __typename?: 'Query';
  applicationPermissions?: Array<{
    __typename?: 'PermissionProjection';
    id: string;
    name?: string | null;
    description?: string | null;
    applicationId?: string | null;
    isActive?: boolean | null;
  }> | null;
};

export type ApplicationsQueryVariables = Exact<{ [key: string]: never }>;

export type ApplicationsQuery = {
  __typename?: 'Query';
  applications?: Array<{
    __typename?: 'ApplicationProjection';
    id: string;
    name?: string | null;
    slug?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    isLive?: boolean | null;
    proxyLocation?: string | null;
    prospectiveAllowed?: boolean | null;
    isRoot?: boolean | null;
    type?: string | null;
    acronym?: string | null;
    tags: Array<{
      __typename?: 'ApplicationToTagRelationship';
      value: {
        __typename?: 'TagProjection';
        id: string;
        name?: string | null;
        description?: string | null;
      };
    }>;
  }> | null;
};

export type ApplicationsWithFiltersQueryVariables = Exact<{
  tagIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type ApplicationsWithFiltersQuery = {
  __typename?: 'Query';
  myApplications?: Array<{
    __typename?: 'ApplicationProjection';
    id: string;
    name?: string | null;
    slug?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    isLive?: boolean | null;
    proxyLocation?: string | null;
    prospectiveAllowed?: boolean | null;
    isRoot?: boolean | null;
    type?: string | null;
    acronym?: string | null;
    tags: Array<{
      __typename?: 'ApplicationToTagRelationship';
      value: {
        __typename?: 'TagProjection';
        id: string;
        name?: string | null;
        description?: string | null;
      };
    }>;
  }> | null;
};

export type Get_HierarchiesQueryVariables = Exact<{
  searchTerms?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
}>;

export type Get_HierarchiesQuery = {
  __typename?: 'Query';
  paginatedHierarchies: {
    __typename?: 'FilteredHierarchyProjection';
    hierarchies: Array<{
      __typename?: 'HierarchyProjection';
      id: string;
      label: string;
      name?: string | null;
      parentId?: string | null;
      parentLevel?: string | null;
      level?: string | null;
    }>;
  };
};

export type MyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type MyProfileQuery = {
  __typename?: 'Query';
  myProfile?: {
    __typename?: 'ProfileProjection';
    id: string;
    title?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    emailAddress?: string | null;
    countryCode?: string | null;
    contactNumber?: string | null;
    invitationStatus?: ProfileInvitation | null;
    providerId?: string | null;
    selectedAccountId?: string | null;
    status?: ProfileStatus | null;
    TAndCAgreed?: boolean | null;
    accounts: Array<{
      __typename?: 'ProfileToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        accountTypeId?: string | null;
        profileId?: string | null;
        isPartner?: boolean | null;
        partnerName?: string | null;
        partnerContact?: string | null;
        status?: string | null;
        statusChangedAt?: string | null;
        reason?: string | null;
      };
    }>;
  } | null;
};

export type Get_My_AccountsQueryVariables = Exact<{ [key: string]: never }>;

export type Get_My_AccountsQuery = {
  __typename?: 'Query';
  myProfileAccounts: Array<{
    __typename?: 'AccountProjection';
    id: string;
    name?: string | null;
    accountTypeId?: string | null;
    profileId?: string | null;
    isPartner?: boolean | null;
    partnerName?: string | null;
    partnerContact?: string | null;
    status?: string | null;
    statusChangedAt?: string | null;
    reason?: string | null;
  }>;
};

export type Applications_MyApplicationsQueryVariables = Exact<{
  tagIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type Applications_MyApplicationsQuery = {
  __typename?: 'Query';
  myApplications?: Array<{
    __typename?: 'ApplicationProjection';
    id: string;
    acronym?: string | null;
    name?: string | null;
    slug?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    isLive?: boolean | null;
    proxyLocation?: string | null;
    prospectiveAllowed?: boolean | null;
    isRoot?: boolean | null;
    type?: string | null;
    tags: Array<{
      __typename?: 'ApplicationToTagRelationship';
      value: { __typename?: 'TagProjection'; id: string; name?: string | null };
    }>;
  }> | null;
};

export type Applications_TagRelationshipFragment = {
  __typename?: 'ApplicationToTagRelationship';
  value: { __typename?: 'TagProjection'; id: string; name?: string | null };
};

export type Applications_TagFragment = {
  __typename?: 'TagProjection';
  id: string;
  name?: string | null;
};

export type Get_Application_Groups_And_Profile_Groups_By_Account_IdQueryVariables =
  Exact<{
    id: Scalars['String']['input'];
  }>;

export type Get_Application_Groups_And_Profile_Groups_By_Account_IdQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'AccountProjection';
    applicationGroups: Array<{
      __typename?: 'AccountApplicationGroupProjection';
      id: string;
      name: string;
      applications: Array<{
        __typename?: 'AccountGroupToApplicationRelationship';
        value: {
          __typename?: 'ApplicationProjection';
          id: string;
          name?: string | null;
          slug?: string | null;
          shortDescription?: string | null;
          longDescription?: string | null;
          isLive?: boolean | null;
          proxyLocation?: string | null;
          prospectiveAllowed?: boolean | null;
          isRoot?: boolean | null;
          type?: string | null;
          acronym?: string | null;
        };
      }>;
    }>;
    profileGroups: Array<{
      __typename?: 'AccountProfileGroupProjection';
      id: string;
      name: string;
      profiles: Array<{
        __typename?: 'AccountToProfileRelationship';
        value: {
          __typename?: 'ProfileProjection';
          id: string;
          title?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          preferredName?: string | null;
          emailAddress?: string | null;
          countryCode?: string | null;
          contactNumber?: string | null;
          invitationStatus?: ProfileInvitation | null;
          providerId?: string | null;
          selectedAccountId?: string | null;
          status?: ProfileStatus | null;
          TAndCAgreed?: boolean | null;
        };
      }>;
    }>;
  } | null;
};

export type Get_My_ApplicationsQueryVariables = Exact<{ [key: string]: never }>;

export type Get_My_ApplicationsQuery = {
  __typename?: 'Query';
  myApplications?: Array<{
    __typename?: 'ApplicationProjection';
    id: string;
    name?: string | null;
    slug?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    isLive?: boolean | null;
    proxyLocation?: string | null;
    prospectiveAllowed?: boolean | null;
    isRoot?: boolean | null;
    type?: string | null;
    acronym?: string | null;
    tags: Array<{
      __typename?: 'ApplicationToTagRelationship';
      value: {
        __typename?: 'TagProjection';
        id: string;
        name?: string | null;
        description?: string | null;
      };
    }>;
  }> | null;
};

export type Get_My_Tags_With_ApplicationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Get_My_Tags_With_ApplicationsQuery = {
  __typename?: 'Query';
  myTags: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
    description?: string | null;
    applications: Array<{
      __typename?: 'TagToApplicationRelationship';
      value: {
        __typename?: 'ApplicationProjection';
        id: string;
        name?: string | null;
        slug?: string | null;
        shortDescription?: string | null;
        longDescription?: string | null;
        isLive?: boolean | null;
        proxyLocation?: string | null;
        prospectiveAllowed?: boolean | null;
        isRoot?: boolean | null;
        type?: string | null;
        acronym?: string | null;
      };
    }>;
  }>;
};

export type Get_My_TagsQueryVariables = Exact<{ [key: string]: never }>;

export type Get_My_TagsQuery = {
  __typename?: 'Query';
  myTags: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
    description?: string | null;
  }>;
};

export type Get_Notification_By_Id_QueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type Get_Notification_By_Id_QueryQuery = {
  __typename?: 'Query';
  getNotification?: {
    __typename?: 'NotificationProjection';
    entityId: string;
    uid: string;
    context: string;
    message: string;
    readIds?: Array<string> | null;
    heading: string;
    type: string;
    createdAt: string;
    audience: string;
    audienceId: string;
    authorId: string;
    category: string;
    targetId?: string | null;
    sourceId: string;
  } | null;
};

export type Get_Notifications_QueryQueryVariables = Exact<{
  page: Scalars['Float']['input'];
  filters?: InputMaybe<NotificationFilters>;
}>;

export type Get_Notifications_QueryQuery = {
  __typename?: 'Query';
  getNotifications: {
    __typename?: 'NotificationListProjection';
    unreadCount: number;
    page: number;
    data: Array<{
      __typename?: 'NotificationProjection';
      entityId: string;
      uid: string;
      context: string;
      message: string;
      readIds?: Array<string> | null;
      heading: string;
      type: string;
      createdAt: string;
      audience: string;
      audienceId: string;
      authorId: string;
      category: string;
      targetId?: string | null;
      sourceId: string;
      assignedTo?: {
        __typename?: 'AccountProjection';
        id: string;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            firstName?: string | null;
            lastName?: string | null;
          };
        } | null;
      } | null;
    }>;
  };
};

export type Get_Profiles_QueryQueryVariables = Exact<{
  searchTerms?: InputMaybe<Scalars['String']['input']>;
  accountTypeIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  tagIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  roleIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  hierarchyIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  statuses?: InputMaybe<Array<ProfileStatus> | ProfileStatus>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
}>;

export type Get_Profiles_QueryQuery = {
  __typename?: 'Query';
  paginatedProfiles: {
    __typename?: 'FilteredProfileProjection';
    page: number;
    count: number;
    perPage: number;
    profiles: Array<{
      __typename?: 'ProfileProjection';
      id: string;
      title?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      preferredName?: string | null;
      emailAddress?: string | null;
      countryCode?: string | null;
      contactNumber?: string | null;
      invitationStatus?: ProfileInvitation | null;
      providerId?: string | null;
      selectedAccountId?: string | null;
      status?: ProfileStatus | null;
      TAndCAgreed?: boolean | null;
      accounts: Array<{
        __typename?: 'ProfileToAccountRelationship';
        value: {
          __typename?: 'AccountProjection';
          id: string;
          name?: string | null;
          organisationGroups: Array<{
            __typename?: 'OrganisationGroupProjection';
            id: string;
            name?: string | null;
          }>;
        };
      }>;
    }>;
  };
};

export type QueryQueryVariables = Exact<{
  searchTerms?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
}>;

export type QueryQuery = {
  __typename?: 'Query';
  paginatedProfiles: {
    __typename?: 'FilteredProfileProjection';
    page: number;
    count: number;
    perPage: number;
    profiles: Array<{
      __typename?: 'ProfileProjection';
      id: string;
      title?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      preferredName?: string | null;
      emailAddress?: string | null;
      countryCode?: string | null;
      contactNumber?: string | null;
      invitationStatus?: ProfileInvitation | null;
      providerId?: string | null;
      selectedAccountId?: string | null;
      status?: ProfileStatus | null;
      TAndCAgreed?: boolean | null;
      accounts: Array<{
        __typename?: 'ProfileToAccountRelationship';
        value: {
          __typename?: 'AccountProjection';
          id: string;
          name?: string | null;
          accountTypeId?: string | null;
          status?: string | null;
          profileId?: string | null;
          organisationGroups: Array<{
            __typename?: 'OrganisationGroupProjection';
            id: string;
            name?: string | null;
          }>;
        };
      }>;
    }>;
  };
};

export type RoleQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RoleQuery = {
  __typename?: 'Query';
  role: {
    __typename?: 'RoleProjection';
    id: string;
    name?: string | null;
    description?: string | null;
    hiddenForRequests?: boolean | null;
    roleApplications: Array<{
      __typename?: 'RoleToApplicationRelationship';
      value: {
        __typename?: 'ApplicationProjection';
        id: string;
        name?: string | null;
        slug?: string | null;
        shortDescription?: string | null;
        longDescription?: string | null;
        isLive?: boolean | null;
        proxyLocation?: string | null;
        prospectiveAllowed?: boolean | null;
        isRoot?: boolean | null;
        type?: string | null;
        acronym?: string | null;
      };
    }>;
    rolePermissions: Array<{
      __typename?: 'RoleToPermissionRelationship';
      value: { __typename?: 'PermissionProjection'; id: string };
    }>;
    rolePermissionGroups: Array<{
      __typename?: 'RoleToPermissionGroupRelationship';
      value: { __typename?: 'PermissionGroupProjection'; id: string };
    }>;
    accountType: {
      __typename?: 'RoleToAccountTypeRelationship';
      value: {
        __typename?: 'AccountTypeProjection';
        id: string;
        name: string;
        description: string;
        checkType: string;
        approvedDomains: Array<string>;
        rejectedDomains: Array<string>;
        isAdmin: boolean;
        isApprover: boolean;
      };
    };
    tags?: Array<{
      __typename?: 'RoleToTagRelationship';
      value: {
        __typename?: 'TagProjection';
        id: string;
        name?: string | null;
        description?: string | null;
      };
    }> | null;
  };
};

export type RolesQueryVariables = Exact<{ [key: string]: never }>;

export type RolesQuery = {
  __typename?: 'Query';
  roles?: Array<{
    __typename?: 'RoleProjection';
    id: string;
    name?: string | null;
    description?: string | null;
    hiddenForRequests?: boolean | null;
  }> | null;
};

export type SiteQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type SiteQuery = {
  __typename?: 'Query';
  site?: {
    __typename?: 'SiteProjection';
    id: string;
    name?: string | null;
    supplierCode?: string | null;
    supplierNumber?: string | null;
    approvedDomains: Array<string>;
    rejectedDomains: Array<string>;
    status?: string | null;
    activeStatus?: boolean | null;
    groupId?: string | null;
    parentGroup?: {
      __typename?: 'SiteToOrganisationGroupRelationship';
      value: {
        __typename?: 'OrganisationGroupProjection';
        id: string;
        name?: string | null;
      };
    } | null;
    accounts: Array<{
      __typename?: 'SiteToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            title?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            preferredName?: string | null;
            emailAddress?: string | null;
            countryCode?: string | null;
            contactNumber?: string | null;
            invitationStatus?: ProfileInvitation | null;
            providerId?: string | null;
            selectedAccountId?: string | null;
            status?: ProfileStatus | null;
            TAndCAgreed?: boolean | null;
          };
        } | null;
      };
    }>;
  } | null;
};

export type SiteDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
  accountId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SiteDetailsQuery = {
  __typename?: 'Query';
  site?: {
    __typename?: 'SiteProjection';
    id: string;
    name?: string | null;
    supplierCode?: string | null;
    supplierNumber?: string | null;
    approvedDomains: Array<string>;
    rejectedDomains: Array<string>;
    status?: string | null;
    activeStatus?: boolean | null;
    groupId?: string | null;
    parentGroup?: {
      __typename?: 'SiteToOrganisationGroupRelationship';
      value: {
        __typename?: 'OrganisationGroupProjection';
        id: string;
        name?: string | null;
      };
    } | null;
    accounts: Array<{
      __typename?: 'SiteToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
          };
        } | null;
      };
    }>;
  } | null;
};

export type MySitesQueryVariables = Exact<{ [key: string]: never }>;

export type MySitesQuery = {
  __typename?: 'Query';
  mySites: Array<{
    __typename?: 'SiteProjection';
    id: string;
    name?: string | null;
    supplierCode?: string | null;
    supplierNumber?: string | null;
    approvedDomains: Array<string>;
    rejectedDomains: Array<string>;
    status?: string | null;
    activeStatus?: boolean | null;
    groupId?: string | null;
  }>;
};

export type GroupSitesQueryVariables = Exact<{
  groupId: Scalars['String']['input'];
}>;

export type GroupSitesQuery = {
  __typename?: 'Query';
  groupSites?: Array<{
    __typename?: 'SiteProjection';
    id: string;
    name?: string | null;
    supplierCode?: string | null;
    supplierNumber?: string | null;
    approvedDomains: Array<string>;
    rejectedDomains: Array<string>;
    status?: string | null;
    activeStatus?: boolean | null;
    groupId?: string | null;
  }> | null;
};

export type TagsByAccountTypeQueryVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
}>;

export type TagsByAccountTypeQuery = {
  __typename?: 'Query';
  tagsByAccountType: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
    description?: string | null;
  }>;
};

export type GetTagsByAccountTypeQueryVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
}>;

export type GetTagsByAccountTypeQuery = {
  __typename?: 'Query';
  tagsByAccountType: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
    description?: string | null;
  }>;
};

export type AccountTypeManagement_AccountTypesFragment = {
  __typename?: 'AccountTypeProjection';
  id: string;
  name: string;
};

export type AccountTypeManagement_AccountTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AccountTypeManagement_AccountTypesQuery = {
  __typename?: 'Query';
  accountTypes: Array<{
    __typename?: 'AccountTypeProjection';
    id: string;
    name: string;
  }>;
};

export type AccountTypeDetails_AccountTypeInfoFragment = {
  __typename?: 'AccountTypeProjection';
  id: string;
  name: string;
  description: string;
  checkType: string;
  approvedDomains: Array<string>;
  rejectedDomains: Array<string>;
  linkedApplications?: Array<{
    __typename?: 'AccountTypeToApplicationRelationship';
    value: {
      __typename?: 'ApplicationProjection';
      id: string;
      name?: string | null;
      acronym?: string | null;
      shortDescription?: string | null;
      longDescription?: string | null;
      slug?: string | null;
      proxyLocation?: string | null;
      type?: string | null;
    };
  }> | null;
  tags: Array<{
    __typename?: 'AccountTypeToTagRelationship';
    value: { __typename?: 'TagProjection'; id: string; name?: string | null };
  }>;
  admins: Array<{
    __typename?: 'AccountTypeToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      profile?: {
        __typename?: 'AccountToProfileRelationship';
        value: {
          __typename?: 'ProfileProjection';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          emailAddress?: string | null;
        };
      } | null;
    };
  }>;
  approvers: Array<{
    __typename?: 'AccountTypeToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      profile?: {
        __typename?: 'AccountToProfileRelationship';
        value: {
          __typename?: 'ProfileProjection';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          emailAddress?: string | null;
        };
      } | null;
    };
  }>;
};

export type AccountTypeDetails_ApplicationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AccountTypeDetails_ApplicationsQuery = {
  __typename?: 'Query';
  applications?: Array<{
    __typename?: 'ApplicationProjection';
    id: string;
    name?: string | null;
    acronym?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    slug?: string | null;
    proxyLocation?: string | null;
    type?: string | null;
  }> | null;
};

export type AccountTypeDetails_AccountTypeQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AccountTypeDetails_AccountTypeQuery = {
  __typename?: 'Query';
  accountType: {
    __typename?: 'AccountTypeProjection';
    id: string;
    name: string;
    description: string;
    checkType: string;
    approvedDomains: Array<string>;
    rejectedDomains: Array<string>;
    linkedApplications?: Array<{
      __typename?: 'AccountTypeToApplicationRelationship';
      value: {
        __typename?: 'ApplicationProjection';
        id: string;
        name?: string | null;
        acronym?: string | null;
        shortDescription?: string | null;
        longDescription?: string | null;
        slug?: string | null;
        proxyLocation?: string | null;
        type?: string | null;
      };
    }> | null;
    tags: Array<{
      __typename?: 'AccountTypeToTagRelationship';
      value: { __typename?: 'TagProjection'; id: string; name?: string | null };
    }>;
    admins: Array<{
      __typename?: 'AccountTypeToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            emailAddress?: string | null;
          };
        } | null;
      };
    }>;
    approvers: Array<{
      __typename?: 'AccountTypeToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            emailAddress?: string | null;
          };
        } | null;
      };
    }>;
  };
};

export type AccountTypeDetails_TagsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AccountTypeDetails_TagsQuery = {
  __typename?: 'Query';
  tags: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
  }>;
};

export type SectionRoles_RolesByAccountTypeFragment = {
  __typename?: 'RoleProjection';
  id: string;
  name?: string | null;
};

export type SectionRoles_RolesByAccountTypeQueryVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
}>;

export type SectionRoles_RolesByAccountTypeQuery = {
  __typename?: 'Query';
  rolesByAccountType: Array<{
    __typename?: 'RoleProjection';
    id: string;
    name?: string | null;
  }>;
};

export type MyAppGroupsCoreFragment = {
  __typename?: 'AccountApplicationGroupProjection';
  id: string;
  name: string;
  applications: Array<{
    __typename?: 'AccountGroupToApplicationRelationship';
    label?: string | null;
    value: {
      __typename?: 'ApplicationProjection';
      id: string;
      name?: string | null;
      slug?: string | null;
      shortDescription?: string | null;
      longDescription?: string | null;
      isLive?: boolean | null;
      proxyLocation?: string | null;
      prospectiveAllowed?: boolean | null;
      isRoot?: boolean | null;
      type?: string | null;
      acronym?: string | null;
    };
  }>;
};

export type MyAppGroupsApplicationFragment = {
  __typename?: 'AccountGroupToApplicationRelationship';
  label?: string | null;
  value: {
    __typename?: 'ApplicationProjection';
    id: string;
    name?: string | null;
    slug?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    isLive?: boolean | null;
    proxyLocation?: string | null;
    prospectiveAllowed?: boolean | null;
    isRoot?: boolean | null;
    type?: string | null;
    acronym?: string | null;
  };
};

export type MyAppGroupAppDetailsFragment = {
  __typename?: 'ApplicationProjection';
  id: string;
  name?: string | null;
  slug?: string | null;
  shortDescription?: string | null;
  longDescription?: string | null;
  isLive?: boolean | null;
  proxyLocation?: string | null;
  prospectiveAllowed?: boolean | null;
  isRoot?: boolean | null;
  type?: string | null;
  acronym?: string | null;
};

export type MyAppGroupAccountInfoFragment = {
  __typename?: 'AccountProjection';
  applicationGroups: Array<{
    __typename?: 'AccountApplicationGroupProjection';
    id: string;
    name: string;
    applications: Array<{
      __typename?: 'AccountGroupToApplicationRelationship';
      label?: string | null;
      value: {
        __typename?: 'ApplicationProjection';
        id: string;
        name?: string | null;
        slug?: string | null;
        shortDescription?: string | null;
        longDescription?: string | null;
        isLive?: boolean | null;
        proxyLocation?: string | null;
        prospectiveAllowed?: boolean | null;
        isRoot?: boolean | null;
        type?: string | null;
        acronym?: string | null;
      };
    }>;
  }>;
};

export type GetApplicationGroupsByAccountIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetApplicationGroupsByAccountIdQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'AccountProjection';
    applicationGroups: Array<{
      __typename?: 'AccountApplicationGroupProjection';
      id: string;
      name: string;
      applications: Array<{
        __typename?: 'AccountGroupToApplicationRelationship';
        label?: string | null;
        value: {
          __typename?: 'ApplicationProjection';
          id: string;
          name?: string | null;
          slug?: string | null;
          shortDescription?: string | null;
          longDescription?: string | null;
          isLive?: boolean | null;
          proxyLocation?: string | null;
          prospectiveAllowed?: boolean | null;
          isRoot?: boolean | null;
          type?: string | null;
          acronym?: string | null;
        };
      }>;
    }>;
  } | null;
};

export type TagForApplicationFragment = {
  __typename?: 'TagProjection';
  id: string;
  name?: string | null;
};

export type GetAllTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTagsQuery = {
  __typename?: 'Query';
  tags: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
  }>;
};

export type LoggedInProfileAccountInfoFragment = {
  __typename?: 'AccountProjection';
  id: string;
  name?: string | null;
  accountTypeId?: string | null;
  status?: string | null;
};

export type LogginProfileAccountRelationshipFragment = {
  __typename?: 'ProfileToAccountRelationship';
  value: {
    __typename?: 'AccountProjection';
    id: string;
    name?: string | null;
    accountTypeId?: string | null;
    status?: string | null;
  };
};

export type LoggedInProfileFullFragment = {
  __typename?: 'ProfileProjection';
  id: string;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  preferredName?: string | null;
  emailAddress?: string | null;
  countryCode?: string | null;
  contactNumber?: string | null;
  provider?: string | null;
  providerId?: string | null;
  selectedAccountId?: string | null;
  invitationStatus?: ProfileInvitation | null;
  TAndCAgreed?: boolean | null;
  status?: ProfileStatus | null;
  accounts: Array<{
    __typename?: 'ProfileToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      accountTypeId?: string | null;
      status?: string | null;
    };
  }>;
};

export type LoggedInProfileQueryVariables = Exact<{ [key: string]: never }>;

export type LoggedInProfileQuery = {
  __typename?: 'Query';
  myProfile?: {
    __typename?: 'ProfileProjection';
    id: string;
    title?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    emailAddress?: string | null;
    countryCode?: string | null;
    contactNumber?: string | null;
    provider?: string | null;
    providerId?: string | null;
    selectedAccountId?: string | null;
    invitationStatus?: ProfileInvitation | null;
    TAndCAgreed?: boolean | null;
    status?: ProfileStatus | null;
    accounts: Array<{
      __typename?: 'ProfileToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        accountTypeId?: string | null;
        status?: string | null;
      };
    }>;
  } | null;
};

export type CreateAccType_GetApplicationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CreateAccType_GetApplicationsQuery = {
  __typename?: 'Query';
  applications?: Array<{
    __typename?: 'ApplicationProjection';
    id: string;
    acronym?: string | null;
    name?: string | null;
    slug?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    isLive?: boolean | null;
    proxyLocation?: string | null;
    prospectiveAllowed?: boolean | null;
    isRoot?: boolean | null;
    type?: string | null;
    admins: Array<{
      __typename?: 'ApplicationToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            emailAddress?: string | null;
          };
        } | null;
      };
    }>;
    approvers: Array<{
      __typename?: 'ApplicationToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            emailAddress?: string | null;
          };
        } | null;
      };
    }>;
    tags: Array<{
      __typename?: 'ApplicationToTagRelationship';
      label?: string | null;
      value: { __typename?: 'TagProjection'; id: string; name?: string | null };
    }>;
  }> | null;
};

export type CreateAccType_ApplicationFragment = {
  __typename?: 'ApplicationProjection';
  id: string;
  acronym?: string | null;
  name?: string | null;
  slug?: string | null;
  shortDescription?: string | null;
  longDescription?: string | null;
  isLive?: boolean | null;
  proxyLocation?: string | null;
  prospectiveAllowed?: boolean | null;
  isRoot?: boolean | null;
  type?: string | null;
  admins: Array<{
    __typename?: 'ApplicationToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      profile?: {
        __typename?: 'AccountToProfileRelationship';
        value: {
          __typename?: 'ProfileProjection';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          emailAddress?: string | null;
        };
      } | null;
    };
  }>;
  approvers: Array<{
    __typename?: 'ApplicationToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      profile?: {
        __typename?: 'AccountToProfileRelationship';
        value: {
          __typename?: 'ProfileProjection';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          emailAddress?: string | null;
        };
      } | null;
    };
  }>;
  tags: Array<{
    __typename?: 'ApplicationToTagRelationship';
    label?: string | null;
    value: { __typename?: 'TagProjection'; id: string; name?: string | null };
  }>;
};

export type CreateAccType_App_Tag_RelationFragment = {
  __typename?: 'ApplicationToTagRelationship';
  label?: string | null;
  value: { __typename?: 'TagProjection'; id: string; name?: string | null };
};

export type CreateAccType_App_TagFragment = {
  __typename?: 'TagProjection';
  id: string;
  name?: string | null;
};

export type DeleteAccount_AccountFragment = {
  __typename?: 'AccountProjection';
  id: string;
  name?: string | null;
  status?: string | null;
};

export type DeleteAccount_MyProfileFragment = {
  __typename?: 'ProfileProjection';
  id: string;
  invitationStatus?: ProfileInvitation | null;
  selectedAccountId?: string | null;
  accounts: Array<{
    __typename?: 'ProfileToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      status?: string | null;
    };
  }>;
};

export type DeleteAccount_MyProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteAccount_MyProfileQuery = {
  __typename?: 'Query';
  myProfile?: {
    __typename?: 'ProfileProjection';
    id: string;
    invitationStatus?: ProfileInvitation | null;
    selectedAccountId?: string | null;
    accounts: Array<{
      __typename?: 'ProfileToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        status?: string | null;
      };
    }>;
  } | null;
};

export type SiteGroupsPage_PaginatedGroupsQueryVariables = Exact<{
  input: PaginatedGroupsInput;
}>;

export type SiteGroupsPage_PaginatedGroupsQuery = {
  __typename?: 'Query';
  paginatedGroups: {
    __typename?: 'FilteredGroupsProjection';
    count: number;
    page: number;
    perPage: number;
    groups: Array<{
      __typename?: 'OrganisationGroupProjection';
      id: string;
      name?: string | null;
      sites: Array<{
        __typename?: 'OrganisationGroupToSiteRelationship';
        value: {
          __typename?: 'SiteProjection';
          id: string;
          name?: string | null;
          supplierCode?: string | null;
          supplierNumber?: string | null;
          approvedDomains: Array<string>;
          rejectedDomains: Array<string>;
          status?: string | null;
          activeStatus?: boolean | null;
        };
      }>;
    }>;
  };
};

export type InviteProfileForm_RolesByAccountTypeFragment = {
  __typename?: 'RoleProjection';
  id: string;
  name?: string | null;
  hiddenForRequests?: boolean | null;
};

export type InviteProfileForm_AccountTypesFragment = {
  __typename?: 'AccountTypeProjection';
  id: string;
  name: string;
  checkType: string;
};

export type InviteProfileForm_TagsByAccountTypeFragment = {
  __typename?: 'TagProjection';
  id: string;
  name?: string | null;
};

export type InviteProfileForm_RolesByAccountTypeQueryVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
}>;

export type InviteProfileForm_RolesByAccountTypeQuery = {
  __typename?: 'Query';
  rolesByAccountType: Array<{
    __typename?: 'RoleProjection';
    id: string;
    name?: string | null;
    description?: string | null;
    hiddenForRequests?: boolean | null;
  }>;
};

export type InviteProfileForm_AccountTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InviteProfileForm_AccountTypesQuery = {
  __typename?: 'Query';
  accountTypes: Array<{
    __typename?: 'AccountTypeProjection';
    id: string;
    name: string;
    checkType: string;
  }>;
};

export type InviteProfileForm_TagsByAccountTypeQueryVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
}>;

export type InviteProfileForm_TagsByAccountTypeQuery = {
  __typename?: 'Query';
  tagsByAccountType: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
  }>;
};

export type LoginPage_MyProfileFragment = {
  __typename?: 'ProfileProjection';
  id: string;
  invitationStatus?: ProfileInvitation | null;
  preferredName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  title?: string | null;
  contactNumber?: string | null;
  countryCode?: string | null;
  selectedAccountId?: string | null;
  accounts: Array<{
    __typename?: 'ProfileToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      status?: string | null;
    };
  }>;
};

export type LoginPage_MyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type LoginPage_MyProfileQuery = {
  __typename?: 'Query';
  myProfile?: {
    __typename?: 'ProfileProjection';
    id: string;
    invitationStatus?: ProfileInvitation | null;
    preferredName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    emailAddress?: string | null;
    title?: string | null;
    contactNumber?: string | null;
    countryCode?: string | null;
    selectedAccountId?: string | null;
    accounts: Array<{
      __typename?: 'ProfileToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        status?: string | null;
      };
    }>;
  } | null;
};

export type AccountRequestNotification_AccountTypeDetailsFragment = {
  __typename?: 'AccountTypeProjection';
  id: string;
  name: string;
};

export type RoleRequestNotification_RoleInfoFragment = {
  __typename?: 'RoleProjection';
  id: string;
  name?: string | null;
  accountType: {
    __typename?: 'RoleToAccountTypeRelationship';
    value: { __typename?: 'AccountTypeProjection'; name: string };
  };
};

export type RoleRequestNotification_RoleQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RoleRequestNotification_RoleQuery = {
  __typename?: 'Query';
  role: {
    __typename?: 'RoleProjection';
    id: string;
    name?: string | null;
    accountType: {
      __typename?: 'RoleToAccountTypeRelationship';
      value: { __typename?: 'AccountTypeProjection'; name: string };
    };
  };
};

export type Notification_SiteRequestSiteInfoQueryVariables = Exact<{
  id: Scalars['String']['input'];
  accountId: Scalars['String']['input'];
}>;

export type Notification_SiteRequestSiteInfoQuery = {
  __typename?: 'Query';
  site?: {
    __typename?: 'SiteProjection';
    id: string;
    name?: string | null;
    supplierCode?: string | null;
    supplierNumber?: string | null;
    status?: string | null;
    activeStatus?: boolean | null;
    groupId?: string | null;
    parentGroup?: {
      __typename?: 'SiteToOrganisationGroupRelationship';
      value: {
        __typename?: 'OrganisationGroupProjection';
        id: string;
        name?: string | null;
      };
    } | null;
    account?: {
      __typename?: 'SiteToAccountRelationship';
      requestedId?: string | null;
    } | null;
  } | null;
};

export type Notifications_AccountInfoFragment = {
  __typename?: 'AccountProjection';
  id: string;
  name?: string | null;
  accountTypeId?: string | null;
  isPartner?: boolean | null;
  partnerName?: string | null;
  partnerContact?: string | null;
  accountType: {
    __typename?: 'AccountTypeProjection';
    checkType: string;
    id: string;
    name: string;
  };
  profile?: {
    __typename?: 'AccountToProfileRelationship';
    value: {
      __typename?: 'ProfileProjection';
      firstName?: string | null;
      lastName?: string | null;
      emailAddress?: string | null;
    };
  } | null;
};

export type Notification_InfoFragment = {
  __typename?: 'NotificationProjection';
  entityId: string;
  uid: string;
  context: string;
  message: string;
  readIds?: Array<string> | null;
  heading: string;
  type: string;
  createdAt: string;
  audience: string;
  audienceId: string;
  authorId: string;
  category: string;
  targetId?: string | null;
  sourceId: string;
};

export type Notifications_AccountQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type Notifications_AccountQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'AccountProjection';
    id: string;
    name?: string | null;
    accountTypeId?: string | null;
    isPartner?: boolean | null;
    partnerName?: string | null;
    partnerContact?: string | null;
    accountType: {
      __typename?: 'AccountTypeProjection';
      checkType: string;
      id: string;
      name: string;
    };
    profile?: {
      __typename?: 'AccountToProfileRelationship';
      value: {
        __typename?: 'ProfileProjection';
        firstName?: string | null;
        lastName?: string | null;
        emailAddress?: string | null;
      };
    } | null;
  } | null;
};

export type Notifications_NotificationsByAccountQueryVariables = Exact<{
  page: Scalars['Float']['input'];
  filters?: InputMaybe<NotificationFilters>;
}>;

export type Notifications_NotificationsByAccountQuery = {
  __typename?: 'Query';
  getNotifications: {
    __typename?: 'NotificationListProjection';
    unreadCount: number;
    page: number;
    data: Array<{
      __typename?: 'NotificationProjection';
      entityId: string;
      uid: string;
      context: string;
      message: string;
      readIds?: Array<string> | null;
      heading: string;
      type: string;
      createdAt: string;
      audience: string;
      audienceId: string;
      authorId: string;
      category: string;
      targetId?: string | null;
      sourceId: string;
      assignedTo?: {
        __typename?: 'AccountProjection';
        id: string;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            firstName?: string | null;
            lastName?: string | null;
          };
        } | null;
      } | null;
    }>;
  };
};

export type OrganisationsPage_SitesQueryVariables = Exact<{
  input: PaginatedSitesInput;
}>;

export type OrganisationsPage_SitesQuery = {
  __typename?: 'Query';
  paginatedSites: {
    __typename?: 'FilteredSitesProjection';
    count: number;
    page: number;
    perPage: number;
    sites: Array<{
      __typename?: 'SiteProjection';
      id: string;
      name?: string | null;
      supplierCode?: string | null;
      supplierNumber?: string | null;
      approvedDomains: Array<string>;
      rejectedDomains: Array<string>;
      status?: string | null;
      activeStatus?: boolean | null;
      groupId?: string | null;
    }>;
  };
};

export type ProfilePage_AccountTypesFragment = {
  __typename?: 'AccountTypeProjection';
  id: string;
  name: string;
};

export type ProfilePage_TagsFragment = {
  __typename?: 'TagProjection';
  id: string;
  name?: string | null;
};

export type ProfilePage_RolesFragment = {
  __typename?: 'RoleProjection';
  id: string;
  name?: string | null;
};

export type ProfilePage_HierarchyFragment = {
  __typename?: 'HierarchyProjection';
  id: string;
  name?: string | null;
  level?: string | null;
};

export type ProfilePage_ProfileFragment = {
  __typename?: 'ProfileProjection';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  preferredName?: string | null;
  title?: string | null;
  emailAddress?: string | null;
  countryCode?: string | null;
  contactNumber?: string | null;
  accounts: Array<{
    __typename?: 'ProfileToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      organisationGroups: Array<{
        __typename?: 'OrganisationGroupProjection';
        id: string;
        name?: string | null;
      }>;
    };
  }>;
};

export type ProfilePage_ProfilesQueryVariables = Exact<{
  searchTerms?: InputMaybe<Scalars['String']['input']>;
  accountTypeIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  tagIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  roleIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  hierarchyIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  statuses?: InputMaybe<Array<ProfileStatus> | ProfileStatus>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
}>;

export type ProfilePage_ProfilesQuery = {
  __typename?: 'Query';
  paginatedProfiles: {
    __typename?: 'FilteredProfileProjection';
    page: number;
    count: number;
    perPage: number;
    profiles: Array<{
      __typename?: 'ProfileProjection';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      preferredName?: string | null;
      title?: string | null;
      emailAddress?: string | null;
      countryCode?: string | null;
      contactNumber?: string | null;
      accounts: Array<{
        __typename?: 'ProfileToAccountRelationship';
        value: {
          __typename?: 'AccountProjection';
          id: string;
          name?: string | null;
          organisationGroups: Array<{
            __typename?: 'OrganisationGroupProjection';
            id: string;
            name?: string | null;
          }>;
        };
      }>;
    }>;
  };
};

export type ProfilePage_RolesQueryVariables = Exact<{ [key: string]: never }>;

export type ProfilePage_RolesQuery = {
  __typename?: 'Query';
  roles?: Array<{
    __typename?: 'RoleProjection';
    id: string;
    name?: string | null;
  }> | null;
};

export type ProfilePage_HierarchyQueryVariables = Exact<{
  searchTerms?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
}>;

export type ProfilePage_HierarchyQuery = {
  __typename?: 'Query';
  paginatedHierarchies: {
    __typename?: 'FilteredHierarchyProjection';
    hierarchies: Array<{
      __typename?: 'HierarchyProjection';
      id: string;
      name?: string | null;
      level?: string | null;
    }>;
  };
};

export type ProfilePage_TagsQueryVariables = Exact<{ [key: string]: never }>;

export type ProfilePage_TagsQuery = {
  __typename?: 'Query';
  tags: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
  }>;
};

export type ProfilePage_AccountTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProfilePage_AccountTypesQuery = {
  __typename?: 'Query';
  accountTypes: Array<{
    __typename?: 'AccountTypeProjection';
    id: string;
    name: string;
  }>;
};

export type Prospective_ApplicationInfoFragment = {
  __typename?: 'ApplicationProjection';
  id: string;
  acronym?: string | null;
  name?: string | null;
  shortDescription?: string | null;
  longDescription?: string | null;
  slug?: string | null;
  isLive?: boolean | null;
  proxyLocation?: string | null;
  isRoot?: boolean | null;
  type?: string | null;
};

export type Prospective_MyApplicationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Prospective_MyApplicationsQuery = {
  __typename?: 'Query';
  myApplications?: Array<{
    __typename?: 'ApplicationProjection';
    id: string;
    acronym?: string | null;
    name?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    slug?: string | null;
    isLive?: boolean | null;
    proxyLocation?: string | null;
    isRoot?: boolean | null;
    type?: string | null;
  }> | null;
};

export type RequestAccountForm_GetAccountTypeFragment = {
  __typename?: 'AccountTypeProjection';
  id: string;
  name: string;
  checkType: string;
};

export type RequestAccountForm_GetRolesByAccountTypeFragment = {
  __typename?: 'RoleProjection';
  id: string;
  name?: string | null;
  description?: string | null;
};

export type RequestAccountForm_TagsByAccountTypeFragment = {
  __typename?: 'TagProjection';
  id: string;
  name?: string | null;
};

export type RequestAccountForm_RolesByAccountTypeQueryVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
}>;

export type RequestAccountForm_RolesByAccountTypeQuery = {
  __typename?: 'Query';
  rolesByAccountType: Array<{
    __typename?: 'RoleProjection';
    id: string;
    name?: string | null;
    description?: string | null;
  }>;
};

export type RequestAccountForm_AccountTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RequestAccountForm_AccountTypesQuery = {
  __typename?: 'Query';
  accountTypes: Array<{
    __typename?: 'AccountTypeProjection';
    id: string;
    name: string;
    checkType: string;
  }>;
};

export type RequestAccountForm_TagsByAccountTypeQueryVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
}>;

export type RequestAccountForm_TagsByAccountTypeQuery = {
  __typename?: 'Query';
  tagsByAccountType: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
  }>;
};

export type Request_Account_Profile_DetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type Request_Account_Profile_DetailsQuery = {
  __typename?: 'Query';
  profile?: {
    __typename?: 'ProfileProjection';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    emailAddress?: string | null;
  } | null;
};

export type ManageRole_RoleInfoFragment = {
  __typename?: 'RoleProjection';
  id: string;
  name?: string | null;
  description?: string | null;
  accountType: {
    __typename?: 'RoleToAccountTypeRelationship';
    value: { __typename?: 'AccountTypeProjection'; id: string; name: string };
  };
  roleApplications: Array<{
    __typename?: 'RoleToApplicationRelationship';
    value: {
      __typename?: 'ApplicationProjection';
      id: string;
      acronym?: string | null;
      name?: string | null;
      shortDescription?: string | null;
      slug?: string | null;
      isLive?: boolean | null;
      proxyLocation?: string | null;
    };
  }>;
  rolePermissions: Array<{
    __typename?: 'RoleToPermissionRelationship';
    value: {
      __typename?: 'PermissionProjection';
      id: string;
      name?: string | null;
    };
  }>;
  rolePermissionGroups: Array<{
    __typename?: 'RoleToPermissionGroupRelationship';
    value: {
      __typename?: 'PermissionGroupProjection';
      id: string;
      name?: string | null;
    };
  }>;
  tags?: Array<{
    __typename?: 'RoleToTagRelationship';
    value: { __typename?: 'TagProjection'; id: string; name?: string | null };
  }> | null;
};

export type ManageRole_RoleQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ManageRole_RoleQuery = {
  __typename?: 'Query';
  role: {
    __typename?: 'RoleProjection';
    id: string;
    name?: string | null;
    hiddenForRequests?: boolean | null;
    description?: string | null;
    accountType: {
      __typename?: 'RoleToAccountTypeRelationship';
      value: { __typename?: 'AccountTypeProjection'; id: string; name: string };
    };
    roleApplications: Array<{
      __typename?: 'RoleToApplicationRelationship';
      value: {
        __typename?: 'ApplicationProjection';
        id: string;
        acronym?: string | null;
        name?: string | null;
        shortDescription?: string | null;
        slug?: string | null;
        isLive?: boolean | null;
        proxyLocation?: string | null;
      };
    }>;
    rolePermissions: Array<{
      __typename?: 'RoleToPermissionRelationship';
      value: {
        __typename?: 'PermissionProjection';
        id: string;
        name?: string | null;
      };
    }>;
    rolePermissionGroups: Array<{
      __typename?: 'RoleToPermissionGroupRelationship';
      value: {
        __typename?: 'PermissionGroupProjection';
        id: string;
        name?: string | null;
      };
    }>;
    tags?: Array<{
      __typename?: 'RoleToTagRelationship';
      value: { __typename?: 'TagProjection'; id: string; name?: string | null };
    }> | null;
  };
};

export type ManageRole_TagsQueryVariables = Exact<{ [key: string]: never }>;

export type ManageRole_TagsQuery = {
  __typename?: 'Query';
  tags: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
  }>;
};

export type RoleApplicationsSection_ApplicationInfoFragment = {
  __typename?: 'ApplicationProjection';
  id: string;
  acronym?: string | null;
  name?: string | null;
  shortDescription?: string | null;
  slug?: string | null;
  isLive?: boolean | null;
  proxyLocation?: string | null;
};

export type RoleDetailsSection_PermissionDetailsFragment = {
  __typename?: 'PermissionProjection';
  id: string;
  name?: string | null;
};

export type RoleDetailsSection_PermissionGroupDetailsFragment = {
  __typename?: 'PermissionGroupProjection';
  id: string;
  name?: string | null;
};

export type RoleApplicationsSection_ApplicationsListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RoleApplicationsSection_ApplicationsListQuery = {
  __typename?: 'Query';
  applications?: Array<{
    __typename?: 'ApplicationProjection';
    id: string;
    name?: string | null;
    acronym?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    slug?: string | null;
    proxyLocation?: string | null;
    type?: string | null;
  }> | null;
};

export type RoleDetailsSection_RoleDetailsFragment = {
  __typename?: 'RoleProjection';
  id: string;
  name?: string | null;
  description?: string | null;
};

export type RoleHiddenSection_RoleHiddenFragment = {
  __typename?: 'RoleProjection';
  id: string;
  name?: string | null;
  hiddenForRequests?: boolean | null;
  accountType: {
    __typename?: 'RoleToAccountTypeRelationship';
    value: { __typename?: 'AccountTypeProjection'; id: string };
  };
};

export type SitePage_SitesQueryVariables = Exact<{
  input: PaginatedSitesInput;
}>;

export type SitePage_SitesQuery = {
  __typename?: 'Query';
  paginatedSites: {
    __typename?: 'FilteredSitesProjection';
    count: number;
    page: number;
    perPage: number;
    sites: Array<{
      __typename?: 'SiteProjection';
      id: string;
      name?: string | null;
      supplierCode?: string | null;
      supplierNumber?: string | null;
      approvedDomains: Array<string>;
      rejectedDomains: Array<string>;
      status?: string | null;
      activeStatus?: boolean | null;
      groupId?: string | null;
    }>;
  };
};

export type DeleteTagFragment = {
  __typename?: 'TagProjection';
  id: string;
  name?: string | null;
};

export type TagManagerFragment = {
  __typename?: 'TagProjection';
  id: string;
  name?: string | null;
  description?: string | null;
};

export type TagManagerQueryVariables = Exact<{ [key: string]: never }>;

export type TagManagerQuery = {
  __typename?: 'Query';
  tags: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
    description?: string | null;
  }>;
};

export type TermsAndConditions_AccountDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type TermsAndConditions_AccountDetailsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'AccountProjection';
    id: string;
    sites: Array<{
      __typename?: 'AccountToSiteRelationship';
      value: {
        __typename?: 'SiteProjection';
        name?: string | null;
        supplierCode?: string | null;
        supplierNumber?: string | null;
        approvedDomains: Array<string>;
        rejectedDomains: Array<string>;
        status?: string | null;
        activeStatus?: boolean | null;
        groupId?: string | null;
        parentGroup?: {
          __typename?: 'SiteToOrganisationGroupRelationship';
          value: { __typename?: 'OrganisationGroupProjection'; id: string };
        } | null;
      };
    }>;
  } | null;
};

export type TermsAndConditions_GroupSitesFragment = {
  __typename?: 'SiteProjection';
  id: string;
  name?: string | null;
  supplierCode?: string | null;
  supplierNumber?: string | null;
  approvedDomains: Array<string>;
  rejectedDomains: Array<string>;
  status?: string | null;
  tncStatus?: string | null;
};

export type TermsAndConditions_GroupSitesQueryVariables = Exact<{
  groupId: Scalars['String']['input'];
}>;

export type TermsAndConditions_GroupSitesQuery = {
  __typename?: 'Query';
  groupSites?: Array<{
    __typename?: 'SiteProjection';
    id: string;
    name?: string | null;
    supplierCode?: string | null;
    supplierNumber?: string | null;
    approvedDomains: Array<string>;
    rejectedDomains: Array<string>;
    status?: string | null;
    tncStatus?: string | null;
  }> | null;
};

export type PermissionGroupComp_ApplicationInfoFragment = {
  __typename?: 'ApplicationProjection';
  id: string;
  name?: string | null;
};

export type PermissionGroupComp_PermissionGroupFragment = {
  __typename?: 'PermissionGroupProjection';
  id: string;
  name?: string | null;
  applicationId?: string | null;
  permissions: Array<{
    __typename?: 'PermissionGroupToPermissionRelationship';
    value: {
      __typename?: 'PermissionProjection';
      id: string;
      name?: string | null;
    };
  }>;
};

export type ApplicationPermissionGroupsQueryVariables = Exact<{
  applicationId: Scalars['String']['input'];
}>;

export type ApplicationPermissionGroupsQuery = {
  __typename?: 'Query';
  applicationPermissionGroups?: Array<{
    __typename?: 'PermissionGroupProjection';
    id: string;
    name?: string | null;
    applicationId?: string | null;
    permissions: Array<{
      __typename?: 'PermissionGroupToPermissionRelationship';
      value: {
        __typename?: 'PermissionProjection';
        id: string;
        name?: string | null;
      };
    }>;
  }> | null;
};

export type PermissionGroupRow_PermissionGroupInfoFragment = {
  __typename?: 'PermissionGroupProjection';
  id: string;
  name?: string | null;
  applicationId?: string | null;
  permissions: Array<{
    __typename?: 'PermissionGroupToPermissionRelationship';
    value: {
      __typename?: 'PermissionProjection';
      id: string;
      name?: string | null;
    };
  }>;
};

export type ViewApplicaion_ApplicationInfoFragment = {
  __typename?: 'ApplicationProjection';
  id: string;
  name?: string | null;
  acronym?: string | null;
  shortDescription?: string | null;
  longDescription?: string | null;
  proxyLocation?: string | null;
  isLive?: boolean | null;
  prospectiveAllowed?: boolean | null;
  slug?: string | null;
  type?: string | null;
  admins: Array<{
    __typename?: 'ApplicationToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      profile?: {
        __typename?: 'AccountToProfileRelationship';
        value: {
          __typename?: 'ProfileProjection';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          emailAddress?: string | null;
        };
      } | null;
    };
  }>;
  approvers: Array<{
    __typename?: 'ApplicationToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      profile?: {
        __typename?: 'AccountToProfileRelationship';
        value: {
          __typename?: 'ProfileProjection';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          emailAddress?: string | null;
        };
      } | null;
    };
  }>;
  tags: Array<{
    __typename?: 'ApplicationToTagRelationship';
    value: { __typename?: 'TagProjection'; id: string; name?: string | null };
  }>;
  linkedApplications?: Array<{
    __typename?: 'LinkedApplicationsRelationship';
    value: {
      __typename?: 'ApplicationProjection';
      id: string;
      name?: string | null;
      acronym?: string | null;
      shortDescription?: string | null;
      longDescription?: string | null;
      slug?: string | null;
      proxyLocation?: string | null;
      type?: string | null;
    };
  }> | null;
};

export type ViewApplicaion_TagsQueryVariables = Exact<{ [key: string]: never }>;

export type ViewApplicaion_TagsQuery = {
  __typename?: 'Query';
  tags: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
  }>;
};

export type ViewApplicaion_GetPermissionsFragment = {
  __typename?: 'PermissionProjection';
  id: string;
  name?: string | null;
};

export type ViewApplicaion_GetPermissionsQueryVariables = Exact<{
  applicationId: Scalars['String']['input'];
}>;

export type ViewApplicaion_GetPermissionsQuery = {
  __typename?: 'Query';
  applicationPermissions?: Array<{
    __typename?: 'PermissionProjection';
    id: string;
    name?: string | null;
  }> | null;
};

export type ViewApplicaion_ApplicationInfoQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ViewApplicaion_ApplicationInfoQuery = {
  __typename?: 'Query';
  application?: {
    __typename?: 'ApplicationProjection';
    id: string;
    name?: string | null;
    acronym?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    proxyLocation?: string | null;
    isLive?: boolean | null;
    prospectiveAllowed?: boolean | null;
    slug?: string | null;
    type?: string | null;
    admins: Array<{
      __typename?: 'ApplicationToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            emailAddress?: string | null;
          };
        } | null;
      };
    }>;
    approvers: Array<{
      __typename?: 'ApplicationToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            emailAddress?: string | null;
          };
        } | null;
      };
    }>;
    tags: Array<{
      __typename?: 'ApplicationToTagRelationship';
      value: { __typename?: 'TagProjection'; id: string; name?: string | null };
    }>;
    linkedApplications?: Array<{
      __typename?: 'LinkedApplicationsRelationship';
      value: {
        __typename?: 'ApplicationProjection';
        id: string;
        name?: string | null;
        acronym?: string | null;
        shortDescription?: string | null;
        longDescription?: string | null;
        slug?: string | null;
        proxyLocation?: string | null;
        type?: string | null;
      };
    }> | null;
  } | null;
};

export type ViewApplicaion_ApplicationsListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ViewApplicaion_ApplicationsListQuery = {
  __typename?: 'Query';
  applications?: Array<{
    __typename?: 'ApplicationProjection';
    id: string;
    name?: string | null;
    acronym?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
  }> | null;
};

export type ViewOrganisation_GetOrganisationFragment = {
  __typename?: 'OrganisationGroupProjection';
  id: string;
  name?: string | null;
  sites: Array<{
    __typename?: 'OrganisationGroupToSiteRelationship';
    value: { __typename?: 'SiteProjection'; id: string; name?: string | null };
  }>;
};

export type OrganisationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type OrganisationQuery = {
  __typename?: 'Query';
  organisation?: {
    __typename?: 'OrganisationGroupProjection';
    id: string;
    name?: string | null;
    sites: Array<{
      __typename?: 'OrganisationGroupToSiteRelationship';
      value: {
        __typename?: 'SiteProjection';
        id: string;
        name?: string | null;
      };
    }>;
  } | null;
};

export type AccountCard_AccountDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AccountCard_AccountDetailsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'AccountProjection';
    id: string;
    name?: string | null;
    accountTypeId?: string | null;
    status?: string | null;
    jobTitle?: string | null;
    accountType: {
      __typename?: 'AccountTypeProjection';
      id: string;
      name: string;
      checkType: string;
    };
    roles: Array<{
      __typename?: 'AccountToRoleRelationship';
      status: RoleStatusEnum;
      value: {
        __typename?: 'RoleProjection';
        id: string;
        name?: string | null;
      };
    }>;
    organisationGroups: Array<{
      __typename?: 'OrganisationGroupProjection';
      id: string;
      name?: string | null;
    }>;
    sites: Array<{
      __typename?: 'AccountToSiteRelationship';
      status?: AssociationStatus | null;
      value: {
        __typename?: 'SiteProjection';
        id: string;
        name?: string | null;
        parentGroup?: {
          __typename?: 'SiteToOrganisationGroupRelationship';
          value: { __typename?: 'OrganisationGroupProjection'; id: string };
        } | null;
      };
    }>;
    tags: Array<{
      __typename?: 'AccountToTagRelationship';
      value: { __typename?: 'TagProjection'; id: string; name?: string | null };
    }>;
  } | null;
};

export type AccountCard_TagsQueryVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
}>;

export type AccountCard_TagsQuery = {
  __typename?: 'Query';
  tagsByAccountType: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
  }>;
};

export type AccountCard_RolesByAccountTypeQueryVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
}>;

export type AccountCard_RolesByAccountTypeQuery = {
  __typename?: 'Query';
  rolesByAccountType: Array<{
    __typename?: 'RoleProjection';
    id: string;
    name?: string | null;
  }>;
};

export type AccountSwitcher_AccountInfoFragment = {
  __typename?: 'AccountProjection';
  id: string;
  name?: string | null;
};

export type AccountSwitcher_AccountOptionsQueryVariables = Exact<{
  profileId: Scalars['String']['input'];
}>;

export type AccountSwitcher_AccountOptionsQuery = {
  __typename?: 'Query';
  profileAccounts: Array<{
    __typename?: 'AccountProjection';
    id: string;
    name?: string | null;
  }>;
};

export type UpdateAccountDetailsScreen_AccountInfoFragment = {
  __typename?: 'AccountProjection';
  id: string;
  name?: string | null;
  jobTitle?: string | null;
};

export type ProfileCard_ProfileInfoFragment = {
  __typename?: 'ProfileProjection';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  preferredName?: string | null;
  emailAddress?: string | null;
  contactNumber?: string | null;
  title?: string | null;
  countryCode?: string | null;
  providerId?: string | null;
  status?: ProfileStatus | null;
};

export type SupplierCodesSection_SiteInfoFragment = {
  __typename?: 'SiteProjection';
  id: string;
  name?: string | null;
  parentGroup?: {
    __typename?: 'SiteToOrganisationGroupRelationship';
    value: { __typename?: 'OrganisationGroupProjection'; id: string };
  } | null;
};

export type SupplierCodesSection_AccountToSiteRelationshipFragment = {
  __typename?: 'AccountToSiteRelationship';
  status?: AssociationStatus | null;
  value: {
    __typename?: 'SiteProjection';
    id: string;
    name?: string | null;
    parentGroup?: {
      __typename?: 'SiteToOrganisationGroupRelationship';
      value: { __typename?: 'OrganisationGroupProjection'; id: string };
    } | null;
  };
};

export type ViewProfile_ProfileInfoFragment = {
  __typename?: 'ProfileProjection';
  id: string;
  firstName?: string | null;
  selectedAccountId?: string | null;
};

export type ProfileQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ProfileQuery = {
  __typename?: 'Query';
  profile?: {
    __typename?: 'ProfileProjection';
    id: string;
    firstName?: string | null;
    selectedAccountId?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    emailAddress?: string | null;
    contactNumber?: string | null;
    title?: string | null;
    countryCode?: string | null;
    providerId?: string | null;
    status?: ProfileStatus | null;
  } | null;
};

export type ViewSiteFragment = {
  __typename?: 'SiteProjection';
  id: string;
  name?: string | null;
  supplierNumber?: string | null;
  supplierCode?: string | null;
  activeStatus?: boolean | null;
  status?: string | null;
  approvedDomains: Array<string>;
  rejectedDomains: Array<string>;
  parentGroup?: {
    __typename?: 'SiteToOrganisationGroupRelationship';
    value: {
      __typename?: 'OrganisationGroupProjection';
      id: string;
      name?: string | null;
    };
  } | null;
  accounts: Array<{
    __typename?: 'SiteToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
      profile?: {
        __typename?: 'AccountToProfileRelationship';
        value: {
          __typename?: 'ProfileProjection';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          emailAddress?: string | null;
        };
      } | null;
    };
  }>;
};

export type ViewSiteQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ViewSiteQuery = {
  __typename?: 'Query';
  site?: {
    __typename?: 'SiteProjection';
    id: string;
    name?: string | null;
    supplierNumber?: string | null;
    supplierCode?: string | null;
    activeStatus?: boolean | null;
    status?: string | null;
    approvedDomains: Array<string>;
    rejectedDomains: Array<string>;
    parentGroup?: {
      __typename?: 'SiteToOrganisationGroupRelationship';
      value: {
        __typename?: 'OrganisationGroupProjection';
        id: string;
        name?: string | null;
      };
    } | null;
    accounts: Array<{
      __typename?: 'SiteToAccountRelationship';
      value: {
        __typename?: 'AccountProjection';
        id: string;
        name?: string | null;
        profile?: {
          __typename?: 'AccountToProfileRelationship';
          value: {
            __typename?: 'ProfileProjection';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            emailAddress?: string | null;
          };
        } | null;
      };
    }>;
  } | null;
};

export type AddAccountByProfileScreen_ProfilesFragment = {
  __typename?: 'ProfileProjection';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  accounts: Array<{
    __typename?: 'ProfileToAccountRelationship';
    value: {
      __typename?: 'AccountProjection';
      id: string;
      name?: string | null;
    };
  }>;
};

export type AddAccountByProfileScreen_ProfileSearchQueryVariables = Exact<{
  searchTerms?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
}>;

export type AddAccountByProfileScreen_ProfileSearchQuery = {
  __typename?: 'Query';
  paginatedProfiles: {
    __typename?: 'FilteredProfileProjection';
    page: number;
    count: number;
    perPage: number;
    profiles: Array<{
      __typename?: 'ProfileProjection';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      emailAddress?: string | null;
      accounts: Array<{
        __typename?: 'ProfileToAccountRelationship';
        value: {
          __typename?: 'AccountProjection';
          id: string;
          name?: string | null;
        };
      }>;
    }>;
  };
};

export type AddNewAppGroupScreen_ApplicationFragment = {
  __typename?: 'ApplicationProjection';
  id: string;
  name?: string | null;
  acronym?: string | null;
  shortDescription?: string | null;
};

export type AddNewAppGroupScreen_MyApplicationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AddNewAppGroupScreen_MyApplicationsQuery = {
  __typename?: 'Query';
  myApplications?: Array<{
    __typename?: 'ApplicationProjection';
    id: string;
    name?: string | null;
    acronym?: string | null;
    shortDescription?: string | null;
  }> | null;
};

export type AddNewPermissionToGroupScreen_PermissionInfoFragment = {
  __typename?: 'PermissionProjection';
  id: string;
  name?: string | null;
};

export type AddNewPermissionToGroupScreen_PermissionByAppQueryVariables =
  Exact<{
    applicationId: Scalars['String']['input'];
  }>;

export type AddNewPermissionToGroupScreen_PermissionByAppQuery = {
  __typename?: 'Query';
  applicationPermissions?: Array<{
    __typename?: 'PermissionProjection';
    id: string;
    name?: string | null;
  }> | null;
};

export type EditAppGroupScreen_ApplicationInfoFragment = {
  __typename?: 'ApplicationProjection';
  id: string;
  name?: string | null;
  acronym?: string | null;
  shortDescription?: string | null;
};

export type EditAppGroupScreen_MyApplicationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EditAppGroupScreen_MyApplicationsQuery = {
  __typename?: 'Query';
  myApplications?: Array<{
    __typename?: 'ApplicationProjection';
    id: string;
    name?: string | null;
    acronym?: string | null;
    shortDescription?: string | null;
  }> | null;
};

export type AddSupplierCode_SiteInfoFragment = {
  __typename?: 'SiteProjection';
  id: string;
  name?: string | null;
};

export type AddSupplierCode_GroupSitesQueryVariables = Exact<{
  groupId: Scalars['String']['input'];
}>;

export type AddSupplierCode_GroupSitesQuery = {
  __typename?: 'Query';
  groupSites?: Array<{
    __typename?: 'SiteProjection';
    id: string;
    name?: string | null;
  }> | null;
};

export type RemoveSupplierCode_SiteInfoFragment = {
  __typename?: 'SiteProjection';
  id: string;
  name?: string | null;
};

export type EditTag_TagFragment = {
  __typename?: 'TagProjection';
  name?: string | null;
};

export type GetAppPermissionsQueryVariables = Exact<{
  applicationId: Scalars['String']['input'];
}>;

export type GetAppPermissionsQuery = {
  __typename?: 'Query';
  applicationPermissions?: Array<{
    __typename?: 'PermissionProjection';
    id: string;
    name?: string | null;
    description?: string | null;
    applicationId?: string | null;
  }> | null;
};

export type GetAppPermissionGroupsQueryVariables = Exact<{
  applicationId: Scalars['String']['input'];
}>;

export type GetAppPermissionGroupsQuery = {
  __typename?: 'Query';
  applicationPermissionGroups?: Array<{
    __typename?: 'PermissionGroupProjection';
    id: string;
    name?: string | null;
    description?: string | null;
    applicationId?: string | null;
    permissions: Array<{
      __typename?: 'PermissionGroupToPermissionRelationship';
      value: {
        __typename?: 'PermissionProjection';
        id: string;
        name?: string | null;
        description?: string | null;
        applicationId?: string | null;
      };
    }>;
  }> | null;
};

export type AppPermissionGroupToPermissionRelationFragment = {
  __typename?: 'PermissionGroupToPermissionRelationship';
  value: {
    __typename?: 'PermissionProjection';
    id: string;
    name?: string | null;
    description?: string | null;
    applicationId?: string | null;
  };
};

export type AppPermissionFragment = {
  __typename?: 'PermissionProjection';
  id: string;
  name?: string | null;
  description?: string | null;
  applicationId?: string | null;
};

export type AppPermissionGroupFragment = {
  __typename?: 'PermissionGroupProjection';
  id: string;
  name?: string | null;
  description?: string | null;
  applicationId?: string | null;
};

export type RemoveRoleScreen_RoleInfoFragment = {
  __typename?: 'RoleProjection';
  id: string;
  name?: string | null;
};

export type AddTagsComponent_TagsByAccountTypeQueryVariables = Exact<{
  accountTypeId: Scalars['String']['input'];
}>;

export type AddTagsComponent_TagsByAccountTypeQuery = {
  __typename?: 'Query';
  tagsByAccountType: Array<{
    __typename?: 'TagProjection';
    id: string;
    name?: string | null;
  }>;
};

export type MyAppsGroupTab_AppGroupFragment = {
  __typename?: 'AccountApplicationGroupProjection';
  id: string;
  name: string;
  applications: Array<{
    __typename?: 'AccountGroupToApplicationRelationship';
    value: {
      __typename?: 'ApplicationProjection';
      id: string;
      name?: string | null;
      slug?: string | null;
      shortDescription?: string | null;
      longDescription?: string | null;
      isLive?: boolean | null;
      proxyLocation?: string | null;
      prospectiveAllowed?: boolean | null;
      isRoot?: boolean | null;
      type?: string | null;
      acronym?: string | null;
      tags: Array<{
        __typename?: 'ApplicationToTagRelationship';
        value: {
          __typename?: 'TagProjection';
          id: string;
          name?: string | null;
          description?: string | null;
        };
      }>;
    };
  }>;
};

export type MyAppsGroupTab_AccountApplicationGroupsFragment = {
  __typename?: 'AccountProjection';
  applicationGroups: Array<{
    __typename?: 'AccountApplicationGroupProjection';
    id: string;
    name: string;
    applications: Array<{
      __typename?: 'AccountGroupToApplicationRelationship';
      value: {
        __typename?: 'ApplicationProjection';
        id: string;
        name?: string | null;
        slug?: string | null;
        shortDescription?: string | null;
        longDescription?: string | null;
        isLive?: boolean | null;
        proxyLocation?: string | null;
        prospectiveAllowed?: boolean | null;
        isRoot?: boolean | null;
        type?: string | null;
        acronym?: string | null;
        tags: Array<{
          __typename?: 'ApplicationToTagRelationship';
          value: {
            __typename?: 'TagProjection';
            id: string;
            name?: string | null;
            description?: string | null;
          };
        }>;
      };
    }>;
  }>;
};

export type MyAppsGroupTab_AccountApplicationGroupsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type MyAppsGroupTab_AccountApplicationGroupsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'AccountProjection';
    applicationGroups: Array<{
      __typename?: 'AccountApplicationGroupProjection';
      id: string;
      name: string;
      applications: Array<{
        __typename?: 'AccountGroupToApplicationRelationship';
        value: {
          __typename?: 'ApplicationProjection';
          id: string;
          name?: string | null;
          slug?: string | null;
          shortDescription?: string | null;
          longDescription?: string | null;
          isLive?: boolean | null;
          proxyLocation?: string | null;
          prospectiveAllowed?: boolean | null;
          isRoot?: boolean | null;
          type?: string | null;
          acronym?: string | null;
          tags: Array<{
            __typename?: 'ApplicationToTagRelationship';
            value: {
              __typename?: 'TagProjection';
              id: string;
              name?: string | null;
              description?: string | null;
            };
          }>;
        };
      }>;
    }>;
  } | null;
};

export type PermissionDeleteRow_PermissionInfoFragment = {
  __typename?: 'PermissionProjection';
  id: string;
  name?: string | null;
};

export const SelectedAccountFragmentDoc = gql`
  fragment SelectedAccount on AccountProjection {
    id
    name
    accountTypeId
    accountSetup
    tags {
      value {
        id
        name
      }
    }
  }
`;
export const PermissionDeleteRow_PermissionInfoFragmentDoc = gql`
  fragment PermissionDeleteRow_PermissionInfo on PermissionProjection {
    id
    name
  }
`;
export const PermissionGroupRow_PermissionGroupInfoFragmentDoc = gql`
  fragment PermissionGroupRow_PermissionGroupInfo on PermissionGroupProjection {
    id
    name
    applicationId
    permissions {
      value {
        ...PermissionDeleteRow_PermissionInfo
      }
    }
  }
  ${PermissionDeleteRow_PermissionInfoFragmentDoc}
`;
export const PermissionGroupSection_PermissionGroupInfoFragmentDoc = gql`
  fragment PermissionGroupSection_PermissionGroupInfo on PermissionGroupProjection {
    ...PermissionGroupRow_PermissionGroupInfo
  }
  ${PermissionGroupRow_PermissionGroupInfoFragmentDoc}
`;
export const PermissionsSection_PermissionInfoFragmentDoc = gql`
  fragment PermissionsSection_PermissionInfo on PermissionProjection {
    id
    name
  }
`;
export const RemoveRoleScreen_RoleInfoFragmentDoc = gql`
  fragment RemoveRoleScreen_RoleInfo on RoleProjection {
    id
    name
  }
`;
export const RoleSection_RoleInfoFragmentDoc = gql`
  fragment RoleSection_RoleInfo on RoleProjection {
    ...RemoveRoleScreen_RoleInfo
  }
  ${RemoveRoleScreen_RoleInfoFragmentDoc}
`;
export const RoleSelection_AccountToRoleRelationshipFragmentDoc = gql`
  fragment RoleSelection_AccountToRoleRelationship on AccountToRoleRelationship {
    status
    value {
      ...RoleSection_RoleInfo
    }
  }
  ${RoleSection_RoleInfoFragmentDoc}
`;
export const AccountCoreFragmentDoc = gql`
  fragment AccountCore on AccountProjection {
    id
    name
    accountTypeId
    profileId
    isPartner
    partnerName
    partnerContact
    status
    statusChangedAt
    reason
  }
`;
export const AccountTypeCoreFragmentDoc = gql`
  fragment AccountTypeCore on AccountTypeProjection {
    id
    name
    description
    checkType
    approvedDomains
    rejectedDomains
    isAdmin
    isApprover
  }
`;
export const NotificationCoreFragmentDoc = gql`
  fragment NotificationCore on NotificationProjection {
    entityId
    uid
    context
    message
    readIds
    heading
    type
    createdAt
    audience
    audienceId
    authorId
    category
    targetId
    sourceId
  }
`;
export const OrganisationGroupCoreFragmentDoc = gql`
  fragment OrganisationGroupCore on OrganisationGroupProjection {
    id
    name
  }
`;
export const PermissionCoreFragmentDoc = gql`
  fragment PermissionCore on PermissionProjection {
    id
    name
    description
    applicationId
    isActive
  }
`;
export const PermissionGroupCoreFragmentDoc = gql`
  fragment PermissionGroupCore on PermissionGroupProjection {
    id
    name
    description
    applicationId
  }
`;
export const ProfileCoreFragmentDoc = gql`
  fragment ProfileCore on ProfileProjection {
    id
    title
    firstName
    lastName
    preferredName
    emailAddress
    countryCode
    contactNumber
    invitationStatus
    providerId
    selectedAccountId
    status
    TAndCAgreed
  }
`;
export const RoleCoreFragmentDoc = gql`
  fragment RoleCore on RoleProjection {
    id
    name
    description
    hiddenForRequests
  }
`;
export const SiteCoreFragmentDoc = gql`
  fragment SiteCore on SiteProjection {
    id
    name
    supplierCode
    supplierNumber
    approvedDomains
    rejectedDomains
    status
    activeStatus
    groupId
  }
`;
export const Applications_TagFragmentDoc = gql`
  fragment applications_tag on TagProjection {
    id
    name
  }
`;
export const Applications_TagRelationshipFragmentDoc = gql`
  fragment applications_tagRelationship on ApplicationToTagRelationship {
    value {
      ...applications_tag
    }
  }
  ${Applications_TagFragmentDoc}
`;
export const AccountTypeManagement_AccountTypesFragmentDoc = gql`
  fragment AccountTypeManagement_AccountTypes on AccountTypeProjection {
    id
    name
  }
`;
export const LinkAppsSection_LinkAppInfoFragmentDoc = gql`
  fragment LinkAppsSection_linkAppInfo on ApplicationProjection {
    id
    name
    acronym
    shortDescription
    longDescription
    slug
    proxyLocation
    type
  }
`;
export const TagSelection_TagsFragmentDoc = gql`
  fragment TagSelection_Tags on TagProjection {
    id
    name
  }
`;
export const AccountSelectorSection_AccountsFragmentDoc = gql`
  fragment AccountSelectorSection_accounts on AccountProjection {
    id
    name
    profile {
      value {
        id
        firstName
        lastName
        emailAddress
      }
    }
  }
`;
export const AccountTypeDetails_AccountTypeInfoFragmentDoc = gql`
  fragment AccountTypeDetails_AccountTypeInfo on AccountTypeProjection {
    id
    name
    description
    checkType
    approvedDomains
    rejectedDomains
    linkedApplications {
      value {
        ...LinkAppsSection_linkAppInfo
      }
    }
    tags {
      value {
        ...TagSelection_Tags
      }
    }
    admins {
      value {
        ...AccountSelectorSection_accounts
      }
    }
    approvers {
      value {
        ...AccountSelectorSection_accounts
      }
    }
  }
  ${LinkAppsSection_LinkAppInfoFragmentDoc}
  ${TagSelection_TagsFragmentDoc}
  ${AccountSelectorSection_AccountsFragmentDoc}
`;
export const SectionRoles_RolesByAccountTypeFragmentDoc = gql`
  fragment SectionRoles_RolesByAccountType on RoleProjection {
    id
    name
  }
`;
export const MyAppGroupAppDetailsFragmentDoc = gql`
  fragment myAppGroupAppDetails on ApplicationProjection {
    id
    name
    slug
    shortDescription
    longDescription
    isLive
    proxyLocation
    prospectiveAllowed
    isRoot
    type
    acronym
  }
`;
export const MyAppGroupsApplicationFragmentDoc = gql`
  fragment myAppGroupsApplication on AccountGroupToApplicationRelationship {
    label
    value {
      ...myAppGroupAppDetails
    }
  }
  ${MyAppGroupAppDetailsFragmentDoc}
`;
export const MyAppGroupsCoreFragmentDoc = gql`
  fragment myAppGroupsCore on AccountApplicationGroupProjection {
    id
    name
    applications {
      ...myAppGroupsApplication
    }
  }
  ${MyAppGroupsApplicationFragmentDoc}
`;
export const MyAppGroupAccountInfoFragmentDoc = gql`
  fragment myAppGroupAccountInfo on AccountProjection {
    applicationGroups {
      ...myAppGroupsCore
    }
  }
  ${MyAppGroupsCoreFragmentDoc}
`;
export const TagForApplicationFragmentDoc = gql`
  fragment tagForApplication on TagProjection {
    id
    name
  }
`;
export const LoggedInProfileAccountInfoFragmentDoc = gql`
  fragment loggedInProfileAccountInfo on AccountProjection {
    id
    name
    accountTypeId
    status
  }
`;
export const LogginProfileAccountRelationshipFragmentDoc = gql`
  fragment logginProfileAccountRelationship on ProfileToAccountRelationship {
    value {
      ...loggedInProfileAccountInfo
    }
  }
  ${LoggedInProfileAccountInfoFragmentDoc}
`;
export const LoggedInProfileFullFragmentDoc = gql`
  fragment loggedInProfileFull on ProfileProjection {
    id
    title
    firstName
    lastName
    preferredName
    emailAddress
    countryCode
    contactNumber
    provider
    providerId
    selectedAccountId
    invitationStatus
    TAndCAgreed
    status
    accounts {
      ...logginProfileAccountRelationship
    }
  }
  ${LogginProfileAccountRelationshipFragmentDoc}
`;
export const CreateAccType_App_TagFragmentDoc = gql`
  fragment createAccType_app_tag on TagProjection {
    id
    name
  }
`;
export const CreateAccType_App_Tag_RelationFragmentDoc = gql`
  fragment createAccType_app_tag_relation on ApplicationToTagRelationship {
    label
    value {
      ...createAccType_app_tag
    }
  }
  ${CreateAccType_App_TagFragmentDoc}
`;
export const CreateAccType_ApplicationFragmentDoc = gql`
  fragment createAccType_application on ApplicationProjection {
    id
    acronym
    name
    slug
    shortDescription
    longDescription
    isLive
    proxyLocation
    prospectiveAllowed
    isRoot
    type
    admins {
      value {
        ...AccountSelectorSection_accounts
      }
    }
    approvers {
      value {
        ...AccountSelectorSection_accounts
      }
    }
    tags {
      ...createAccType_app_tag_relation
    }
  }
  ${AccountSelectorSection_AccountsFragmentDoc}
  ${CreateAccType_App_Tag_RelationFragmentDoc}
`;
export const DeleteAccount_AccountFragmentDoc = gql`
  fragment DeleteAccount_Account on AccountProjection {
    id
    name
    status
  }
`;
export const DeleteAccount_MyProfileFragmentDoc = gql`
  fragment DeleteAccount_MyProfile on ProfileProjection {
    id
    invitationStatus
    selectedAccountId
    accounts {
      value {
        ...DeleteAccount_Account
      }
    }
  }
  ${DeleteAccount_AccountFragmentDoc}
`;
export const InviteProfileForm_RolesByAccountTypeFragmentDoc = gql`
  fragment InviteProfileForm_RolesByAccountType on RoleProjection {
    id
    name
    hiddenForRequests
  }
`;
export const InviteProfileForm_AccountTypesFragmentDoc = gql`
  fragment InviteProfileForm_AccountTypes on AccountTypeProjection {
    id
    name
    checkType
  }
`;
export const InviteProfileForm_TagsByAccountTypeFragmentDoc = gql`
  fragment InviteProfileForm_TagsByAccountType on TagProjection {
    id
    name
  }
`;
export const LoginPage_MyProfileFragmentDoc = gql`
  fragment LoginPage_MyProfile on ProfileProjection {
    id
    invitationStatus
    preferredName
    firstName
    lastName
    emailAddress
    title
    contactNumber
    countryCode
    selectedAccountId
    accounts {
      value {
        id
        name
        status
      }
    }
  }
`;
export const RoleRequestNotification_RoleInfoFragmentDoc = gql`
  fragment RoleRequestNotification_RoleInfo on RoleProjection {
    id
    name
    accountType {
      value {
        name
      }
    }
  }
`;
export const AccountRequestNotification_AccountTypeDetailsFragmentDoc = gql`
  fragment AccountRequestNotification_AccountTypeDetails on AccountTypeProjection {
    id
    name
  }
`;
export const Notifications_AccountInfoFragmentDoc = gql`
  fragment Notifications_AccountInfo on AccountProjection {
    id
    name
    accountTypeId
    isPartner
    partnerName
    partnerContact
    accountType {
      ...AccountRequestNotification_AccountTypeDetails
      checkType
    }
    profile {
      value {
        firstName
        lastName
        emailAddress
      }
    }
  }
  ${AccountRequestNotification_AccountTypeDetailsFragmentDoc}
`;
export const Notification_InfoFragmentDoc = gql`
  fragment Notification_Info on NotificationProjection {
    entityId
    uid
    context
    message
    readIds
    heading
    type
    createdAt
    audience
    audienceId
    authorId
    category
    targetId
    sourceId
  }
`;
export const ProfilePage_AccountTypesFragmentDoc = gql`
  fragment ProfilePage_AccountTypes on AccountTypeProjection {
    id
    name
  }
`;
export const ProfilePage_TagsFragmentDoc = gql`
  fragment ProfilePage_Tags on TagProjection {
    id
    name
  }
`;
export const ProfilePage_RolesFragmentDoc = gql`
  fragment ProfilePage_Roles on RoleProjection {
    id
    name
  }
`;
export const ProfilePage_HierarchyFragmentDoc = gql`
  fragment ProfilePage_Hierarchy on HierarchyProjection {
    id
    name
    level
  }
`;
export const ProfilePage_ProfileFragmentDoc = gql`
  fragment ProfilePage_Profile on ProfileProjection {
    id
    firstName
    lastName
    preferredName
    title
    emailAddress
    countryCode
    contactNumber
    accounts {
      value {
        id
        name
        organisationGroups {
          id
          name
        }
      }
    }
  }
`;
export const Prospective_ApplicationInfoFragmentDoc = gql`
  fragment Prospective_ApplicationInfo on ApplicationProjection {
    id
    acronym
    name
    shortDescription
    longDescription
    slug
    isLive
    proxyLocation
    isRoot
    type
  }
`;
export const RequestAccountForm_GetAccountTypeFragmentDoc = gql`
  fragment RequestAccountForm_GetAccountType on AccountTypeProjection {
    id
    name
    checkType
  }
`;
export const RequestAccountForm_GetRolesByAccountTypeFragmentDoc = gql`
  fragment RequestAccountForm_GetRolesByAccountType on RoleProjection {
    id
    name
    description
  }
`;
export const RequestAccountForm_TagsByAccountTypeFragmentDoc = gql`
  fragment RequestAccountForm_TagsByAccountType on TagProjection {
    id
    name
  }
`;
export const RoleDetailsSection_RoleDetailsFragmentDoc = gql`
  fragment RoleDetailsSection_RoleDetails on RoleProjection {
    id
    name
    description
  }
`;
export const RoleApplicationsSection_ApplicationInfoFragmentDoc = gql`
  fragment RoleApplicationsSection_ApplicationInfo on ApplicationProjection {
    id
    acronym
    name
    shortDescription
    slug
    isLive
    proxyLocation
  }
`;
export const RoleDetailsSection_PermissionDetailsFragmentDoc = gql`
  fragment RoleDetailsSection_PermissionDetails on PermissionProjection {
    id
    name
  }
`;
export const RoleDetailsSection_PermissionGroupDetailsFragmentDoc = gql`
  fragment RoleDetailsSection_PermissionGroupDetails on PermissionGroupProjection {
    id
    name
  }
`;
export const ManageRole_RoleInfoFragmentDoc = gql`
  fragment ManageRole_RoleInfo on RoleProjection {
    id
    name
    description
    ...RoleDetailsSection_RoleDetails
    accountType {
      value {
        id
        name
      }
    }
    roleApplications {
      value {
        ...RoleApplicationsSection_ApplicationInfo
      }
    }
    rolePermissions {
      value {
        ...RoleDetailsSection_PermissionDetails
      }
    }
    rolePermissionGroups {
      value {
        ...RoleDetailsSection_PermissionGroupDetails
      }
    }
    tags {
      value {
        ...TagSelection_Tags
      }
    }
  }
  ${RoleDetailsSection_RoleDetailsFragmentDoc}
  ${RoleApplicationsSection_ApplicationInfoFragmentDoc}
  ${RoleDetailsSection_PermissionDetailsFragmentDoc}
  ${RoleDetailsSection_PermissionGroupDetailsFragmentDoc}
  ${TagSelection_TagsFragmentDoc}
`;
export const RoleHiddenSection_RoleHiddenFragmentDoc = gql`
  fragment RoleHiddenSection_RoleHidden on RoleProjection {
    id
    name
    hiddenForRequests
    accountType {
      value {
        id
      }
    }
  }
`;
export const DeleteTagFragmentDoc = gql`
  fragment DeleteTag on TagProjection {
    id
    name
  }
`;
export const TagManagerFragmentDoc = gql`
  fragment TagManager on TagProjection {
    id
    name
    description
  }
`;
export const TermsAndConditions_GroupSitesFragmentDoc = gql`
  fragment termsAndConditions_GroupSites on SiteProjection {
    id
    name
    supplierCode
    supplierNumber
    approvedDomains
    rejectedDomains
    status
    tncStatus
  }
`;
export const PermissionGroupComp_ApplicationInfoFragmentDoc = gql`
  fragment PermissionGroupComp_ApplicationInfo on ApplicationProjection {
    id
    name
  }
`;
export const PermissionGroupComp_PermissionGroupFragmentDoc = gql`
  fragment PermissionGroupComp_PermissionGroup on PermissionGroupProjection {
    id
    name
    applicationId
    permissions {
      value {
        id
        name
      }
    }
  }
`;
export const ViewApplicaion_ApplicationInfoFragmentDoc = gql`
  fragment ViewApplicaion_ApplicationInfo on ApplicationProjection {
    id
    name
    acronym
    shortDescription
    longDescription
    admins {
      value {
        ...AccountSelectorSection_accounts
      }
    }
    approvers {
      value {
        ...AccountSelectorSection_accounts
      }
    }
    tags {
      value {
        ...TagSelection_Tags
      }
    }
    proxyLocation
    isLive
    prospectiveAllowed
    linkedApplications {
      value {
        ...LinkAppsSection_linkAppInfo
      }
    }
    slug
    type
  }
  ${AccountSelectorSection_AccountsFragmentDoc}
  ${TagSelection_TagsFragmentDoc}
  ${LinkAppsSection_LinkAppInfoFragmentDoc}
`;
export const ViewApplicaion_GetPermissionsFragmentDoc = gql`
  fragment ViewApplicaion_GetPermissions on PermissionProjection {
    id
    name
  }
`;
export const ViewOrganisation_GetOrganisationFragmentDoc = gql`
  fragment ViewOrganisation_GetOrganisation on OrganisationGroupProjection {
    id
    name
    sites {
      value {
        id
        name
      }
    }
  }
`;
export const AccountSwitcher_AccountInfoFragmentDoc = gql`
  fragment AccountSwitcher_AccountInfo on AccountProjection {
    id
    name
  }
`;
export const UpdateAccountDetailsScreen_AccountInfoFragmentDoc = gql`
  fragment UpdateAccountDetailsScreen_AccountInfo on AccountProjection {
    id
    name
    jobTitle
  }
`;
export const ProfileCard_ProfileInfoFragmentDoc = gql`
  fragment ProfileCard_ProfileInfo on ProfileProjection {
    id
    firstName
    lastName
    preferredName
    emailAddress
    contactNumber
    title
    countryCode
    providerId
    status
  }
`;
export const AddSupplierCode_SiteInfoFragmentDoc = gql`
  fragment AddSupplierCode_SiteInfo on SiteProjection {
    id
    name
  }
`;
export const RemoveSupplierCode_SiteInfoFragmentDoc = gql`
  fragment RemoveSupplierCode_SiteInfo on SiteProjection {
    id
    name
  }
`;
export const SupplierCodesSection_SiteInfoFragmentDoc = gql`
  fragment SupplierCodesSection_SiteInfo on SiteProjection {
    ...AddSupplierCode_SiteInfo
    ...RemoveSupplierCode_SiteInfo
    parentGroup {
      value {
        id
      }
    }
  }
  ${AddSupplierCode_SiteInfoFragmentDoc}
  ${RemoveSupplierCode_SiteInfoFragmentDoc}
`;
export const SupplierCodesSection_AccountToSiteRelationshipFragmentDoc = gql`
  fragment SupplierCodesSection_AccountToSiteRelationship on AccountToSiteRelationship {
    status
    value {
      ...SupplierCodesSection_SiteInfo
    }
  }
  ${SupplierCodesSection_SiteInfoFragmentDoc}
`;
export const ViewProfile_ProfileInfoFragmentDoc = gql`
  fragment ViewProfile_ProfileInfo on ProfileProjection {
    id
    firstName
    selectedAccountId
  }
`;
export const ViewSiteFragmentDoc = gql`
  fragment ViewSite on SiteProjection {
    id
    name
    supplierNumber
    supplierCode
    activeStatus
    status
    approvedDomains
    rejectedDomains
    parentGroup {
      value {
        id
        name
      }
    }
    accounts {
      value {
        ...AccountSelectorSection_accounts
      }
    }
  }
  ${AccountSelectorSection_AccountsFragmentDoc}
`;
export const AddAccountByProfileScreen_ProfilesFragmentDoc = gql`
  fragment AddAccountByProfileScreen_Profiles on ProfileProjection {
    id
    firstName
    lastName
    emailAddress
    accounts {
      value {
        id
        name
      }
    }
  }
`;
export const AddNewAppGroupScreen_ApplicationFragmentDoc = gql`
  fragment AddNewAppGroupScreen_Application on ApplicationProjection {
    id
    name
    acronym
    shortDescription
  }
`;
export const AddNewPermissionToGroupScreen_PermissionInfoFragmentDoc = gql`
  fragment AddNewPermissionToGroupScreen_PermissionInfo on PermissionProjection {
    id
    name
  }
`;
export const EditAppGroupScreen_ApplicationInfoFragmentDoc = gql`
  fragment EditAppGroupScreen_ApplicationInfo on ApplicationProjection {
    id
    name
    acronym
    shortDescription
  }
`;
export const EditTag_TagFragmentDoc = gql`
  fragment EditTag_Tag on TagProjection {
    name
  }
`;
export const AppPermissionFragmentDoc = gql`
  fragment appPermission on PermissionProjection {
    id
    name
    description
    applicationId
  }
`;
export const AppPermissionGroupToPermissionRelationFragmentDoc = gql`
  fragment appPermissionGroupToPermissionRelation on PermissionGroupToPermissionRelationship {
    value {
      ...appPermission
    }
  }
  ${AppPermissionFragmentDoc}
`;
export const AppPermissionGroupFragmentDoc = gql`
  fragment appPermissionGroup on PermissionGroupProjection {
    id
    name
    description
    applicationId
  }
`;
export const ApplicationCoreFragmentDoc = gql`
  fragment ApplicationCore on ApplicationProjection {
    id
    name
    slug
    shortDescription
    longDescription
    isLive
    proxyLocation
    prospectiveAllowed
    isRoot
    type
    acronym
  }
`;
export const TagCoreFragmentDoc = gql`
  fragment TagCore on TagProjection {
    id
    name
    description
  }
`;
export const MyAppsGroupTab_AppGroupFragmentDoc = gql`
  fragment MyAppsGroupTab_AppGroup on AccountApplicationGroupProjection {
    id
    name
    applications {
      value {
        ...ApplicationCore
        tags {
          value {
            ...TagCore
          }
        }
      }
    }
  }
  ${ApplicationCoreFragmentDoc}
  ${TagCoreFragmentDoc}
`;
export const MyAppsGroupTab_AccountApplicationGroupsFragmentDoc = gql`
  fragment MyAppsGroupTab_AccountApplicationGroups on AccountProjection {
    applicationGroups {
      ...MyAppsGroupTab_AppGroup
    }
  }
  ${MyAppsGroupTab_AppGroupFragmentDoc}
`;
export const ProtectedLayout_MyProfileDocument = gql`
  query ProtectedLayout_MyProfile {
    myProfile {
      id
      firstName
      lastName
      preferredName
      emailAddress
      title
      contactNumber
      countryCode
      selectedAccountId
      status
      accounts {
        value {
          ...SelectedAccount
        }
      }
    }
  }
  ${SelectedAccountFragmentDoc}
`;

/**
 * __useProtectedLayout_MyProfileQuery__
 *
 * To run a query within a React component, call `useProtectedLayout_MyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProtectedLayout_MyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProtectedLayout_MyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProtectedLayout_MyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProtectedLayout_MyProfileQuery,
    ProtectedLayout_MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProtectedLayout_MyProfileQuery,
    ProtectedLayout_MyProfileQueryVariables
  >(ProtectedLayout_MyProfileDocument, options);
}
export function useProtectedLayout_MyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProtectedLayout_MyProfileQuery,
    ProtectedLayout_MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProtectedLayout_MyProfileQuery,
    ProtectedLayout_MyProfileQueryVariables
  >(ProtectedLayout_MyProfileDocument, options);
}
export function useProtectedLayout_MyProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProtectedLayout_MyProfileQuery,
    ProtectedLayout_MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProtectedLayout_MyProfileQuery,
    ProtectedLayout_MyProfileQueryVariables
  >(ProtectedLayout_MyProfileDocument, options);
}
export type ProtectedLayout_MyProfileQueryHookResult = ReturnType<
  typeof useProtectedLayout_MyProfileQuery
>;
export type ProtectedLayout_MyProfileLazyQueryHookResult = ReturnType<
  typeof useProtectedLayout_MyProfileLazyQuery
>;
export type ProtectedLayout_MyProfileSuspenseQueryHookResult = ReturnType<
  typeof useProtectedLayout_MyProfileSuspenseQuery
>;
export type ProtectedLayout_MyProfileQueryResult = Apollo.QueryResult<
  ProtectedLayout_MyProfileQuery,
  ProtectedLayout_MyProfileQueryVariables
>;
export const AcceptTAndCDocument = gql`
  mutation acceptTAndC($supplierCode: String!) {
    acceptTAndC(supplierCode: $supplierCode)
  }
`;
export type AcceptTAndCMutationFn = Apollo.MutationFunction<
  AcceptTAndCMutation,
  AcceptTAndCMutationVariables
>;

/**
 * __useAcceptTAndCMutation__
 *
 * To run a mutation, you first call `useAcceptTAndCMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTAndCMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTAndCMutation, { data, loading, error }] = useAcceptTAndCMutation({
 *   variables: {
 *      supplierCode: // value for 'supplierCode'
 *   },
 * });
 */
export function useAcceptTAndCMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTAndCMutation,
    AcceptTAndCMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptTAndCMutation, AcceptTAndCMutationVariables>(
    AcceptTAndCDocument,
    options,
  );
}
export type AcceptTAndCMutationHookResult = ReturnType<
  typeof useAcceptTAndCMutation
>;
export type AcceptTAndCMutationResult =
  Apollo.MutationResult<AcceptTAndCMutation>;
export type AcceptTAndCMutationOptions = Apollo.BaseMutationOptions<
  AcceptTAndCMutation,
  AcceptTAndCMutationVariables
>;
export const AccountUpdateDetailsDocument = gql`
  mutation accountUpdateDetails($id: String!, $input: AccountDetails!) {
    accountUpdateDetails(id: $id, input: $input) {
      id
    }
  }
`;
export type AccountUpdateDetailsMutationFn = Apollo.MutationFunction<
  AccountUpdateDetailsMutation,
  AccountUpdateDetailsMutationVariables
>;

/**
 * __useAccountUpdateDetailsMutation__
 *
 * To run a mutation, you first call `useAccountUpdateDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountUpdateDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountUpdateDetailsMutation, { data, loading, error }] = useAccountUpdateDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountUpdateDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AccountUpdateDetailsMutation,
    AccountUpdateDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AccountUpdateDetailsMutation,
    AccountUpdateDetailsMutationVariables
  >(AccountUpdateDetailsDocument, options);
}
export type AccountUpdateDetailsMutationHookResult = ReturnType<
  typeof useAccountUpdateDetailsMutation
>;
export type AccountUpdateDetailsMutationResult =
  Apollo.MutationResult<AccountUpdateDetailsMutation>;
export type AccountUpdateDetailsMutationOptions = Apollo.BaseMutationOptions<
  AccountUpdateDetailsMutation,
  AccountUpdateDetailsMutationVariables
>;
export const AddApplicationAudienceForAccountTypeDocument = gql`
  mutation AddApplicationAudienceForAccountType(
    $accountTypeId: String!
    $applicationIds: [String!]!
  ) {
    addApplicationAudienceForAccountType(
      accountTypeId: $accountTypeId
      applicationIds: $applicationIds
    ) {
      id
    }
  }
`;
export type AddApplicationAudienceForAccountTypeMutationFn =
  Apollo.MutationFunction<
    AddApplicationAudienceForAccountTypeMutation,
    AddApplicationAudienceForAccountTypeMutationVariables
  >;

/**
 * __useAddApplicationAudienceForAccountTypeMutation__
 *
 * To run a mutation, you first call `useAddApplicationAudienceForAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApplicationAudienceForAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApplicationAudienceForAccountTypeMutation, { data, loading, error }] = useAddApplicationAudienceForAccountTypeMutation({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *      applicationIds: // value for 'applicationIds'
 *   },
 * });
 */
export function useAddApplicationAudienceForAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddApplicationAudienceForAccountTypeMutation,
    AddApplicationAudienceForAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddApplicationAudienceForAccountTypeMutation,
    AddApplicationAudienceForAccountTypeMutationVariables
  >(AddApplicationAudienceForAccountTypeDocument, options);
}
export type AddApplicationAudienceForAccountTypeMutationHookResult = ReturnType<
  typeof useAddApplicationAudienceForAccountTypeMutation
>;
export type AddApplicationAudienceForAccountTypeMutationResult =
  Apollo.MutationResult<AddApplicationAudienceForAccountTypeMutation>;
export type AddApplicationAudienceForAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    AddApplicationAudienceForAccountTypeMutation,
    AddApplicationAudienceForAccountTypeMutationVariables
  >;
export const AddApprovedDomainToAccountTypeDocument = gql`
  mutation addApprovedDomainToAccountType($id: String!, $domain: String!) {
    addApprovedDomainToAccountType(id: $id, domain: $domain) {
      id
    }
  }
`;
export type AddApprovedDomainToAccountTypeMutationFn = Apollo.MutationFunction<
  AddApprovedDomainToAccountTypeMutation,
  AddApprovedDomainToAccountTypeMutationVariables
>;

/**
 * __useAddApprovedDomainToAccountTypeMutation__
 *
 * To run a mutation, you first call `useAddApprovedDomainToAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApprovedDomainToAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApprovedDomainToAccountTypeMutation, { data, loading, error }] = useAddApprovedDomainToAccountTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useAddApprovedDomainToAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddApprovedDomainToAccountTypeMutation,
    AddApprovedDomainToAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddApprovedDomainToAccountTypeMutation,
    AddApprovedDomainToAccountTypeMutationVariables
  >(AddApprovedDomainToAccountTypeDocument, options);
}
export type AddApprovedDomainToAccountTypeMutationHookResult = ReturnType<
  typeof useAddApprovedDomainToAccountTypeMutation
>;
export type AddApprovedDomainToAccountTypeMutationResult =
  Apollo.MutationResult<AddApprovedDomainToAccountTypeMutation>;
export type AddApprovedDomainToAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    AddApprovedDomainToAccountTypeMutation,
    AddApprovedDomainToAccountTypeMutationVariables
  >;
export const AddApprovedDomainToSiteDocument = gql`
  mutation addApprovedDomainToSite($id: String!, $domain: String!) {
    addApprovedDomainToSite(id: $id, domain: $domain) {
      id
    }
  }
`;
export type AddApprovedDomainToSiteMutationFn = Apollo.MutationFunction<
  AddApprovedDomainToSiteMutation,
  AddApprovedDomainToSiteMutationVariables
>;

/**
 * __useAddApprovedDomainToSiteMutation__
 *
 * To run a mutation, you first call `useAddApprovedDomainToSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApprovedDomainToSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApprovedDomainToSiteMutation, { data, loading, error }] = useAddApprovedDomainToSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useAddApprovedDomainToSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddApprovedDomainToSiteMutation,
    AddApprovedDomainToSiteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddApprovedDomainToSiteMutation,
    AddApprovedDomainToSiteMutationVariables
  >(AddApprovedDomainToSiteDocument, options);
}
export type AddApprovedDomainToSiteMutationHookResult = ReturnType<
  typeof useAddApprovedDomainToSiteMutation
>;
export type AddApprovedDomainToSiteMutationResult =
  Apollo.MutationResult<AddApprovedDomainToSiteMutation>;
export type AddApprovedDomainToSiteMutationOptions = Apollo.BaseMutationOptions<
  AddApprovedDomainToSiteMutation,
  AddApprovedDomainToSiteMutationVariables
>;
export const AddRejectedDomainToAccountTypeDocument = gql`
  mutation addRejectedDomainToAccountType($id: String!, $domain: String!) {
    addRejectedDomainToAccountType(id: $id, domain: $domain) {
      id
    }
  }
`;
export type AddRejectedDomainToAccountTypeMutationFn = Apollo.MutationFunction<
  AddRejectedDomainToAccountTypeMutation,
  AddRejectedDomainToAccountTypeMutationVariables
>;

/**
 * __useAddRejectedDomainToAccountTypeMutation__
 *
 * To run a mutation, you first call `useAddRejectedDomainToAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRejectedDomainToAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRejectedDomainToAccountTypeMutation, { data, loading, error }] = useAddRejectedDomainToAccountTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useAddRejectedDomainToAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRejectedDomainToAccountTypeMutation,
    AddRejectedDomainToAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRejectedDomainToAccountTypeMutation,
    AddRejectedDomainToAccountTypeMutationVariables
  >(AddRejectedDomainToAccountTypeDocument, options);
}
export type AddRejectedDomainToAccountTypeMutationHookResult = ReturnType<
  typeof useAddRejectedDomainToAccountTypeMutation
>;
export type AddRejectedDomainToAccountTypeMutationResult =
  Apollo.MutationResult<AddRejectedDomainToAccountTypeMutation>;
export type AddRejectedDomainToAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    AddRejectedDomainToAccountTypeMutation,
    AddRejectedDomainToAccountTypeMutationVariables
  >;
export const AddRejectedDomainToSiteDocument = gql`
  mutation addRejectedDomainToSite($id: String!, $domain: String!) {
    addRejectedDomainToSite(id: $id, domain: $domain) {
      id
    }
  }
`;
export type AddRejectedDomainToSiteMutationFn = Apollo.MutationFunction<
  AddRejectedDomainToSiteMutation,
  AddRejectedDomainToSiteMutationVariables
>;

/**
 * __useAddRejectedDomainToSiteMutation__
 *
 * To run a mutation, you first call `useAddRejectedDomainToSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRejectedDomainToSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRejectedDomainToSiteMutation, { data, loading, error }] = useAddRejectedDomainToSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useAddRejectedDomainToSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRejectedDomainToSiteMutation,
    AddRejectedDomainToSiteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRejectedDomainToSiteMutation,
    AddRejectedDomainToSiteMutationVariables
  >(AddRejectedDomainToSiteDocument, options);
}
export type AddRejectedDomainToSiteMutationHookResult = ReturnType<
  typeof useAddRejectedDomainToSiteMutation
>;
export type AddRejectedDomainToSiteMutationResult =
  Apollo.MutationResult<AddRejectedDomainToSiteMutation>;
export type AddRejectedDomainToSiteMutationOptions = Apollo.BaseMutationOptions<
  AddRejectedDomainToSiteMutation,
  AddRejectedDomainToSiteMutationVariables
>;
export const Add_Tags_To_RoleDocument = gql`
  mutation ADD_TAGS_TO_ROLE($id: String!, $tagIds: [String!]!) {
    roleAddTags(id: $id, tagIds: $tagIds) {
      id
    }
  }
`;
export type Add_Tags_To_RoleMutationFn = Apollo.MutationFunction<
  Add_Tags_To_RoleMutation,
  Add_Tags_To_RoleMutationVariables
>;

/**
 * __useAdd_Tags_To_RoleMutation__
 *
 * To run a mutation, you first call `useAdd_Tags_To_RoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Tags_To_RoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagsToRoleMutation, { data, loading, error }] = useAdd_Tags_To_RoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useAdd_Tags_To_RoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Add_Tags_To_RoleMutation,
    Add_Tags_To_RoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Add_Tags_To_RoleMutation,
    Add_Tags_To_RoleMutationVariables
  >(Add_Tags_To_RoleDocument, options);
}
export type Add_Tags_To_RoleMutationHookResult = ReturnType<
  typeof useAdd_Tags_To_RoleMutation
>;
export type Add_Tags_To_RoleMutationResult =
  Apollo.MutationResult<Add_Tags_To_RoleMutation>;
export type Add_Tags_To_RoleMutationOptions = Apollo.BaseMutationOptions<
  Add_Tags_To_RoleMutation,
  Add_Tags_To_RoleMutationVariables
>;
export const ApproveAccountTypeForAccountDocument = gql`
  mutation approveAccountTypeForAccount(
    $accountTypeId: String!
    $accountId: String!
  ) {
    approveAccountTypeForAccount(
      accountTypeId: $accountTypeId
      accountId: $accountId
    ) {
      id
    }
  }
`;
export type ApproveAccountTypeForAccountMutationFn = Apollo.MutationFunction<
  ApproveAccountTypeForAccountMutation,
  ApproveAccountTypeForAccountMutationVariables
>;

/**
 * __useApproveAccountTypeForAccountMutation__
 *
 * To run a mutation, you first call `useApproveAccountTypeForAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAccountTypeForAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAccountTypeForAccountMutation, { data, loading, error }] = useApproveAccountTypeForAccountMutation({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useApproveAccountTypeForAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveAccountTypeForAccountMutation,
    ApproveAccountTypeForAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveAccountTypeForAccountMutation,
    ApproveAccountTypeForAccountMutationVariables
  >(ApproveAccountTypeForAccountDocument, options);
}
export type ApproveAccountTypeForAccountMutationHookResult = ReturnType<
  typeof useApproveAccountTypeForAccountMutation
>;
export type ApproveAccountTypeForAccountMutationResult =
  Apollo.MutationResult<ApproveAccountTypeForAccountMutation>;
export type ApproveAccountTypeForAccountMutationOptions =
  Apollo.BaseMutationOptions<
    ApproveAccountTypeForAccountMutation,
    ApproveAccountTypeForAccountMutationVariables
  >;
export const ApproveRolesRequestDocument = gql`
  mutation approveRolesRequest($input: RolesRequestInput!) {
    approveRolesRequest(input: $input) {
      id
    }
  }
`;
export type ApproveRolesRequestMutationFn = Apollo.MutationFunction<
  ApproveRolesRequestMutation,
  ApproveRolesRequestMutationVariables
>;

/**
 * __useApproveRolesRequestMutation__
 *
 * To run a mutation, you first call `useApproveRolesRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveRolesRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveRolesRequestMutation, { data, loading, error }] = useApproveRolesRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveRolesRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveRolesRequestMutation,
    ApproveRolesRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveRolesRequestMutation,
    ApproveRolesRequestMutationVariables
  >(ApproveRolesRequestDocument, options);
}
export type ApproveRolesRequestMutationHookResult = ReturnType<
  typeof useApproveRolesRequestMutation
>;
export type ApproveRolesRequestMutationResult =
  Apollo.MutationResult<ApproveRolesRequestMutation>;
export type ApproveRolesRequestMutationOptions = Apollo.BaseMutationOptions<
  ApproveRolesRequestMutation,
  ApproveRolesRequestMutationVariables
>;
export const SiteAddApprovedAccountsDocument = gql`
  mutation siteAddApprovedAccounts($siteId: String!, $accountIds: [String!]!) {
    siteAddApprovedAccounts(siteId: $siteId, accountIds: $accountIds) {
      id
    }
  }
`;
export type SiteAddApprovedAccountsMutationFn = Apollo.MutationFunction<
  SiteAddApprovedAccountsMutation,
  SiteAddApprovedAccountsMutationVariables
>;

/**
 * __useSiteAddApprovedAccountsMutation__
 *
 * To run a mutation, you first call `useSiteAddApprovedAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteAddApprovedAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteAddApprovedAccountsMutation, { data, loading, error }] = useSiteAddApprovedAccountsMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useSiteAddApprovedAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SiteAddApprovedAccountsMutation,
    SiteAddApprovedAccountsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SiteAddApprovedAccountsMutation,
    SiteAddApprovedAccountsMutationVariables
  >(SiteAddApprovedAccountsDocument, options);
}
export type SiteAddApprovedAccountsMutationHookResult = ReturnType<
  typeof useSiteAddApprovedAccountsMutation
>;
export type SiteAddApprovedAccountsMutationResult =
  Apollo.MutationResult<SiteAddApprovedAccountsMutation>;
export type SiteAddApprovedAccountsMutationOptions = Apollo.BaseMutationOptions<
  SiteAddApprovedAccountsMutation,
  SiteAddApprovedAccountsMutationVariables
>;
export const AddApplicationsToMyGroupDocument = gql`
  mutation addApplicationsToMyGroup($input: AccountApplicationsGroups!) {
    addApplicationsToMyGroup(input: $input) {
      id
    }
  }
`;
export type AddApplicationsToMyGroupMutationFn = Apollo.MutationFunction<
  AddApplicationsToMyGroupMutation,
  AddApplicationsToMyGroupMutationVariables
>;

/**
 * __useAddApplicationsToMyGroupMutation__
 *
 * To run a mutation, you first call `useAddApplicationsToMyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApplicationsToMyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApplicationsToMyGroupMutation, { data, loading, error }] = useAddApplicationsToMyGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddApplicationsToMyGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddApplicationsToMyGroupMutation,
    AddApplicationsToMyGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddApplicationsToMyGroupMutation,
    AddApplicationsToMyGroupMutationVariables
  >(AddApplicationsToMyGroupDocument, options);
}
export type AddApplicationsToMyGroupMutationHookResult = ReturnType<
  typeof useAddApplicationsToMyGroupMutation
>;
export type AddApplicationsToMyGroupMutationResult =
  Apollo.MutationResult<AddApplicationsToMyGroupMutation>;
export type AddApplicationsToMyGroupMutationOptions =
  Apollo.BaseMutationOptions<
    AddApplicationsToMyGroupMutation,
    AddApplicationsToMyGroupMutationVariables
  >;
export const AssociateAccountToSitesDocument = gql`
  mutation associateAccountToSites($id: String!, $siteIds: [String!]!) {
    associateAccountToSites(id: $id, siteIds: $siteIds) {
      id
    }
  }
`;
export type AssociateAccountToSitesMutationFn = Apollo.MutationFunction<
  AssociateAccountToSitesMutation,
  AssociateAccountToSitesMutationVariables
>;

/**
 * __useAssociateAccountToSitesMutation__
 *
 * To run a mutation, you first call `useAssociateAccountToSitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateAccountToSitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateAccountToSitesMutation, { data, loading, error }] = useAssociateAccountToSitesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      siteIds: // value for 'siteIds'
 *   },
 * });
 */
export function useAssociateAccountToSitesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateAccountToSitesMutation,
    AssociateAccountToSitesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateAccountToSitesMutation,
    AssociateAccountToSitesMutationVariables
  >(AssociateAccountToSitesDocument, options);
}
export type AssociateAccountToSitesMutationHookResult = ReturnType<
  typeof useAssociateAccountToSitesMutation
>;
export type AssociateAccountToSitesMutationResult =
  Apollo.MutationResult<AssociateAccountToSitesMutation>;
export type AssociateAccountToSitesMutationOptions = Apollo.BaseMutationOptions<
  AssociateAccountToSitesMutation,
  AssociateAccountToSitesMutationVariables
>;
export const AssociateAdminsToAccountTypeDocument = gql`
  mutation associateAdminsToAccountType(
    $accountTypeId: String!
    $accountIds: [String!]!
  ) {
    associateAdminsToAccountType(
      accountTypeId: $accountTypeId
      accountIds: $accountIds
    ) {
      id
    }
  }
`;
export type AssociateAdminsToAccountTypeMutationFn = Apollo.MutationFunction<
  AssociateAdminsToAccountTypeMutation,
  AssociateAdminsToAccountTypeMutationVariables
>;

/**
 * __useAssociateAdminsToAccountTypeMutation__
 *
 * To run a mutation, you first call `useAssociateAdminsToAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateAdminsToAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateAdminsToAccountTypeMutation, { data, loading, error }] = useAssociateAdminsToAccountTypeMutation({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useAssociateAdminsToAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateAdminsToAccountTypeMutation,
    AssociateAdminsToAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateAdminsToAccountTypeMutation,
    AssociateAdminsToAccountTypeMutationVariables
  >(AssociateAdminsToAccountTypeDocument, options);
}
export type AssociateAdminsToAccountTypeMutationHookResult = ReturnType<
  typeof useAssociateAdminsToAccountTypeMutation
>;
export type AssociateAdminsToAccountTypeMutationResult =
  Apollo.MutationResult<AssociateAdminsToAccountTypeMutation>;
export type AssociateAdminsToAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    AssociateAdminsToAccountTypeMutation,
    AssociateAdminsToAccountTypeMutationVariables
  >;
export const AssociateAdminsToApplicationDocument = gql`
  mutation associateAdminsToApplication(
    $applicationId: String!
    $accountIds: [String!]!
  ) {
    associateAdminsToApplication(
      applicationId: $applicationId
      accountIds: $accountIds
    ) {
      id
    }
  }
`;
export type AssociateAdminsToApplicationMutationFn = Apollo.MutationFunction<
  AssociateAdminsToApplicationMutation,
  AssociateAdminsToApplicationMutationVariables
>;

/**
 * __useAssociateAdminsToApplicationMutation__
 *
 * To run a mutation, you first call `useAssociateAdminsToApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateAdminsToApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateAdminsToApplicationMutation, { data, loading, error }] = useAssociateAdminsToApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useAssociateAdminsToApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateAdminsToApplicationMutation,
    AssociateAdminsToApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateAdminsToApplicationMutation,
    AssociateAdminsToApplicationMutationVariables
  >(AssociateAdminsToApplicationDocument, options);
}
export type AssociateAdminsToApplicationMutationHookResult = ReturnType<
  typeof useAssociateAdminsToApplicationMutation
>;
export type AssociateAdminsToApplicationMutationResult =
  Apollo.MutationResult<AssociateAdminsToApplicationMutation>;
export type AssociateAdminsToApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    AssociateAdminsToApplicationMutation,
    AssociateAdminsToApplicationMutationVariables
  >;
export const AssociateApproversToAccountTypeDocument = gql`
  mutation associateApproversToAccountType(
    $accountTypeId: String!
    $accountIds: [String!]!
  ) {
    associateApproversToAccountType(
      accountTypeId: $accountTypeId
      accountIds: $accountIds
    ) {
      id
    }
  }
`;
export type AssociateApproversToAccountTypeMutationFn = Apollo.MutationFunction<
  AssociateApproversToAccountTypeMutation,
  AssociateApproversToAccountTypeMutationVariables
>;

/**
 * __useAssociateApproversToAccountTypeMutation__
 *
 * To run a mutation, you first call `useAssociateApproversToAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateApproversToAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateApproversToAccountTypeMutation, { data, loading, error }] = useAssociateApproversToAccountTypeMutation({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useAssociateApproversToAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateApproversToAccountTypeMutation,
    AssociateApproversToAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateApproversToAccountTypeMutation,
    AssociateApproversToAccountTypeMutationVariables
  >(AssociateApproversToAccountTypeDocument, options);
}
export type AssociateApproversToAccountTypeMutationHookResult = ReturnType<
  typeof useAssociateApproversToAccountTypeMutation
>;
export type AssociateApproversToAccountTypeMutationResult =
  Apollo.MutationResult<AssociateApproversToAccountTypeMutation>;
export type AssociateApproversToAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    AssociateApproversToAccountTypeMutation,
    AssociateApproversToAccountTypeMutationVariables
  >;
export const AssociateApproversToApplicationDocument = gql`
  mutation associateApproversToApplication(
    $applicationId: String!
    $accountIds: [String!]!
  ) {
    associateApproversToApplication(
      applicationId: $applicationId
      accountIds: $accountIds
    ) {
      id
    }
  }
`;
export type AssociateApproversToApplicationMutationFn = Apollo.MutationFunction<
  AssociateApproversToApplicationMutation,
  AssociateApproversToApplicationMutationVariables
>;

/**
 * __useAssociateApproversToApplicationMutation__
 *
 * To run a mutation, you first call `useAssociateApproversToApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateApproversToApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateApproversToApplicationMutation, { data, loading, error }] = useAssociateApproversToApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useAssociateApproversToApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateApproversToApplicationMutation,
    AssociateApproversToApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateApproversToApplicationMutation,
    AssociateApproversToApplicationMutationVariables
  >(AssociateApproversToApplicationDocument, options);
}
export type AssociateApproversToApplicationMutationHookResult = ReturnType<
  typeof useAssociateApproversToApplicationMutation
>;
export type AssociateApproversToApplicationMutationResult =
  Apollo.MutationResult<AssociateApproversToApplicationMutation>;
export type AssociateApproversToApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    AssociateApproversToApplicationMutation,
    AssociateApproversToApplicationMutationVariables
  >;
export const AssociateDependentApplicationsDocument = gql`
  mutation associateDependentApplications(
    $applicationId: String!
    $dependentApplicationIds: [String!]!
  ) {
    associateDependentApplications(
      applicationId: $applicationId
      dependentApplicationIds: $dependentApplicationIds
    ) {
      id
    }
  }
`;
export type AssociateDependentApplicationsMutationFn = Apollo.MutationFunction<
  AssociateDependentApplicationsMutation,
  AssociateDependentApplicationsMutationVariables
>;

/**
 * __useAssociateDependentApplicationsMutation__
 *
 * To run a mutation, you first call `useAssociateDependentApplicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateDependentApplicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateDependentApplicationsMutation, { data, loading, error }] = useAssociateDependentApplicationsMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      dependentApplicationIds: // value for 'dependentApplicationIds'
 *   },
 * });
 */
export function useAssociateDependentApplicationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateDependentApplicationsMutation,
    AssociateDependentApplicationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateDependentApplicationsMutation,
    AssociateDependentApplicationsMutationVariables
  >(AssociateDependentApplicationsDocument, options);
}
export type AssociateDependentApplicationsMutationHookResult = ReturnType<
  typeof useAssociateDependentApplicationsMutation
>;
export type AssociateDependentApplicationsMutationResult =
  Apollo.MutationResult<AssociateDependentApplicationsMutation>;
export type AssociateDependentApplicationsMutationOptions =
  Apollo.BaseMutationOptions<
    AssociateDependentApplicationsMutation,
    AssociateDependentApplicationsMutationVariables
  >;
export const AssociateGroupsToRoleDocument = gql`
  mutation associateGroupsToRole($input: AssociateGroupsToRoleInput!) {
    associateGroupsToRole(input: $input) {
      id
    }
  }
`;
export type AssociateGroupsToRoleMutationFn = Apollo.MutationFunction<
  AssociateGroupsToRoleMutation,
  AssociateGroupsToRoleMutationVariables
>;

/**
 * __useAssociateGroupsToRoleMutation__
 *
 * To run a mutation, you first call `useAssociateGroupsToRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateGroupsToRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateGroupsToRoleMutation, { data, loading, error }] = useAssociateGroupsToRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssociateGroupsToRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateGroupsToRoleMutation,
    AssociateGroupsToRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateGroupsToRoleMutation,
    AssociateGroupsToRoleMutationVariables
  >(AssociateGroupsToRoleDocument, options);
}
export type AssociateGroupsToRoleMutationHookResult = ReturnType<
  typeof useAssociateGroupsToRoleMutation
>;
export type AssociateGroupsToRoleMutationResult =
  Apollo.MutationResult<AssociateGroupsToRoleMutation>;
export type AssociateGroupsToRoleMutationOptions = Apollo.BaseMutationOptions<
  AssociateGroupsToRoleMutation,
  AssociateGroupsToRoleMutationVariables
>;
export const AssociatePermissionsToGroupDocument = gql`
  mutation associatePermissionsToGroup(
    $input: AssociatePermissionsToGroupInput!
  ) {
    associatePermissionsToGroup(input: $input) {
      groupId
    }
  }
`;
export type AssociatePermissionsToGroupMutationFn = Apollo.MutationFunction<
  AssociatePermissionsToGroupMutation,
  AssociatePermissionsToGroupMutationVariables
>;

/**
 * __useAssociatePermissionsToGroupMutation__
 *
 * To run a mutation, you first call `useAssociatePermissionsToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociatePermissionsToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associatePermissionsToGroupMutation, { data, loading, error }] = useAssociatePermissionsToGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssociatePermissionsToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociatePermissionsToGroupMutation,
    AssociatePermissionsToGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociatePermissionsToGroupMutation,
    AssociatePermissionsToGroupMutationVariables
  >(AssociatePermissionsToGroupDocument, options);
}
export type AssociatePermissionsToGroupMutationHookResult = ReturnType<
  typeof useAssociatePermissionsToGroupMutation
>;
export type AssociatePermissionsToGroupMutationResult =
  Apollo.MutationResult<AssociatePermissionsToGroupMutation>;
export type AssociatePermissionsToGroupMutationOptions =
  Apollo.BaseMutationOptions<
    AssociatePermissionsToGroupMutation,
    AssociatePermissionsToGroupMutationVariables
  >;
export const AssociatePermissionsToRoleDocument = gql`
  mutation associatePermissionsToRole(
    $input: AssociatePermissionsToRoleInput!
  ) {
    associatePermissionsToRole(input: $input) {
      id
    }
  }
`;
export type AssociatePermissionsToRoleMutationFn = Apollo.MutationFunction<
  AssociatePermissionsToRoleMutation,
  AssociatePermissionsToRoleMutationVariables
>;

/**
 * __useAssociatePermissionsToRoleMutation__
 *
 * To run a mutation, you first call `useAssociatePermissionsToRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociatePermissionsToRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associatePermissionsToRoleMutation, { data, loading, error }] = useAssociatePermissionsToRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssociatePermissionsToRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociatePermissionsToRoleMutation,
    AssociatePermissionsToRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociatePermissionsToRoleMutation,
    AssociatePermissionsToRoleMutationVariables
  >(AssociatePermissionsToRoleDocument, options);
}
export type AssociatePermissionsToRoleMutationHookResult = ReturnType<
  typeof useAssociatePermissionsToRoleMutation
>;
export type AssociatePermissionsToRoleMutationResult =
  Apollo.MutationResult<AssociatePermissionsToRoleMutation>;
export type AssociatePermissionsToRoleMutationOptions =
  Apollo.BaseMutationOptions<
    AssociatePermissionsToRoleMutation,
    AssociatePermissionsToRoleMutationVariables
  >;
export const AssociateTagsToAccountDocument = gql`
  mutation associateTagsToAccount($accountId: String!, $tagIds: [String!]!) {
    associateTagsToAccount(accountId: $accountId, tagIds: $tagIds) {
      id
    }
  }
`;
export type AssociateTagsToAccountMutationFn = Apollo.MutationFunction<
  AssociateTagsToAccountMutation,
  AssociateTagsToAccountMutationVariables
>;

/**
 * __useAssociateTagsToAccountMutation__
 *
 * To run a mutation, you first call `useAssociateTagsToAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateTagsToAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateTagsToAccountMutation, { data, loading, error }] = useAssociateTagsToAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useAssociateTagsToAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateTagsToAccountMutation,
    AssociateTagsToAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateTagsToAccountMutation,
    AssociateTagsToAccountMutationVariables
  >(AssociateTagsToAccountDocument, options);
}
export type AssociateTagsToAccountMutationHookResult = ReturnType<
  typeof useAssociateTagsToAccountMutation
>;
export type AssociateTagsToAccountMutationResult =
  Apollo.MutationResult<AssociateTagsToAccountMutation>;
export type AssociateTagsToAccountMutationOptions = Apollo.BaseMutationOptions<
  AssociateTagsToAccountMutation,
  AssociateTagsToAccountMutationVariables
>;
export const AssociateTagsToAccountTypeDocument = gql`
  mutation associateTagsToAccountType(
    $accountTypeId: String!
    $tagIds: [String!]!
  ) {
    associateTagsToAccountType(accountTypeId: $accountTypeId, tagIds: $tagIds) {
      id
    }
  }
`;
export type AssociateTagsToAccountTypeMutationFn = Apollo.MutationFunction<
  AssociateTagsToAccountTypeMutation,
  AssociateTagsToAccountTypeMutationVariables
>;

/**
 * __useAssociateTagsToAccountTypeMutation__
 *
 * To run a mutation, you first call `useAssociateTagsToAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateTagsToAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateTagsToAccountTypeMutation, { data, loading, error }] = useAssociateTagsToAccountTypeMutation({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useAssociateTagsToAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateTagsToAccountTypeMutation,
    AssociateTagsToAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateTagsToAccountTypeMutation,
    AssociateTagsToAccountTypeMutationVariables
  >(AssociateTagsToAccountTypeDocument, options);
}
export type AssociateTagsToAccountTypeMutationHookResult = ReturnType<
  typeof useAssociateTagsToAccountTypeMutation
>;
export type AssociateTagsToAccountTypeMutationResult =
  Apollo.MutationResult<AssociateTagsToAccountTypeMutation>;
export type AssociateTagsToAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    AssociateTagsToAccountTypeMutation,
    AssociateTagsToAccountTypeMutationVariables
  >;
export const AssociateTagsToApplicationDocument = gql`
  mutation associateTagsToApplication($id: String!, $tagIds: [String!]!) {
    associateTagsToApplication(id: $id, tagIds: $tagIds) {
      id
    }
  }
`;
export type AssociateTagsToApplicationMutationFn = Apollo.MutationFunction<
  AssociateTagsToApplicationMutation,
  AssociateTagsToApplicationMutationVariables
>;

/**
 * __useAssociateTagsToApplicationMutation__
 *
 * To run a mutation, you first call `useAssociateTagsToApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateTagsToApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateTagsToApplicationMutation, { data, loading, error }] = useAssociateTagsToApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useAssociateTagsToApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateTagsToApplicationMutation,
    AssociateTagsToApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateTagsToApplicationMutation,
    AssociateTagsToApplicationMutationVariables
  >(AssociateTagsToApplicationDocument, options);
}
export type AssociateTagsToApplicationMutationHookResult = ReturnType<
  typeof useAssociateTagsToApplicationMutation
>;
export type AssociateTagsToApplicationMutationResult =
  Apollo.MutationResult<AssociateTagsToApplicationMutation>;
export type AssociateTagsToApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    AssociateTagsToApplicationMutation,
    AssociateTagsToApplicationMutationVariables
  >;
export const CompleteAccountDocument = gql`
  mutation completeAccount($name: String!, $tagIds: [String!]!) {
    completeAccount(name: $name, tagIds: $tagIds) {
      id
    }
  }
`;
export type CompleteAccountMutationFn = Apollo.MutationFunction<
  CompleteAccountMutation,
  CompleteAccountMutationVariables
>;

/**
 * __useCompleteAccountMutation__
 *
 * To run a mutation, you first call `useCompleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAccountMutation, { data, loading, error }] = useCompleteAccountMutation({
 *   variables: {
 *      name: // value for 'name'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useCompleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAccountMutation,
    CompleteAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteAccountMutation,
    CompleteAccountMutationVariables
  >(CompleteAccountDocument, options);
}
export type CompleteAccountMutationHookResult = ReturnType<
  typeof useCompleteAccountMutation
>;
export type CompleteAccountMutationResult =
  Apollo.MutationResult<CompleteAccountMutation>;
export type CompleteAccountMutationOptions = Apollo.BaseMutationOptions<
  CompleteAccountMutation,
  CompleteAccountMutationVariables
>;
export const CompleteProfileInvitationDocument = gql`
  mutation completeProfileInvitation($id: String!, $profile: ProfileUpdate!) {
    completeProfileInvitation(id: $id, profile: $profile) {
      id
    }
  }
`;
export type CompleteProfileInvitationMutationFn = Apollo.MutationFunction<
  CompleteProfileInvitationMutation,
  CompleteProfileInvitationMutationVariables
>;

/**
 * __useCompleteProfileInvitationMutation__
 *
 * To run a mutation, you first call `useCompleteProfileInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProfileInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProfileInvitationMutation, { data, loading, error }] = useCompleteProfileInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useCompleteProfileInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteProfileInvitationMutation,
    CompleteProfileInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteProfileInvitationMutation,
    CompleteProfileInvitationMutationVariables
  >(CompleteProfileInvitationDocument, options);
}
export type CompleteProfileInvitationMutationHookResult = ReturnType<
  typeof useCompleteProfileInvitationMutation
>;
export type CompleteProfileInvitationMutationResult =
  Apollo.MutationResult<CompleteProfileInvitationMutation>;
export type CompleteProfileInvitationMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteProfileInvitationMutation,
    CompleteProfileInvitationMutationVariables
  >;
export const CreateAccountTypeDocument = gql`
  mutation createAccountType($type: AccountTypeCreation!) {
    createAccountType(type: $type) {
      id
    }
  }
`;
export type CreateAccountTypeMutationFn = Apollo.MutationFunction<
  CreateAccountTypeMutation,
  CreateAccountTypeMutationVariables
>;

/**
 * __useCreateAccountTypeMutation__
 *
 * To run a mutation, you first call `useCreateAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountTypeMutation, { data, loading, error }] = useCreateAccountTypeMutation({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountTypeMutation,
    CreateAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountTypeMutation,
    CreateAccountTypeMutationVariables
  >(CreateAccountTypeDocument, options);
}
export type CreateAccountTypeMutationHookResult = ReturnType<
  typeof useCreateAccountTypeMutation
>;
export type CreateAccountTypeMutationResult =
  Apollo.MutationResult<CreateAccountTypeMutation>;
export type CreateAccountTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountTypeMutation,
  CreateAccountTypeMutationVariables
>;
export const CreateApplicationDocument = gql`
  mutation createApplication($application: ApplicationCreation!) {
    createApplication(application: $application) {
      id
    }
  }
`;
export type CreateApplicationMutationFn = Apollo.MutationFunction<
  CreateApplicationMutation,
  CreateApplicationMutationVariables
>;

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMutation, { data, loading, error }] = useCreateApplicationMutation({
 *   variables: {
 *      application: // value for 'application'
 *   },
 * });
 */
export function useCreateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationMutation,
    CreateApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApplicationMutation,
    CreateApplicationMutationVariables
  >(CreateApplicationDocument, options);
}
export type CreateApplicationMutationHookResult = ReturnType<
  typeof useCreateApplicationMutation
>;
export type CreateApplicationMutationResult =
  Apollo.MutationResult<CreateApplicationMutation>;
export type CreateApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationMutation,
  CreateApplicationMutationVariables
>;
export const CreatePermissionDocument = gql`
  mutation createPermission($permission: PermissionCreation!) {
    createPermission(permission: $permission) {
      id
    }
  }
`;
export type CreatePermissionMutationFn = Apollo.MutationFunction<
  CreatePermissionMutation,
  CreatePermissionMutationVariables
>;

/**
 * __useCreatePermissionMutation__
 *
 * To run a mutation, you first call `useCreatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermissionMutation, { data, loading, error }] = useCreatePermissionMutation({
 *   variables: {
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useCreatePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePermissionMutation,
    CreatePermissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePermissionMutation,
    CreatePermissionMutationVariables
  >(CreatePermissionDocument, options);
}
export type CreatePermissionMutationHookResult = ReturnType<
  typeof useCreatePermissionMutation
>;
export type CreatePermissionMutationResult =
  Apollo.MutationResult<CreatePermissionMutation>;
export type CreatePermissionMutationOptions = Apollo.BaseMutationOptions<
  CreatePermissionMutation,
  CreatePermissionMutationVariables
>;
export const CreatePermissionGroupDocument = gql`
  mutation createPermissionGroup($group: GroupCreation!) {
    createGroup(group: $group) {
      id
    }
  }
`;
export type CreatePermissionGroupMutationFn = Apollo.MutationFunction<
  CreatePermissionGroupMutation,
  CreatePermissionGroupMutationVariables
>;

/**
 * __useCreatePermissionGroupMutation__
 *
 * To run a mutation, you first call `useCreatePermissionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermissionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermissionGroupMutation, { data, loading, error }] = useCreatePermissionGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useCreatePermissionGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePermissionGroupMutation,
    CreatePermissionGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePermissionGroupMutation,
    CreatePermissionGroupMutationVariables
  >(CreatePermissionGroupDocument, options);
}
export type CreatePermissionGroupMutationHookResult = ReturnType<
  typeof useCreatePermissionGroupMutation
>;
export type CreatePermissionGroupMutationResult =
  Apollo.MutationResult<CreatePermissionGroupMutation>;
export type CreatePermissionGroupMutationOptions = Apollo.BaseMutationOptions<
  CreatePermissionGroupMutation,
  CreatePermissionGroupMutationVariables
>;
export const CreateRoleDocument = gql`
  mutation createRole($role: RoleCreation!) {
    createRole(role: $role) {
      id
    }
  }
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(
    CreateRoleDocument,
    options,
  );
}
export type CreateRoleMutationHookResult = ReturnType<
  typeof useCreateRoleMutation
>;
export type CreateRoleMutationResult =
  Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;
export const CreateTagDocument = gql`
  mutation createTag($tag: TagCreation!) {
    createTag(tag: $tag) {
      id
    }
  }
`;
export type CreateTagMutationFn = Apollo.MutationFunction<
  CreateTagMutation,
  CreateTagMutationVariables
>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTagMutation,
    CreateTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(
    CreateTagDocument,
    options,
  );
}
export type CreateTagMutationHookResult = ReturnType<
  typeof useCreateTagMutation
>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<
  CreateTagMutation,
  CreateTagMutationVariables
>;
export const DeclineRolesRequestDocument = gql`
  mutation declineRolesRequest($input: RolesRequestInput!) {
    declineRolesRequest(input: $input) {
      id
    }
  }
`;
export type DeclineRolesRequestMutationFn = Apollo.MutationFunction<
  DeclineRolesRequestMutation,
  DeclineRolesRequestMutationVariables
>;

/**
 * __useDeclineRolesRequestMutation__
 *
 * To run a mutation, you first call `useDeclineRolesRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineRolesRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineRolesRequestMutation, { data, loading, error }] = useDeclineRolesRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineRolesRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineRolesRequestMutation,
    DeclineRolesRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeclineRolesRequestMutation,
    DeclineRolesRequestMutationVariables
  >(DeclineRolesRequestDocument, options);
}
export type DeclineRolesRequestMutationHookResult = ReturnType<
  typeof useDeclineRolesRequestMutation
>;
export type DeclineRolesRequestMutationResult =
  Apollo.MutationResult<DeclineRolesRequestMutation>;
export type DeclineRolesRequestMutationOptions = Apollo.BaseMutationOptions<
  DeclineRolesRequestMutation,
  DeclineRolesRequestMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation deleteAccount($accountId: String!) {
    deleteAccount(accountId: $accountId) {
      id
    }
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<
  typeof useDeleteAccountMutation
>;
export type DeleteAccountMutationResult =
  Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const DeleteApplicationGroupDocument = gql`
  mutation deleteApplicationGroup($applicationGroupId: String!) {
    deleteApplicationGroup(applicationGroupId: $applicationGroupId) {
      id
    }
  }
`;
export type DeleteApplicationGroupMutationFn = Apollo.MutationFunction<
  DeleteApplicationGroupMutation,
  DeleteApplicationGroupMutationVariables
>;

/**
 * __useDeleteApplicationGroupMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationGroupMutation, { data, loading, error }] = useDeleteApplicationGroupMutation({
 *   variables: {
 *      applicationGroupId: // value for 'applicationGroupId'
 *   },
 * });
 */
export function useDeleteApplicationGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationGroupMutation,
    DeleteApplicationGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationGroupMutation,
    DeleteApplicationGroupMutationVariables
  >(DeleteApplicationGroupDocument, options);
}
export type DeleteApplicationGroupMutationHookResult = ReturnType<
  typeof useDeleteApplicationGroupMutation
>;
export type DeleteApplicationGroupMutationResult =
  Apollo.MutationResult<DeleteApplicationGroupMutation>;
export type DeleteApplicationGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteApplicationGroupMutation,
  DeleteApplicationGroupMutationVariables
>;
export const DeletePermissionDocument = gql`
  mutation deletePermission($id: String!) {
    deletePermission(id: $id) {
      id
    }
  }
`;
export type DeletePermissionMutationFn = Apollo.MutationFunction<
  DeletePermissionMutation,
  DeletePermissionMutationVariables
>;

/**
 * __useDeletePermissionMutation__
 *
 * To run a mutation, you first call `useDeletePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionMutation, { data, loading, error }] = useDeletePermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePermissionMutation,
    DeletePermissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePermissionMutation,
    DeletePermissionMutationVariables
  >(DeletePermissionDocument, options);
}
export type DeletePermissionMutationHookResult = ReturnType<
  typeof useDeletePermissionMutation
>;
export type DeletePermissionMutationResult =
  Apollo.MutationResult<DeletePermissionMutation>;
export type DeletePermissionMutationOptions = Apollo.BaseMutationOptions<
  DeletePermissionMutation,
  DeletePermissionMutationVariables
>;
export const DeletePermissionGroupDocument = gql`
  mutation deletePermissionGroup($id: String!) {
    deleteGroup(id: $id) {
      id
    }
  }
`;
export type DeletePermissionGroupMutationFn = Apollo.MutationFunction<
  DeletePermissionGroupMutation,
  DeletePermissionGroupMutationVariables
>;

/**
 * __useDeletePermissionGroupMutation__
 *
 * To run a mutation, you first call `useDeletePermissionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionGroupMutation, { data, loading, error }] = useDeletePermissionGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePermissionGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePermissionGroupMutation,
    DeletePermissionGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePermissionGroupMutation,
    DeletePermissionGroupMutationVariables
  >(DeletePermissionGroupDocument, options);
}
export type DeletePermissionGroupMutationHookResult = ReturnType<
  typeof useDeletePermissionGroupMutation
>;
export type DeletePermissionGroupMutationResult =
  Apollo.MutationResult<DeletePermissionGroupMutation>;
export type DeletePermissionGroupMutationOptions = Apollo.BaseMutationOptions<
  DeletePermissionGroupMutation,
  DeletePermissionGroupMutationVariables
>;
export const DeleteRolesDocument = gql`
  mutation deleteRoles($roleIds: [String!]!) {
    deleteRoles(roleIds: $roleIds)
  }
`;
export type DeleteRolesMutationFn = Apollo.MutationFunction<
  DeleteRolesMutation,
  DeleteRolesMutationVariables
>;

/**
 * __useDeleteRolesMutation__
 *
 * To run a mutation, you first call `useDeleteRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolesMutation, { data, loading, error }] = useDeleteRolesMutation({
 *   variables: {
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useDeleteRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRolesMutation,
    DeleteRolesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRolesMutation, DeleteRolesMutationVariables>(
    DeleteRolesDocument,
    options,
  );
}
export type DeleteRolesMutationHookResult = ReturnType<
  typeof useDeleteRolesMutation
>;
export type DeleteRolesMutationResult =
  Apollo.MutationResult<DeleteRolesMutation>;
export type DeleteRolesMutationOptions = Apollo.BaseMutationOptions<
  DeleteRolesMutation,
  DeleteRolesMutationVariables
>;
export const DeleteTagDocument = gql`
  mutation deleteTag($id: String!) {
    deleteTag(id: $id) {
      id
    }
  }
`;
export type DeleteTagMutationFn = Apollo.MutationFunction<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTagMutation,
    DeleteTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(
    DeleteTagDocument,
    options,
  );
}
export type DeleteTagMutationHookResult = ReturnType<
  typeof useDeleteTagMutation
>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;
export const DissociateAccountFromRolesDocument = gql`
  mutation dissociateAccountFromRoles($id: String!, $roleIds: [String!]!) {
    dissociateAccountFromRoles(id: $id, roleIds: $roleIds) {
      id
    }
  }
`;
export type DissociateAccountFromRolesMutationFn = Apollo.MutationFunction<
  DissociateAccountFromRolesMutation,
  DissociateAccountFromRolesMutationVariables
>;

/**
 * __useDissociateAccountFromRolesMutation__
 *
 * To run a mutation, you first call `useDissociateAccountFromRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateAccountFromRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateAccountFromRolesMutation, { data, loading, error }] = useDissociateAccountFromRolesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useDissociateAccountFromRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociateAccountFromRolesMutation,
    DissociateAccountFromRolesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociateAccountFromRolesMutation,
    DissociateAccountFromRolesMutationVariables
  >(DissociateAccountFromRolesDocument, options);
}
export type DissociateAccountFromRolesMutationHookResult = ReturnType<
  typeof useDissociateAccountFromRolesMutation
>;
export type DissociateAccountFromRolesMutationResult =
  Apollo.MutationResult<DissociateAccountFromRolesMutation>;
export type DissociateAccountFromRolesMutationOptions =
  Apollo.BaseMutationOptions<
    DissociateAccountFromRolesMutation,
    DissociateAccountFromRolesMutationVariables
  >;
export const DissociateAdminFromAccountTypeDocument = gql`
  mutation dissociateAdminFromAccountType(
    $accountTypeId: String!
    $accountId: String!
  ) {
    dissociateAdminFromAccountType(
      accountTypeId: $accountTypeId
      accountId: $accountId
    ) {
      id
    }
  }
`;
export type DissociateAdminFromAccountTypeMutationFn = Apollo.MutationFunction<
  DissociateAdminFromAccountTypeMutation,
  DissociateAdminFromAccountTypeMutationVariables
>;

/**
 * __useDissociateAdminFromAccountTypeMutation__
 *
 * To run a mutation, you first call `useDissociateAdminFromAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateAdminFromAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateAdminFromAccountTypeMutation, { data, loading, error }] = useDissociateAdminFromAccountTypeMutation({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDissociateAdminFromAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociateAdminFromAccountTypeMutation,
    DissociateAdminFromAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociateAdminFromAccountTypeMutation,
    DissociateAdminFromAccountTypeMutationVariables
  >(DissociateAdminFromAccountTypeDocument, options);
}
export type DissociateAdminFromAccountTypeMutationHookResult = ReturnType<
  typeof useDissociateAdminFromAccountTypeMutation
>;
export type DissociateAdminFromAccountTypeMutationResult =
  Apollo.MutationResult<DissociateAdminFromAccountTypeMutation>;
export type DissociateAdminFromAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    DissociateAdminFromAccountTypeMutation,
    DissociateAdminFromAccountTypeMutationVariables
  >;
export const DissociateAdminsFromApplicationDocument = gql`
  mutation dissociateAdminsFromApplication(
    $applicationId: String!
    $accountIds: [String!]!
  ) {
    dissociateAdminsFromApplication(
      applicationId: $applicationId
      accountIds: $accountIds
    ) {
      id
    }
  }
`;
export type DissociateAdminsFromApplicationMutationFn = Apollo.MutationFunction<
  DissociateAdminsFromApplicationMutation,
  DissociateAdminsFromApplicationMutationVariables
>;

/**
 * __useDissociateAdminsFromApplicationMutation__
 *
 * To run a mutation, you first call `useDissociateAdminsFromApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateAdminsFromApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateAdminsFromApplicationMutation, { data, loading, error }] = useDissociateAdminsFromApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useDissociateAdminsFromApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociateAdminsFromApplicationMutation,
    DissociateAdminsFromApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociateAdminsFromApplicationMutation,
    DissociateAdminsFromApplicationMutationVariables
  >(DissociateAdminsFromApplicationDocument, options);
}
export type DissociateAdminsFromApplicationMutationHookResult = ReturnType<
  typeof useDissociateAdminsFromApplicationMutation
>;
export type DissociateAdminsFromApplicationMutationResult =
  Apollo.MutationResult<DissociateAdminsFromApplicationMutation>;
export type DissociateAdminsFromApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    DissociateAdminsFromApplicationMutation,
    DissociateAdminsFromApplicationMutationVariables
  >;
export const DissociateApproverFromAccountTypeDocument = gql`
  mutation dissociateApproverFromAccountType(
    $accountTypeId: String!
    $accountId: String!
  ) {
    dissociateApproverFromAccountType(
      accountTypeId: $accountTypeId
      accountId: $accountId
    ) {
      id
    }
  }
`;
export type DissociateApproverFromAccountTypeMutationFn =
  Apollo.MutationFunction<
    DissociateApproverFromAccountTypeMutation,
    DissociateApproverFromAccountTypeMutationVariables
  >;

/**
 * __useDissociateApproverFromAccountTypeMutation__
 *
 * To run a mutation, you first call `useDissociateApproverFromAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateApproverFromAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateApproverFromAccountTypeMutation, { data, loading, error }] = useDissociateApproverFromAccountTypeMutation({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDissociateApproverFromAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociateApproverFromAccountTypeMutation,
    DissociateApproverFromAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociateApproverFromAccountTypeMutation,
    DissociateApproverFromAccountTypeMutationVariables
  >(DissociateApproverFromAccountTypeDocument, options);
}
export type DissociateApproverFromAccountTypeMutationHookResult = ReturnType<
  typeof useDissociateApproverFromAccountTypeMutation
>;
export type DissociateApproverFromAccountTypeMutationResult =
  Apollo.MutationResult<DissociateApproverFromAccountTypeMutation>;
export type DissociateApproverFromAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    DissociateApproverFromAccountTypeMutation,
    DissociateApproverFromAccountTypeMutationVariables
  >;
export const DissociateApproversFromApplicationDocument = gql`
  mutation dissociateApproversFromApplication(
    $applicationId: String!
    $accountIds: [String!]!
  ) {
    dissociateApproversFromApplication(
      applicationId: $applicationId
      accountIds: $accountIds
    ) {
      id
    }
  }
`;
export type DissociateApproversFromApplicationMutationFn =
  Apollo.MutationFunction<
    DissociateApproversFromApplicationMutation,
    DissociateApproversFromApplicationMutationVariables
  >;

/**
 * __useDissociateApproversFromApplicationMutation__
 *
 * To run a mutation, you first call `useDissociateApproversFromApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateApproversFromApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateApproversFromApplicationMutation, { data, loading, error }] = useDissociateApproversFromApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useDissociateApproversFromApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociateApproversFromApplicationMutation,
    DissociateApproversFromApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociateApproversFromApplicationMutation,
    DissociateApproversFromApplicationMutationVariables
  >(DissociateApproversFromApplicationDocument, options);
}
export type DissociateApproversFromApplicationMutationHookResult = ReturnType<
  typeof useDissociateApproversFromApplicationMutation
>;
export type DissociateApproversFromApplicationMutationResult =
  Apollo.MutationResult<DissociateApproversFromApplicationMutation>;
export type DissociateApproversFromApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    DissociateApproversFromApplicationMutation,
    DissociateApproversFromApplicationMutationVariables
  >;
export const DissociateDependentApplicationsDocument = gql`
  mutation dissociateDependentApplications(
    $applicationId: String!
    $dependentApplicationIds: [String!]!
  ) {
    dissociateDependentApplications(
      applicationId: $applicationId
      dependentApplicationIds: $dependentApplicationIds
    ) {
      id
    }
  }
`;
export type DissociateDependentApplicationsMutationFn = Apollo.MutationFunction<
  DissociateDependentApplicationsMutation,
  DissociateDependentApplicationsMutationVariables
>;

/**
 * __useDissociateDependentApplicationsMutation__
 *
 * To run a mutation, you first call `useDissociateDependentApplicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateDependentApplicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateDependentApplicationsMutation, { data, loading, error }] = useDissociateDependentApplicationsMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      dependentApplicationIds: // value for 'dependentApplicationIds'
 *   },
 * });
 */
export function useDissociateDependentApplicationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociateDependentApplicationsMutation,
    DissociateDependentApplicationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociateDependentApplicationsMutation,
    DissociateDependentApplicationsMutationVariables
  >(DissociateDependentApplicationsDocument, options);
}
export type DissociateDependentApplicationsMutationHookResult = ReturnType<
  typeof useDissociateDependentApplicationsMutation
>;
export type DissociateDependentApplicationsMutationResult =
  Apollo.MutationResult<DissociateDependentApplicationsMutation>;
export type DissociateDependentApplicationsMutationOptions =
  Apollo.BaseMutationOptions<
    DissociateDependentApplicationsMutation,
    DissociateDependentApplicationsMutationVariables
  >;
export const DissociateGroupsFromRoleDocument = gql`
  mutation dissociateGroupsFromRole($input: DissociateGroupsFromRoleInput!) {
    dissociateGroupsFromRole(input: $input) {
      id
    }
  }
`;
export type DissociateGroupsFromRoleMutationFn = Apollo.MutationFunction<
  DissociateGroupsFromRoleMutation,
  DissociateGroupsFromRoleMutationVariables
>;

/**
 * __useDissociateGroupsFromRoleMutation__
 *
 * To run a mutation, you first call `useDissociateGroupsFromRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateGroupsFromRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateGroupsFromRoleMutation, { data, loading, error }] = useDissociateGroupsFromRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDissociateGroupsFromRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociateGroupsFromRoleMutation,
    DissociateGroupsFromRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociateGroupsFromRoleMutation,
    DissociateGroupsFromRoleMutationVariables
  >(DissociateGroupsFromRoleDocument, options);
}
export type DissociateGroupsFromRoleMutationHookResult = ReturnType<
  typeof useDissociateGroupsFromRoleMutation
>;
export type DissociateGroupsFromRoleMutationResult =
  Apollo.MutationResult<DissociateGroupsFromRoleMutation>;
export type DissociateGroupsFromRoleMutationOptions =
  Apollo.BaseMutationOptions<
    DissociateGroupsFromRoleMutation,
    DissociateGroupsFromRoleMutationVariables
  >;
export const DissociatePermissionsFromGroupDocument = gql`
  mutation dissociatePermissionsFromGroup(
    $input: DissociatePermissionsFromGroupInput!
  ) {
    dissociatePermissionsFromGroup(input: $input) {
      dissociatedPermissions
      groupId
    }
  }
`;
export type DissociatePermissionsFromGroupMutationFn = Apollo.MutationFunction<
  DissociatePermissionsFromGroupMutation,
  DissociatePermissionsFromGroupMutationVariables
>;

/**
 * __useDissociatePermissionsFromGroupMutation__
 *
 * To run a mutation, you first call `useDissociatePermissionsFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociatePermissionsFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociatePermissionsFromGroupMutation, { data, loading, error }] = useDissociatePermissionsFromGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDissociatePermissionsFromGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociatePermissionsFromGroupMutation,
    DissociatePermissionsFromGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociatePermissionsFromGroupMutation,
    DissociatePermissionsFromGroupMutationVariables
  >(DissociatePermissionsFromGroupDocument, options);
}
export type DissociatePermissionsFromGroupMutationHookResult = ReturnType<
  typeof useDissociatePermissionsFromGroupMutation
>;
export type DissociatePermissionsFromGroupMutationResult =
  Apollo.MutationResult<DissociatePermissionsFromGroupMutation>;
export type DissociatePermissionsFromGroupMutationOptions =
  Apollo.BaseMutationOptions<
    DissociatePermissionsFromGroupMutation,
    DissociatePermissionsFromGroupMutationVariables
  >;
export const DissociatePermissionsFromRoleDocument = gql`
  mutation dissociatePermissionsFromRole(
    $input: DissociatePermissionsFromRoleInput!
  ) {
    dissociatePermissionsFromRole(input: $input) {
      id
    }
  }
`;
export type DissociatePermissionsFromRoleMutationFn = Apollo.MutationFunction<
  DissociatePermissionsFromRoleMutation,
  DissociatePermissionsFromRoleMutationVariables
>;

/**
 * __useDissociatePermissionsFromRoleMutation__
 *
 * To run a mutation, you first call `useDissociatePermissionsFromRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociatePermissionsFromRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociatePermissionsFromRoleMutation, { data, loading, error }] = useDissociatePermissionsFromRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDissociatePermissionsFromRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociatePermissionsFromRoleMutation,
    DissociatePermissionsFromRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociatePermissionsFromRoleMutation,
    DissociatePermissionsFromRoleMutationVariables
  >(DissociatePermissionsFromRoleDocument, options);
}
export type DissociatePermissionsFromRoleMutationHookResult = ReturnType<
  typeof useDissociatePermissionsFromRoleMutation
>;
export type DissociatePermissionsFromRoleMutationResult =
  Apollo.MutationResult<DissociatePermissionsFromRoleMutation>;
export type DissociatePermissionsFromRoleMutationOptions =
  Apollo.BaseMutationOptions<
    DissociatePermissionsFromRoleMutation,
    DissociatePermissionsFromRoleMutationVariables
  >;
export const DissociateAccountFromSitesDocument = gql`
  mutation dissociateAccountFromSites($id: String!, $siteIds: [String!]!) {
    dissociateAccountFromSites(id: $id, siteIds: $siteIds) {
      id
    }
  }
`;
export type DissociateAccountFromSitesMutationFn = Apollo.MutationFunction<
  DissociateAccountFromSitesMutation,
  DissociateAccountFromSitesMutationVariables
>;

/**
 * __useDissociateAccountFromSitesMutation__
 *
 * To run a mutation, you first call `useDissociateAccountFromSitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateAccountFromSitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateAccountFromSitesMutation, { data, loading, error }] = useDissociateAccountFromSitesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      siteIds: // value for 'siteIds'
 *   },
 * });
 */
export function useDissociateAccountFromSitesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociateAccountFromSitesMutation,
    DissociateAccountFromSitesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociateAccountFromSitesMutation,
    DissociateAccountFromSitesMutationVariables
  >(DissociateAccountFromSitesDocument, options);
}
export type DissociateAccountFromSitesMutationHookResult = ReturnType<
  typeof useDissociateAccountFromSitesMutation
>;
export type DissociateAccountFromSitesMutationResult =
  Apollo.MutationResult<DissociateAccountFromSitesMutation>;
export type DissociateAccountFromSitesMutationOptions =
  Apollo.BaseMutationOptions<
    DissociateAccountFromSitesMutation,
    DissociateAccountFromSitesMutationVariables
  >;
export const DissociateTagFromAccountTypeDocument = gql`
  mutation dissociateTagFromAccountType(
    $accountTypeId: String!
    $tagId: String!
  ) {
    dissociateTagFromAccountType(accountTypeId: $accountTypeId, tagId: $tagId) {
      id
    }
  }
`;
export type DissociateTagFromAccountTypeMutationFn = Apollo.MutationFunction<
  DissociateTagFromAccountTypeMutation,
  DissociateTagFromAccountTypeMutationVariables
>;

/**
 * __useDissociateTagFromAccountTypeMutation__
 *
 * To run a mutation, you first call `useDissociateTagFromAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateTagFromAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateTagFromAccountTypeMutation, { data, loading, error }] = useDissociateTagFromAccountTypeMutation({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useDissociateTagFromAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociateTagFromAccountTypeMutation,
    DissociateTagFromAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociateTagFromAccountTypeMutation,
    DissociateTagFromAccountTypeMutationVariables
  >(DissociateTagFromAccountTypeDocument, options);
}
export type DissociateTagFromAccountTypeMutationHookResult = ReturnType<
  typeof useDissociateTagFromAccountTypeMutation
>;
export type DissociateTagFromAccountTypeMutationResult =
  Apollo.MutationResult<DissociateTagFromAccountTypeMutation>;
export type DissociateTagFromAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    DissociateTagFromAccountTypeMutation,
    DissociateTagFromAccountTypeMutationVariables
  >;
export const DissociateTagsFromAccountDocument = gql`
  mutation dissociateTagsFromAccount($accountId: String!, $tagIds: [String!]!) {
    dissociateTagsFromAccount(accountId: $accountId, tagIds: $tagIds) {
      id
    }
  }
`;
export type DissociateTagsFromAccountMutationFn = Apollo.MutationFunction<
  DissociateTagsFromAccountMutation,
  DissociateTagsFromAccountMutationVariables
>;

/**
 * __useDissociateTagsFromAccountMutation__
 *
 * To run a mutation, you first call `useDissociateTagsFromAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateTagsFromAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateTagsFromAccountMutation, { data, loading, error }] = useDissociateTagsFromAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useDissociateTagsFromAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociateTagsFromAccountMutation,
    DissociateTagsFromAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociateTagsFromAccountMutation,
    DissociateTagsFromAccountMutationVariables
  >(DissociateTagsFromAccountDocument, options);
}
export type DissociateTagsFromAccountMutationHookResult = ReturnType<
  typeof useDissociateTagsFromAccountMutation
>;
export type DissociateTagsFromAccountMutationResult =
  Apollo.MutationResult<DissociateTagsFromAccountMutation>;
export type DissociateTagsFromAccountMutationOptions =
  Apollo.BaseMutationOptions<
    DissociateTagsFromAccountMutation,
    DissociateTagsFromAccountMutationVariables
  >;
export const DissociateTagsFromApplicationDocument = gql`
  mutation dissociateTagsFromApplication($id: String!, $tagIds: [String!]!) {
    dissociateTagsFromApplication(id: $id, tagIds: $tagIds) {
      id
    }
  }
`;
export type DissociateTagsFromApplicationMutationFn = Apollo.MutationFunction<
  DissociateTagsFromApplicationMutation,
  DissociateTagsFromApplicationMutationVariables
>;

/**
 * __useDissociateTagsFromApplicationMutation__
 *
 * To run a mutation, you first call `useDissociateTagsFromApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateTagsFromApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateTagsFromApplicationMutation, { data, loading, error }] = useDissociateTagsFromApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useDissociateTagsFromApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DissociateTagsFromApplicationMutation,
    DissociateTagsFromApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DissociateTagsFromApplicationMutation,
    DissociateTagsFromApplicationMutationVariables
  >(DissociateTagsFromApplicationDocument, options);
}
export type DissociateTagsFromApplicationMutationHookResult = ReturnType<
  typeof useDissociateTagsFromApplicationMutation
>;
export type DissociateTagsFromApplicationMutationResult =
  Apollo.MutationResult<DissociateTagsFromApplicationMutation>;
export type DissociateTagsFromApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    DissociateTagsFromApplicationMutation,
    DissociateTagsFromApplicationMutationVariables
  >;
export const InviteProfileDocument = gql`
  mutation inviteProfile($id: String!, $options: InvitationRequestArgs!) {
    inviteProfile(id: $id, options: $options) {
      id
    }
  }
`;
export type InviteProfileMutationFn = Apollo.MutationFunction<
  InviteProfileMutation,
  InviteProfileMutationVariables
>;

/**
 * __useInviteProfileMutation__
 *
 * To run a mutation, you first call `useInviteProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteProfileMutation, { data, loading, error }] = useInviteProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useInviteProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteProfileMutation,
    InviteProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteProfileMutation,
    InviteProfileMutationVariables
  >(InviteProfileDocument, options);
}
export type InviteProfileMutationHookResult = ReturnType<
  typeof useInviteProfileMutation
>;
export type InviteProfileMutationResult =
  Apollo.MutationResult<InviteProfileMutation>;
export type InviteProfileMutationOptions = Apollo.BaseMutationOptions<
  InviteProfileMutation,
  InviteProfileMutationVariables
>;
export const RejectAccountTypeForAccountDocument = gql`
  mutation rejectAccountTypeForAccount($reject: ReasonInput!) {
    rejectAccountTypeForAccount(reject: $reject) {
      id
    }
  }
`;
export type RejectAccountTypeForAccountMutationFn = Apollo.MutationFunction<
  RejectAccountTypeForAccountMutation,
  RejectAccountTypeForAccountMutationVariables
>;

/**
 * __useRejectAccountTypeForAccountMutation__
 *
 * To run a mutation, you first call `useRejectAccountTypeForAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAccountTypeForAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAccountTypeForAccountMutation, { data, loading, error }] = useRejectAccountTypeForAccountMutation({
 *   variables: {
 *      reject: // value for 'reject'
 *   },
 * });
 */
export function useRejectAccountTypeForAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectAccountTypeForAccountMutation,
    RejectAccountTypeForAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RejectAccountTypeForAccountMutation,
    RejectAccountTypeForAccountMutationVariables
  >(RejectAccountTypeForAccountDocument, options);
}
export type RejectAccountTypeForAccountMutationHookResult = ReturnType<
  typeof useRejectAccountTypeForAccountMutation
>;
export type RejectAccountTypeForAccountMutationResult =
  Apollo.MutationResult<RejectAccountTypeForAccountMutation>;
export type RejectAccountTypeForAccountMutationOptions =
  Apollo.BaseMutationOptions<
    RejectAccountTypeForAccountMutation,
    RejectAccountTypeForAccountMutationVariables
  >;
export const SiteRejectAccountRequestDocument = gql`
  mutation siteRejectAccountRequest($reject: RejectInput!) {
    siteRejectAccountRequest(reject: $reject) {
      id
    }
  }
`;
export type SiteRejectAccountRequestMutationFn = Apollo.MutationFunction<
  SiteRejectAccountRequestMutation,
  SiteRejectAccountRequestMutationVariables
>;

/**
 * __useSiteRejectAccountRequestMutation__
 *
 * To run a mutation, you first call `useSiteRejectAccountRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteRejectAccountRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteRejectAccountRequestMutation, { data, loading, error }] = useSiteRejectAccountRequestMutation({
 *   variables: {
 *      reject: // value for 'reject'
 *   },
 * });
 */
export function useSiteRejectAccountRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SiteRejectAccountRequestMutation,
    SiteRejectAccountRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SiteRejectAccountRequestMutation,
    SiteRejectAccountRequestMutationVariables
  >(SiteRejectAccountRequestDocument, options);
}
export type SiteRejectAccountRequestMutationHookResult = ReturnType<
  typeof useSiteRejectAccountRequestMutation
>;
export type SiteRejectAccountRequestMutationResult =
  Apollo.MutationResult<SiteRejectAccountRequestMutation>;
export type SiteRejectAccountRequestMutationOptions =
  Apollo.BaseMutationOptions<
    SiteRejectAccountRequestMutation,
    SiteRejectAccountRequestMutationVariables
  >;
export const RemoveApplicationAudienceForAccountTypeDocument = gql`
  mutation removeApplicationAudienceForAccountType(
    $accountTypeId: String!
    $applicationId: String!
  ) {
    removeApplicationAudienceForAccountType(
      accountTypeId: $accountTypeId
      applicationId: $applicationId
    ) {
      id
    }
  }
`;
export type RemoveApplicationAudienceForAccountTypeMutationFn =
  Apollo.MutationFunction<
    RemoveApplicationAudienceForAccountTypeMutation,
    RemoveApplicationAudienceForAccountTypeMutationVariables
  >;

/**
 * __useRemoveApplicationAudienceForAccountTypeMutation__
 *
 * To run a mutation, you first call `useRemoveApplicationAudienceForAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicationAudienceForAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApplicationAudienceForAccountTypeMutation, { data, loading, error }] = useRemoveApplicationAudienceForAccountTypeMutation({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useRemoveApplicationAudienceForAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveApplicationAudienceForAccountTypeMutation,
    RemoveApplicationAudienceForAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveApplicationAudienceForAccountTypeMutation,
    RemoveApplicationAudienceForAccountTypeMutationVariables
  >(RemoveApplicationAudienceForAccountTypeDocument, options);
}
export type RemoveApplicationAudienceForAccountTypeMutationHookResult =
  ReturnType<typeof useRemoveApplicationAudienceForAccountTypeMutation>;
export type RemoveApplicationAudienceForAccountTypeMutationResult =
  Apollo.MutationResult<RemoveApplicationAudienceForAccountTypeMutation>;
export type RemoveApplicationAudienceForAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveApplicationAudienceForAccountTypeMutation,
    RemoveApplicationAudienceForAccountTypeMutationVariables
  >;
export const RemoveApplicationsFromMyGroupDocument = gql`
  mutation removeApplicationsFromMyGroup($input: AccountApplicationsGroups!) {
    removeApplicationsFromMyGroup(input: $input) {
      id
    }
  }
`;
export type RemoveApplicationsFromMyGroupMutationFn = Apollo.MutationFunction<
  RemoveApplicationsFromMyGroupMutation,
  RemoveApplicationsFromMyGroupMutationVariables
>;

/**
 * __useRemoveApplicationsFromMyGroupMutation__
 *
 * To run a mutation, you first call `useRemoveApplicationsFromMyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicationsFromMyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApplicationsFromMyGroupMutation, { data, loading, error }] = useRemoveApplicationsFromMyGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveApplicationsFromMyGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveApplicationsFromMyGroupMutation,
    RemoveApplicationsFromMyGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveApplicationsFromMyGroupMutation,
    RemoveApplicationsFromMyGroupMutationVariables
  >(RemoveApplicationsFromMyGroupDocument, options);
}
export type RemoveApplicationsFromMyGroupMutationHookResult = ReturnType<
  typeof useRemoveApplicationsFromMyGroupMutation
>;
export type RemoveApplicationsFromMyGroupMutationResult =
  Apollo.MutationResult<RemoveApplicationsFromMyGroupMutation>;
export type RemoveApplicationsFromMyGroupMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveApplicationsFromMyGroupMutation,
    RemoveApplicationsFromMyGroupMutationVariables
  >;
export const RemoveApprovedDomainFromAccountTypeDocument = gql`
  mutation removeApprovedDomainFromAccountType(
    $id: String!
    $domains: [String!]!
  ) {
    removeApprovedDomainFromAccountType(id: $id, domains: $domains) {
      id
    }
  }
`;
export type RemoveApprovedDomainFromAccountTypeMutationFn =
  Apollo.MutationFunction<
    RemoveApprovedDomainFromAccountTypeMutation,
    RemoveApprovedDomainFromAccountTypeMutationVariables
  >;

/**
 * __useRemoveApprovedDomainFromAccountTypeMutation__
 *
 * To run a mutation, you first call `useRemoveApprovedDomainFromAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApprovedDomainFromAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApprovedDomainFromAccountTypeMutation, { data, loading, error }] = useRemoveApprovedDomainFromAccountTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useRemoveApprovedDomainFromAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveApprovedDomainFromAccountTypeMutation,
    RemoveApprovedDomainFromAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveApprovedDomainFromAccountTypeMutation,
    RemoveApprovedDomainFromAccountTypeMutationVariables
  >(RemoveApprovedDomainFromAccountTypeDocument, options);
}
export type RemoveApprovedDomainFromAccountTypeMutationHookResult = ReturnType<
  typeof useRemoveApprovedDomainFromAccountTypeMutation
>;
export type RemoveApprovedDomainFromAccountTypeMutationResult =
  Apollo.MutationResult<RemoveApprovedDomainFromAccountTypeMutation>;
export type RemoveApprovedDomainFromAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveApprovedDomainFromAccountTypeMutation,
    RemoveApprovedDomainFromAccountTypeMutationVariables
  >;
export const RemoveApprovedDomainsFromSiteDocument = gql`
  mutation removeApprovedDomainsFromSite($id: String!, $domains: [String!]!) {
    removeApprovedDomainsFromSite(id: $id, domains: $domains) {
      id
    }
  }
`;
export type RemoveApprovedDomainsFromSiteMutationFn = Apollo.MutationFunction<
  RemoveApprovedDomainsFromSiteMutation,
  RemoveApprovedDomainsFromSiteMutationVariables
>;

/**
 * __useRemoveApprovedDomainsFromSiteMutation__
 *
 * To run a mutation, you first call `useRemoveApprovedDomainsFromSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApprovedDomainsFromSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApprovedDomainsFromSiteMutation, { data, loading, error }] = useRemoveApprovedDomainsFromSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useRemoveApprovedDomainsFromSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveApprovedDomainsFromSiteMutation,
    RemoveApprovedDomainsFromSiteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveApprovedDomainsFromSiteMutation,
    RemoveApprovedDomainsFromSiteMutationVariables
  >(RemoveApprovedDomainsFromSiteDocument, options);
}
export type RemoveApprovedDomainsFromSiteMutationHookResult = ReturnType<
  typeof useRemoveApprovedDomainsFromSiteMutation
>;
export type RemoveApprovedDomainsFromSiteMutationResult =
  Apollo.MutationResult<RemoveApprovedDomainsFromSiteMutation>;
export type RemoveApprovedDomainsFromSiteMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveApprovedDomainsFromSiteMutation,
    RemoveApprovedDomainsFromSiteMutationVariables
  >;
export const RemoveProfileAccessDocument = gql`
  mutation removeProfileAccess($id: String!) {
    removeProfileAccess(id: $id) {
      id
    }
  }
`;
export type RemoveProfileAccessMutationFn = Apollo.MutationFunction<
  RemoveProfileAccessMutation,
  RemoveProfileAccessMutationVariables
>;

/**
 * __useRemoveProfileAccessMutation__
 *
 * To run a mutation, you first call `useRemoveProfileAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProfileAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProfileAccessMutation, { data, loading, error }] = useRemoveProfileAccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveProfileAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProfileAccessMutation,
    RemoveProfileAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveProfileAccessMutation,
    RemoveProfileAccessMutationVariables
  >(RemoveProfileAccessDocument, options);
}
export type RemoveProfileAccessMutationHookResult = ReturnType<
  typeof useRemoveProfileAccessMutation
>;
export type RemoveProfileAccessMutationResult =
  Apollo.MutationResult<RemoveProfileAccessMutation>;
export type RemoveProfileAccessMutationOptions = Apollo.BaseMutationOptions<
  RemoveProfileAccessMutation,
  RemoveProfileAccessMutationVariables
>;
export const RemoveRejectedDomainFromAccountTypeDocument = gql`
  mutation removeRejectedDomainFromAccountType(
    $id: String!
    $domains: [String!]!
  ) {
    removeRejectedDomainFromAccountType(id: $id, domains: $domains) {
      id
    }
  }
`;
export type RemoveRejectedDomainFromAccountTypeMutationFn =
  Apollo.MutationFunction<
    RemoveRejectedDomainFromAccountTypeMutation,
    RemoveRejectedDomainFromAccountTypeMutationVariables
  >;

/**
 * __useRemoveRejectedDomainFromAccountTypeMutation__
 *
 * To run a mutation, you first call `useRemoveRejectedDomainFromAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRejectedDomainFromAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRejectedDomainFromAccountTypeMutation, { data, loading, error }] = useRemoveRejectedDomainFromAccountTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useRemoveRejectedDomainFromAccountTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRejectedDomainFromAccountTypeMutation,
    RemoveRejectedDomainFromAccountTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveRejectedDomainFromAccountTypeMutation,
    RemoveRejectedDomainFromAccountTypeMutationVariables
  >(RemoveRejectedDomainFromAccountTypeDocument, options);
}
export type RemoveRejectedDomainFromAccountTypeMutationHookResult = ReturnType<
  typeof useRemoveRejectedDomainFromAccountTypeMutation
>;
export type RemoveRejectedDomainFromAccountTypeMutationResult =
  Apollo.MutationResult<RemoveRejectedDomainFromAccountTypeMutation>;
export type RemoveRejectedDomainFromAccountTypeMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveRejectedDomainFromAccountTypeMutation,
    RemoveRejectedDomainFromAccountTypeMutationVariables
  >;
export const RemoveRejectedDomainsFromSiteDocument = gql`
  mutation removeRejectedDomainsFromSite($id: String!, $domains: [String!]!) {
    removeRejectedDomainsFromSite(id: $id, domains: $domains) {
      id
    }
  }
`;
export type RemoveRejectedDomainsFromSiteMutationFn = Apollo.MutationFunction<
  RemoveRejectedDomainsFromSiteMutation,
  RemoveRejectedDomainsFromSiteMutationVariables
>;

/**
 * __useRemoveRejectedDomainsFromSiteMutation__
 *
 * To run a mutation, you first call `useRemoveRejectedDomainsFromSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRejectedDomainsFromSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRejectedDomainsFromSiteMutation, { data, loading, error }] = useRemoveRejectedDomainsFromSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useRemoveRejectedDomainsFromSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRejectedDomainsFromSiteMutation,
    RemoveRejectedDomainsFromSiteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveRejectedDomainsFromSiteMutation,
    RemoveRejectedDomainsFromSiteMutationVariables
  >(RemoveRejectedDomainsFromSiteDocument, options);
}
export type RemoveRejectedDomainsFromSiteMutationHookResult = ReturnType<
  typeof useRemoveRejectedDomainsFromSiteMutation
>;
export type RemoveRejectedDomainsFromSiteMutationResult =
  Apollo.MutationResult<RemoveRejectedDomainsFromSiteMutation>;
export type RemoveRejectedDomainsFromSiteMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveRejectedDomainsFromSiteMutation,
    RemoveRejectedDomainsFromSiteMutationVariables
  >;
export const Remove_Tags_From_RoleDocument = gql`
  mutation REMOVE_TAGS_FROM_ROLE($id: String!, $tagIds: [String!]!) {
    roleRemoveTags(id: $id, tagIds: $tagIds) {
      id
    }
  }
`;
export type Remove_Tags_From_RoleMutationFn = Apollo.MutationFunction<
  Remove_Tags_From_RoleMutation,
  Remove_Tags_From_RoleMutationVariables
>;

/**
 * __useRemove_Tags_From_RoleMutation__
 *
 * To run a mutation, you first call `useRemove_Tags_From_RoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemove_Tags_From_RoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagsFromRoleMutation, { data, loading, error }] = useRemove_Tags_From_RoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useRemove_Tags_From_RoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Remove_Tags_From_RoleMutation,
    Remove_Tags_From_RoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Remove_Tags_From_RoleMutation,
    Remove_Tags_From_RoleMutationVariables
  >(Remove_Tags_From_RoleDocument, options);
}
export type Remove_Tags_From_RoleMutationHookResult = ReturnType<
  typeof useRemove_Tags_From_RoleMutation
>;
export type Remove_Tags_From_RoleMutationResult =
  Apollo.MutationResult<Remove_Tags_From_RoleMutation>;
export type Remove_Tags_From_RoleMutationOptions = Apollo.BaseMutationOptions<
  Remove_Tags_From_RoleMutation,
  Remove_Tags_From_RoleMutationVariables
>;
export const RequestAccountDocument = gql`
  mutation requestAccount($account: AccountRequest!) {
    requestAccount(account: $account) {
      id
    }
  }
`;
export type RequestAccountMutationFn = Apollo.MutationFunction<
  RequestAccountMutation,
  RequestAccountMutationVariables
>;

/**
 * __useRequestAccountMutation__
 *
 * To run a mutation, you first call `useRequestAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAccountMutation, { data, loading, error }] = useRequestAccountMutation({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useRequestAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestAccountMutation,
    RequestAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestAccountMutation,
    RequestAccountMutationVariables
  >(RequestAccountDocument, options);
}
export type RequestAccountMutationHookResult = ReturnType<
  typeof useRequestAccountMutation
>;
export type RequestAccountMutationResult =
  Apollo.MutationResult<RequestAccountMutation>;
export type RequestAccountMutationOptions = Apollo.BaseMutationOptions<
  RequestAccountMutation,
  RequestAccountMutationVariables
>;
export const RequestPermissionsDocument = gql`
  mutation requestPermissions($input: PermissionRequestInput!) {
    requestPermissions(input: $input) {
      accountId
    }
  }
`;
export type RequestPermissionsMutationFn = Apollo.MutationFunction<
  RequestPermissionsMutation,
  RequestPermissionsMutationVariables
>;

/**
 * __useRequestPermissionsMutation__
 *
 * To run a mutation, you first call `useRequestPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPermissionsMutation, { data, loading, error }] = useRequestPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPermissionsMutation,
    RequestPermissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPermissionsMutation,
    RequestPermissionsMutationVariables
  >(RequestPermissionsDocument, options);
}
export type RequestPermissionsMutationHookResult = ReturnType<
  typeof useRequestPermissionsMutation
>;
export type RequestPermissionsMutationResult =
  Apollo.MutationResult<RequestPermissionsMutation>;
export type RequestPermissionsMutationOptions = Apollo.BaseMutationOptions<
  RequestPermissionsMutation,
  RequestPermissionsMutationVariables
>;
export const RequestPermissionGroupsDocument = gql`
  mutation requestPermissionGroups($input: PermissionGroupRequestInput!) {
    requestPermissionGroups(input: $input) {
      accountId
    }
  }
`;
export type RequestPermissionGroupsMutationFn = Apollo.MutationFunction<
  RequestPermissionGroupsMutation,
  RequestPermissionGroupsMutationVariables
>;

/**
 * __useRequestPermissionGroupsMutation__
 *
 * To run a mutation, you first call `useRequestPermissionGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPermissionGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPermissionGroupsMutation, { data, loading, error }] = useRequestPermissionGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPermissionGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPermissionGroupsMutation,
    RequestPermissionGroupsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPermissionGroupsMutation,
    RequestPermissionGroupsMutationVariables
  >(RequestPermissionGroupsDocument, options);
}
export type RequestPermissionGroupsMutationHookResult = ReturnType<
  typeof useRequestPermissionGroupsMutation
>;
export type RequestPermissionGroupsMutationResult =
  Apollo.MutationResult<RequestPermissionGroupsMutation>;
export type RequestPermissionGroupsMutationOptions = Apollo.BaseMutationOptions<
  RequestPermissionGroupsMutation,
  RequestPermissionGroupsMutationVariables
>;
export const RequestProfileDocument = gql`
  mutation requestProfile($profile: ProfileRequest!) {
    requestProfile(profile: $profile) {
      id
    }
  }
`;
export type RequestProfileMutationFn = Apollo.MutationFunction<
  RequestProfileMutation,
  RequestProfileMutationVariables
>;

/**
 * __useRequestProfileMutation__
 *
 * To run a mutation, you first call `useRequestProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestProfileMutation, { data, loading, error }] = useRequestProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useRequestProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestProfileMutation,
    RequestProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestProfileMutation,
    RequestProfileMutationVariables
  >(RequestProfileDocument, options);
}
export type RequestProfileMutationHookResult = ReturnType<
  typeof useRequestProfileMutation
>;
export type RequestProfileMutationResult =
  Apollo.MutationResult<RequestProfileMutation>;
export type RequestProfileMutationOptions = Apollo.BaseMutationOptions<
  RequestProfileMutation,
  RequestProfileMutationVariables
>;
export const RequestRoleDocument = gql`
  mutation requestRole($input: RolesRequestInput!) {
    requestRoles(input: $input) {
      id
    }
  }
`;
export type RequestRoleMutationFn = Apollo.MutationFunction<
  RequestRoleMutation,
  RequestRoleMutationVariables
>;

/**
 * __useRequestRoleMutation__
 *
 * To run a mutation, you first call `useRequestRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestRoleMutation, { data, loading, error }] = useRequestRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestRoleMutation,
    RequestRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestRoleMutation, RequestRoleMutationVariables>(
    RequestRoleDocument,
    options,
  );
}
export type RequestRoleMutationHookResult = ReturnType<
  typeof useRequestRoleMutation
>;
export type RequestRoleMutationResult =
  Apollo.MutationResult<RequestRoleMutation>;
export type RequestRoleMutationOptions = Apollo.BaseMutationOptions<
  RequestRoleMutation,
  RequestRoleMutationVariables
>;
export const ResyncProfileEmailDocument = gql`
  mutation resyncProfileEmail($id: String!) {
    resyncProfileEmail(id: $id) {
      id
    }
  }
`;
export type ResyncProfileEmailMutationFn = Apollo.MutationFunction<
  ResyncProfileEmailMutation,
  ResyncProfileEmailMutationVariables
>;

/**
 * __useResyncProfileEmailMutation__
 *
 * To run a mutation, you first call `useResyncProfileEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResyncProfileEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resyncProfileEmailMutation, { data, loading, error }] = useResyncProfileEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResyncProfileEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResyncProfileEmailMutation,
    ResyncProfileEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResyncProfileEmailMutation,
    ResyncProfileEmailMutationVariables
  >(ResyncProfileEmailDocument, options);
}
export type ResyncProfileEmailMutationHookResult = ReturnType<
  typeof useResyncProfileEmailMutation
>;
export type ResyncProfileEmailMutationResult =
  Apollo.MutationResult<ResyncProfileEmailMutation>;
export type ResyncProfileEmailMutationOptions = Apollo.BaseMutationOptions<
  ResyncProfileEmailMutation,
  ResyncProfileEmailMutationVariables
>;
export const RoleAddApplicationsAudienceDocument = gql`
  mutation roleAddApplicationsAudience(
    $id: String!
    $applicationIds: [String!]!
  ) {
    roleAddApplicationsAudience(id: $id, applicationIds: $applicationIds) {
      id
    }
  }
`;
export type RoleAddApplicationsAudienceMutationFn = Apollo.MutationFunction<
  RoleAddApplicationsAudienceMutation,
  RoleAddApplicationsAudienceMutationVariables
>;

/**
 * __useRoleAddApplicationsAudienceMutation__
 *
 * To run a mutation, you first call `useRoleAddApplicationsAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoleAddApplicationsAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roleAddApplicationsAudienceMutation, { data, loading, error }] = useRoleAddApplicationsAudienceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      applicationIds: // value for 'applicationIds'
 *   },
 * });
 */
export function useRoleAddApplicationsAudienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RoleAddApplicationsAudienceMutation,
    RoleAddApplicationsAudienceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RoleAddApplicationsAudienceMutation,
    RoleAddApplicationsAudienceMutationVariables
  >(RoleAddApplicationsAudienceDocument, options);
}
export type RoleAddApplicationsAudienceMutationHookResult = ReturnType<
  typeof useRoleAddApplicationsAudienceMutation
>;
export type RoleAddApplicationsAudienceMutationResult =
  Apollo.MutationResult<RoleAddApplicationsAudienceMutation>;
export type RoleAddApplicationsAudienceMutationOptions =
  Apollo.BaseMutationOptions<
    RoleAddApplicationsAudienceMutation,
    RoleAddApplicationsAudienceMutationVariables
  >;
export const RoleRemoveApplicationsAudienceDocument = gql`
  mutation roleRemoveApplicationsAudience(
    $id: String!
    $applicationIds: [String!]!
  ) {
    roleRemoveApplicationsAudience(id: $id, applicationIds: $applicationIds) {
      id
    }
  }
`;
export type RoleRemoveApplicationsAudienceMutationFn = Apollo.MutationFunction<
  RoleRemoveApplicationsAudienceMutation,
  RoleRemoveApplicationsAudienceMutationVariables
>;

/**
 * __useRoleRemoveApplicationsAudienceMutation__
 *
 * To run a mutation, you first call `useRoleRemoveApplicationsAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoleRemoveApplicationsAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roleRemoveApplicationsAudienceMutation, { data, loading, error }] = useRoleRemoveApplicationsAudienceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      applicationIds: // value for 'applicationIds'
 *   },
 * });
 */
export function useRoleRemoveApplicationsAudienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RoleRemoveApplicationsAudienceMutation,
    RoleRemoveApplicationsAudienceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RoleRemoveApplicationsAudienceMutation,
    RoleRemoveApplicationsAudienceMutationVariables
  >(RoleRemoveApplicationsAudienceDocument, options);
}
export type RoleRemoveApplicationsAudienceMutationHookResult = ReturnType<
  typeof useRoleRemoveApplicationsAudienceMutation
>;
export type RoleRemoveApplicationsAudienceMutationResult =
  Apollo.MutationResult<RoleRemoveApplicationsAudienceMutation>;
export type RoleRemoveApplicationsAudienceMutationOptions =
  Apollo.BaseMutationOptions<
    RoleRemoveApplicationsAudienceMutation,
    RoleRemoveApplicationsAudienceMutationVariables
  >;
export const SiteRemoveApprovedAccountDocument = gql`
  mutation siteRemoveApprovedAccount($siteId: String!, $accountId: String!) {
    siteRemoveApprovedAccount(siteId: $siteId, accountId: $accountId) {
      id
    }
  }
`;
export type SiteRemoveApprovedAccountMutationFn = Apollo.MutationFunction<
  SiteRemoveApprovedAccountMutation,
  SiteRemoveApprovedAccountMutationVariables
>;

/**
 * __useSiteRemoveApprovedAccountMutation__
 *
 * To run a mutation, you first call `useSiteRemoveApprovedAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteRemoveApprovedAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteRemoveApprovedAccountMutation, { data, loading, error }] = useSiteRemoveApprovedAccountMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useSiteRemoveApprovedAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SiteRemoveApprovedAccountMutation,
    SiteRemoveApprovedAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SiteRemoveApprovedAccountMutation,
    SiteRemoveApprovedAccountMutationVariables
  >(SiteRemoveApprovedAccountDocument, options);
}
export type SiteRemoveApprovedAccountMutationHookResult = ReturnType<
  typeof useSiteRemoveApprovedAccountMutation
>;
export type SiteRemoveApprovedAccountMutationResult =
  Apollo.MutationResult<SiteRemoveApprovedAccountMutation>;
export type SiteRemoveApprovedAccountMutationOptions =
  Apollo.BaseMutationOptions<
    SiteRemoveApprovedAccountMutation,
    SiteRemoveApprovedAccountMutationVariables
  >;
export const TakeOverDocument = gql`
  mutation takeOver($entityId: String!) {
    takeOver(entityId: $entityId) {
      unreadCount
    }
  }
`;
export type TakeOverMutationFn = Apollo.MutationFunction<
  TakeOverMutation,
  TakeOverMutationVariables
>;

/**
 * __useTakeOverMutation__
 *
 * To run a mutation, you first call `useTakeOverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeOverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeOverMutation, { data, loading, error }] = useTakeOverMutation({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useTakeOverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TakeOverMutation,
    TakeOverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TakeOverMutation, TakeOverMutationVariables>(
    TakeOverDocument,
    options,
  );
}
export type TakeOverMutationHookResult = ReturnType<typeof useTakeOverMutation>;
export type TakeOverMutationResult = Apollo.MutationResult<TakeOverMutation>;
export type TakeOverMutationOptions = Apollo.BaseMutationOptions<
  TakeOverMutation,
  TakeOverMutationVariables
>;
export const UnassignNotificationDocument = gql`
  mutation unassignNotification($entityId: String!) {
    unassignNotification(entityId: $entityId) {
      unreadCount
    }
  }
`;
export type UnassignNotificationMutationFn = Apollo.MutationFunction<
  UnassignNotificationMutation,
  UnassignNotificationMutationVariables
>;

/**
 * __useUnassignNotificationMutation__
 *
 * To run a mutation, you first call `useUnassignNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignNotificationMutation, { data, loading, error }] = useUnassignNotificationMutation({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useUnassignNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignNotificationMutation,
    UnassignNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnassignNotificationMutation,
    UnassignNotificationMutationVariables
  >(UnassignNotificationDocument, options);
}
export type UnassignNotificationMutationHookResult = ReturnType<
  typeof useUnassignNotificationMutation
>;
export type UnassignNotificationMutationResult =
  Apollo.MutationResult<UnassignNotificationMutation>;
export type UnassignNotificationMutationOptions = Apollo.BaseMutationOptions<
  UnassignNotificationMutation,
  UnassignNotificationMutationVariables
>;
export const AccountUpdateNameDocument = gql`
  mutation accountUpdateName($id: String!, $name: String!) {
    accountUpdateName(id: $id, name: $name) {
      id
    }
  }
`;
export type AccountUpdateNameMutationFn = Apollo.MutationFunction<
  AccountUpdateNameMutation,
  AccountUpdateNameMutationVariables
>;

/**
 * __useAccountUpdateNameMutation__
 *
 * To run a mutation, you first call `useAccountUpdateNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountUpdateNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountUpdateNameMutation, { data, loading, error }] = useAccountUpdateNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAccountUpdateNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AccountUpdateNameMutation,
    AccountUpdateNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AccountUpdateNameMutation,
    AccountUpdateNameMutationVariables
  >(AccountUpdateNameDocument, options);
}
export type AccountUpdateNameMutationHookResult = ReturnType<
  typeof useAccountUpdateNameMutation
>;
export type AccountUpdateNameMutationResult =
  Apollo.MutationResult<AccountUpdateNameMutation>;
export type AccountUpdateNameMutationOptions = Apollo.BaseMutationOptions<
  AccountUpdateNameMutation,
  AccountUpdateNameMutationVariables
>;
export const UpdateAccountTypeDescriptionDocument = gql`
  mutation updateAccountTypeDescription(
    $accountTypeId: String!
    $description: String!
  ) {
    updateAccountTypeDescription(
      accountTypeId: $accountTypeId
      description: $description
    ) {
      id
    }
  }
`;
export type UpdateAccountTypeDescriptionMutationFn = Apollo.MutationFunction<
  UpdateAccountTypeDescriptionMutation,
  UpdateAccountTypeDescriptionMutationVariables
>;

/**
 * __useUpdateAccountTypeDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateAccountTypeDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountTypeDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountTypeDescriptionMutation, { data, loading, error }] = useUpdateAccountTypeDescriptionMutation({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateAccountTypeDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountTypeDescriptionMutation,
    UpdateAccountTypeDescriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountTypeDescriptionMutation,
    UpdateAccountTypeDescriptionMutationVariables
  >(UpdateAccountTypeDescriptionDocument, options);
}
export type UpdateAccountTypeDescriptionMutationHookResult = ReturnType<
  typeof useUpdateAccountTypeDescriptionMutation
>;
export type UpdateAccountTypeDescriptionMutationResult =
  Apollo.MutationResult<UpdateAccountTypeDescriptionMutation>;
export type UpdateAccountTypeDescriptionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAccountTypeDescriptionMutation,
    UpdateAccountTypeDescriptionMutationVariables
  >;
export const UpdateApplicationDetailsDocument = gql`
  mutation updateApplicationDetails(
    $id: String!
    $input: EditApplicationDetails!
  ) {
    updateApplicationDetails(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateApplicationDetailsMutationFn = Apollo.MutationFunction<
  UpdateApplicationDetailsMutation,
  UpdateApplicationDetailsMutationVariables
>;

/**
 * __useUpdateApplicationDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationDetailsMutation, { data, loading, error }] = useUpdateApplicationDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationDetailsMutation,
    UpdateApplicationDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationDetailsMutation,
    UpdateApplicationDetailsMutationVariables
  >(UpdateApplicationDetailsDocument, options);
}
export type UpdateApplicationDetailsMutationHookResult = ReturnType<
  typeof useUpdateApplicationDetailsMutation
>;
export type UpdateApplicationDetailsMutationResult =
  Apollo.MutationResult<UpdateApplicationDetailsMutation>;
export type UpdateApplicationDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationDetailsMutation,
    UpdateApplicationDetailsMutationVariables
  >;
export const UpdateApplicationIsLiveDocument = gql`
  mutation updateApplicationIsLive($id: String!, $isLive: Boolean!) {
    updateApplicationIsLive(id: $id, isLive: $isLive) {
      id
    }
  }
`;
export type UpdateApplicationIsLiveMutationFn = Apollo.MutationFunction<
  UpdateApplicationIsLiveMutation,
  UpdateApplicationIsLiveMutationVariables
>;

/**
 * __useUpdateApplicationIsLiveMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationIsLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationIsLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationIsLiveMutation, { data, loading, error }] = useUpdateApplicationIsLiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isLive: // value for 'isLive'
 *   },
 * });
 */
export function useUpdateApplicationIsLiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationIsLiveMutation,
    UpdateApplicationIsLiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationIsLiveMutation,
    UpdateApplicationIsLiveMutationVariables
  >(UpdateApplicationIsLiveDocument, options);
}
export type UpdateApplicationIsLiveMutationHookResult = ReturnType<
  typeof useUpdateApplicationIsLiveMutation
>;
export type UpdateApplicationIsLiveMutationResult =
  Apollo.MutationResult<UpdateApplicationIsLiveMutation>;
export type UpdateApplicationIsLiveMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationIsLiveMutation,
  UpdateApplicationIsLiveMutationVariables
>;
export const UpdateApplicationProspectiveAllowedDocument = gql`
  mutation updateApplicationProspectiveAllowed(
    $id: String!
    $prospectiveAllowed: Boolean!
  ) {
    updateApplicationProspectiveAllowed(
      id: $id
      prospectiveAllowed: $prospectiveAllowed
    ) {
      id
    }
  }
`;
export type UpdateApplicationProspectiveAllowedMutationFn =
  Apollo.MutationFunction<
    UpdateApplicationProspectiveAllowedMutation,
    UpdateApplicationProspectiveAllowedMutationVariables
  >;

/**
 * __useUpdateApplicationProspectiveAllowedMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationProspectiveAllowedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationProspectiveAllowedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationProspectiveAllowedMutation, { data, loading, error }] = useUpdateApplicationProspectiveAllowedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      prospectiveAllowed: // value for 'prospectiveAllowed'
 *   },
 * });
 */
export function useUpdateApplicationProspectiveAllowedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationProspectiveAllowedMutation,
    UpdateApplicationProspectiveAllowedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationProspectiveAllowedMutation,
    UpdateApplicationProspectiveAllowedMutationVariables
  >(UpdateApplicationProspectiveAllowedDocument, options);
}
export type UpdateApplicationProspectiveAllowedMutationHookResult = ReturnType<
  typeof useUpdateApplicationProspectiveAllowedMutation
>;
export type UpdateApplicationProspectiveAllowedMutationResult =
  Apollo.MutationResult<UpdateApplicationProspectiveAllowedMutation>;
export type UpdateApplicationProspectiveAllowedMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationProspectiveAllowedMutation,
    UpdateApplicationProspectiveAllowedMutationVariables
  >;
export const UpdateApplicationProxyLocationDocument = gql`
  mutation updateApplicationProxyLocation(
    $id: String!
    $proxyLocation: String!
  ) {
    updateApplicationProxyLocation(id: $id, proxyLocation: $proxyLocation) {
      id
    }
  }
`;
export type UpdateApplicationProxyLocationMutationFn = Apollo.MutationFunction<
  UpdateApplicationProxyLocationMutation,
  UpdateApplicationProxyLocationMutationVariables
>;

/**
 * __useUpdateApplicationProxyLocationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationProxyLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationProxyLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationProxyLocationMutation, { data, loading, error }] = useUpdateApplicationProxyLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      proxyLocation: // value for 'proxyLocation'
 *   },
 * });
 */
export function useUpdateApplicationProxyLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationProxyLocationMutation,
    UpdateApplicationProxyLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationProxyLocationMutation,
    UpdateApplicationProxyLocationMutationVariables
  >(UpdateApplicationProxyLocationDocument, options);
}
export type UpdateApplicationProxyLocationMutationHookResult = ReturnType<
  typeof useUpdateApplicationProxyLocationMutation
>;
export type UpdateApplicationProxyLocationMutationResult =
  Apollo.MutationResult<UpdateApplicationProxyLocationMutation>;
export type UpdateApplicationProxyLocationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationProxyLocationMutation,
    UpdateApplicationProxyLocationMutationVariables
  >;
export const UpdateProfileDocument = gql`
  mutation updateProfile($id: String!, $profile: ProfileUpdate!) {
    updateProfile(id: $id, profile: $profile) {
      id
    }
  }
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>;
export type UpdateProfileMutationResult =
  Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const UpdateProfileEmailAddressDocument = gql`
  mutation updateProfileEmailAddress(
    $id: String!
    $input: ProfileUpdateEmailAddress!
  ) {
    updateProfileEmailAddress(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateProfileEmailAddressMutationFn = Apollo.MutationFunction<
  UpdateProfileEmailAddressMutation,
  UpdateProfileEmailAddressMutationVariables
>;

/**
 * __useUpdateProfileEmailAddressMutation__
 *
 * To run a mutation, you first call `useUpdateProfileEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileEmailAddressMutation, { data, loading, error }] = useUpdateProfileEmailAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileEmailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileEmailAddressMutation,
    UpdateProfileEmailAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProfileEmailAddressMutation,
    UpdateProfileEmailAddressMutationVariables
  >(UpdateProfileEmailAddressDocument, options);
}
export type UpdateProfileEmailAddressMutationHookResult = ReturnType<
  typeof useUpdateProfileEmailAddressMutation
>;
export type UpdateProfileEmailAddressMutationResult =
  Apollo.MutationResult<UpdateProfileEmailAddressMutation>;
export type UpdateProfileEmailAddressMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProfileEmailAddressMutation,
    UpdateProfileEmailAddressMutationVariables
  >;
export const UpdateRoleDocument = gql`
  mutation updateRole($id: String!, $role: RoleUpdate!) {
    updateRole(id: $id, role: $role) {
      id
    }
  }
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(
    UpdateRoleDocument,
    options,
  );
}
export type UpdateRoleMutationHookResult = ReturnType<
  typeof useUpdateRoleMutation
>;
export type UpdateRoleMutationResult =
  Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;
export const UpdateTagDescriptionDocument = gql`
  mutation updateTagDescription($id: String!, $description: String!) {
    updateTagDescription(id: $id, description: $description) {
      id
    }
  }
`;
export type UpdateTagDescriptionMutationFn = Apollo.MutationFunction<
  UpdateTagDescriptionMutation,
  UpdateTagDescriptionMutationVariables
>;

/**
 * __useUpdateTagDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateTagDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagDescriptionMutation, { data, loading, error }] = useUpdateTagDescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateTagDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTagDescriptionMutation,
    UpdateTagDescriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTagDescriptionMutation,
    UpdateTagDescriptionMutationVariables
  >(UpdateTagDescriptionDocument, options);
}
export type UpdateTagDescriptionMutationHookResult = ReturnType<
  typeof useUpdateTagDescriptionMutation
>;
export type UpdateTagDescriptionMutationResult =
  Apollo.MutationResult<UpdateTagDescriptionMutation>;
export type UpdateTagDescriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateTagDescriptionMutation,
  UpdateTagDescriptionMutationVariables
>;
export const RoleUpdateHiddenForRequestsDocument = gql`
  mutation roleUpdateHiddenForRequests(
    $id: String!
    $hiddenForRequests: Boolean!
  ) {
    roleUpdateHiddenForRequests(
      id: $id
      hiddenForRequests: $hiddenForRequests
    ) {
      id
    }
  }
`;
export type RoleUpdateHiddenForRequestsMutationFn = Apollo.MutationFunction<
  RoleUpdateHiddenForRequestsMutation,
  RoleUpdateHiddenForRequestsMutationVariables
>;

/**
 * __useRoleUpdateHiddenForRequestsMutation__
 *
 * To run a mutation, you first call `useRoleUpdateHiddenForRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoleUpdateHiddenForRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roleUpdateHiddenForRequestsMutation, { data, loading, error }] = useRoleUpdateHiddenForRequestsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      hiddenForRequests: // value for 'hiddenForRequests'
 *   },
 * });
 */
export function useRoleUpdateHiddenForRequestsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RoleUpdateHiddenForRequestsMutation,
    RoleUpdateHiddenForRequestsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RoleUpdateHiddenForRequestsMutation,
    RoleUpdateHiddenForRequestsMutationVariables
  >(RoleUpdateHiddenForRequestsDocument, options);
}
export type RoleUpdateHiddenForRequestsMutationHookResult = ReturnType<
  typeof useRoleUpdateHiddenForRequestsMutation
>;
export type RoleUpdateHiddenForRequestsMutationResult =
  Apollo.MutationResult<RoleUpdateHiddenForRequestsMutation>;
export type RoleUpdateHiddenForRequestsMutationOptions =
  Apollo.BaseMutationOptions<
    RoleUpdateHiddenForRequestsMutation,
    RoleUpdateHiddenForRequestsMutationVariables
  >;
export const AccountDocument = gql`
  query account($id: String!) {
    account(id: $id) {
      ...AccountCore
      accountType {
        id
        checkType
      }
      profile {
        value {
          firstName
          lastName
          emailAddress
        }
      }
      tags {
        value {
          name
          id
          description
        }
      }
      organisationGroups {
        name
        id
      }
      roles {
        value {
          id
          name
          description
        }
      }
    }
  }
  ${AccountCoreFragmentDoc}
`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountQuery(
  baseOptions: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export function useAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountQuery,
    AccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export function useAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountQuery,
    AccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountSuspenseQueryHookResult = ReturnType<
  typeof useAccountSuspenseQuery
>;
export type AccountQueryResult = Apollo.QueryResult<
  AccountQuery,
  AccountQueryVariables
>;
export const AccountTypeDocument = gql`
  query AccountType($id: String!) {
    accountType(id: $id) {
      ...AccountTypeCore
      admins {
        value {
          ...AccountCore
          profile {
            value {
              ...ProfileCore
            }
          }
        }
      }
      approvers {
        value {
          ...AccountCore
          profile {
            value {
              ...ProfileCore
            }
          }
        }
      }
      linkedApplications {
        value {
          ...ApplicationCore
        }
      }
      tags {
        value {
          ...TagCore
        }
      }
    }
  }
  ${AccountTypeCoreFragmentDoc}
  ${AccountCoreFragmentDoc}
  ${ProfileCoreFragmentDoc}
  ${ApplicationCoreFragmentDoc}
  ${TagCoreFragmentDoc}
`;

/**
 * __useAccountTypeQuery__
 *
 * To run a query within a React component, call `useAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountTypeQuery,
    AccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountTypeQuery, AccountTypeQueryVariables>(
    AccountTypeDocument,
    options,
  );
}
export function useAccountTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountTypeQuery,
    AccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountTypeQuery, AccountTypeQueryVariables>(
    AccountTypeDocument,
    options,
  );
}
export function useAccountTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountTypeQuery,
    AccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountTypeQuery, AccountTypeQueryVariables>(
    AccountTypeDocument,
    options,
  );
}
export type AccountTypeQueryHookResult = ReturnType<typeof useAccountTypeQuery>;
export type AccountTypeLazyQueryHookResult = ReturnType<
  typeof useAccountTypeLazyQuery
>;
export type AccountTypeSuspenseQueryHookResult = ReturnType<
  typeof useAccountTypeSuspenseQuery
>;
export type AccountTypeQueryResult = Apollo.QueryResult<
  AccountTypeQuery,
  AccountTypeQueryVariables
>;
export const AccountTypesDocument = gql`
  query AccountTypes {
    accountTypes {
      ...AccountTypeCore
    }
  }
  ${AccountTypeCoreFragmentDoc}
`;

/**
 * __useAccountTypesQuery__
 *
 * To run a query within a React component, call `useAccountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountTypesQuery,
    AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountTypesQuery, AccountTypesQueryVariables>(
    AccountTypesDocument,
    options,
  );
}
export function useAccountTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountTypesQuery,
    AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountTypesQuery, AccountTypesQueryVariables>(
    AccountTypesDocument,
    options,
  );
}
export function useAccountTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountTypesQuery,
    AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountTypesQuery, AccountTypesQueryVariables>(
    AccountTypesDocument,
    options,
  );
}
export type AccountTypesQueryHookResult = ReturnType<
  typeof useAccountTypesQuery
>;
export type AccountTypesLazyQueryHookResult = ReturnType<
  typeof useAccountTypesLazyQuery
>;
export type AccountTypesSuspenseQueryHookResult = ReturnType<
  typeof useAccountTypesSuspenseQuery
>;
export type AccountTypesQueryResult = Apollo.QueryResult<
  AccountTypesQuery,
  AccountTypesQueryVariables
>;
export const GetAccountsByProfileIdDocument = gql`
  query getAccountsByProfileId($profileId: String!) {
    profileAccounts(profileId: $profileId) {
      ...AccountCore
    }
  }
  ${AccountCoreFragmentDoc}
`;

/**
 * __useGetAccountsByProfileIdQuery__
 *
 * To run a query within a React component, call `useGetAccountsByProfileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsByProfileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsByProfileIdQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetAccountsByProfileIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountsByProfileIdQuery,
    GetAccountsByProfileIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountsByProfileIdQuery,
    GetAccountsByProfileIdQueryVariables
  >(GetAccountsByProfileIdDocument, options);
}
export function useGetAccountsByProfileIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountsByProfileIdQuery,
    GetAccountsByProfileIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountsByProfileIdQuery,
    GetAccountsByProfileIdQueryVariables
  >(GetAccountsByProfileIdDocument, options);
}
export function useGetAccountsByProfileIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAccountsByProfileIdQuery,
    GetAccountsByProfileIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAccountsByProfileIdQuery,
    GetAccountsByProfileIdQueryVariables
  >(GetAccountsByProfileIdDocument, options);
}
export type GetAccountsByProfileIdQueryHookResult = ReturnType<
  typeof useGetAccountsByProfileIdQuery
>;
export type GetAccountsByProfileIdLazyQueryHookResult = ReturnType<
  typeof useGetAccountsByProfileIdLazyQuery
>;
export type GetAccountsByProfileIdSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountsByProfileIdSuspenseQuery
>;
export type GetAccountsByProfileIdQueryResult = Apollo.QueryResult<
  GetAccountsByProfileIdQuery,
  GetAccountsByProfileIdQueryVariables
>;
export const AccountsDocument = gql`
  query Accounts($page: Float!, $pageSize: Float!) {
    accounts(page: $page, pageSize: $pageSize) {
      count
      page
      perPage
      accounts {
        ...AccountCore
        organisationGroups {
          name
        }
        profile {
          value {
            firstName
            lastName
            emailAddress
          }
        }
        sites {
          value {
            supplierCode
          }
        }
      }
    }
  }
  ${AccountCoreFragmentDoc}
`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(
    AccountsDocument,
    options,
  );
}
export function useAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountsQuery,
    AccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(
    AccountsDocument,
    options,
  );
}
export function useAccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountsQuery,
    AccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountsQuery, AccountsQueryVariables>(
    AccountsDocument,
    options,
  );
}
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<
  typeof useAccountsLazyQuery
>;
export type AccountsSuspenseQueryHookResult = ReturnType<
  typeof useAccountsSuspenseQuery
>;
export type AccountsQueryResult = Apollo.QueryResult<
  AccountsQuery,
  AccountsQueryVariables
>;
export const TagsDocument = gql`
  query Tags {
    tags {
      ...TagCore
    }
  }
  ${TagCoreFragmentDoc}
`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
}
export function useTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(
    TagsDocument,
    options,
  );
}
export function useTagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TagsQuery, TagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TagsQuery, TagsQueryVariables>(
    TagsDocument,
    options,
  );
}
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsSuspenseQueryHookResult = ReturnType<
  typeof useTagsSuspenseQuery
>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const ApplicationDocument = gql`
  query Application($id: String!) {
    application(id: $id) {
      ...ApplicationCore
      tags {
        value {
          ...TagCore
        }
      }
      admins {
        value {
          ...AccountCore
          profile {
            value {
              ...ProfileCore
            }
          }
        }
      }
      approvers {
        value {
          ...AccountCore
          profile {
            value {
              ...ProfileCore
            }
          }
        }
      }
      linkedApplications {
        value {
          ...ApplicationCore
        }
      }
    }
  }
  ${ApplicationCoreFragmentDoc}
  ${TagCoreFragmentDoc}
  ${AccountCoreFragmentDoc}
  ${ProfileCoreFragmentDoc}
`;

/**
 * __useApplicationQuery__
 *
 * To run a query within a React component, call `useApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationQuery,
    ApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationQuery, ApplicationQueryVariables>(
    ApplicationDocument,
    options,
  );
}
export function useApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationQuery,
    ApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationQuery, ApplicationQueryVariables>(
    ApplicationDocument,
    options,
  );
}
export function useApplicationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ApplicationQuery,
    ApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ApplicationQuery, ApplicationQueryVariables>(
    ApplicationDocument,
    options,
  );
}
export type ApplicationQueryHookResult = ReturnType<typeof useApplicationQuery>;
export type ApplicationLazyQueryHookResult = ReturnType<
  typeof useApplicationLazyQuery
>;
export type ApplicationSuspenseQueryHookResult = ReturnType<
  typeof useApplicationSuspenseQuery
>;
export type ApplicationQueryResult = Apollo.QueryResult<
  ApplicationQuery,
  ApplicationQueryVariables
>;
export const AccountApplicationGroupsDocument = gql`
  query AccountApplicationGroups($id: String!) {
    account(id: $id) {
      applicationGroups {
        id
        name
        applications {
          value {
            ...ApplicationCore
            tags {
              value {
                ...TagCore
              }
            }
          }
        }
      }
    }
  }
  ${ApplicationCoreFragmentDoc}
  ${TagCoreFragmentDoc}
`;

/**
 * __useAccountApplicationGroupsQuery__
 *
 * To run a query within a React component, call `useAccountApplicationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountApplicationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountApplicationGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountApplicationGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountApplicationGroupsQuery,
    AccountApplicationGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountApplicationGroupsQuery,
    AccountApplicationGroupsQueryVariables
  >(AccountApplicationGroupsDocument, options);
}
export function useAccountApplicationGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountApplicationGroupsQuery,
    AccountApplicationGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountApplicationGroupsQuery,
    AccountApplicationGroupsQueryVariables
  >(AccountApplicationGroupsDocument, options);
}
export function useAccountApplicationGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountApplicationGroupsQuery,
    AccountApplicationGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountApplicationGroupsQuery,
    AccountApplicationGroupsQueryVariables
  >(AccountApplicationGroupsDocument, options);
}
export type AccountApplicationGroupsQueryHookResult = ReturnType<
  typeof useAccountApplicationGroupsQuery
>;
export type AccountApplicationGroupsLazyQueryHookResult = ReturnType<
  typeof useAccountApplicationGroupsLazyQuery
>;
export type AccountApplicationGroupsSuspenseQueryHookResult = ReturnType<
  typeof useAccountApplicationGroupsSuspenseQuery
>;
export type AccountApplicationGroupsQueryResult = Apollo.QueryResult<
  AccountApplicationGroupsQuery,
  AccountApplicationGroupsQueryVariables
>;
export const ApplicationPermissionsDocument = gql`
  query ApplicationPermissions($applicationId: String!) {
    applicationPermissions(applicationId: $applicationId) {
      ...PermissionCore
    }
  }
  ${PermissionCoreFragmentDoc}
`;

/**
 * __useApplicationPermissionsQuery__
 *
 * To run a query within a React component, call `useApplicationPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationPermissionsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useApplicationPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationPermissionsQuery,
    ApplicationPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationPermissionsQuery,
    ApplicationPermissionsQueryVariables
  >(ApplicationPermissionsDocument, options);
}
export function useApplicationPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationPermissionsQuery,
    ApplicationPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationPermissionsQuery,
    ApplicationPermissionsQueryVariables
  >(ApplicationPermissionsDocument, options);
}
export function useApplicationPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ApplicationPermissionsQuery,
    ApplicationPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ApplicationPermissionsQuery,
    ApplicationPermissionsQueryVariables
  >(ApplicationPermissionsDocument, options);
}
export type ApplicationPermissionsQueryHookResult = ReturnType<
  typeof useApplicationPermissionsQuery
>;
export type ApplicationPermissionsLazyQueryHookResult = ReturnType<
  typeof useApplicationPermissionsLazyQuery
>;
export type ApplicationPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useApplicationPermissionsSuspenseQuery
>;
export type ApplicationPermissionsQueryResult = Apollo.QueryResult<
  ApplicationPermissionsQuery,
  ApplicationPermissionsQueryVariables
>;
export const ApplicationsDocument = gql`
  query Applications {
    applications {
      ...ApplicationCore
      tags {
        value {
          ...TagCore
        }
      }
    }
  }
  ${ApplicationCoreFragmentDoc}
  ${TagCoreFragmentDoc}
`;

/**
 * __useApplicationsQuery__
 *
 * To run a query within a React component, call `useApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApplicationsQuery,
    ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationsQuery, ApplicationsQueryVariables>(
    ApplicationsDocument,
    options,
  );
}
export function useApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsQuery,
    ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationsQuery, ApplicationsQueryVariables>(
    ApplicationsDocument,
    options,
  );
}
export function useApplicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ApplicationsQuery,
    ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ApplicationsQuery, ApplicationsQueryVariables>(
    ApplicationsDocument,
    options,
  );
}
export type ApplicationsQueryHookResult = ReturnType<
  typeof useApplicationsQuery
>;
export type ApplicationsLazyQueryHookResult = ReturnType<
  typeof useApplicationsLazyQuery
>;
export type ApplicationsSuspenseQueryHookResult = ReturnType<
  typeof useApplicationsSuspenseQuery
>;
export type ApplicationsQueryResult = Apollo.QueryResult<
  ApplicationsQuery,
  ApplicationsQueryVariables
>;
export const ApplicationsWithFiltersDocument = gql`
  query ApplicationsWithFilters($tagIds: [String!]) {
    myApplications(tagIds: $tagIds) {
      ...ApplicationCore
      tags {
        value {
          ...TagCore
        }
      }
    }
  }
  ${ApplicationCoreFragmentDoc}
  ${TagCoreFragmentDoc}
`;

/**
 * __useApplicationsWithFiltersQuery__
 *
 * To run a query within a React component, call `useApplicationsWithFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsWithFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsWithFiltersQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useApplicationsWithFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApplicationsWithFiltersQuery,
    ApplicationsWithFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationsWithFiltersQuery,
    ApplicationsWithFiltersQueryVariables
  >(ApplicationsWithFiltersDocument, options);
}
export function useApplicationsWithFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsWithFiltersQuery,
    ApplicationsWithFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationsWithFiltersQuery,
    ApplicationsWithFiltersQueryVariables
  >(ApplicationsWithFiltersDocument, options);
}
export function useApplicationsWithFiltersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ApplicationsWithFiltersQuery,
    ApplicationsWithFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ApplicationsWithFiltersQuery,
    ApplicationsWithFiltersQueryVariables
  >(ApplicationsWithFiltersDocument, options);
}
export type ApplicationsWithFiltersQueryHookResult = ReturnType<
  typeof useApplicationsWithFiltersQuery
>;
export type ApplicationsWithFiltersLazyQueryHookResult = ReturnType<
  typeof useApplicationsWithFiltersLazyQuery
>;
export type ApplicationsWithFiltersSuspenseQueryHookResult = ReturnType<
  typeof useApplicationsWithFiltersSuspenseQuery
>;
export type ApplicationsWithFiltersQueryResult = Apollo.QueryResult<
  ApplicationsWithFiltersQuery,
  ApplicationsWithFiltersQueryVariables
>;
export const Get_HierarchiesDocument = gql`
  query GET_HIERARCHIES($searchTerms: String, $page: Float, $pageSize: Float) {
    paginatedHierarchies(
      searchTerms: $searchTerms
      page: $page
      pageSize: $pageSize
    ) {
      hierarchies {
        id
        label
        name
        parentId
        parentLevel
        level
      }
    }
  }
`;

/**
 * __useGet_HierarchiesQuery__
 *
 * To run a query within a React component, call `useGet_HierarchiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_HierarchiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_HierarchiesQuery({
 *   variables: {
 *      searchTerms: // value for 'searchTerms'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGet_HierarchiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_HierarchiesQuery,
    Get_HierarchiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_HierarchiesQuery, Get_HierarchiesQueryVariables>(
    Get_HierarchiesDocument,
    options,
  );
}
export function useGet_HierarchiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_HierarchiesQuery,
    Get_HierarchiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_HierarchiesQuery,
    Get_HierarchiesQueryVariables
  >(Get_HierarchiesDocument, options);
}
export function useGet_HierarchiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Get_HierarchiesQuery,
    Get_HierarchiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Get_HierarchiesQuery,
    Get_HierarchiesQueryVariables
  >(Get_HierarchiesDocument, options);
}
export type Get_HierarchiesQueryHookResult = ReturnType<
  typeof useGet_HierarchiesQuery
>;
export type Get_HierarchiesLazyQueryHookResult = ReturnType<
  typeof useGet_HierarchiesLazyQuery
>;
export type Get_HierarchiesSuspenseQueryHookResult = ReturnType<
  typeof useGet_HierarchiesSuspenseQuery
>;
export type Get_HierarchiesQueryResult = Apollo.QueryResult<
  Get_HierarchiesQuery,
  Get_HierarchiesQueryVariables
>;
export const MyProfileDocument = gql`
  query MyProfile {
    myProfile {
      ...ProfileCore
      accounts {
        value {
          ...AccountCore
        }
      }
    }
  }
  ${ProfileCoreFragmentDoc}
  ${AccountCoreFragmentDoc}
`;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyProfileQuery,
    MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(
    MyProfileDocument,
    options,
  );
}
export function useMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyProfileQuery,
    MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(
    MyProfileDocument,
    options,
  );
}
export function useMyProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MyProfileQuery,
    MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MyProfileQuery, MyProfileQueryVariables>(
    MyProfileDocument,
    options,
  );
}
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<
  typeof useMyProfileLazyQuery
>;
export type MyProfileSuspenseQueryHookResult = ReturnType<
  typeof useMyProfileSuspenseQuery
>;
export type MyProfileQueryResult = Apollo.QueryResult<
  MyProfileQuery,
  MyProfileQueryVariables
>;
export const Get_My_AccountsDocument = gql`
  query GET_MY_ACCOUNTS {
    myProfileAccounts {
      ...AccountCore
    }
  }
  ${AccountCoreFragmentDoc}
`;

/**
 * __useGet_My_AccountsQuery__
 *
 * To run a query within a React component, call `useGet_My_AccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_My_AccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_My_AccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_My_AccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_My_AccountsQuery,
    Get_My_AccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_My_AccountsQuery, Get_My_AccountsQueryVariables>(
    Get_My_AccountsDocument,
    options,
  );
}
export function useGet_My_AccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_My_AccountsQuery,
    Get_My_AccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_My_AccountsQuery,
    Get_My_AccountsQueryVariables
  >(Get_My_AccountsDocument, options);
}
export function useGet_My_AccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Get_My_AccountsQuery,
    Get_My_AccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Get_My_AccountsQuery,
    Get_My_AccountsQueryVariables
  >(Get_My_AccountsDocument, options);
}
export type Get_My_AccountsQueryHookResult = ReturnType<
  typeof useGet_My_AccountsQuery
>;
export type Get_My_AccountsLazyQueryHookResult = ReturnType<
  typeof useGet_My_AccountsLazyQuery
>;
export type Get_My_AccountsSuspenseQueryHookResult = ReturnType<
  typeof useGet_My_AccountsSuspenseQuery
>;
export type Get_My_AccountsQueryResult = Apollo.QueryResult<
  Get_My_AccountsQuery,
  Get_My_AccountsQueryVariables
>;
export const Applications_MyApplicationsDocument = gql`
  query applications_myApplications($tagIds: [String!]) {
    myApplications(tagIds: $tagIds) {
      id
      acronym
      name
      slug
      shortDescription
      longDescription
      isLive
      proxyLocation
      prospectiveAllowed
      isRoot
      type
      tags {
        ...applications_tagRelationship
      }
    }
  }
  ${Applications_TagRelationshipFragmentDoc}
`;

/**
 * __useApplications_MyApplicationsQuery__
 *
 * To run a query within a React component, call `useApplications_MyApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplications_MyApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplications_MyApplicationsQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useApplications_MyApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Applications_MyApplicationsQuery,
    Applications_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Applications_MyApplicationsQuery,
    Applications_MyApplicationsQueryVariables
  >(Applications_MyApplicationsDocument, options);
}
export function useApplications_MyApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Applications_MyApplicationsQuery,
    Applications_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Applications_MyApplicationsQuery,
    Applications_MyApplicationsQueryVariables
  >(Applications_MyApplicationsDocument, options);
}
export function useApplications_MyApplicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Applications_MyApplicationsQuery,
    Applications_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Applications_MyApplicationsQuery,
    Applications_MyApplicationsQueryVariables
  >(Applications_MyApplicationsDocument, options);
}
export type Applications_MyApplicationsQueryHookResult = ReturnType<
  typeof useApplications_MyApplicationsQuery
>;
export type Applications_MyApplicationsLazyQueryHookResult = ReturnType<
  typeof useApplications_MyApplicationsLazyQuery
>;
export type Applications_MyApplicationsSuspenseQueryHookResult = ReturnType<
  typeof useApplications_MyApplicationsSuspenseQuery
>;
export type Applications_MyApplicationsQueryResult = Apollo.QueryResult<
  Applications_MyApplicationsQuery,
  Applications_MyApplicationsQueryVariables
>;
export const Get_Application_Groups_And_Profile_Groups_By_Account_IdDocument = gql`
  query GET_APPLICATION_GROUPS_AND_PROFILE_GROUPS_BY_ACCOUNT_ID($id: String!) {
    account(id: $id) {
      applicationGroups {
        id
        name
        applications {
          value {
            ...ApplicationCore
          }
        }
      }
      profileGroups {
        id
        name
        profiles {
          value {
            ...ProfileCore
          }
        }
      }
    }
  }
  ${ApplicationCoreFragmentDoc}
  ${ProfileCoreFragmentDoc}
`;

/**
 * __useGet_Application_Groups_And_Profile_Groups_By_Account_IdQuery__
 *
 * To run a query within a React component, call `useGet_Application_Groups_And_Profile_Groups_By_Account_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Application_Groups_And_Profile_Groups_By_Account_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Application_Groups_And_Profile_Groups_By_Account_IdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Application_Groups_And_Profile_Groups_By_Account_IdQuery(
  baseOptions: Apollo.QueryHookOptions<
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQuery,
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQuery,
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQueryVariables
  >(Get_Application_Groups_And_Profile_Groups_By_Account_IdDocument, options);
}
export function useGet_Application_Groups_And_Profile_Groups_By_Account_IdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQuery,
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQuery,
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQueryVariables
  >(Get_Application_Groups_And_Profile_Groups_By_Account_IdDocument, options);
}
export function useGet_Application_Groups_And_Profile_Groups_By_Account_IdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQuery,
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQuery,
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQueryVariables
  >(Get_Application_Groups_And_Profile_Groups_By_Account_IdDocument, options);
}
export type Get_Application_Groups_And_Profile_Groups_By_Account_IdQueryHookResult =
  ReturnType<
    typeof useGet_Application_Groups_And_Profile_Groups_By_Account_IdQuery
  >;
export type Get_Application_Groups_And_Profile_Groups_By_Account_IdLazyQueryHookResult =
  ReturnType<
    typeof useGet_Application_Groups_And_Profile_Groups_By_Account_IdLazyQuery
  >;
export type Get_Application_Groups_And_Profile_Groups_By_Account_IdSuspenseQueryHookResult =
  ReturnType<
    typeof useGet_Application_Groups_And_Profile_Groups_By_Account_IdSuspenseQuery
  >;
export type Get_Application_Groups_And_Profile_Groups_By_Account_IdQueryResult =
  Apollo.QueryResult<
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQuery,
    Get_Application_Groups_And_Profile_Groups_By_Account_IdQueryVariables
  >;
export const Get_My_ApplicationsDocument = gql`
  query GET_MY_APPLICATIONS {
    myApplications {
      ...ApplicationCore
      tags {
        value {
          ...TagCore
        }
      }
    }
  }
  ${ApplicationCoreFragmentDoc}
  ${TagCoreFragmentDoc}
`;

/**
 * __useGet_My_ApplicationsQuery__
 *
 * To run a query within a React component, call `useGet_My_ApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_My_ApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_My_ApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_My_ApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_My_ApplicationsQuery,
    Get_My_ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_My_ApplicationsQuery,
    Get_My_ApplicationsQueryVariables
  >(Get_My_ApplicationsDocument, options);
}
export function useGet_My_ApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_My_ApplicationsQuery,
    Get_My_ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_My_ApplicationsQuery,
    Get_My_ApplicationsQueryVariables
  >(Get_My_ApplicationsDocument, options);
}
export function useGet_My_ApplicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Get_My_ApplicationsQuery,
    Get_My_ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Get_My_ApplicationsQuery,
    Get_My_ApplicationsQueryVariables
  >(Get_My_ApplicationsDocument, options);
}
export type Get_My_ApplicationsQueryHookResult = ReturnType<
  typeof useGet_My_ApplicationsQuery
>;
export type Get_My_ApplicationsLazyQueryHookResult = ReturnType<
  typeof useGet_My_ApplicationsLazyQuery
>;
export type Get_My_ApplicationsSuspenseQueryHookResult = ReturnType<
  typeof useGet_My_ApplicationsSuspenseQuery
>;
export type Get_My_ApplicationsQueryResult = Apollo.QueryResult<
  Get_My_ApplicationsQuery,
  Get_My_ApplicationsQueryVariables
>;
export const Get_My_Tags_With_ApplicationsDocument = gql`
  query GET_MY_TAGS_WITH_APPLICATIONS {
    myTags {
      ...TagCore
      applications {
        value {
          ...ApplicationCore
        }
      }
    }
  }
  ${TagCoreFragmentDoc}
  ${ApplicationCoreFragmentDoc}
`;

/**
 * __useGet_My_Tags_With_ApplicationsQuery__
 *
 * To run a query within a React component, call `useGet_My_Tags_With_ApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_My_Tags_With_ApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_My_Tags_With_ApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_My_Tags_With_ApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_My_Tags_With_ApplicationsQuery,
    Get_My_Tags_With_ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_My_Tags_With_ApplicationsQuery,
    Get_My_Tags_With_ApplicationsQueryVariables
  >(Get_My_Tags_With_ApplicationsDocument, options);
}
export function useGet_My_Tags_With_ApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_My_Tags_With_ApplicationsQuery,
    Get_My_Tags_With_ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_My_Tags_With_ApplicationsQuery,
    Get_My_Tags_With_ApplicationsQueryVariables
  >(Get_My_Tags_With_ApplicationsDocument, options);
}
export function useGet_My_Tags_With_ApplicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Get_My_Tags_With_ApplicationsQuery,
    Get_My_Tags_With_ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Get_My_Tags_With_ApplicationsQuery,
    Get_My_Tags_With_ApplicationsQueryVariables
  >(Get_My_Tags_With_ApplicationsDocument, options);
}
export type Get_My_Tags_With_ApplicationsQueryHookResult = ReturnType<
  typeof useGet_My_Tags_With_ApplicationsQuery
>;
export type Get_My_Tags_With_ApplicationsLazyQueryHookResult = ReturnType<
  typeof useGet_My_Tags_With_ApplicationsLazyQuery
>;
export type Get_My_Tags_With_ApplicationsSuspenseQueryHookResult = ReturnType<
  typeof useGet_My_Tags_With_ApplicationsSuspenseQuery
>;
export type Get_My_Tags_With_ApplicationsQueryResult = Apollo.QueryResult<
  Get_My_Tags_With_ApplicationsQuery,
  Get_My_Tags_With_ApplicationsQueryVariables
>;
export const Get_My_TagsDocument = gql`
  query GET_MY_TAGS {
    myTags {
      ...TagCore
    }
  }
  ${TagCoreFragmentDoc}
`;

/**
 * __useGet_My_TagsQuery__
 *
 * To run a query within a React component, call `useGet_My_TagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_My_TagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_My_TagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_My_TagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_My_TagsQuery,
    Get_My_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_My_TagsQuery, Get_My_TagsQueryVariables>(
    Get_My_TagsDocument,
    options,
  );
}
export function useGet_My_TagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_My_TagsQuery,
    Get_My_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_My_TagsQuery, Get_My_TagsQueryVariables>(
    Get_My_TagsDocument,
    options,
  );
}
export function useGet_My_TagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Get_My_TagsQuery,
    Get_My_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Get_My_TagsQuery, Get_My_TagsQueryVariables>(
    Get_My_TagsDocument,
    options,
  );
}
export type Get_My_TagsQueryHookResult = ReturnType<typeof useGet_My_TagsQuery>;
export type Get_My_TagsLazyQueryHookResult = ReturnType<
  typeof useGet_My_TagsLazyQuery
>;
export type Get_My_TagsSuspenseQueryHookResult = ReturnType<
  typeof useGet_My_TagsSuspenseQuery
>;
export type Get_My_TagsQueryResult = Apollo.QueryResult<
  Get_My_TagsQuery,
  Get_My_TagsQueryVariables
>;
export const Get_Notification_By_Id_QueryDocument = gql`
  query GET_NOTIFICATION_BY_ID_QUERY($id: String!) {
    getNotification(entityId: $id) {
      ...NotificationCore
    }
  }
  ${NotificationCoreFragmentDoc}
`;

/**
 * __useGet_Notification_By_Id_QueryQuery__
 *
 * To run a query within a React component, call `useGet_Notification_By_Id_QueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Notification_By_Id_QueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Notification_By_Id_QueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Notification_By_Id_QueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    Get_Notification_By_Id_QueryQuery,
    Get_Notification_By_Id_QueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Notification_By_Id_QueryQuery,
    Get_Notification_By_Id_QueryQueryVariables
  >(Get_Notification_By_Id_QueryDocument, options);
}
export function useGet_Notification_By_Id_QueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Notification_By_Id_QueryQuery,
    Get_Notification_By_Id_QueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Notification_By_Id_QueryQuery,
    Get_Notification_By_Id_QueryQueryVariables
  >(Get_Notification_By_Id_QueryDocument, options);
}
export function useGet_Notification_By_Id_QuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Get_Notification_By_Id_QueryQuery,
    Get_Notification_By_Id_QueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Get_Notification_By_Id_QueryQuery,
    Get_Notification_By_Id_QueryQueryVariables
  >(Get_Notification_By_Id_QueryDocument, options);
}
export type Get_Notification_By_Id_QueryQueryHookResult = ReturnType<
  typeof useGet_Notification_By_Id_QueryQuery
>;
export type Get_Notification_By_Id_QueryLazyQueryHookResult = ReturnType<
  typeof useGet_Notification_By_Id_QueryLazyQuery
>;
export type Get_Notification_By_Id_QuerySuspenseQueryHookResult = ReturnType<
  typeof useGet_Notification_By_Id_QuerySuspenseQuery
>;
export type Get_Notification_By_Id_QueryQueryResult = Apollo.QueryResult<
  Get_Notification_By_Id_QueryQuery,
  Get_Notification_By_Id_QueryQueryVariables
>;
export const Get_Notifications_QueryDocument = gql`
  query GET_NOTIFICATIONS_QUERY($page: Float!, $filters: NotificationFilters) {
    getNotifications(page: $page, filters: $filters) {
      unreadCount
      page
      data {
        ...NotificationCore
        assignedTo {
          id
          profile {
            value {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
  ${NotificationCoreFragmentDoc}
`;

/**
 * __useGet_Notifications_QueryQuery__
 *
 * To run a query within a React component, call `useGet_Notifications_QueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Notifications_QueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Notifications_QueryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGet_Notifications_QueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    Get_Notifications_QueryQuery,
    Get_Notifications_QueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Notifications_QueryQuery,
    Get_Notifications_QueryQueryVariables
  >(Get_Notifications_QueryDocument, options);
}
export function useGet_Notifications_QueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Notifications_QueryQuery,
    Get_Notifications_QueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Notifications_QueryQuery,
    Get_Notifications_QueryQueryVariables
  >(Get_Notifications_QueryDocument, options);
}
export function useGet_Notifications_QuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Get_Notifications_QueryQuery,
    Get_Notifications_QueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Get_Notifications_QueryQuery,
    Get_Notifications_QueryQueryVariables
  >(Get_Notifications_QueryDocument, options);
}
export type Get_Notifications_QueryQueryHookResult = ReturnType<
  typeof useGet_Notifications_QueryQuery
>;
export type Get_Notifications_QueryLazyQueryHookResult = ReturnType<
  typeof useGet_Notifications_QueryLazyQuery
>;
export type Get_Notifications_QuerySuspenseQueryHookResult = ReturnType<
  typeof useGet_Notifications_QuerySuspenseQuery
>;
export type Get_Notifications_QueryQueryResult = Apollo.QueryResult<
  Get_Notifications_QueryQuery,
  Get_Notifications_QueryQueryVariables
>;
export const Get_Profiles_QueryDocument = gql`
  query GET_PROFILES_QUERY(
    $searchTerms: String
    $accountTypeIds: [String!]
    $tagIds: [String!]
    $roleIds: [String!]
    $hierarchyIds: [String!]
    $statuses: [ProfileStatus!]
    $page: Float
    $pageSize: Float
  ) {
    paginatedProfiles(
      searchTerms: $searchTerms
      accountTypeIds: $accountTypeIds
      tagIds: $tagIds
      roleIds: $roleIds
      hierarchyIds: $hierarchyIds
      statuses: $statuses
      page: $page
      pageSize: $pageSize
    ) {
      page
      count
      perPage
      profiles {
        ...ProfileCore
        accounts {
          value {
            id
            name
            organisationGroups {
              id
              name
            }
          }
        }
      }
    }
  }
  ${ProfileCoreFragmentDoc}
`;

/**
 * __useGet_Profiles_QueryQuery__
 *
 * To run a query within a React component, call `useGet_Profiles_QueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Profiles_QueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Profiles_QueryQuery({
 *   variables: {
 *      searchTerms: // value for 'searchTerms'
 *      accountTypeIds: // value for 'accountTypeIds'
 *      tagIds: // value for 'tagIds'
 *      roleIds: // value for 'roleIds'
 *      hierarchyIds: // value for 'hierarchyIds'
 *      statuses: // value for 'statuses'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGet_Profiles_QueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Profiles_QueryQuery,
    Get_Profiles_QueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Profiles_QueryQuery,
    Get_Profiles_QueryQueryVariables
  >(Get_Profiles_QueryDocument, options);
}
export function useGet_Profiles_QueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Profiles_QueryQuery,
    Get_Profiles_QueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Profiles_QueryQuery,
    Get_Profiles_QueryQueryVariables
  >(Get_Profiles_QueryDocument, options);
}
export function useGet_Profiles_QuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Get_Profiles_QueryQuery,
    Get_Profiles_QueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Get_Profiles_QueryQuery,
    Get_Profiles_QueryQueryVariables
  >(Get_Profiles_QueryDocument, options);
}
export type Get_Profiles_QueryQueryHookResult = ReturnType<
  typeof useGet_Profiles_QueryQuery
>;
export type Get_Profiles_QueryLazyQueryHookResult = ReturnType<
  typeof useGet_Profiles_QueryLazyQuery
>;
export type Get_Profiles_QuerySuspenseQueryHookResult = ReturnType<
  typeof useGet_Profiles_QuerySuspenseQuery
>;
export type Get_Profiles_QueryQueryResult = Apollo.QueryResult<
  Get_Profiles_QueryQuery,
  Get_Profiles_QueryQueryVariables
>;
export const QueryDocument = gql`
  query Query($searchTerms: String, $page: Float, $pageSize: Float) {
    paginatedProfiles(
      searchTerms: $searchTerms
      page: $page
      pageSize: $pageSize
    ) {
      profiles {
        ...ProfileCore
        accounts {
          value {
            id
            name
            accountTypeId
            status
            profileId
            organisationGroups {
              id
              name
            }
          }
        }
      }
      page
      count
      perPage
    }
  }
  ${ProfileCoreFragmentDoc}
`;

/**
 * __useQueryQuery__
 *
 * To run a query within a React component, call `useQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQuery({
 *   variables: {
 *      searchTerms: // value for 'searchTerms'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryQuery, QueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryQuery, QueryQueryVariables>(
    QueryDocument,
    options,
  );
}
export function useQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryQuery, QueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryQuery, QueryQueryVariables>(
    QueryDocument,
    options,
  );
}
export function useQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryQuery,
    QueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryQuery, QueryQueryVariables>(
    QueryDocument,
    options,
  );
}
export type QueryQueryHookResult = ReturnType<typeof useQueryQuery>;
export type QueryLazyQueryHookResult = ReturnType<typeof useQueryLazyQuery>;
export type QuerySuspenseQueryHookResult = ReturnType<
  typeof useQuerySuspenseQuery
>;
export type QueryQueryResult = Apollo.QueryResult<
  QueryQuery,
  QueryQueryVariables
>;
export const RoleDocument = gql`
  query Role($id: String!) {
    role(id: $id) {
      ...RoleCore
      roleApplications {
        value {
          ...ApplicationCore
        }
      }
      rolePermissions {
        value {
          id
        }
      }
      rolePermissionGroups {
        value {
          id
        }
      }
      accountType {
        value {
          ...AccountTypeCore
        }
      }
      tags {
        value {
          ...TagCore
        }
      }
    }
  }
  ${RoleCoreFragmentDoc}
  ${ApplicationCoreFragmentDoc}
  ${AccountTypeCoreFragmentDoc}
  ${TagCoreFragmentDoc}
`;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleQuery(
  baseOptions: Apollo.QueryHookOptions<RoleQuery, RoleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleQuery, RoleQueryVariables>(RoleDocument, options);
}
export function useRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RoleQuery, RoleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleQuery, RoleQueryVariables>(
    RoleDocument,
    options,
  );
}
export function useRoleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RoleQuery, RoleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RoleQuery, RoleQueryVariables>(
    RoleDocument,
    options,
  );
}
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export type RoleSuspenseQueryHookResult = ReturnType<
  typeof useRoleSuspenseQuery
>;
export type RoleQueryResult = Apollo.QueryResult<RoleQuery, RoleQueryVariables>;
export const RolesDocument = gql`
  query Roles {
    roles {
      ...RoleCore
    }
  }
  ${RoleCoreFragmentDoc}
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RolesQuery, RolesQueryVariables>(
    RolesDocument,
    options,
  );
}
export function useRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(
    RolesDocument,
    options,
  );
}
export function useRolesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RolesQuery,
    RolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RolesQuery, RolesQueryVariables>(
    RolesDocument,
    options,
  );
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesSuspenseQueryHookResult = ReturnType<
  typeof useRolesSuspenseQuery
>;
export type RolesQueryResult = Apollo.QueryResult<
  RolesQuery,
  RolesQueryVariables
>;
export const SiteDocument = gql`
  query Site($id: String!) {
    site(id: $id) {
      ...SiteCore
      parentGroup {
        value {
          id
          name
        }
      }
      accounts {
        value {
          id
          name
          profile {
            value {
              ...ProfileCore
            }
          }
        }
      }
    }
  }
  ${SiteCoreFragmentDoc}
  ${ProfileCoreFragmentDoc}
`;

/**
 * __useSiteQuery__
 *
 * To run a query within a React component, call `useSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSiteQuery(
  baseOptions: Apollo.QueryHookOptions<SiteQuery, SiteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SiteQuery, SiteQueryVariables>(SiteDocument, options);
}
export function useSiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SiteQuery, SiteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SiteQuery, SiteQueryVariables>(
    SiteDocument,
    options,
  );
}
export function useSiteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SiteQuery, SiteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SiteQuery, SiteQueryVariables>(
    SiteDocument,
    options,
  );
}
export type SiteQueryHookResult = ReturnType<typeof useSiteQuery>;
export type SiteLazyQueryHookResult = ReturnType<typeof useSiteLazyQuery>;
export type SiteSuspenseQueryHookResult = ReturnType<
  typeof useSiteSuspenseQuery
>;
export type SiteQueryResult = Apollo.QueryResult<SiteQuery, SiteQueryVariables>;
export const SiteDetailsDocument = gql`
  query SiteDetails($id: String!, $accountId: String) {
    site(id: $id) {
      ...SiteCore
      parentGroup {
        value {
          id
          name
        }
      }
      accounts {
        value {
          id
          name
          profile {
            value {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
  ${SiteCoreFragmentDoc}
`;

/**
 * __useSiteDetailsQuery__
 *
 * To run a query within a React component, call `useSiteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useSiteDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SiteDetailsQuery,
    SiteDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SiteDetailsQuery, SiteDetailsQueryVariables>(
    SiteDetailsDocument,
    options,
  );
}
export function useSiteDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SiteDetailsQuery,
    SiteDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SiteDetailsQuery, SiteDetailsQueryVariables>(
    SiteDetailsDocument,
    options,
  );
}
export function useSiteDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SiteDetailsQuery,
    SiteDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SiteDetailsQuery, SiteDetailsQueryVariables>(
    SiteDetailsDocument,
    options,
  );
}
export type SiteDetailsQueryHookResult = ReturnType<typeof useSiteDetailsQuery>;
export type SiteDetailsLazyQueryHookResult = ReturnType<
  typeof useSiteDetailsLazyQuery
>;
export type SiteDetailsSuspenseQueryHookResult = ReturnType<
  typeof useSiteDetailsSuspenseQuery
>;
export type SiteDetailsQueryResult = Apollo.QueryResult<
  SiteDetailsQuery,
  SiteDetailsQueryVariables
>;
export const MySitesDocument = gql`
  query MySites {
    mySites {
      ...SiteCore
    }
  }
  ${SiteCoreFragmentDoc}
`;

/**
 * __useMySitesQuery__
 *
 * To run a query within a React component, call `useMySitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySitesQuery(
  baseOptions?: Apollo.QueryHookOptions<MySitesQuery, MySitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MySitesQuery, MySitesQueryVariables>(
    MySitesDocument,
    options,
  );
}
export function useMySitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MySitesQuery,
    MySitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MySitesQuery, MySitesQueryVariables>(
    MySitesDocument,
    options,
  );
}
export function useMySitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MySitesQuery,
    MySitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MySitesQuery, MySitesQueryVariables>(
    MySitesDocument,
    options,
  );
}
export type MySitesQueryHookResult = ReturnType<typeof useMySitesQuery>;
export type MySitesLazyQueryHookResult = ReturnType<typeof useMySitesLazyQuery>;
export type MySitesSuspenseQueryHookResult = ReturnType<
  typeof useMySitesSuspenseQuery
>;
export type MySitesQueryResult = Apollo.QueryResult<
  MySitesQuery,
  MySitesQueryVariables
>;
export const GroupSitesDocument = gql`
  query groupSites($groupId: String!) {
    groupSites(groupId: $groupId) {
      ...SiteCore
    }
  }
  ${SiteCoreFragmentDoc}
`;

/**
 * __useGroupSitesQuery__
 *
 * To run a query within a React component, call `useGroupSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupSitesQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupSitesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupSitesQuery,
    GroupSitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupSitesQuery, GroupSitesQueryVariables>(
    GroupSitesDocument,
    options,
  );
}
export function useGroupSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupSitesQuery,
    GroupSitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupSitesQuery, GroupSitesQueryVariables>(
    GroupSitesDocument,
    options,
  );
}
export function useGroupSitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GroupSitesQuery,
    GroupSitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GroupSitesQuery, GroupSitesQueryVariables>(
    GroupSitesDocument,
    options,
  );
}
export type GroupSitesQueryHookResult = ReturnType<typeof useGroupSitesQuery>;
export type GroupSitesLazyQueryHookResult = ReturnType<
  typeof useGroupSitesLazyQuery
>;
export type GroupSitesSuspenseQueryHookResult = ReturnType<
  typeof useGroupSitesSuspenseQuery
>;
export type GroupSitesQueryResult = Apollo.QueryResult<
  GroupSitesQuery,
  GroupSitesQueryVariables
>;
export const TagsByAccountTypeDocument = gql`
  query tagsByAccountType($accountTypeId: String!) {
    tagsByAccountType(accountTypeId: $accountTypeId) {
      ...TagCore
    }
  }
  ${TagCoreFragmentDoc}
`;

/**
 * __useTagsByAccountTypeQuery__
 *
 * To run a query within a React component, call `useTagsByAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsByAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsByAccountTypeQuery({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *   },
 * });
 */
export function useTagsByAccountTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    TagsByAccountTypeQuery,
    TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TagsByAccountTypeQuery,
    TagsByAccountTypeQueryVariables
  >(TagsByAccountTypeDocument, options);
}
export function useTagsByAccountTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TagsByAccountTypeQuery,
    TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TagsByAccountTypeQuery,
    TagsByAccountTypeQueryVariables
  >(TagsByAccountTypeDocument, options);
}
export function useTagsByAccountTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TagsByAccountTypeQuery,
    TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TagsByAccountTypeQuery,
    TagsByAccountTypeQueryVariables
  >(TagsByAccountTypeDocument, options);
}
export type TagsByAccountTypeQueryHookResult = ReturnType<
  typeof useTagsByAccountTypeQuery
>;
export type TagsByAccountTypeLazyQueryHookResult = ReturnType<
  typeof useTagsByAccountTypeLazyQuery
>;
export type TagsByAccountTypeSuspenseQueryHookResult = ReturnType<
  typeof useTagsByAccountTypeSuspenseQuery
>;
export type TagsByAccountTypeQueryResult = Apollo.QueryResult<
  TagsByAccountTypeQuery,
  TagsByAccountTypeQueryVariables
>;
export const GetTagsByAccountTypeDocument = gql`
  query getTagsByAccountType($accountTypeId: String!) {
    tagsByAccountType(accountTypeId: $accountTypeId) {
      ...TagCore
    }
  }
  ${TagCoreFragmentDoc}
`;

/**
 * __useGetTagsByAccountTypeQuery__
 *
 * To run a query within a React component, call `useGetTagsByAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsByAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsByAccountTypeQuery({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *   },
 * });
 */
export function useGetTagsByAccountTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTagsByAccountTypeQuery,
    GetTagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTagsByAccountTypeQuery,
    GetTagsByAccountTypeQueryVariables
  >(GetTagsByAccountTypeDocument, options);
}
export function useGetTagsByAccountTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTagsByAccountTypeQuery,
    GetTagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTagsByAccountTypeQuery,
    GetTagsByAccountTypeQueryVariables
  >(GetTagsByAccountTypeDocument, options);
}
export function useGetTagsByAccountTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTagsByAccountTypeQuery,
    GetTagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetTagsByAccountTypeQuery,
    GetTagsByAccountTypeQueryVariables
  >(GetTagsByAccountTypeDocument, options);
}
export type GetTagsByAccountTypeQueryHookResult = ReturnType<
  typeof useGetTagsByAccountTypeQuery
>;
export type GetTagsByAccountTypeLazyQueryHookResult = ReturnType<
  typeof useGetTagsByAccountTypeLazyQuery
>;
export type GetTagsByAccountTypeSuspenseQueryHookResult = ReturnType<
  typeof useGetTagsByAccountTypeSuspenseQuery
>;
export type GetTagsByAccountTypeQueryResult = Apollo.QueryResult<
  GetTagsByAccountTypeQuery,
  GetTagsByAccountTypeQueryVariables
>;
export const AccountTypeManagement_AccountTypesDocument = gql`
  query AccountTypeManagement_AccountTypes {
    accountTypes {
      ...AccountTypeManagement_AccountTypes
    }
  }
  ${AccountTypeManagement_AccountTypesFragmentDoc}
`;

/**
 * __useAccountTypeManagement_AccountTypesQuery__
 *
 * To run a query within a React component, call `useAccountTypeManagement_AccountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTypeManagement_AccountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTypeManagement_AccountTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountTypeManagement_AccountTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountTypeManagement_AccountTypesQuery,
    AccountTypeManagement_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountTypeManagement_AccountTypesQuery,
    AccountTypeManagement_AccountTypesQueryVariables
  >(AccountTypeManagement_AccountTypesDocument, options);
}
export function useAccountTypeManagement_AccountTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountTypeManagement_AccountTypesQuery,
    AccountTypeManagement_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountTypeManagement_AccountTypesQuery,
    AccountTypeManagement_AccountTypesQueryVariables
  >(AccountTypeManagement_AccountTypesDocument, options);
}
export function useAccountTypeManagement_AccountTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountTypeManagement_AccountTypesQuery,
    AccountTypeManagement_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountTypeManagement_AccountTypesQuery,
    AccountTypeManagement_AccountTypesQueryVariables
  >(AccountTypeManagement_AccountTypesDocument, options);
}
export type AccountTypeManagement_AccountTypesQueryHookResult = ReturnType<
  typeof useAccountTypeManagement_AccountTypesQuery
>;
export type AccountTypeManagement_AccountTypesLazyQueryHookResult = ReturnType<
  typeof useAccountTypeManagement_AccountTypesLazyQuery
>;
export type AccountTypeManagement_AccountTypesSuspenseQueryHookResult =
  ReturnType<typeof useAccountTypeManagement_AccountTypesSuspenseQuery>;
export type AccountTypeManagement_AccountTypesQueryResult = Apollo.QueryResult<
  AccountTypeManagement_AccountTypesQuery,
  AccountTypeManagement_AccountTypesQueryVariables
>;
export const AccountTypeDetails_ApplicationsDocument = gql`
  query AccountTypeDetails_Applications {
    applications {
      ...LinkAppsSection_linkAppInfo
    }
  }
  ${LinkAppsSection_LinkAppInfoFragmentDoc}
`;

/**
 * __useAccountTypeDetails_ApplicationsQuery__
 *
 * To run a query within a React component, call `useAccountTypeDetails_ApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTypeDetails_ApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTypeDetails_ApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountTypeDetails_ApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountTypeDetails_ApplicationsQuery,
    AccountTypeDetails_ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountTypeDetails_ApplicationsQuery,
    AccountTypeDetails_ApplicationsQueryVariables
  >(AccountTypeDetails_ApplicationsDocument, options);
}
export function useAccountTypeDetails_ApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountTypeDetails_ApplicationsQuery,
    AccountTypeDetails_ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountTypeDetails_ApplicationsQuery,
    AccountTypeDetails_ApplicationsQueryVariables
  >(AccountTypeDetails_ApplicationsDocument, options);
}
export function useAccountTypeDetails_ApplicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountTypeDetails_ApplicationsQuery,
    AccountTypeDetails_ApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountTypeDetails_ApplicationsQuery,
    AccountTypeDetails_ApplicationsQueryVariables
  >(AccountTypeDetails_ApplicationsDocument, options);
}
export type AccountTypeDetails_ApplicationsQueryHookResult = ReturnType<
  typeof useAccountTypeDetails_ApplicationsQuery
>;
export type AccountTypeDetails_ApplicationsLazyQueryHookResult = ReturnType<
  typeof useAccountTypeDetails_ApplicationsLazyQuery
>;
export type AccountTypeDetails_ApplicationsSuspenseQueryHookResult = ReturnType<
  typeof useAccountTypeDetails_ApplicationsSuspenseQuery
>;
export type AccountTypeDetails_ApplicationsQueryResult = Apollo.QueryResult<
  AccountTypeDetails_ApplicationsQuery,
  AccountTypeDetails_ApplicationsQueryVariables
>;
export const AccountTypeDetails_AccountTypeDocument = gql`
  query AccountTypeDetails_AccountType($id: String!) {
    accountType(id: $id) {
      ...AccountTypeDetails_AccountTypeInfo
    }
  }
  ${AccountTypeDetails_AccountTypeInfoFragmentDoc}
`;

/**
 * __useAccountTypeDetails_AccountTypeQuery__
 *
 * To run a query within a React component, call `useAccountTypeDetails_AccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTypeDetails_AccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTypeDetails_AccountTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountTypeDetails_AccountTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountTypeDetails_AccountTypeQuery,
    AccountTypeDetails_AccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountTypeDetails_AccountTypeQuery,
    AccountTypeDetails_AccountTypeQueryVariables
  >(AccountTypeDetails_AccountTypeDocument, options);
}
export function useAccountTypeDetails_AccountTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountTypeDetails_AccountTypeQuery,
    AccountTypeDetails_AccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountTypeDetails_AccountTypeQuery,
    AccountTypeDetails_AccountTypeQueryVariables
  >(AccountTypeDetails_AccountTypeDocument, options);
}
export function useAccountTypeDetails_AccountTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountTypeDetails_AccountTypeQuery,
    AccountTypeDetails_AccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountTypeDetails_AccountTypeQuery,
    AccountTypeDetails_AccountTypeQueryVariables
  >(AccountTypeDetails_AccountTypeDocument, options);
}
export type AccountTypeDetails_AccountTypeQueryHookResult = ReturnType<
  typeof useAccountTypeDetails_AccountTypeQuery
>;
export type AccountTypeDetails_AccountTypeLazyQueryHookResult = ReturnType<
  typeof useAccountTypeDetails_AccountTypeLazyQuery
>;
export type AccountTypeDetails_AccountTypeSuspenseQueryHookResult = ReturnType<
  typeof useAccountTypeDetails_AccountTypeSuspenseQuery
>;
export type AccountTypeDetails_AccountTypeQueryResult = Apollo.QueryResult<
  AccountTypeDetails_AccountTypeQuery,
  AccountTypeDetails_AccountTypeQueryVariables
>;
export const AccountTypeDetails_TagsDocument = gql`
  query AccountTypeDetails_Tags {
    tags {
      ...TagSelection_Tags
    }
  }
  ${TagSelection_TagsFragmentDoc}
`;

/**
 * __useAccountTypeDetails_TagsQuery__
 *
 * To run a query within a React component, call `useAccountTypeDetails_TagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTypeDetails_TagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTypeDetails_TagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountTypeDetails_TagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountTypeDetails_TagsQuery,
    AccountTypeDetails_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountTypeDetails_TagsQuery,
    AccountTypeDetails_TagsQueryVariables
  >(AccountTypeDetails_TagsDocument, options);
}
export function useAccountTypeDetails_TagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountTypeDetails_TagsQuery,
    AccountTypeDetails_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountTypeDetails_TagsQuery,
    AccountTypeDetails_TagsQueryVariables
  >(AccountTypeDetails_TagsDocument, options);
}
export function useAccountTypeDetails_TagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountTypeDetails_TagsQuery,
    AccountTypeDetails_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountTypeDetails_TagsQuery,
    AccountTypeDetails_TagsQueryVariables
  >(AccountTypeDetails_TagsDocument, options);
}
export type AccountTypeDetails_TagsQueryHookResult = ReturnType<
  typeof useAccountTypeDetails_TagsQuery
>;
export type AccountTypeDetails_TagsLazyQueryHookResult = ReturnType<
  typeof useAccountTypeDetails_TagsLazyQuery
>;
export type AccountTypeDetails_TagsSuspenseQueryHookResult = ReturnType<
  typeof useAccountTypeDetails_TagsSuspenseQuery
>;
export type AccountTypeDetails_TagsQueryResult = Apollo.QueryResult<
  AccountTypeDetails_TagsQuery,
  AccountTypeDetails_TagsQueryVariables
>;
export const SectionRoles_RolesByAccountTypeDocument = gql`
  query SectionRoles_RolesByAccountType($accountTypeId: String!) {
    rolesByAccountType(accountTypeId: $accountTypeId) {
      ...SectionRoles_RolesByAccountType
    }
  }
  ${SectionRoles_RolesByAccountTypeFragmentDoc}
`;

/**
 * __useSectionRoles_RolesByAccountTypeQuery__
 *
 * To run a query within a React component, call `useSectionRoles_RolesByAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionRoles_RolesByAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionRoles_RolesByAccountTypeQuery({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *   },
 * });
 */
export function useSectionRoles_RolesByAccountTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    SectionRoles_RolesByAccountTypeQuery,
    SectionRoles_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SectionRoles_RolesByAccountTypeQuery,
    SectionRoles_RolesByAccountTypeQueryVariables
  >(SectionRoles_RolesByAccountTypeDocument, options);
}
export function useSectionRoles_RolesByAccountTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SectionRoles_RolesByAccountTypeQuery,
    SectionRoles_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SectionRoles_RolesByAccountTypeQuery,
    SectionRoles_RolesByAccountTypeQueryVariables
  >(SectionRoles_RolesByAccountTypeDocument, options);
}
export function useSectionRoles_RolesByAccountTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SectionRoles_RolesByAccountTypeQuery,
    SectionRoles_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SectionRoles_RolesByAccountTypeQuery,
    SectionRoles_RolesByAccountTypeQueryVariables
  >(SectionRoles_RolesByAccountTypeDocument, options);
}
export type SectionRoles_RolesByAccountTypeQueryHookResult = ReturnType<
  typeof useSectionRoles_RolesByAccountTypeQuery
>;
export type SectionRoles_RolesByAccountTypeLazyQueryHookResult = ReturnType<
  typeof useSectionRoles_RolesByAccountTypeLazyQuery
>;
export type SectionRoles_RolesByAccountTypeSuspenseQueryHookResult = ReturnType<
  typeof useSectionRoles_RolesByAccountTypeSuspenseQuery
>;
export type SectionRoles_RolesByAccountTypeQueryResult = Apollo.QueryResult<
  SectionRoles_RolesByAccountTypeQuery,
  SectionRoles_RolesByAccountTypeQueryVariables
>;
export const GetApplicationGroupsByAccountIdDocument = gql`
  query getApplicationGroupsByAccountId($id: String!) {
    account(id: $id) {
      ...myAppGroupAccountInfo
    }
  }
  ${MyAppGroupAccountInfoFragmentDoc}
`;

/**
 * __useGetApplicationGroupsByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetApplicationGroupsByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationGroupsByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationGroupsByAccountIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationGroupsByAccountIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationGroupsByAccountIdQuery,
    GetApplicationGroupsByAccountIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationGroupsByAccountIdQuery,
    GetApplicationGroupsByAccountIdQueryVariables
  >(GetApplicationGroupsByAccountIdDocument, options);
}
export function useGetApplicationGroupsByAccountIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationGroupsByAccountIdQuery,
    GetApplicationGroupsByAccountIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationGroupsByAccountIdQuery,
    GetApplicationGroupsByAccountIdQueryVariables
  >(GetApplicationGroupsByAccountIdDocument, options);
}
export function useGetApplicationGroupsByAccountIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetApplicationGroupsByAccountIdQuery,
    GetApplicationGroupsByAccountIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetApplicationGroupsByAccountIdQuery,
    GetApplicationGroupsByAccountIdQueryVariables
  >(GetApplicationGroupsByAccountIdDocument, options);
}
export type GetApplicationGroupsByAccountIdQueryHookResult = ReturnType<
  typeof useGetApplicationGroupsByAccountIdQuery
>;
export type GetApplicationGroupsByAccountIdLazyQueryHookResult = ReturnType<
  typeof useGetApplicationGroupsByAccountIdLazyQuery
>;
export type GetApplicationGroupsByAccountIdSuspenseQueryHookResult = ReturnType<
  typeof useGetApplicationGroupsByAccountIdSuspenseQuery
>;
export type GetApplicationGroupsByAccountIdQueryResult = Apollo.QueryResult<
  GetApplicationGroupsByAccountIdQuery,
  GetApplicationGroupsByAccountIdQueryVariables
>;
export const GetAllTagsDocument = gql`
  query getAllTags {
    tags {
      ...tagForApplication
    }
  }
  ${TagForApplicationFragmentDoc}
`;

/**
 * __useGetAllTagsQuery__
 *
 * To run a query within a React component, call `useGetAllTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTagsQuery,
    GetAllTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(
    GetAllTagsDocument,
    options,
  );
}
export function useGetAllTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTagsQuery,
    GetAllTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(
    GetAllTagsDocument,
    options,
  );
}
export function useGetAllTagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllTagsQuery,
    GetAllTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(
    GetAllTagsDocument,
    options,
  );
}
export type GetAllTagsQueryHookResult = ReturnType<typeof useGetAllTagsQuery>;
export type GetAllTagsLazyQueryHookResult = ReturnType<
  typeof useGetAllTagsLazyQuery
>;
export type GetAllTagsSuspenseQueryHookResult = ReturnType<
  typeof useGetAllTagsSuspenseQuery
>;
export type GetAllTagsQueryResult = Apollo.QueryResult<
  GetAllTagsQuery,
  GetAllTagsQueryVariables
>;
export const LoggedInProfileDocument = gql`
  query loggedInProfile {
    myProfile {
      ...loggedInProfileFull
    }
  }
  ${LoggedInProfileFullFragmentDoc}
`;

/**
 * __useLoggedInProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoggedInProfileQuery,
    LoggedInProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoggedInProfileQuery, LoggedInProfileQueryVariables>(
    LoggedInProfileDocument,
    options,
  );
}
export function useLoggedInProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoggedInProfileQuery,
    LoggedInProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoggedInProfileQuery,
    LoggedInProfileQueryVariables
  >(LoggedInProfileDocument, options);
}
export function useLoggedInProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LoggedInProfileQuery,
    LoggedInProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoggedInProfileQuery,
    LoggedInProfileQueryVariables
  >(LoggedInProfileDocument, options);
}
export type LoggedInProfileQueryHookResult = ReturnType<
  typeof useLoggedInProfileQuery
>;
export type LoggedInProfileLazyQueryHookResult = ReturnType<
  typeof useLoggedInProfileLazyQuery
>;
export type LoggedInProfileSuspenseQueryHookResult = ReturnType<
  typeof useLoggedInProfileSuspenseQuery
>;
export type LoggedInProfileQueryResult = Apollo.QueryResult<
  LoggedInProfileQuery,
  LoggedInProfileQueryVariables
>;
export const CreateAccType_GetApplicationsDocument = gql`
  query createAccType_getApplications {
    applications {
      ...createAccType_application
    }
  }
  ${CreateAccType_ApplicationFragmentDoc}
`;

/**
 * __useCreateAccType_GetApplicationsQuery__
 *
 * To run a query within a React component, call `useCreateAccType_GetApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateAccType_GetApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateAccType_GetApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateAccType_GetApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CreateAccType_GetApplicationsQuery,
    CreateAccType_GetApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CreateAccType_GetApplicationsQuery,
    CreateAccType_GetApplicationsQueryVariables
  >(CreateAccType_GetApplicationsDocument, options);
}
export function useCreateAccType_GetApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateAccType_GetApplicationsQuery,
    CreateAccType_GetApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CreateAccType_GetApplicationsQuery,
    CreateAccType_GetApplicationsQueryVariables
  >(CreateAccType_GetApplicationsDocument, options);
}
export function useCreateAccType_GetApplicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CreateAccType_GetApplicationsQuery,
    CreateAccType_GetApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CreateAccType_GetApplicationsQuery,
    CreateAccType_GetApplicationsQueryVariables
  >(CreateAccType_GetApplicationsDocument, options);
}
export type CreateAccType_GetApplicationsQueryHookResult = ReturnType<
  typeof useCreateAccType_GetApplicationsQuery
>;
export type CreateAccType_GetApplicationsLazyQueryHookResult = ReturnType<
  typeof useCreateAccType_GetApplicationsLazyQuery
>;
export type CreateAccType_GetApplicationsSuspenseQueryHookResult = ReturnType<
  typeof useCreateAccType_GetApplicationsSuspenseQuery
>;
export type CreateAccType_GetApplicationsQueryResult = Apollo.QueryResult<
  CreateAccType_GetApplicationsQuery,
  CreateAccType_GetApplicationsQueryVariables
>;
export const DeleteAccount_MyProfileDocument = gql`
  query DeleteAccount_MyProfile {
    myProfile {
      ...DeleteAccount_MyProfile
    }
  }
  ${DeleteAccount_MyProfileFragmentDoc}
`;

/**
 * __useDeleteAccount_MyProfileQuery__
 *
 * To run a query within a React component, call `useDeleteAccount_MyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccount_MyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteAccount_MyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccount_MyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DeleteAccount_MyProfileQuery,
    DeleteAccount_MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DeleteAccount_MyProfileQuery,
    DeleteAccount_MyProfileQueryVariables
  >(DeleteAccount_MyProfileDocument, options);
}
export function useDeleteAccount_MyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeleteAccount_MyProfileQuery,
    DeleteAccount_MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DeleteAccount_MyProfileQuery,
    DeleteAccount_MyProfileQueryVariables
  >(DeleteAccount_MyProfileDocument, options);
}
export function useDeleteAccount_MyProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DeleteAccount_MyProfileQuery,
    DeleteAccount_MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DeleteAccount_MyProfileQuery,
    DeleteAccount_MyProfileQueryVariables
  >(DeleteAccount_MyProfileDocument, options);
}
export type DeleteAccount_MyProfileQueryHookResult = ReturnType<
  typeof useDeleteAccount_MyProfileQuery
>;
export type DeleteAccount_MyProfileLazyQueryHookResult = ReturnType<
  typeof useDeleteAccount_MyProfileLazyQuery
>;
export type DeleteAccount_MyProfileSuspenseQueryHookResult = ReturnType<
  typeof useDeleteAccount_MyProfileSuspenseQuery
>;
export type DeleteAccount_MyProfileQueryResult = Apollo.QueryResult<
  DeleteAccount_MyProfileQuery,
  DeleteAccount_MyProfileQueryVariables
>;
export const SiteGroupsPage_PaginatedGroupsDocument = gql`
  query SiteGroupsPage_paginatedGroups($input: PaginatedGroupsInput!) {
    paginatedGroups(input: $input) {
      count
      page
      perPage
      groups {
        id
        name
        sites {
          value {
            id
            name
            supplierCode
            supplierNumber
            approvedDomains
            rejectedDomains
            status
            activeStatus
          }
        }
      }
    }
  }
`;

/**
 * __useSiteGroupsPage_PaginatedGroupsQuery__
 *
 * To run a query within a React component, call `useSiteGroupsPage_PaginatedGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteGroupsPage_PaginatedGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteGroupsPage_PaginatedGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSiteGroupsPage_PaginatedGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SiteGroupsPage_PaginatedGroupsQuery,
    SiteGroupsPage_PaginatedGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SiteGroupsPage_PaginatedGroupsQuery,
    SiteGroupsPage_PaginatedGroupsQueryVariables
  >(SiteGroupsPage_PaginatedGroupsDocument, options);
}
export function useSiteGroupsPage_PaginatedGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SiteGroupsPage_PaginatedGroupsQuery,
    SiteGroupsPage_PaginatedGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SiteGroupsPage_PaginatedGroupsQuery,
    SiteGroupsPage_PaginatedGroupsQueryVariables
  >(SiteGroupsPage_PaginatedGroupsDocument, options);
}
export function useSiteGroupsPage_PaginatedGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SiteGroupsPage_PaginatedGroupsQuery,
    SiteGroupsPage_PaginatedGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SiteGroupsPage_PaginatedGroupsQuery,
    SiteGroupsPage_PaginatedGroupsQueryVariables
  >(SiteGroupsPage_PaginatedGroupsDocument, options);
}
export type SiteGroupsPage_PaginatedGroupsQueryHookResult = ReturnType<
  typeof useSiteGroupsPage_PaginatedGroupsQuery
>;
export type SiteGroupsPage_PaginatedGroupsLazyQueryHookResult = ReturnType<
  typeof useSiteGroupsPage_PaginatedGroupsLazyQuery
>;
export type SiteGroupsPage_PaginatedGroupsSuspenseQueryHookResult = ReturnType<
  typeof useSiteGroupsPage_PaginatedGroupsSuspenseQuery
>;
export type SiteGroupsPage_PaginatedGroupsQueryResult = Apollo.QueryResult<
  SiteGroupsPage_PaginatedGroupsQuery,
  SiteGroupsPage_PaginatedGroupsQueryVariables
>;
export const InviteProfileForm_RolesByAccountTypeDocument = gql`
  query InviteProfileForm_RolesByAccountType($accountTypeId: String!) {
    rolesByAccountType(accountTypeId: $accountTypeId) {
      id
      name
      description
      hiddenForRequests
    }
  }
`;

/**
 * __useInviteProfileForm_RolesByAccountTypeQuery__
 *
 * To run a query within a React component, call `useInviteProfileForm_RolesByAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteProfileForm_RolesByAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteProfileForm_RolesByAccountTypeQuery({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *   },
 * });
 */
export function useInviteProfileForm_RolesByAccountTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    InviteProfileForm_RolesByAccountTypeQuery,
    InviteProfileForm_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InviteProfileForm_RolesByAccountTypeQuery,
    InviteProfileForm_RolesByAccountTypeQueryVariables
  >(InviteProfileForm_RolesByAccountTypeDocument, options);
}
export function useInviteProfileForm_RolesByAccountTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InviteProfileForm_RolesByAccountTypeQuery,
    InviteProfileForm_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InviteProfileForm_RolesByAccountTypeQuery,
    InviteProfileForm_RolesByAccountTypeQueryVariables
  >(InviteProfileForm_RolesByAccountTypeDocument, options);
}
export function useInviteProfileForm_RolesByAccountTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InviteProfileForm_RolesByAccountTypeQuery,
    InviteProfileForm_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InviteProfileForm_RolesByAccountTypeQuery,
    InviteProfileForm_RolesByAccountTypeQueryVariables
  >(InviteProfileForm_RolesByAccountTypeDocument, options);
}
export type InviteProfileForm_RolesByAccountTypeQueryHookResult = ReturnType<
  typeof useInviteProfileForm_RolesByAccountTypeQuery
>;
export type InviteProfileForm_RolesByAccountTypeLazyQueryHookResult =
  ReturnType<typeof useInviteProfileForm_RolesByAccountTypeLazyQuery>;
export type InviteProfileForm_RolesByAccountTypeSuspenseQueryHookResult =
  ReturnType<typeof useInviteProfileForm_RolesByAccountTypeSuspenseQuery>;
export type InviteProfileForm_RolesByAccountTypeQueryResult =
  Apollo.QueryResult<
    InviteProfileForm_RolesByAccountTypeQuery,
    InviteProfileForm_RolesByAccountTypeQueryVariables
  >;
export const InviteProfileForm_AccountTypesDocument = gql`
  query InviteProfileForm_AccountTypes {
    accountTypes {
      ...InviteProfileForm_AccountTypes
    }
  }
  ${InviteProfileForm_AccountTypesFragmentDoc}
`;

/**
 * __useInviteProfileForm_AccountTypesQuery__
 *
 * To run a query within a React component, call `useInviteProfileForm_AccountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteProfileForm_AccountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteProfileForm_AccountTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInviteProfileForm_AccountTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InviteProfileForm_AccountTypesQuery,
    InviteProfileForm_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InviteProfileForm_AccountTypesQuery,
    InviteProfileForm_AccountTypesQueryVariables
  >(InviteProfileForm_AccountTypesDocument, options);
}
export function useInviteProfileForm_AccountTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InviteProfileForm_AccountTypesQuery,
    InviteProfileForm_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InviteProfileForm_AccountTypesQuery,
    InviteProfileForm_AccountTypesQueryVariables
  >(InviteProfileForm_AccountTypesDocument, options);
}
export function useInviteProfileForm_AccountTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InviteProfileForm_AccountTypesQuery,
    InviteProfileForm_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InviteProfileForm_AccountTypesQuery,
    InviteProfileForm_AccountTypesQueryVariables
  >(InviteProfileForm_AccountTypesDocument, options);
}
export type InviteProfileForm_AccountTypesQueryHookResult = ReturnType<
  typeof useInviteProfileForm_AccountTypesQuery
>;
export type InviteProfileForm_AccountTypesLazyQueryHookResult = ReturnType<
  typeof useInviteProfileForm_AccountTypesLazyQuery
>;
export type InviteProfileForm_AccountTypesSuspenseQueryHookResult = ReturnType<
  typeof useInviteProfileForm_AccountTypesSuspenseQuery
>;
export type InviteProfileForm_AccountTypesQueryResult = Apollo.QueryResult<
  InviteProfileForm_AccountTypesQuery,
  InviteProfileForm_AccountTypesQueryVariables
>;
export const InviteProfileForm_TagsByAccountTypeDocument = gql`
  query InviteProfileForm_TagsByAccountType($accountTypeId: String!) {
    tagsByAccountType(accountTypeId: $accountTypeId) {
      ...InviteProfileForm_TagsByAccountType
    }
  }
  ${InviteProfileForm_TagsByAccountTypeFragmentDoc}
`;

/**
 * __useInviteProfileForm_TagsByAccountTypeQuery__
 *
 * To run a query within a React component, call `useInviteProfileForm_TagsByAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteProfileForm_TagsByAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteProfileForm_TagsByAccountTypeQuery({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *   },
 * });
 */
export function useInviteProfileForm_TagsByAccountTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    InviteProfileForm_TagsByAccountTypeQuery,
    InviteProfileForm_TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InviteProfileForm_TagsByAccountTypeQuery,
    InviteProfileForm_TagsByAccountTypeQueryVariables
  >(InviteProfileForm_TagsByAccountTypeDocument, options);
}
export function useInviteProfileForm_TagsByAccountTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InviteProfileForm_TagsByAccountTypeQuery,
    InviteProfileForm_TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InviteProfileForm_TagsByAccountTypeQuery,
    InviteProfileForm_TagsByAccountTypeQueryVariables
  >(InviteProfileForm_TagsByAccountTypeDocument, options);
}
export function useInviteProfileForm_TagsByAccountTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InviteProfileForm_TagsByAccountTypeQuery,
    InviteProfileForm_TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InviteProfileForm_TagsByAccountTypeQuery,
    InviteProfileForm_TagsByAccountTypeQueryVariables
  >(InviteProfileForm_TagsByAccountTypeDocument, options);
}
export type InviteProfileForm_TagsByAccountTypeQueryHookResult = ReturnType<
  typeof useInviteProfileForm_TagsByAccountTypeQuery
>;
export type InviteProfileForm_TagsByAccountTypeLazyQueryHookResult = ReturnType<
  typeof useInviteProfileForm_TagsByAccountTypeLazyQuery
>;
export type InviteProfileForm_TagsByAccountTypeSuspenseQueryHookResult =
  ReturnType<typeof useInviteProfileForm_TagsByAccountTypeSuspenseQuery>;
export type InviteProfileForm_TagsByAccountTypeQueryResult = Apollo.QueryResult<
  InviteProfileForm_TagsByAccountTypeQuery,
  InviteProfileForm_TagsByAccountTypeQueryVariables
>;
export const LoginPage_MyProfileDocument = gql`
  query LoginPage_MyProfile {
    myProfile {
      ...LoginPage_MyProfile
    }
  }
  ${LoginPage_MyProfileFragmentDoc}
`;

/**
 * __useLoginPage_MyProfileQuery__
 *
 * To run a query within a React component, call `useLoginPage_MyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginPage_MyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginPage_MyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoginPage_MyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoginPage_MyProfileQuery,
    LoginPage_MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoginPage_MyProfileQuery,
    LoginPage_MyProfileQueryVariables
  >(LoginPage_MyProfileDocument, options);
}
export function useLoginPage_MyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoginPage_MyProfileQuery,
    LoginPage_MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoginPage_MyProfileQuery,
    LoginPage_MyProfileQueryVariables
  >(LoginPage_MyProfileDocument, options);
}
export function useLoginPage_MyProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LoginPage_MyProfileQuery,
    LoginPage_MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoginPage_MyProfileQuery,
    LoginPage_MyProfileQueryVariables
  >(LoginPage_MyProfileDocument, options);
}
export type LoginPage_MyProfileQueryHookResult = ReturnType<
  typeof useLoginPage_MyProfileQuery
>;
export type LoginPage_MyProfileLazyQueryHookResult = ReturnType<
  typeof useLoginPage_MyProfileLazyQuery
>;
export type LoginPage_MyProfileSuspenseQueryHookResult = ReturnType<
  typeof useLoginPage_MyProfileSuspenseQuery
>;
export type LoginPage_MyProfileQueryResult = Apollo.QueryResult<
  LoginPage_MyProfileQuery,
  LoginPage_MyProfileQueryVariables
>;
export const RoleRequestNotification_RoleDocument = gql`
  query RoleRequestNotification_Role($id: String!) {
    role(id: $id) {
      ...RoleRequestNotification_RoleInfo
    }
  }
  ${RoleRequestNotification_RoleInfoFragmentDoc}
`;

/**
 * __useRoleRequestNotification_RoleQuery__
 *
 * To run a query within a React component, call `useRoleRequestNotification_RoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleRequestNotification_RoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleRequestNotification_RoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleRequestNotification_RoleQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleRequestNotification_RoleQuery,
    RoleRequestNotification_RoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RoleRequestNotification_RoleQuery,
    RoleRequestNotification_RoleQueryVariables
  >(RoleRequestNotification_RoleDocument, options);
}
export function useRoleRequestNotification_RoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleRequestNotification_RoleQuery,
    RoleRequestNotification_RoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleRequestNotification_RoleQuery,
    RoleRequestNotification_RoleQueryVariables
  >(RoleRequestNotification_RoleDocument, options);
}
export function useRoleRequestNotification_RoleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RoleRequestNotification_RoleQuery,
    RoleRequestNotification_RoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RoleRequestNotification_RoleQuery,
    RoleRequestNotification_RoleQueryVariables
  >(RoleRequestNotification_RoleDocument, options);
}
export type RoleRequestNotification_RoleQueryHookResult = ReturnType<
  typeof useRoleRequestNotification_RoleQuery
>;
export type RoleRequestNotification_RoleLazyQueryHookResult = ReturnType<
  typeof useRoleRequestNotification_RoleLazyQuery
>;
export type RoleRequestNotification_RoleSuspenseQueryHookResult = ReturnType<
  typeof useRoleRequestNotification_RoleSuspenseQuery
>;
export type RoleRequestNotification_RoleQueryResult = Apollo.QueryResult<
  RoleRequestNotification_RoleQuery,
  RoleRequestNotification_RoleQueryVariables
>;
export const Notification_SiteRequestSiteInfoDocument = gql`
  query Notification_SiteRequestSiteInfo($id: String!, $accountId: String!) {
    site(id: $id) {
      id
      name
      supplierCode
      supplierNumber
      status
      activeStatus
      groupId
      parentGroup {
        value {
          id
          name
        }
      }
      account(accountId: $accountId) {
        requestedId
      }
    }
  }
`;

/**
 * __useNotification_SiteRequestSiteInfoQuery__
 *
 * To run a query within a React component, call `useNotification_SiteRequestSiteInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotification_SiteRequestSiteInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotification_SiteRequestSiteInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useNotification_SiteRequestSiteInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    Notification_SiteRequestSiteInfoQuery,
    Notification_SiteRequestSiteInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Notification_SiteRequestSiteInfoQuery,
    Notification_SiteRequestSiteInfoQueryVariables
  >(Notification_SiteRequestSiteInfoDocument, options);
}
export function useNotification_SiteRequestSiteInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Notification_SiteRequestSiteInfoQuery,
    Notification_SiteRequestSiteInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Notification_SiteRequestSiteInfoQuery,
    Notification_SiteRequestSiteInfoQueryVariables
  >(Notification_SiteRequestSiteInfoDocument, options);
}
export function useNotification_SiteRequestSiteInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Notification_SiteRequestSiteInfoQuery,
    Notification_SiteRequestSiteInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Notification_SiteRequestSiteInfoQuery,
    Notification_SiteRequestSiteInfoQueryVariables
  >(Notification_SiteRequestSiteInfoDocument, options);
}
export type Notification_SiteRequestSiteInfoQueryHookResult = ReturnType<
  typeof useNotification_SiteRequestSiteInfoQuery
>;
export type Notification_SiteRequestSiteInfoLazyQueryHookResult = ReturnType<
  typeof useNotification_SiteRequestSiteInfoLazyQuery
>;
export type Notification_SiteRequestSiteInfoSuspenseQueryHookResult =
  ReturnType<typeof useNotification_SiteRequestSiteInfoSuspenseQuery>;
export type Notification_SiteRequestSiteInfoQueryResult = Apollo.QueryResult<
  Notification_SiteRequestSiteInfoQuery,
  Notification_SiteRequestSiteInfoQueryVariables
>;
export const Notifications_AccountDocument = gql`
  query Notifications_Account($id: String!) {
    account(id: $id) {
      ...Notifications_AccountInfo
    }
  }
  ${Notifications_AccountInfoFragmentDoc}
`;

/**
 * __useNotifications_AccountQuery__
 *
 * To run a query within a React component, call `useNotifications_AccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotifications_AccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifications_AccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotifications_AccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Notifications_AccountQuery,
    Notifications_AccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Notifications_AccountQuery,
    Notifications_AccountQueryVariables
  >(Notifications_AccountDocument, options);
}
export function useNotifications_AccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Notifications_AccountQuery,
    Notifications_AccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Notifications_AccountQuery,
    Notifications_AccountQueryVariables
  >(Notifications_AccountDocument, options);
}
export function useNotifications_AccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Notifications_AccountQuery,
    Notifications_AccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Notifications_AccountQuery,
    Notifications_AccountQueryVariables
  >(Notifications_AccountDocument, options);
}
export type Notifications_AccountQueryHookResult = ReturnType<
  typeof useNotifications_AccountQuery
>;
export type Notifications_AccountLazyQueryHookResult = ReturnType<
  typeof useNotifications_AccountLazyQuery
>;
export type Notifications_AccountSuspenseQueryHookResult = ReturnType<
  typeof useNotifications_AccountSuspenseQuery
>;
export type Notifications_AccountQueryResult = Apollo.QueryResult<
  Notifications_AccountQuery,
  Notifications_AccountQueryVariables
>;
export const Notifications_NotificationsByAccountDocument = gql`
  query Notifications_NotificationsByAccount(
    $page: Float!
    $filters: NotificationFilters
  ) {
    getNotifications(page: $page, filters: $filters) {
      unreadCount
      page
      data {
        ...Notification_Info
        assignedTo {
          id
          profile {
            value {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
  ${Notification_InfoFragmentDoc}
`;

/**
 * __useNotifications_NotificationsByAccountQuery__
 *
 * To run a query within a React component, call `useNotifications_NotificationsByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotifications_NotificationsByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifications_NotificationsByAccountQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useNotifications_NotificationsByAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Notifications_NotificationsByAccountQuery,
    Notifications_NotificationsByAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Notifications_NotificationsByAccountQuery,
    Notifications_NotificationsByAccountQueryVariables
  >(Notifications_NotificationsByAccountDocument, options);
}
export function useNotifications_NotificationsByAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Notifications_NotificationsByAccountQuery,
    Notifications_NotificationsByAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Notifications_NotificationsByAccountQuery,
    Notifications_NotificationsByAccountQueryVariables
  >(Notifications_NotificationsByAccountDocument, options);
}
export function useNotifications_NotificationsByAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Notifications_NotificationsByAccountQuery,
    Notifications_NotificationsByAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Notifications_NotificationsByAccountQuery,
    Notifications_NotificationsByAccountQueryVariables
  >(Notifications_NotificationsByAccountDocument, options);
}
export type Notifications_NotificationsByAccountQueryHookResult = ReturnType<
  typeof useNotifications_NotificationsByAccountQuery
>;
export type Notifications_NotificationsByAccountLazyQueryHookResult =
  ReturnType<typeof useNotifications_NotificationsByAccountLazyQuery>;
export type Notifications_NotificationsByAccountSuspenseQueryHookResult =
  ReturnType<typeof useNotifications_NotificationsByAccountSuspenseQuery>;
export type Notifications_NotificationsByAccountQueryResult =
  Apollo.QueryResult<
    Notifications_NotificationsByAccountQuery,
    Notifications_NotificationsByAccountQueryVariables
  >;
export const OrganisationsPage_SitesDocument = gql`
  query OrganisationsPage_Sites($input: PaginatedSitesInput!) {
    paginatedSites(input: $input) {
      count
      page
      perPage
      sites {
        id
        name
        supplierCode
        supplierNumber
        approvedDomains
        rejectedDomains
        status
        activeStatus
        groupId
      }
    }
  }
`;

/**
 * __useOrganisationsPage_SitesQuery__
 *
 * To run a query within a React component, call `useOrganisationsPage_SitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsPage_SitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsPage_SitesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationsPage_SitesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganisationsPage_SitesQuery,
    OrganisationsPage_SitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganisationsPage_SitesQuery,
    OrganisationsPage_SitesQueryVariables
  >(OrganisationsPage_SitesDocument, options);
}
export function useOrganisationsPage_SitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganisationsPage_SitesQuery,
    OrganisationsPage_SitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganisationsPage_SitesQuery,
    OrganisationsPage_SitesQueryVariables
  >(OrganisationsPage_SitesDocument, options);
}
export function useOrganisationsPage_SitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrganisationsPage_SitesQuery,
    OrganisationsPage_SitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrganisationsPage_SitesQuery,
    OrganisationsPage_SitesQueryVariables
  >(OrganisationsPage_SitesDocument, options);
}
export type OrganisationsPage_SitesQueryHookResult = ReturnType<
  typeof useOrganisationsPage_SitesQuery
>;
export type OrganisationsPage_SitesLazyQueryHookResult = ReturnType<
  typeof useOrganisationsPage_SitesLazyQuery
>;
export type OrganisationsPage_SitesSuspenseQueryHookResult = ReturnType<
  typeof useOrganisationsPage_SitesSuspenseQuery
>;
export type OrganisationsPage_SitesQueryResult = Apollo.QueryResult<
  OrganisationsPage_SitesQuery,
  OrganisationsPage_SitesQueryVariables
>;
export const ProfilePage_ProfilesDocument = gql`
  query ProfilePage_Profiles(
    $searchTerms: String
    $accountTypeIds: [String!]
    $tagIds: [String!]
    $roleIds: [String!]
    $hierarchyIds: [String!]
    $statuses: [ProfileStatus!]
    $page: Float
    $pageSize: Float
  ) {
    paginatedProfiles(
      searchTerms: $searchTerms
      accountTypeIds: $accountTypeIds
      tagIds: $tagIds
      roleIds: $roleIds
      hierarchyIds: $hierarchyIds
      statuses: $statuses
      page: $page
      pageSize: $pageSize
    ) {
      page
      count
      perPage
      profiles {
        ...ProfilePage_Profile
      }
    }
  }
  ${ProfilePage_ProfileFragmentDoc}
`;

/**
 * __useProfilePage_ProfilesQuery__
 *
 * To run a query within a React component, call `useProfilePage_ProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePage_ProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePage_ProfilesQuery({
 *   variables: {
 *      searchTerms: // value for 'searchTerms'
 *      accountTypeIds: // value for 'accountTypeIds'
 *      tagIds: // value for 'tagIds'
 *      roleIds: // value for 'roleIds'
 *      hierarchyIds: // value for 'hierarchyIds'
 *      statuses: // value for 'statuses'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useProfilePage_ProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfilePage_ProfilesQuery,
    ProfilePage_ProfilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfilePage_ProfilesQuery,
    ProfilePage_ProfilesQueryVariables
  >(ProfilePage_ProfilesDocument, options);
}
export function useProfilePage_ProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfilePage_ProfilesQuery,
    ProfilePage_ProfilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfilePage_ProfilesQuery,
    ProfilePage_ProfilesQueryVariables
  >(ProfilePage_ProfilesDocument, options);
}
export function useProfilePage_ProfilesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProfilePage_ProfilesQuery,
    ProfilePage_ProfilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProfilePage_ProfilesQuery,
    ProfilePage_ProfilesQueryVariables
  >(ProfilePage_ProfilesDocument, options);
}
export type ProfilePage_ProfilesQueryHookResult = ReturnType<
  typeof useProfilePage_ProfilesQuery
>;
export type ProfilePage_ProfilesLazyQueryHookResult = ReturnType<
  typeof useProfilePage_ProfilesLazyQuery
>;
export type ProfilePage_ProfilesSuspenseQueryHookResult = ReturnType<
  typeof useProfilePage_ProfilesSuspenseQuery
>;
export type ProfilePage_ProfilesQueryResult = Apollo.QueryResult<
  ProfilePage_ProfilesQuery,
  ProfilePage_ProfilesQueryVariables
>;
export const ProfilePage_RolesDocument = gql`
  query ProfilePage_Roles {
    roles {
      ...ProfilePage_Roles
    }
  }
  ${ProfilePage_RolesFragmentDoc}
`;

/**
 * __useProfilePage_RolesQuery__
 *
 * To run a query within a React component, call `useProfilePage_RolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePage_RolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePage_RolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilePage_RolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfilePage_RolesQuery,
    ProfilePage_RolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfilePage_RolesQuery,
    ProfilePage_RolesQueryVariables
  >(ProfilePage_RolesDocument, options);
}
export function useProfilePage_RolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfilePage_RolesQuery,
    ProfilePage_RolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfilePage_RolesQuery,
    ProfilePage_RolesQueryVariables
  >(ProfilePage_RolesDocument, options);
}
export function useProfilePage_RolesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProfilePage_RolesQuery,
    ProfilePage_RolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProfilePage_RolesQuery,
    ProfilePage_RolesQueryVariables
  >(ProfilePage_RolesDocument, options);
}
export type ProfilePage_RolesQueryHookResult = ReturnType<
  typeof useProfilePage_RolesQuery
>;
export type ProfilePage_RolesLazyQueryHookResult = ReturnType<
  typeof useProfilePage_RolesLazyQuery
>;
export type ProfilePage_RolesSuspenseQueryHookResult = ReturnType<
  typeof useProfilePage_RolesSuspenseQuery
>;
export type ProfilePage_RolesQueryResult = Apollo.QueryResult<
  ProfilePage_RolesQuery,
  ProfilePage_RolesQueryVariables
>;
export const ProfilePage_HierarchyDocument = gql`
  query ProfilePage_Hierarchy(
    $searchTerms: String
    $page: Float
    $pageSize: Float
  ) {
    paginatedHierarchies(
      searchTerms: $searchTerms
      page: $page
      pageSize: $pageSize
    ) {
      hierarchies {
        ...ProfilePage_Hierarchy
      }
    }
  }
  ${ProfilePage_HierarchyFragmentDoc}
`;

/**
 * __useProfilePage_HierarchyQuery__
 *
 * To run a query within a React component, call `useProfilePage_HierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePage_HierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePage_HierarchyQuery({
 *   variables: {
 *      searchTerms: // value for 'searchTerms'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useProfilePage_HierarchyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfilePage_HierarchyQuery,
    ProfilePage_HierarchyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfilePage_HierarchyQuery,
    ProfilePage_HierarchyQueryVariables
  >(ProfilePage_HierarchyDocument, options);
}
export function useProfilePage_HierarchyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfilePage_HierarchyQuery,
    ProfilePage_HierarchyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfilePage_HierarchyQuery,
    ProfilePage_HierarchyQueryVariables
  >(ProfilePage_HierarchyDocument, options);
}
export function useProfilePage_HierarchySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProfilePage_HierarchyQuery,
    ProfilePage_HierarchyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProfilePage_HierarchyQuery,
    ProfilePage_HierarchyQueryVariables
  >(ProfilePage_HierarchyDocument, options);
}
export type ProfilePage_HierarchyQueryHookResult = ReturnType<
  typeof useProfilePage_HierarchyQuery
>;
export type ProfilePage_HierarchyLazyQueryHookResult = ReturnType<
  typeof useProfilePage_HierarchyLazyQuery
>;
export type ProfilePage_HierarchySuspenseQueryHookResult = ReturnType<
  typeof useProfilePage_HierarchySuspenseQuery
>;
export type ProfilePage_HierarchyQueryResult = Apollo.QueryResult<
  ProfilePage_HierarchyQuery,
  ProfilePage_HierarchyQueryVariables
>;
export const ProfilePage_TagsDocument = gql`
  query ProfilePage_Tags {
    tags {
      ...ProfilePage_Tags
    }
  }
  ${ProfilePage_TagsFragmentDoc}
`;

/**
 * __useProfilePage_TagsQuery__
 *
 * To run a query within a React component, call `useProfilePage_TagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePage_TagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePage_TagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilePage_TagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfilePage_TagsQuery,
    ProfilePage_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfilePage_TagsQuery, ProfilePage_TagsQueryVariables>(
    ProfilePage_TagsDocument,
    options,
  );
}
export function useProfilePage_TagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfilePage_TagsQuery,
    ProfilePage_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfilePage_TagsQuery,
    ProfilePage_TagsQueryVariables
  >(ProfilePage_TagsDocument, options);
}
export function useProfilePage_TagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProfilePage_TagsQuery,
    ProfilePage_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProfilePage_TagsQuery,
    ProfilePage_TagsQueryVariables
  >(ProfilePage_TagsDocument, options);
}
export type ProfilePage_TagsQueryHookResult = ReturnType<
  typeof useProfilePage_TagsQuery
>;
export type ProfilePage_TagsLazyQueryHookResult = ReturnType<
  typeof useProfilePage_TagsLazyQuery
>;
export type ProfilePage_TagsSuspenseQueryHookResult = ReturnType<
  typeof useProfilePage_TagsSuspenseQuery
>;
export type ProfilePage_TagsQueryResult = Apollo.QueryResult<
  ProfilePage_TagsQuery,
  ProfilePage_TagsQueryVariables
>;
export const ProfilePage_AccountTypesDocument = gql`
  query ProfilePage_AccountTypes {
    accountTypes {
      ...ProfilePage_AccountTypes
    }
  }
  ${ProfilePage_AccountTypesFragmentDoc}
`;

/**
 * __useProfilePage_AccountTypesQuery__
 *
 * To run a query within a React component, call `useProfilePage_AccountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePage_AccountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePage_AccountTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilePage_AccountTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfilePage_AccountTypesQuery,
    ProfilePage_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfilePage_AccountTypesQuery,
    ProfilePage_AccountTypesQueryVariables
  >(ProfilePage_AccountTypesDocument, options);
}
export function useProfilePage_AccountTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfilePage_AccountTypesQuery,
    ProfilePage_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfilePage_AccountTypesQuery,
    ProfilePage_AccountTypesQueryVariables
  >(ProfilePage_AccountTypesDocument, options);
}
export function useProfilePage_AccountTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProfilePage_AccountTypesQuery,
    ProfilePage_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProfilePage_AccountTypesQuery,
    ProfilePage_AccountTypesQueryVariables
  >(ProfilePage_AccountTypesDocument, options);
}
export type ProfilePage_AccountTypesQueryHookResult = ReturnType<
  typeof useProfilePage_AccountTypesQuery
>;
export type ProfilePage_AccountTypesLazyQueryHookResult = ReturnType<
  typeof useProfilePage_AccountTypesLazyQuery
>;
export type ProfilePage_AccountTypesSuspenseQueryHookResult = ReturnType<
  typeof useProfilePage_AccountTypesSuspenseQuery
>;
export type ProfilePage_AccountTypesQueryResult = Apollo.QueryResult<
  ProfilePage_AccountTypesQuery,
  ProfilePage_AccountTypesQueryVariables
>;
export const Prospective_MyApplicationsDocument = gql`
  query Prospective_MyApplications {
    myApplications {
      ...Prospective_ApplicationInfo
    }
  }
  ${Prospective_ApplicationInfoFragmentDoc}
`;

/**
 * __useProspective_MyApplicationsQuery__
 *
 * To run a query within a React component, call `useProspective_MyApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspective_MyApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspective_MyApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProspective_MyApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Prospective_MyApplicationsQuery,
    Prospective_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Prospective_MyApplicationsQuery,
    Prospective_MyApplicationsQueryVariables
  >(Prospective_MyApplicationsDocument, options);
}
export function useProspective_MyApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Prospective_MyApplicationsQuery,
    Prospective_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Prospective_MyApplicationsQuery,
    Prospective_MyApplicationsQueryVariables
  >(Prospective_MyApplicationsDocument, options);
}
export function useProspective_MyApplicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Prospective_MyApplicationsQuery,
    Prospective_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Prospective_MyApplicationsQuery,
    Prospective_MyApplicationsQueryVariables
  >(Prospective_MyApplicationsDocument, options);
}
export type Prospective_MyApplicationsQueryHookResult = ReturnType<
  typeof useProspective_MyApplicationsQuery
>;
export type Prospective_MyApplicationsLazyQueryHookResult = ReturnType<
  typeof useProspective_MyApplicationsLazyQuery
>;
export type Prospective_MyApplicationsSuspenseQueryHookResult = ReturnType<
  typeof useProspective_MyApplicationsSuspenseQuery
>;
export type Prospective_MyApplicationsQueryResult = Apollo.QueryResult<
  Prospective_MyApplicationsQuery,
  Prospective_MyApplicationsQueryVariables
>;
export const RequestAccountForm_RolesByAccountTypeDocument = gql`
  query RequestAccountForm_RolesByAccountType($accountTypeId: String!) {
    rolesByAccountType(accountTypeId: $accountTypeId) {
      id
      name
      description
    }
  }
`;

/**
 * __useRequestAccountForm_RolesByAccountTypeQuery__
 *
 * To run a query within a React component, call `useRequestAccountForm_RolesByAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestAccountForm_RolesByAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestAccountForm_RolesByAccountTypeQuery({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *   },
 * });
 */
export function useRequestAccountForm_RolesByAccountTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequestAccountForm_RolesByAccountTypeQuery,
    RequestAccountForm_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequestAccountForm_RolesByAccountTypeQuery,
    RequestAccountForm_RolesByAccountTypeQueryVariables
  >(RequestAccountForm_RolesByAccountTypeDocument, options);
}
export function useRequestAccountForm_RolesByAccountTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestAccountForm_RolesByAccountTypeQuery,
    RequestAccountForm_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestAccountForm_RolesByAccountTypeQuery,
    RequestAccountForm_RolesByAccountTypeQueryVariables
  >(RequestAccountForm_RolesByAccountTypeDocument, options);
}
export function useRequestAccountForm_RolesByAccountTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RequestAccountForm_RolesByAccountTypeQuery,
    RequestAccountForm_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RequestAccountForm_RolesByAccountTypeQuery,
    RequestAccountForm_RolesByAccountTypeQueryVariables
  >(RequestAccountForm_RolesByAccountTypeDocument, options);
}
export type RequestAccountForm_RolesByAccountTypeQueryHookResult = ReturnType<
  typeof useRequestAccountForm_RolesByAccountTypeQuery
>;
export type RequestAccountForm_RolesByAccountTypeLazyQueryHookResult =
  ReturnType<typeof useRequestAccountForm_RolesByAccountTypeLazyQuery>;
export type RequestAccountForm_RolesByAccountTypeSuspenseQueryHookResult =
  ReturnType<typeof useRequestAccountForm_RolesByAccountTypeSuspenseQuery>;
export type RequestAccountForm_RolesByAccountTypeQueryResult =
  Apollo.QueryResult<
    RequestAccountForm_RolesByAccountTypeQuery,
    RequestAccountForm_RolesByAccountTypeQueryVariables
  >;
export const RequestAccountForm_AccountTypesDocument = gql`
  query RequestAccountForm_AccountTypes {
    accountTypes {
      ...RequestAccountForm_GetAccountType
    }
  }
  ${RequestAccountForm_GetAccountTypeFragmentDoc}
`;

/**
 * __useRequestAccountForm_AccountTypesQuery__
 *
 * To run a query within a React component, call `useRequestAccountForm_AccountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestAccountForm_AccountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestAccountForm_AccountTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestAccountForm_AccountTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RequestAccountForm_AccountTypesQuery,
    RequestAccountForm_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequestAccountForm_AccountTypesQuery,
    RequestAccountForm_AccountTypesQueryVariables
  >(RequestAccountForm_AccountTypesDocument, options);
}
export function useRequestAccountForm_AccountTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestAccountForm_AccountTypesQuery,
    RequestAccountForm_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestAccountForm_AccountTypesQuery,
    RequestAccountForm_AccountTypesQueryVariables
  >(RequestAccountForm_AccountTypesDocument, options);
}
export function useRequestAccountForm_AccountTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RequestAccountForm_AccountTypesQuery,
    RequestAccountForm_AccountTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RequestAccountForm_AccountTypesQuery,
    RequestAccountForm_AccountTypesQueryVariables
  >(RequestAccountForm_AccountTypesDocument, options);
}
export type RequestAccountForm_AccountTypesQueryHookResult = ReturnType<
  typeof useRequestAccountForm_AccountTypesQuery
>;
export type RequestAccountForm_AccountTypesLazyQueryHookResult = ReturnType<
  typeof useRequestAccountForm_AccountTypesLazyQuery
>;
export type RequestAccountForm_AccountTypesSuspenseQueryHookResult = ReturnType<
  typeof useRequestAccountForm_AccountTypesSuspenseQuery
>;
export type RequestAccountForm_AccountTypesQueryResult = Apollo.QueryResult<
  RequestAccountForm_AccountTypesQuery,
  RequestAccountForm_AccountTypesQueryVariables
>;
export const RequestAccountForm_TagsByAccountTypeDocument = gql`
  query RequestAccountForm_TagsByAccountType($accountTypeId: String!) {
    tagsByAccountType(accountTypeId: $accountTypeId) {
      ...RequestAccountForm_TagsByAccountType
    }
  }
  ${RequestAccountForm_TagsByAccountTypeFragmentDoc}
`;

/**
 * __useRequestAccountForm_TagsByAccountTypeQuery__
 *
 * To run a query within a React component, call `useRequestAccountForm_TagsByAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestAccountForm_TagsByAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestAccountForm_TagsByAccountTypeQuery({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *   },
 * });
 */
export function useRequestAccountForm_TagsByAccountTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequestAccountForm_TagsByAccountTypeQuery,
    RequestAccountForm_TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequestAccountForm_TagsByAccountTypeQuery,
    RequestAccountForm_TagsByAccountTypeQueryVariables
  >(RequestAccountForm_TagsByAccountTypeDocument, options);
}
export function useRequestAccountForm_TagsByAccountTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestAccountForm_TagsByAccountTypeQuery,
    RequestAccountForm_TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestAccountForm_TagsByAccountTypeQuery,
    RequestAccountForm_TagsByAccountTypeQueryVariables
  >(RequestAccountForm_TagsByAccountTypeDocument, options);
}
export function useRequestAccountForm_TagsByAccountTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RequestAccountForm_TagsByAccountTypeQuery,
    RequestAccountForm_TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RequestAccountForm_TagsByAccountTypeQuery,
    RequestAccountForm_TagsByAccountTypeQueryVariables
  >(RequestAccountForm_TagsByAccountTypeDocument, options);
}
export type RequestAccountForm_TagsByAccountTypeQueryHookResult = ReturnType<
  typeof useRequestAccountForm_TagsByAccountTypeQuery
>;
export type RequestAccountForm_TagsByAccountTypeLazyQueryHookResult =
  ReturnType<typeof useRequestAccountForm_TagsByAccountTypeLazyQuery>;
export type RequestAccountForm_TagsByAccountTypeSuspenseQueryHookResult =
  ReturnType<typeof useRequestAccountForm_TagsByAccountTypeSuspenseQuery>;
export type RequestAccountForm_TagsByAccountTypeQueryResult =
  Apollo.QueryResult<
    RequestAccountForm_TagsByAccountTypeQuery,
    RequestAccountForm_TagsByAccountTypeQueryVariables
  >;
export const Request_Account_Profile_DetailsDocument = gql`
  query Request_Account_Profile_Details($id: String!) {
    profile(id: $id) {
      id
      firstName
      lastName
      emailAddress
    }
  }
`;

/**
 * __useRequest_Account_Profile_DetailsQuery__
 *
 * To run a query within a React component, call `useRequest_Account_Profile_DetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequest_Account_Profile_DetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequest_Account_Profile_DetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequest_Account_Profile_DetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Request_Account_Profile_DetailsQuery,
    Request_Account_Profile_DetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Request_Account_Profile_DetailsQuery,
    Request_Account_Profile_DetailsQueryVariables
  >(Request_Account_Profile_DetailsDocument, options);
}
export function useRequest_Account_Profile_DetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Request_Account_Profile_DetailsQuery,
    Request_Account_Profile_DetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Request_Account_Profile_DetailsQuery,
    Request_Account_Profile_DetailsQueryVariables
  >(Request_Account_Profile_DetailsDocument, options);
}
export function useRequest_Account_Profile_DetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Request_Account_Profile_DetailsQuery,
    Request_Account_Profile_DetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Request_Account_Profile_DetailsQuery,
    Request_Account_Profile_DetailsQueryVariables
  >(Request_Account_Profile_DetailsDocument, options);
}
export type Request_Account_Profile_DetailsQueryHookResult = ReturnType<
  typeof useRequest_Account_Profile_DetailsQuery
>;
export type Request_Account_Profile_DetailsLazyQueryHookResult = ReturnType<
  typeof useRequest_Account_Profile_DetailsLazyQuery
>;
export type Request_Account_Profile_DetailsSuspenseQueryHookResult = ReturnType<
  typeof useRequest_Account_Profile_DetailsSuspenseQuery
>;
export type Request_Account_Profile_DetailsQueryResult = Apollo.QueryResult<
  Request_Account_Profile_DetailsQuery,
  Request_Account_Profile_DetailsQueryVariables
>;
export const ManageRole_RoleDocument = gql`
  query ManageRole_Role($id: String!) {
    role(id: $id) {
      ...RoleHiddenSection_RoleHidden
      ...ManageRole_RoleInfo
    }
  }
  ${RoleHiddenSection_RoleHiddenFragmentDoc}
  ${ManageRole_RoleInfoFragmentDoc}
`;

/**
 * __useManageRole_RoleQuery__
 *
 * To run a query within a React component, call `useManageRole_RoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageRole_RoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageRole_RoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManageRole_RoleQuery(
  baseOptions: Apollo.QueryHookOptions<
    ManageRole_RoleQuery,
    ManageRole_RoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageRole_RoleQuery, ManageRole_RoleQueryVariables>(
    ManageRole_RoleDocument,
    options,
  );
}
export function useManageRole_RoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManageRole_RoleQuery,
    ManageRole_RoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManageRole_RoleQuery,
    ManageRole_RoleQueryVariables
  >(ManageRole_RoleDocument, options);
}
export function useManageRole_RoleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ManageRole_RoleQuery,
    ManageRole_RoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManageRole_RoleQuery,
    ManageRole_RoleQueryVariables
  >(ManageRole_RoleDocument, options);
}
export type ManageRole_RoleQueryHookResult = ReturnType<
  typeof useManageRole_RoleQuery
>;
export type ManageRole_RoleLazyQueryHookResult = ReturnType<
  typeof useManageRole_RoleLazyQuery
>;
export type ManageRole_RoleSuspenseQueryHookResult = ReturnType<
  typeof useManageRole_RoleSuspenseQuery
>;
export type ManageRole_RoleQueryResult = Apollo.QueryResult<
  ManageRole_RoleQuery,
  ManageRole_RoleQueryVariables
>;
export const ManageRole_TagsDocument = gql`
  query ManageRole_Tags {
    tags {
      ...TagSelection_Tags
    }
  }
  ${TagSelection_TagsFragmentDoc}
`;

/**
 * __useManageRole_TagsQuery__
 *
 * To run a query within a React component, call `useManageRole_TagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageRole_TagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageRole_TagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useManageRole_TagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManageRole_TagsQuery,
    ManageRole_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageRole_TagsQuery, ManageRole_TagsQueryVariables>(
    ManageRole_TagsDocument,
    options,
  );
}
export function useManageRole_TagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManageRole_TagsQuery,
    ManageRole_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManageRole_TagsQuery,
    ManageRole_TagsQueryVariables
  >(ManageRole_TagsDocument, options);
}
export function useManageRole_TagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ManageRole_TagsQuery,
    ManageRole_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManageRole_TagsQuery,
    ManageRole_TagsQueryVariables
  >(ManageRole_TagsDocument, options);
}
export type ManageRole_TagsQueryHookResult = ReturnType<
  typeof useManageRole_TagsQuery
>;
export type ManageRole_TagsLazyQueryHookResult = ReturnType<
  typeof useManageRole_TagsLazyQuery
>;
export type ManageRole_TagsSuspenseQueryHookResult = ReturnType<
  typeof useManageRole_TagsSuspenseQuery
>;
export type ManageRole_TagsQueryResult = Apollo.QueryResult<
  ManageRole_TagsQuery,
  ManageRole_TagsQueryVariables
>;
export const RoleApplicationsSection_ApplicationsListDocument = gql`
  query RoleApplicationsSection_ApplicationsList {
    applications {
      ...LinkAppsSection_linkAppInfo
    }
  }
  ${LinkAppsSection_LinkAppInfoFragmentDoc}
`;

/**
 * __useRoleApplicationsSection_ApplicationsListQuery__
 *
 * To run a query within a React component, call `useRoleApplicationsSection_ApplicationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleApplicationsSection_ApplicationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleApplicationsSection_ApplicationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleApplicationsSection_ApplicationsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RoleApplicationsSection_ApplicationsListQuery,
    RoleApplicationsSection_ApplicationsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RoleApplicationsSection_ApplicationsListQuery,
    RoleApplicationsSection_ApplicationsListQueryVariables
  >(RoleApplicationsSection_ApplicationsListDocument, options);
}
export function useRoleApplicationsSection_ApplicationsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleApplicationsSection_ApplicationsListQuery,
    RoleApplicationsSection_ApplicationsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RoleApplicationsSection_ApplicationsListQuery,
    RoleApplicationsSection_ApplicationsListQueryVariables
  >(RoleApplicationsSection_ApplicationsListDocument, options);
}
export function useRoleApplicationsSection_ApplicationsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RoleApplicationsSection_ApplicationsListQuery,
    RoleApplicationsSection_ApplicationsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RoleApplicationsSection_ApplicationsListQuery,
    RoleApplicationsSection_ApplicationsListQueryVariables
  >(RoleApplicationsSection_ApplicationsListDocument, options);
}
export type RoleApplicationsSection_ApplicationsListQueryHookResult =
  ReturnType<typeof useRoleApplicationsSection_ApplicationsListQuery>;
export type RoleApplicationsSection_ApplicationsListLazyQueryHookResult =
  ReturnType<typeof useRoleApplicationsSection_ApplicationsListLazyQuery>;
export type RoleApplicationsSection_ApplicationsListSuspenseQueryHookResult =
  ReturnType<typeof useRoleApplicationsSection_ApplicationsListSuspenseQuery>;
export type RoleApplicationsSection_ApplicationsListQueryResult =
  Apollo.QueryResult<
    RoleApplicationsSection_ApplicationsListQuery,
    RoleApplicationsSection_ApplicationsListQueryVariables
  >;
export const SitePage_SitesDocument = gql`
  query SitePage_Sites($input: PaginatedSitesInput!) {
    paginatedSites(input: $input) {
      count
      page
      perPage
      sites {
        id
        name
        supplierCode
        supplierNumber
        approvedDomains
        rejectedDomains
        status
        activeStatus
        groupId
      }
    }
  }
`;

/**
 * __useSitePage_SitesQuery__
 *
 * To run a query within a React component, call `useSitePage_SitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitePage_SitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitePage_SitesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSitePage_SitesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SitePage_SitesQuery,
    SitePage_SitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SitePage_SitesQuery, SitePage_SitesQueryVariables>(
    SitePage_SitesDocument,
    options,
  );
}
export function useSitePage_SitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SitePage_SitesQuery,
    SitePage_SitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SitePage_SitesQuery, SitePage_SitesQueryVariables>(
    SitePage_SitesDocument,
    options,
  );
}
export function useSitePage_SitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SitePage_SitesQuery,
    SitePage_SitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SitePage_SitesQuery,
    SitePage_SitesQueryVariables
  >(SitePage_SitesDocument, options);
}
export type SitePage_SitesQueryHookResult = ReturnType<
  typeof useSitePage_SitesQuery
>;
export type SitePage_SitesLazyQueryHookResult = ReturnType<
  typeof useSitePage_SitesLazyQuery
>;
export type SitePage_SitesSuspenseQueryHookResult = ReturnType<
  typeof useSitePage_SitesSuspenseQuery
>;
export type SitePage_SitesQueryResult = Apollo.QueryResult<
  SitePage_SitesQuery,
  SitePage_SitesQueryVariables
>;
export const TagManagerDocument = gql`
  query TagManager {
    tags {
      ...TagManager
      ...DeleteTag
    }
  }
  ${TagManagerFragmentDoc}
  ${DeleteTagFragmentDoc}
`;

/**
 * __useTagManagerQuery__
 *
 * To run a query within a React component, call `useTagManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagManagerQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagManagerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TagManagerQuery,
    TagManagerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagManagerQuery, TagManagerQueryVariables>(
    TagManagerDocument,
    options,
  );
}
export function useTagManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TagManagerQuery,
    TagManagerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagManagerQuery, TagManagerQueryVariables>(
    TagManagerDocument,
    options,
  );
}
export function useTagManagerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TagManagerQuery,
    TagManagerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TagManagerQuery, TagManagerQueryVariables>(
    TagManagerDocument,
    options,
  );
}
export type TagManagerQueryHookResult = ReturnType<typeof useTagManagerQuery>;
export type TagManagerLazyQueryHookResult = ReturnType<
  typeof useTagManagerLazyQuery
>;
export type TagManagerSuspenseQueryHookResult = ReturnType<
  typeof useTagManagerSuspenseQuery
>;
export type TagManagerQueryResult = Apollo.QueryResult<
  TagManagerQuery,
  TagManagerQueryVariables
>;
export const TermsAndConditions_AccountDetailsDocument = gql`
  query TermsAndConditions_AccountDetails($id: String!) {
    account(id: $id) {
      id
      sites {
        value {
          name
          supplierCode
          supplierNumber
          approvedDomains
          rejectedDomains
          status
          activeStatus
          groupId
          parentGroup {
            value {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTermsAndConditions_AccountDetailsQuery__
 *
 * To run a query within a React component, call `useTermsAndConditions_AccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsAndConditions_AccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsAndConditions_AccountDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTermsAndConditions_AccountDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TermsAndConditions_AccountDetailsQuery,
    TermsAndConditions_AccountDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TermsAndConditions_AccountDetailsQuery,
    TermsAndConditions_AccountDetailsQueryVariables
  >(TermsAndConditions_AccountDetailsDocument, options);
}
export function useTermsAndConditions_AccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TermsAndConditions_AccountDetailsQuery,
    TermsAndConditions_AccountDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TermsAndConditions_AccountDetailsQuery,
    TermsAndConditions_AccountDetailsQueryVariables
  >(TermsAndConditions_AccountDetailsDocument, options);
}
export function useTermsAndConditions_AccountDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TermsAndConditions_AccountDetailsQuery,
    TermsAndConditions_AccountDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TermsAndConditions_AccountDetailsQuery,
    TermsAndConditions_AccountDetailsQueryVariables
  >(TermsAndConditions_AccountDetailsDocument, options);
}
export type TermsAndConditions_AccountDetailsQueryHookResult = ReturnType<
  typeof useTermsAndConditions_AccountDetailsQuery
>;
export type TermsAndConditions_AccountDetailsLazyQueryHookResult = ReturnType<
  typeof useTermsAndConditions_AccountDetailsLazyQuery
>;
export type TermsAndConditions_AccountDetailsSuspenseQueryHookResult =
  ReturnType<typeof useTermsAndConditions_AccountDetailsSuspenseQuery>;
export type TermsAndConditions_AccountDetailsQueryResult = Apollo.QueryResult<
  TermsAndConditions_AccountDetailsQuery,
  TermsAndConditions_AccountDetailsQueryVariables
>;
export const TermsAndConditions_GroupSitesDocument = gql`
  query TermsAndConditions_GroupSites($groupId: String!) {
    groupSites(groupId: $groupId) {
      ...termsAndConditions_GroupSites
    }
  }
  ${TermsAndConditions_GroupSitesFragmentDoc}
`;

/**
 * __useTermsAndConditions_GroupSitesQuery__
 *
 * To run a query within a React component, call `useTermsAndConditions_GroupSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsAndConditions_GroupSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsAndConditions_GroupSitesQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useTermsAndConditions_GroupSitesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TermsAndConditions_GroupSitesQuery,
    TermsAndConditions_GroupSitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TermsAndConditions_GroupSitesQuery,
    TermsAndConditions_GroupSitesQueryVariables
  >(TermsAndConditions_GroupSitesDocument, options);
}
export function useTermsAndConditions_GroupSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TermsAndConditions_GroupSitesQuery,
    TermsAndConditions_GroupSitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TermsAndConditions_GroupSitesQuery,
    TermsAndConditions_GroupSitesQueryVariables
  >(TermsAndConditions_GroupSitesDocument, options);
}
export function useTermsAndConditions_GroupSitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TermsAndConditions_GroupSitesQuery,
    TermsAndConditions_GroupSitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TermsAndConditions_GroupSitesQuery,
    TermsAndConditions_GroupSitesQueryVariables
  >(TermsAndConditions_GroupSitesDocument, options);
}
export type TermsAndConditions_GroupSitesQueryHookResult = ReturnType<
  typeof useTermsAndConditions_GroupSitesQuery
>;
export type TermsAndConditions_GroupSitesLazyQueryHookResult = ReturnType<
  typeof useTermsAndConditions_GroupSitesLazyQuery
>;
export type TermsAndConditions_GroupSitesSuspenseQueryHookResult = ReturnType<
  typeof useTermsAndConditions_GroupSitesSuspenseQuery
>;
export type TermsAndConditions_GroupSitesQueryResult = Apollo.QueryResult<
  TermsAndConditions_GroupSitesQuery,
  TermsAndConditions_GroupSitesQueryVariables
>;
export const ApplicationPermissionGroupsDocument = gql`
  query ApplicationPermissionGroups($applicationId: String!) {
    applicationPermissionGroups(applicationId: $applicationId) {
      ...PermissionGroupComp_PermissionGroup
    }
  }
  ${PermissionGroupComp_PermissionGroupFragmentDoc}
`;

/**
 * __useApplicationPermissionGroupsQuery__
 *
 * To run a query within a React component, call `useApplicationPermissionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationPermissionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationPermissionGroupsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useApplicationPermissionGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationPermissionGroupsQuery,
    ApplicationPermissionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationPermissionGroupsQuery,
    ApplicationPermissionGroupsQueryVariables
  >(ApplicationPermissionGroupsDocument, options);
}
export function useApplicationPermissionGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationPermissionGroupsQuery,
    ApplicationPermissionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationPermissionGroupsQuery,
    ApplicationPermissionGroupsQueryVariables
  >(ApplicationPermissionGroupsDocument, options);
}
export function useApplicationPermissionGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ApplicationPermissionGroupsQuery,
    ApplicationPermissionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ApplicationPermissionGroupsQuery,
    ApplicationPermissionGroupsQueryVariables
  >(ApplicationPermissionGroupsDocument, options);
}
export type ApplicationPermissionGroupsQueryHookResult = ReturnType<
  typeof useApplicationPermissionGroupsQuery
>;
export type ApplicationPermissionGroupsLazyQueryHookResult = ReturnType<
  typeof useApplicationPermissionGroupsLazyQuery
>;
export type ApplicationPermissionGroupsSuspenseQueryHookResult = ReturnType<
  typeof useApplicationPermissionGroupsSuspenseQuery
>;
export type ApplicationPermissionGroupsQueryResult = Apollo.QueryResult<
  ApplicationPermissionGroupsQuery,
  ApplicationPermissionGroupsQueryVariables
>;
export const ViewApplicaion_TagsDocument = gql`
  query ViewApplicaion_Tags {
    tags {
      ...TagSelection_Tags
    }
  }
  ${TagSelection_TagsFragmentDoc}
`;

/**
 * __useViewApplicaion_TagsQuery__
 *
 * To run a query within a React component, call `useViewApplicaion_TagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewApplicaion_TagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewApplicaion_TagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewApplicaion_TagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewApplicaion_TagsQuery,
    ViewApplicaion_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewApplicaion_TagsQuery,
    ViewApplicaion_TagsQueryVariables
  >(ViewApplicaion_TagsDocument, options);
}
export function useViewApplicaion_TagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewApplicaion_TagsQuery,
    ViewApplicaion_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewApplicaion_TagsQuery,
    ViewApplicaion_TagsQueryVariables
  >(ViewApplicaion_TagsDocument, options);
}
export function useViewApplicaion_TagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ViewApplicaion_TagsQuery,
    ViewApplicaion_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ViewApplicaion_TagsQuery,
    ViewApplicaion_TagsQueryVariables
  >(ViewApplicaion_TagsDocument, options);
}
export type ViewApplicaion_TagsQueryHookResult = ReturnType<
  typeof useViewApplicaion_TagsQuery
>;
export type ViewApplicaion_TagsLazyQueryHookResult = ReturnType<
  typeof useViewApplicaion_TagsLazyQuery
>;
export type ViewApplicaion_TagsSuspenseQueryHookResult = ReturnType<
  typeof useViewApplicaion_TagsSuspenseQuery
>;
export type ViewApplicaion_TagsQueryResult = Apollo.QueryResult<
  ViewApplicaion_TagsQuery,
  ViewApplicaion_TagsQueryVariables
>;
export const ViewApplicaion_GetPermissionsDocument = gql`
  query ViewApplicaion_GetPermissions($applicationId: String!) {
    applicationPermissions(applicationId: $applicationId) {
      ...ViewApplicaion_GetPermissions
      ...PermissionsSection_PermissionInfo
    }
  }
  ${ViewApplicaion_GetPermissionsFragmentDoc}
  ${PermissionsSection_PermissionInfoFragmentDoc}
`;

/**
 * __useViewApplicaion_GetPermissionsQuery__
 *
 * To run a query within a React component, call `useViewApplicaion_GetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewApplicaion_GetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewApplicaion_GetPermissionsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useViewApplicaion_GetPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewApplicaion_GetPermissionsQuery,
    ViewApplicaion_GetPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewApplicaion_GetPermissionsQuery,
    ViewApplicaion_GetPermissionsQueryVariables
  >(ViewApplicaion_GetPermissionsDocument, options);
}
export function useViewApplicaion_GetPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewApplicaion_GetPermissionsQuery,
    ViewApplicaion_GetPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewApplicaion_GetPermissionsQuery,
    ViewApplicaion_GetPermissionsQueryVariables
  >(ViewApplicaion_GetPermissionsDocument, options);
}
export function useViewApplicaion_GetPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ViewApplicaion_GetPermissionsQuery,
    ViewApplicaion_GetPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ViewApplicaion_GetPermissionsQuery,
    ViewApplicaion_GetPermissionsQueryVariables
  >(ViewApplicaion_GetPermissionsDocument, options);
}
export type ViewApplicaion_GetPermissionsQueryHookResult = ReturnType<
  typeof useViewApplicaion_GetPermissionsQuery
>;
export type ViewApplicaion_GetPermissionsLazyQueryHookResult = ReturnType<
  typeof useViewApplicaion_GetPermissionsLazyQuery
>;
export type ViewApplicaion_GetPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useViewApplicaion_GetPermissionsSuspenseQuery
>;
export type ViewApplicaion_GetPermissionsQueryResult = Apollo.QueryResult<
  ViewApplicaion_GetPermissionsQuery,
  ViewApplicaion_GetPermissionsQueryVariables
>;
export const ViewApplicaion_ApplicationInfoDocument = gql`
  query ViewApplicaion_ApplicationInfo($id: String!) {
    application(id: $id) {
      ...ViewApplicaion_ApplicationInfo
    }
  }
  ${ViewApplicaion_ApplicationInfoFragmentDoc}
`;

/**
 * __useViewApplicaion_ApplicationInfoQuery__
 *
 * To run a query within a React component, call `useViewApplicaion_ApplicationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewApplicaion_ApplicationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewApplicaion_ApplicationInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewApplicaion_ApplicationInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewApplicaion_ApplicationInfoQuery,
    ViewApplicaion_ApplicationInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewApplicaion_ApplicationInfoQuery,
    ViewApplicaion_ApplicationInfoQueryVariables
  >(ViewApplicaion_ApplicationInfoDocument, options);
}
export function useViewApplicaion_ApplicationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewApplicaion_ApplicationInfoQuery,
    ViewApplicaion_ApplicationInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewApplicaion_ApplicationInfoQuery,
    ViewApplicaion_ApplicationInfoQueryVariables
  >(ViewApplicaion_ApplicationInfoDocument, options);
}
export function useViewApplicaion_ApplicationInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ViewApplicaion_ApplicationInfoQuery,
    ViewApplicaion_ApplicationInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ViewApplicaion_ApplicationInfoQuery,
    ViewApplicaion_ApplicationInfoQueryVariables
  >(ViewApplicaion_ApplicationInfoDocument, options);
}
export type ViewApplicaion_ApplicationInfoQueryHookResult = ReturnType<
  typeof useViewApplicaion_ApplicationInfoQuery
>;
export type ViewApplicaion_ApplicationInfoLazyQueryHookResult = ReturnType<
  typeof useViewApplicaion_ApplicationInfoLazyQuery
>;
export type ViewApplicaion_ApplicationInfoSuspenseQueryHookResult = ReturnType<
  typeof useViewApplicaion_ApplicationInfoSuspenseQuery
>;
export type ViewApplicaion_ApplicationInfoQueryResult = Apollo.QueryResult<
  ViewApplicaion_ApplicationInfoQuery,
  ViewApplicaion_ApplicationInfoQueryVariables
>;
export const ViewApplicaion_ApplicationsListDocument = gql`
  query ViewApplicaion_ApplicationsList {
    applications {
      id
      name
      acronym
      shortDescription
      longDescription
    }
  }
`;

/**
 * __useViewApplicaion_ApplicationsListQuery__
 *
 * To run a query within a React component, call `useViewApplicaion_ApplicationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewApplicaion_ApplicationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewApplicaion_ApplicationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewApplicaion_ApplicationsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewApplicaion_ApplicationsListQuery,
    ViewApplicaion_ApplicationsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewApplicaion_ApplicationsListQuery,
    ViewApplicaion_ApplicationsListQueryVariables
  >(ViewApplicaion_ApplicationsListDocument, options);
}
export function useViewApplicaion_ApplicationsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewApplicaion_ApplicationsListQuery,
    ViewApplicaion_ApplicationsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewApplicaion_ApplicationsListQuery,
    ViewApplicaion_ApplicationsListQueryVariables
  >(ViewApplicaion_ApplicationsListDocument, options);
}
export function useViewApplicaion_ApplicationsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ViewApplicaion_ApplicationsListQuery,
    ViewApplicaion_ApplicationsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ViewApplicaion_ApplicationsListQuery,
    ViewApplicaion_ApplicationsListQueryVariables
  >(ViewApplicaion_ApplicationsListDocument, options);
}
export type ViewApplicaion_ApplicationsListQueryHookResult = ReturnType<
  typeof useViewApplicaion_ApplicationsListQuery
>;
export type ViewApplicaion_ApplicationsListLazyQueryHookResult = ReturnType<
  typeof useViewApplicaion_ApplicationsListLazyQuery
>;
export type ViewApplicaion_ApplicationsListSuspenseQueryHookResult = ReturnType<
  typeof useViewApplicaion_ApplicationsListSuspenseQuery
>;
export type ViewApplicaion_ApplicationsListQueryResult = Apollo.QueryResult<
  ViewApplicaion_ApplicationsListQuery,
  ViewApplicaion_ApplicationsListQueryVariables
>;
export const OrganisationDocument = gql`
  query Organisation($id: String!) {
    organisation(id: $id) {
      ...ViewOrganisation_GetOrganisation
    }
  }
  ${ViewOrganisation_GetOrganisationFragmentDoc}
`;

/**
 * __useOrganisationQuery__
 *
 * To run a query within a React component, call `useOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganisationQuery,
    OrganisationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganisationQuery, OrganisationQueryVariables>(
    OrganisationDocument,
    options,
  );
}
export function useOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganisationQuery,
    OrganisationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganisationQuery, OrganisationQueryVariables>(
    OrganisationDocument,
    options,
  );
}
export function useOrganisationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrganisationQuery,
    OrganisationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrganisationQuery, OrganisationQueryVariables>(
    OrganisationDocument,
    options,
  );
}
export type OrganisationQueryHookResult = ReturnType<
  typeof useOrganisationQuery
>;
export type OrganisationLazyQueryHookResult = ReturnType<
  typeof useOrganisationLazyQuery
>;
export type OrganisationSuspenseQueryHookResult = ReturnType<
  typeof useOrganisationSuspenseQuery
>;
export type OrganisationQueryResult = Apollo.QueryResult<
  OrganisationQuery,
  OrganisationQueryVariables
>;
export const AccountCard_AccountDetailsDocument = gql`
  query AccountCard_AccountDetails($id: String!) {
    account(id: $id) {
      id
      ...UpdateAccountDetailsScreen_AccountInfo
      ...AccountSwitcher_AccountInfo
      name
      accountTypeId
      accountType {
        id
        name
        checkType
      }
      roles {
        ...RoleSelection_AccountToRoleRelationship
      }
      organisationGroups {
        id
        name
      }
      sites {
        ...SupplierCodesSection_AccountToSiteRelationship
      }
      tags {
        value {
          ...TagSelection_Tags
        }
      }
      status
      jobTitle
    }
  }
  ${UpdateAccountDetailsScreen_AccountInfoFragmentDoc}
  ${AccountSwitcher_AccountInfoFragmentDoc}
  ${RoleSelection_AccountToRoleRelationshipFragmentDoc}
  ${SupplierCodesSection_AccountToSiteRelationshipFragmentDoc}
  ${TagSelection_TagsFragmentDoc}
`;

/**
 * __useAccountCard_AccountDetailsQuery__
 *
 * To run a query within a React component, call `useAccountCard_AccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCard_AccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCard_AccountDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountCard_AccountDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountCard_AccountDetailsQuery,
    AccountCard_AccountDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountCard_AccountDetailsQuery,
    AccountCard_AccountDetailsQueryVariables
  >(AccountCard_AccountDetailsDocument, options);
}
export function useAccountCard_AccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountCard_AccountDetailsQuery,
    AccountCard_AccountDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountCard_AccountDetailsQuery,
    AccountCard_AccountDetailsQueryVariables
  >(AccountCard_AccountDetailsDocument, options);
}
export function useAccountCard_AccountDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountCard_AccountDetailsQuery,
    AccountCard_AccountDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountCard_AccountDetailsQuery,
    AccountCard_AccountDetailsQueryVariables
  >(AccountCard_AccountDetailsDocument, options);
}
export type AccountCard_AccountDetailsQueryHookResult = ReturnType<
  typeof useAccountCard_AccountDetailsQuery
>;
export type AccountCard_AccountDetailsLazyQueryHookResult = ReturnType<
  typeof useAccountCard_AccountDetailsLazyQuery
>;
export type AccountCard_AccountDetailsSuspenseQueryHookResult = ReturnType<
  typeof useAccountCard_AccountDetailsSuspenseQuery
>;
export type AccountCard_AccountDetailsQueryResult = Apollo.QueryResult<
  AccountCard_AccountDetailsQuery,
  AccountCard_AccountDetailsQueryVariables
>;
export const AccountCard_TagsDocument = gql`
  query AccountCard_Tags($accountTypeId: String!) {
    tagsByAccountType(accountTypeId: $accountTypeId) {
      ...TagSelection_Tags
    }
  }
  ${TagSelection_TagsFragmentDoc}
`;

/**
 * __useAccountCard_TagsQuery__
 *
 * To run a query within a React component, call `useAccountCard_TagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCard_TagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCard_TagsQuery({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *   },
 * });
 */
export function useAccountCard_TagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountCard_TagsQuery,
    AccountCard_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountCard_TagsQuery, AccountCard_TagsQueryVariables>(
    AccountCard_TagsDocument,
    options,
  );
}
export function useAccountCard_TagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountCard_TagsQuery,
    AccountCard_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountCard_TagsQuery,
    AccountCard_TagsQueryVariables
  >(AccountCard_TagsDocument, options);
}
export function useAccountCard_TagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountCard_TagsQuery,
    AccountCard_TagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountCard_TagsQuery,
    AccountCard_TagsQueryVariables
  >(AccountCard_TagsDocument, options);
}
export type AccountCard_TagsQueryHookResult = ReturnType<
  typeof useAccountCard_TagsQuery
>;
export type AccountCard_TagsLazyQueryHookResult = ReturnType<
  typeof useAccountCard_TagsLazyQuery
>;
export type AccountCard_TagsSuspenseQueryHookResult = ReturnType<
  typeof useAccountCard_TagsSuspenseQuery
>;
export type AccountCard_TagsQueryResult = Apollo.QueryResult<
  AccountCard_TagsQuery,
  AccountCard_TagsQueryVariables
>;
export const AccountCard_RolesByAccountTypeDocument = gql`
  query AccountCard_RolesByAccountType($accountTypeId: String!) {
    rolesByAccountType(accountTypeId: $accountTypeId) {
      ...RoleSection_RoleInfo
    }
  }
  ${RoleSection_RoleInfoFragmentDoc}
`;

/**
 * __useAccountCard_RolesByAccountTypeQuery__
 *
 * To run a query within a React component, call `useAccountCard_RolesByAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCard_RolesByAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCard_RolesByAccountTypeQuery({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *   },
 * });
 */
export function useAccountCard_RolesByAccountTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountCard_RolesByAccountTypeQuery,
    AccountCard_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountCard_RolesByAccountTypeQuery,
    AccountCard_RolesByAccountTypeQueryVariables
  >(AccountCard_RolesByAccountTypeDocument, options);
}
export function useAccountCard_RolesByAccountTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountCard_RolesByAccountTypeQuery,
    AccountCard_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountCard_RolesByAccountTypeQuery,
    AccountCard_RolesByAccountTypeQueryVariables
  >(AccountCard_RolesByAccountTypeDocument, options);
}
export function useAccountCard_RolesByAccountTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountCard_RolesByAccountTypeQuery,
    AccountCard_RolesByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountCard_RolesByAccountTypeQuery,
    AccountCard_RolesByAccountTypeQueryVariables
  >(AccountCard_RolesByAccountTypeDocument, options);
}
export type AccountCard_RolesByAccountTypeQueryHookResult = ReturnType<
  typeof useAccountCard_RolesByAccountTypeQuery
>;
export type AccountCard_RolesByAccountTypeLazyQueryHookResult = ReturnType<
  typeof useAccountCard_RolesByAccountTypeLazyQuery
>;
export type AccountCard_RolesByAccountTypeSuspenseQueryHookResult = ReturnType<
  typeof useAccountCard_RolesByAccountTypeSuspenseQuery
>;
export type AccountCard_RolesByAccountTypeQueryResult = Apollo.QueryResult<
  AccountCard_RolesByAccountTypeQuery,
  AccountCard_RolesByAccountTypeQueryVariables
>;
export const AccountSwitcher_AccountOptionsDocument = gql`
  query AccountSwitcher_AccountOptions($profileId: String!) {
    profileAccounts(profileId: $profileId) {
      ...AccountSwitcher_AccountInfo
    }
  }
  ${AccountSwitcher_AccountInfoFragmentDoc}
`;

/**
 * __useAccountSwitcher_AccountOptionsQuery__
 *
 * To run a query within a React component, call `useAccountSwitcher_AccountOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSwitcher_AccountOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSwitcher_AccountOptionsQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useAccountSwitcher_AccountOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountSwitcher_AccountOptionsQuery,
    AccountSwitcher_AccountOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountSwitcher_AccountOptionsQuery,
    AccountSwitcher_AccountOptionsQueryVariables
  >(AccountSwitcher_AccountOptionsDocument, options);
}
export function useAccountSwitcher_AccountOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountSwitcher_AccountOptionsQuery,
    AccountSwitcher_AccountOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountSwitcher_AccountOptionsQuery,
    AccountSwitcher_AccountOptionsQueryVariables
  >(AccountSwitcher_AccountOptionsDocument, options);
}
export function useAccountSwitcher_AccountOptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountSwitcher_AccountOptionsQuery,
    AccountSwitcher_AccountOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountSwitcher_AccountOptionsQuery,
    AccountSwitcher_AccountOptionsQueryVariables
  >(AccountSwitcher_AccountOptionsDocument, options);
}
export type AccountSwitcher_AccountOptionsQueryHookResult = ReturnType<
  typeof useAccountSwitcher_AccountOptionsQuery
>;
export type AccountSwitcher_AccountOptionsLazyQueryHookResult = ReturnType<
  typeof useAccountSwitcher_AccountOptionsLazyQuery
>;
export type AccountSwitcher_AccountOptionsSuspenseQueryHookResult = ReturnType<
  typeof useAccountSwitcher_AccountOptionsSuspenseQuery
>;
export type AccountSwitcher_AccountOptionsQueryResult = Apollo.QueryResult<
  AccountSwitcher_AccountOptionsQuery,
  AccountSwitcher_AccountOptionsQueryVariables
>;
export const ProfileDocument = gql`
  query Profile($id: String!) {
    profile(id: $id) {
      ...ViewProfile_ProfileInfo
      ...ProfileCard_ProfileInfo
    }
  }
  ${ViewProfile_ProfileInfoFragmentDoc}
  ${ProfileCard_ProfileInfoFragmentDoc}
`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileQuery(
  baseOptions: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    options,
  );
}
export function useProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileQuery,
    ProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    options,
  );
}
export function useProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProfileQuery,
    ProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    options,
  );
}
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileSuspenseQueryHookResult = ReturnType<
  typeof useProfileSuspenseQuery
>;
export type ProfileQueryResult = Apollo.QueryResult<
  ProfileQuery,
  ProfileQueryVariables
>;
export const ViewSiteDocument = gql`
  query ViewSite($id: String!) {
    site(id: $id) {
      ...ViewSite
    }
  }
  ${ViewSiteFragmentDoc}
`;

/**
 * __useViewSiteQuery__
 *
 * To run a query within a React component, call `useViewSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewSiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewSiteQuery(
  baseOptions: Apollo.QueryHookOptions<ViewSiteQuery, ViewSiteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewSiteQuery, ViewSiteQueryVariables>(
    ViewSiteDocument,
    options,
  );
}
export function useViewSiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewSiteQuery,
    ViewSiteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewSiteQuery, ViewSiteQueryVariables>(
    ViewSiteDocument,
    options,
  );
}
export function useViewSiteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ViewSiteQuery,
    ViewSiteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ViewSiteQuery, ViewSiteQueryVariables>(
    ViewSiteDocument,
    options,
  );
}
export type ViewSiteQueryHookResult = ReturnType<typeof useViewSiteQuery>;
export type ViewSiteLazyQueryHookResult = ReturnType<
  typeof useViewSiteLazyQuery
>;
export type ViewSiteSuspenseQueryHookResult = ReturnType<
  typeof useViewSiteSuspenseQuery
>;
export type ViewSiteQueryResult = Apollo.QueryResult<
  ViewSiteQuery,
  ViewSiteQueryVariables
>;
export const AddAccountByProfileScreen_ProfileSearchDocument = gql`
  query AddAccountByProfileScreen_ProfileSearch(
    $searchTerms: String
    $page: Float
    $pageSize: Float
  ) {
    paginatedProfiles(
      searchTerms: $searchTerms
      page: $page
      pageSize: $pageSize
    ) {
      page
      count
      perPage
      profiles {
        ...AddAccountByProfileScreen_Profiles
      }
    }
  }
  ${AddAccountByProfileScreen_ProfilesFragmentDoc}
`;

/**
 * __useAddAccountByProfileScreen_ProfileSearchQuery__
 *
 * To run a query within a React component, call `useAddAccountByProfileScreen_ProfileSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddAccountByProfileScreen_ProfileSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddAccountByProfileScreen_ProfileSearchQuery({
 *   variables: {
 *      searchTerms: // value for 'searchTerms'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useAddAccountByProfileScreen_ProfileSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AddAccountByProfileScreen_ProfileSearchQuery,
    AddAccountByProfileScreen_ProfileSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AddAccountByProfileScreen_ProfileSearchQuery,
    AddAccountByProfileScreen_ProfileSearchQueryVariables
  >(AddAccountByProfileScreen_ProfileSearchDocument, options);
}
export function useAddAccountByProfileScreen_ProfileSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddAccountByProfileScreen_ProfileSearchQuery,
    AddAccountByProfileScreen_ProfileSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AddAccountByProfileScreen_ProfileSearchQuery,
    AddAccountByProfileScreen_ProfileSearchQueryVariables
  >(AddAccountByProfileScreen_ProfileSearchDocument, options);
}
export function useAddAccountByProfileScreen_ProfileSearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AddAccountByProfileScreen_ProfileSearchQuery,
    AddAccountByProfileScreen_ProfileSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AddAccountByProfileScreen_ProfileSearchQuery,
    AddAccountByProfileScreen_ProfileSearchQueryVariables
  >(AddAccountByProfileScreen_ProfileSearchDocument, options);
}
export type AddAccountByProfileScreen_ProfileSearchQueryHookResult = ReturnType<
  typeof useAddAccountByProfileScreen_ProfileSearchQuery
>;
export type AddAccountByProfileScreen_ProfileSearchLazyQueryHookResult =
  ReturnType<typeof useAddAccountByProfileScreen_ProfileSearchLazyQuery>;
export type AddAccountByProfileScreen_ProfileSearchSuspenseQueryHookResult =
  ReturnType<typeof useAddAccountByProfileScreen_ProfileSearchSuspenseQuery>;
export type AddAccountByProfileScreen_ProfileSearchQueryResult =
  Apollo.QueryResult<
    AddAccountByProfileScreen_ProfileSearchQuery,
    AddAccountByProfileScreen_ProfileSearchQueryVariables
  >;
export const AddNewAppGroupScreen_MyApplicationsDocument = gql`
  query AddNewAppGroupScreen_MyApplications {
    myApplications {
      ...AddNewAppGroupScreen_Application
    }
  }
  ${AddNewAppGroupScreen_ApplicationFragmentDoc}
`;

/**
 * __useAddNewAppGroupScreen_MyApplicationsQuery__
 *
 * To run a query within a React component, call `useAddNewAppGroupScreen_MyApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddNewAppGroupScreen_MyApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddNewAppGroupScreen_MyApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddNewAppGroupScreen_MyApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AddNewAppGroupScreen_MyApplicationsQuery,
    AddNewAppGroupScreen_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AddNewAppGroupScreen_MyApplicationsQuery,
    AddNewAppGroupScreen_MyApplicationsQueryVariables
  >(AddNewAppGroupScreen_MyApplicationsDocument, options);
}
export function useAddNewAppGroupScreen_MyApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddNewAppGroupScreen_MyApplicationsQuery,
    AddNewAppGroupScreen_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AddNewAppGroupScreen_MyApplicationsQuery,
    AddNewAppGroupScreen_MyApplicationsQueryVariables
  >(AddNewAppGroupScreen_MyApplicationsDocument, options);
}
export function useAddNewAppGroupScreen_MyApplicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AddNewAppGroupScreen_MyApplicationsQuery,
    AddNewAppGroupScreen_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AddNewAppGroupScreen_MyApplicationsQuery,
    AddNewAppGroupScreen_MyApplicationsQueryVariables
  >(AddNewAppGroupScreen_MyApplicationsDocument, options);
}
export type AddNewAppGroupScreen_MyApplicationsQueryHookResult = ReturnType<
  typeof useAddNewAppGroupScreen_MyApplicationsQuery
>;
export type AddNewAppGroupScreen_MyApplicationsLazyQueryHookResult = ReturnType<
  typeof useAddNewAppGroupScreen_MyApplicationsLazyQuery
>;
export type AddNewAppGroupScreen_MyApplicationsSuspenseQueryHookResult =
  ReturnType<typeof useAddNewAppGroupScreen_MyApplicationsSuspenseQuery>;
export type AddNewAppGroupScreen_MyApplicationsQueryResult = Apollo.QueryResult<
  AddNewAppGroupScreen_MyApplicationsQuery,
  AddNewAppGroupScreen_MyApplicationsQueryVariables
>;
export const AddNewPermissionToGroupScreen_PermissionByAppDocument = gql`
  query AddNewPermissionToGroupScreen_PermissionByApp($applicationId: String!) {
    applicationPermissions(applicationId: $applicationId) {
      ...AddNewPermissionToGroupScreen_PermissionInfo
    }
  }
  ${AddNewPermissionToGroupScreen_PermissionInfoFragmentDoc}
`;

/**
 * __useAddNewPermissionToGroupScreen_PermissionByAppQuery__
 *
 * To run a query within a React component, call `useAddNewPermissionToGroupScreen_PermissionByAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddNewPermissionToGroupScreen_PermissionByAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddNewPermissionToGroupScreen_PermissionByAppQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useAddNewPermissionToGroupScreen_PermissionByAppQuery(
  baseOptions: Apollo.QueryHookOptions<
    AddNewPermissionToGroupScreen_PermissionByAppQuery,
    AddNewPermissionToGroupScreen_PermissionByAppQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AddNewPermissionToGroupScreen_PermissionByAppQuery,
    AddNewPermissionToGroupScreen_PermissionByAppQueryVariables
  >(AddNewPermissionToGroupScreen_PermissionByAppDocument, options);
}
export function useAddNewPermissionToGroupScreen_PermissionByAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddNewPermissionToGroupScreen_PermissionByAppQuery,
    AddNewPermissionToGroupScreen_PermissionByAppQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AddNewPermissionToGroupScreen_PermissionByAppQuery,
    AddNewPermissionToGroupScreen_PermissionByAppQueryVariables
  >(AddNewPermissionToGroupScreen_PermissionByAppDocument, options);
}
export function useAddNewPermissionToGroupScreen_PermissionByAppSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AddNewPermissionToGroupScreen_PermissionByAppQuery,
    AddNewPermissionToGroupScreen_PermissionByAppQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AddNewPermissionToGroupScreen_PermissionByAppQuery,
    AddNewPermissionToGroupScreen_PermissionByAppQueryVariables
  >(AddNewPermissionToGroupScreen_PermissionByAppDocument, options);
}
export type AddNewPermissionToGroupScreen_PermissionByAppQueryHookResult =
  ReturnType<typeof useAddNewPermissionToGroupScreen_PermissionByAppQuery>;
export type AddNewPermissionToGroupScreen_PermissionByAppLazyQueryHookResult =
  ReturnType<typeof useAddNewPermissionToGroupScreen_PermissionByAppLazyQuery>;
export type AddNewPermissionToGroupScreen_PermissionByAppSuspenseQueryHookResult =
  ReturnType<
    typeof useAddNewPermissionToGroupScreen_PermissionByAppSuspenseQuery
  >;
export type AddNewPermissionToGroupScreen_PermissionByAppQueryResult =
  Apollo.QueryResult<
    AddNewPermissionToGroupScreen_PermissionByAppQuery,
    AddNewPermissionToGroupScreen_PermissionByAppQueryVariables
  >;
export const EditAppGroupScreen_MyApplicationsDocument = gql`
  query EditAppGroupScreen_MyApplications {
    myApplications {
      ...EditAppGroupScreen_ApplicationInfo
    }
  }
  ${EditAppGroupScreen_ApplicationInfoFragmentDoc}
`;

/**
 * __useEditAppGroupScreen_MyApplicationsQuery__
 *
 * To run a query within a React component, call `useEditAppGroupScreen_MyApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditAppGroupScreen_MyApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditAppGroupScreen_MyApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditAppGroupScreen_MyApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EditAppGroupScreen_MyApplicationsQuery,
    EditAppGroupScreen_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EditAppGroupScreen_MyApplicationsQuery,
    EditAppGroupScreen_MyApplicationsQueryVariables
  >(EditAppGroupScreen_MyApplicationsDocument, options);
}
export function useEditAppGroupScreen_MyApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditAppGroupScreen_MyApplicationsQuery,
    EditAppGroupScreen_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EditAppGroupScreen_MyApplicationsQuery,
    EditAppGroupScreen_MyApplicationsQueryVariables
  >(EditAppGroupScreen_MyApplicationsDocument, options);
}
export function useEditAppGroupScreen_MyApplicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EditAppGroupScreen_MyApplicationsQuery,
    EditAppGroupScreen_MyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EditAppGroupScreen_MyApplicationsQuery,
    EditAppGroupScreen_MyApplicationsQueryVariables
  >(EditAppGroupScreen_MyApplicationsDocument, options);
}
export type EditAppGroupScreen_MyApplicationsQueryHookResult = ReturnType<
  typeof useEditAppGroupScreen_MyApplicationsQuery
>;
export type EditAppGroupScreen_MyApplicationsLazyQueryHookResult = ReturnType<
  typeof useEditAppGroupScreen_MyApplicationsLazyQuery
>;
export type EditAppGroupScreen_MyApplicationsSuspenseQueryHookResult =
  ReturnType<typeof useEditAppGroupScreen_MyApplicationsSuspenseQuery>;
export type EditAppGroupScreen_MyApplicationsQueryResult = Apollo.QueryResult<
  EditAppGroupScreen_MyApplicationsQuery,
  EditAppGroupScreen_MyApplicationsQueryVariables
>;
export const AddSupplierCode_GroupSitesDocument = gql`
  query AddSupplierCode_GroupSites($groupId: String!) {
    groupSites(groupId: $groupId) {
      ...AddSupplierCode_SiteInfo
    }
  }
  ${AddSupplierCode_SiteInfoFragmentDoc}
`;

/**
 * __useAddSupplierCode_GroupSitesQuery__
 *
 * To run a query within a React component, call `useAddSupplierCode_GroupSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierCode_GroupSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddSupplierCode_GroupSitesQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useAddSupplierCode_GroupSitesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AddSupplierCode_GroupSitesQuery,
    AddSupplierCode_GroupSitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AddSupplierCode_GroupSitesQuery,
    AddSupplierCode_GroupSitesQueryVariables
  >(AddSupplierCode_GroupSitesDocument, options);
}
export function useAddSupplierCode_GroupSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddSupplierCode_GroupSitesQuery,
    AddSupplierCode_GroupSitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AddSupplierCode_GroupSitesQuery,
    AddSupplierCode_GroupSitesQueryVariables
  >(AddSupplierCode_GroupSitesDocument, options);
}
export function useAddSupplierCode_GroupSitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AddSupplierCode_GroupSitesQuery,
    AddSupplierCode_GroupSitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AddSupplierCode_GroupSitesQuery,
    AddSupplierCode_GroupSitesQueryVariables
  >(AddSupplierCode_GroupSitesDocument, options);
}
export type AddSupplierCode_GroupSitesQueryHookResult = ReturnType<
  typeof useAddSupplierCode_GroupSitesQuery
>;
export type AddSupplierCode_GroupSitesLazyQueryHookResult = ReturnType<
  typeof useAddSupplierCode_GroupSitesLazyQuery
>;
export type AddSupplierCode_GroupSitesSuspenseQueryHookResult = ReturnType<
  typeof useAddSupplierCode_GroupSitesSuspenseQuery
>;
export type AddSupplierCode_GroupSitesQueryResult = Apollo.QueryResult<
  AddSupplierCode_GroupSitesQuery,
  AddSupplierCode_GroupSitesQueryVariables
>;
export const GetAppPermissionsDocument = gql`
  query getAppPermissions($applicationId: String!) {
    applicationPermissions(applicationId: $applicationId) {
      ...appPermission
    }
  }
  ${AppPermissionFragmentDoc}
`;

/**
 * __useGetAppPermissionsQuery__
 *
 * To run a query within a React component, call `useGetAppPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppPermissionsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGetAppPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppPermissionsQuery,
    GetAppPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppPermissionsQuery,
    GetAppPermissionsQueryVariables
  >(GetAppPermissionsDocument, options);
}
export function useGetAppPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppPermissionsQuery,
    GetAppPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppPermissionsQuery,
    GetAppPermissionsQueryVariables
  >(GetAppPermissionsDocument, options);
}
export function useGetAppPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAppPermissionsQuery,
    GetAppPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAppPermissionsQuery,
    GetAppPermissionsQueryVariables
  >(GetAppPermissionsDocument, options);
}
export type GetAppPermissionsQueryHookResult = ReturnType<
  typeof useGetAppPermissionsQuery
>;
export type GetAppPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetAppPermissionsLazyQuery
>;
export type GetAppPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetAppPermissionsSuspenseQuery
>;
export type GetAppPermissionsQueryResult = Apollo.QueryResult<
  GetAppPermissionsQuery,
  GetAppPermissionsQueryVariables
>;
export const GetAppPermissionGroupsDocument = gql`
  query getAppPermissionGroups($applicationId: String!) {
    applicationPermissionGroups(applicationId: $applicationId) {
      id
      name
      description
      applicationId
      permissions {
        ...appPermissionGroupToPermissionRelation
      }
    }
  }
  ${AppPermissionGroupToPermissionRelationFragmentDoc}
`;

/**
 * __useGetAppPermissionGroupsQuery__
 *
 * To run a query within a React component, call `useGetAppPermissionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppPermissionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppPermissionGroupsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGetAppPermissionGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppPermissionGroupsQuery,
    GetAppPermissionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppPermissionGroupsQuery,
    GetAppPermissionGroupsQueryVariables
  >(GetAppPermissionGroupsDocument, options);
}
export function useGetAppPermissionGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppPermissionGroupsQuery,
    GetAppPermissionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppPermissionGroupsQuery,
    GetAppPermissionGroupsQueryVariables
  >(GetAppPermissionGroupsDocument, options);
}
export function useGetAppPermissionGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAppPermissionGroupsQuery,
    GetAppPermissionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAppPermissionGroupsQuery,
    GetAppPermissionGroupsQueryVariables
  >(GetAppPermissionGroupsDocument, options);
}
export type GetAppPermissionGroupsQueryHookResult = ReturnType<
  typeof useGetAppPermissionGroupsQuery
>;
export type GetAppPermissionGroupsLazyQueryHookResult = ReturnType<
  typeof useGetAppPermissionGroupsLazyQuery
>;
export type GetAppPermissionGroupsSuspenseQueryHookResult = ReturnType<
  typeof useGetAppPermissionGroupsSuspenseQuery
>;
export type GetAppPermissionGroupsQueryResult = Apollo.QueryResult<
  GetAppPermissionGroupsQuery,
  GetAppPermissionGroupsQueryVariables
>;
export const AddTagsComponent_TagsByAccountTypeDocument = gql`
  query AddTagsComponent_TagsByAccountType($accountTypeId: String!) {
    tagsByAccountType(accountTypeId: $accountTypeId) {
      ...TagSelection_Tags
    }
  }
  ${TagSelection_TagsFragmentDoc}
`;

/**
 * __useAddTagsComponent_TagsByAccountTypeQuery__
 *
 * To run a query within a React component, call `useAddTagsComponent_TagsByAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddTagsComponent_TagsByAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddTagsComponent_TagsByAccountTypeQuery({
 *   variables: {
 *      accountTypeId: // value for 'accountTypeId'
 *   },
 * });
 */
export function useAddTagsComponent_TagsByAccountTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AddTagsComponent_TagsByAccountTypeQuery,
    AddTagsComponent_TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AddTagsComponent_TagsByAccountTypeQuery,
    AddTagsComponent_TagsByAccountTypeQueryVariables
  >(AddTagsComponent_TagsByAccountTypeDocument, options);
}
export function useAddTagsComponent_TagsByAccountTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddTagsComponent_TagsByAccountTypeQuery,
    AddTagsComponent_TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AddTagsComponent_TagsByAccountTypeQuery,
    AddTagsComponent_TagsByAccountTypeQueryVariables
  >(AddTagsComponent_TagsByAccountTypeDocument, options);
}
export function useAddTagsComponent_TagsByAccountTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AddTagsComponent_TagsByAccountTypeQuery,
    AddTagsComponent_TagsByAccountTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AddTagsComponent_TagsByAccountTypeQuery,
    AddTagsComponent_TagsByAccountTypeQueryVariables
  >(AddTagsComponent_TagsByAccountTypeDocument, options);
}
export type AddTagsComponent_TagsByAccountTypeQueryHookResult = ReturnType<
  typeof useAddTagsComponent_TagsByAccountTypeQuery
>;
export type AddTagsComponent_TagsByAccountTypeLazyQueryHookResult = ReturnType<
  typeof useAddTagsComponent_TagsByAccountTypeLazyQuery
>;
export type AddTagsComponent_TagsByAccountTypeSuspenseQueryHookResult =
  ReturnType<typeof useAddTagsComponent_TagsByAccountTypeSuspenseQuery>;
export type AddTagsComponent_TagsByAccountTypeQueryResult = Apollo.QueryResult<
  AddTagsComponent_TagsByAccountTypeQuery,
  AddTagsComponent_TagsByAccountTypeQueryVariables
>;
export const MyAppsGroupTab_AccountApplicationGroupsDocument = gql`
  query MyAppsGroupTab_AccountApplicationGroups($id: String!) {
    account(id: $id) {
      ...MyAppsGroupTab_AccountApplicationGroups
    }
  }
  ${MyAppsGroupTab_AccountApplicationGroupsFragmentDoc}
`;

/**
 * __useMyAppsGroupTab_AccountApplicationGroupsQuery__
 *
 * To run a query within a React component, call `useMyAppsGroupTab_AccountApplicationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAppsGroupTab_AccountApplicationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAppsGroupTab_AccountApplicationGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyAppsGroupTab_AccountApplicationGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyAppsGroupTab_AccountApplicationGroupsQuery,
    MyAppsGroupTab_AccountApplicationGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyAppsGroupTab_AccountApplicationGroupsQuery,
    MyAppsGroupTab_AccountApplicationGroupsQueryVariables
  >(MyAppsGroupTab_AccountApplicationGroupsDocument, options);
}
export function useMyAppsGroupTab_AccountApplicationGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyAppsGroupTab_AccountApplicationGroupsQuery,
    MyAppsGroupTab_AccountApplicationGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyAppsGroupTab_AccountApplicationGroupsQuery,
    MyAppsGroupTab_AccountApplicationGroupsQueryVariables
  >(MyAppsGroupTab_AccountApplicationGroupsDocument, options);
}
export function useMyAppsGroupTab_AccountApplicationGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MyAppsGroupTab_AccountApplicationGroupsQuery,
    MyAppsGroupTab_AccountApplicationGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyAppsGroupTab_AccountApplicationGroupsQuery,
    MyAppsGroupTab_AccountApplicationGroupsQueryVariables
  >(MyAppsGroupTab_AccountApplicationGroupsDocument, options);
}
export type MyAppsGroupTab_AccountApplicationGroupsQueryHookResult = ReturnType<
  typeof useMyAppsGroupTab_AccountApplicationGroupsQuery
>;
export type MyAppsGroupTab_AccountApplicationGroupsLazyQueryHookResult =
  ReturnType<typeof useMyAppsGroupTab_AccountApplicationGroupsLazyQuery>;
export type MyAppsGroupTab_AccountApplicationGroupsSuspenseQueryHookResult =
  ReturnType<typeof useMyAppsGroupTab_AccountApplicationGroupsSuspenseQuery>;
export type MyAppsGroupTab_AccountApplicationGroupsQueryResult =
  Apollo.QueryResult<
    MyAppsGroupTab_AccountApplicationGroupsQuery,
    MyAppsGroupTab_AccountApplicationGroupsQueryVariables
  >;
