import { TopOfPage } from '@sainsburys-tech/suppex-top-of-page';
import { generateBreadcrumbs } from 'src/utils/getBreadcrumbs';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetSupplierNews } from 'src/operations/queries/getSupplierNews';
import { IArticleChild } from 'src/interfaces/articleChild.interface';
import { generateNewsArticleTags } from 'src/utils/generateJournalTags';
import { JournalLoading } from 'src/components/JournalLoading';
import { JournalError } from 'src/components/JournalError';
import { TileGroup } from '@sainsburys-tech/suppex-tile-group';
import { TileData } from '@sainsburys-tech/suppex-tile';
import { journalClickHandler } from 'src/utils/journalClickHandler';

export const SupplierNewsPage: FC = () => {
  const navigate = useNavigate();
  const pageSize = 10;
  const [pageIndex, setPageIndex] = useState(1);
  const [latestNews, setLatestNews] = useState([]);
  const [latestNewsForTileGroup, setLatestNewsForTileGroup] = useState<
    TileData[]
  >([]);
  const [totalNews, setTotalNews] = useState(0);

  const {
    data: { news: supplierNews } = {},
    error: newsError,
    loading: newsLoading,
  } = useGetSupplierNews(pageIndex, pageSize);

  const seeMoreHandler = () => {
    setPageIndex(pageIndex + 1);
  };

  useEffect(() => {
    if (supplierNews?.list) {
      setLatestNews(latestNews.concat(supplierNews.list));
      setTotalNews(supplierNews.total_results);
    }
  }, [supplierNews]);

  useEffect(() => {
    if (latestNews.length) {
      const tileData: TileData[] = latestNews.map((article: IArticleChild) => ({
        uid: article.uid,
        title: article.title,
        summary: article.summary,
        tags: generateNewsArticleTags(article),
        child_type: 'ARTICLE',
        image: {
          url: article.thumbnail_image,
          alt: '',
          metaDesc: '',
        },
      }));

      setLatestNewsForTileGroup(tileData);
    }
  }, [latestNews]);

  return (
    <>
      <TopOfPage
        title='News'
        subtitle="The latest supplier news from Sainsbury's"
        breadcrumbs={generateBreadcrumbs({ isNewsLanding: true })}
        LinkType={Link}
      />

      {newsLoading ? (
        <JournalLoading />
      ) : newsError ? (
        <JournalError />
      ) : (
        <>
          {latestNews.length > 0 && (
            <>
              <div data-cy='latestNewsSection' className='ln-u-padding-top*4'>
                <TileGroup
                  title='Latest news'
                  variant='promoted'
                  style='dark'
                  softTop
                  tileClickHandler={(tile: TileData) => {
                    journalClickHandler(tile, navigate);
                  }}
                  tiles={latestNewsForTileGroup.slice(0, 4)}
                />
              </div>
              <div
                data-cy='otherHeadlinesSection'
                className='ln-u-padding-top*4'
              >
                <TileGroup
                  title='Other headlines'
                  tiles={latestNewsForTileGroup.slice(4)}
                  variant='list'
                  style='light'
                  tileClickHandler={(tile: TileData) => {
                    journalClickHandler(tile, navigate);
                  }}
                  seeMoreButtonPosition='bottom'
                  seeMoreHandler={
                    latestNews.length < totalNews ? seeMoreHandler : undefined
                  }
                  seeMoreText='See more'
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
