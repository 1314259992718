import React, { FC, useContext, useEffect, useReducer, useState } from 'react';
import { ApplicationsWrapper } from './applications.styles';
import Container from 'src/components/Container/Container';
import { GridWrapper, GridItem, Card, SearchField } from '@jsluna/react';

import {
  ApplicationsContext,
  IApplicationsState,
} from './context/applications.context';
import { actions, initialState, reducer } from './redux';
import { PageHeader } from 'src/components/PageHeader';

import { SectionHeader } from 'src/shared/components/sectionHeader';
import { AddNewAppGroup } from './components/CreateAppGroup';
import { useNavigate } from 'react-router';
import { routes } from 'src/constants/routes';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';
import { APP_ACCESS } from 'src/enums/permissions.enum';
import { AppTile } from '@sainsburys-tech/supexp-app-tile';
import { IApplication } from 'src/interfaces/application.interface';
import { FilterSelect } from 'src/components/filterSelect';
import { IOption } from 'src/interfaces/option.interface';
import { NoRecordCard } from 'src/components/NoRecordCard';
import {
  Applications_MyApplicationsQueryVariables,
  useApplications_MyApplicationsLazyQuery,
  useGetAllTagsQuery,
} from '../../operations/generated/graphql';
import { ApplicationsLoader } from './components/applicationsLoader/applicationsLoader';

interface DropDownValues {
  id: string;
  [key: string]: any;
}

export const ApplicationsPage: FC = () => {
  const queryParams = new URLSearchParams(location.search);
  const queryParamTagId = queryParams.getAll('tagIds') || [];
  // @ts-ignore
  const { hasPermission } = useContext(AuthContext);
  const [selectedTag, setSelectedTag] = useState<string[]>(queryParamTagId);

  const [searchParams] = useSearchParams();

  const [filters, setFilters] =
    useState<Applications_MyApplicationsQueryVariables>({});

  const { loading: tagsLoading, data: { tags = [] } = {} } =
    useGetAllTagsQuery();

  const [
    searchApplications,
    { loading, error, data: { myApplications = [] } = {} },
  ] = useApplications_MyApplicationsLazyQuery({
    variables: { ...filters },
    fetchPolicy: 'network-only',
  });

  const initialApplicationState: IApplicationsState = {
    mappedApplications: [],
    filteredApplications: [],
    showAllApps: false,
    showRequestPermissionsModal: false,
    showAssignGroupModal: false,
    tagsArray: new Set(),
    selectedApplication: null,
    selectedApplicationGroup: null,
    refetchMyApps: false,
    showAddNewAppGroup: false,
  };

  const [state, dispatch] = useReducer(
    reducer,
    initialState(initialApplicationState),
  );

  const createAppGroupHandler = () => {
    dispatch({
      type: actions.SHOW_ADD_NEW_APP_GROUP,
    });
  };

  const handleAssignModalClose = () => {
    dispatch({
      type: actions.HIDE_ASSIGN_GROUP_MODAL,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const searchFilters = {
      ...(selectedTag.length ? { tagIds: selectedTag } : undefined),
    };

    setFilters(searchFilters);
    updateURLParams(searchFilters);
  }, [selectedTag]);

  useEffect(() => {
    searchApplications().catch(e => console.error('searchApplications', e));
  }, [filters]);

  useEffect(() => {
    if (myApplications) {
      dispatch({
        type: actions.SET_INITIAL_DATA,
        payload: myApplications,
      });
    }
  }, [myApplications]);

  useEffect(() => {
    const action = searchParams.get('action');
    if (action === 'createAppGroup') {
      createAppGroupHandler();
    } else if (action === 'editAppGroup') {
      // editAppGroupHandler()
    }
  }, []);

  const updateURLParams = (
    searchFilters: Applications_MyApplicationsQueryVariables,
  ) => {
    const searchParams = new URLSearchParams();

    if (Array.isArray(searchFilters.tagIds))
      searchFilters.tagIds.forEach(id => searchParams.append('tagIds', id));

    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  };

  const getDefaultOptions = (
    arrayOfObjects: DropDownValues[],
    selected: string[],
  ) => arrayOfObjects.filter(obj => selected.includes(obj.id));

  return (
    <ApplicationsContext.Provider value={{ state, dispatch }}>
      <PageHeader
        heading={`Applications`}
        breadcrumbLinks={[
          {
            name: 'Together With',
            link: '/',
          },
          {
            name: 'All apps',
            link: '',
          },
        ]}
        subHeading='View applications'
      />
      <Container>
        <ApplicationsWrapper>
          {/* 

          TODO: to determine the location later
          
          <MyAppsGroup
            editAppGroupHandler={editAppGroupHandler}
            createAppGroupHandler={createAppGroupHandler}
          /> */}
          <SectionHeader
            heading=''
            subHeading=''
            className='custom-margin'
            callToButton={
              hasPermission(APP_ACCESS.CREATE_APPLICATION) && {
                title: 'Create new application',
                action: () => {
                  navigate(routes.createApplication);
                },
              }
            }
          />

          <GridWrapper verticalAlign='middle'>
            <GridItem size={{ md: '1/4', xs: '1/1' }}>
              <SearchField
                name='search-application'
                label='Search applications'
                onChange={(e: any) => {
                  const filtered = myApplications?.filter(
                    app =>
                      !!app.name &&
                      app.name
                        .toLowerCase()
                        .startsWith(e.target.value.toLowerCase()),
                  );

                  dispatch({
                    type: actions.SET_FILTERED_APPLICATIONS,
                    payload: filtered,
                  });
                }}
                hasButton={false}
              />
            </GridItem>
            <GridItem size={{ md: '1/4', xs: '1/1' }}>
              <div className='ln-c-label'>Filters</div>
              <div className='filters-section'>
                {tags?.length > 0 && (
                  <FilterSelect
                    placeholder='Tag'
                    filterOptions={[
                      ...tags.map(tag => ({
                        label: tag.name,
                        value: tag.id,
                      })),
                    ]}
                    onChangeFn={(selected: IOption[]) => {
                      setSelectedTag(selected.map(tag => tag.value));
                    }}
                    defaultSelectedOptions={getDefaultOptions(
                      tags,
                      selectedTag,
                    ).map(tag => ({
                      label: tag.name,
                      value: tag.id,
                    }))}
                  />
                )}
              </div>
            </GridItem>
          </GridWrapper>

          {loading || tagsLoading ? (
            <>
              <ApplicationsLoader />
              <ApplicationsLoader />
              <ApplicationsLoader />
            </>
          ) : (
            <>
              {state?.filteredApplications?.length === 0 && (
                <NoRecordCard recordType='Applications' />
              )}
              <GridWrapper equalHeight>
                {state?.filteredApplications?.map(
                  (application: IApplication) => (
                    <GridItem
                      size={{ md: '1/3', xs: '1/1' }}
                      className='app-grid-item'
                      key={application.id}
                    >
                      <Card className='ln-u-margin-bottom*2'>
                        <AppTile
                          application={application}
                          navigateToDetailsPage={() => {
                            navigate(`/applications/${application.id}`);
                          }}
                        />
                      </Card>
                    </GridItem>
                  ),
                )}
              </GridWrapper>
            </>
          )}
        </ApplicationsWrapper>

        <AddNewAppGroup />
      </Container>
    </ApplicationsContext.Provider>
  );
};
