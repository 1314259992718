import { routes } from 'src/constants/routes';
import React, { FC } from 'react';
import { JournalError } from 'src/components/JournalError';
import { JournalLoading } from 'src/components/JournalLoading';
import { useGetGSCOPContent } from 'src/operations/queries/getGSCOPContent';
import { TopOfPage } from '@sainsburys-tech/suppex-top-of-page';
import { Link } from 'react-router-dom';
import { GridWrapper, GridItem, Container } from '@jsluna/react';
import DOMPurify from 'dompurify';

interface IGSCOPContent {
  created_at: string;
  header_title: string;
  sections: string;
  uid: string;
  updated_at: string;
  _version: string;
}

interface IGSCOPSection {
  body: string;
  title: string;
}

export const GSCOPPage: FC = () => {
  const {
    data: { gscop } = {},
    error: gscopError,
    loading: gscopLoading,
  } = useGetGSCOPContent();

  const renderHeader = (gscopContent: IGSCOPContent) => {
    const links = [
      { title: 'Home', href: routes.root, active: false },
      { title: 'GSCOP', href: routes.gscop, active: true },
    ];
    const { header_title } = gscopContent;
    return (
      <TopOfPage
        title={header_title.replace(/(<([^>]+)>)/gi, '')}
        breadcrumbs={links}
        LinkType={Link}
      />
    );
  };

  const renderSections = (section: IGSCOPSection, index: number) => {
    return (
      <>
        {index !== 0 && <hr className='border-bottom-grey-light' />}
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(section.title),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(section.body, {
              ADD_TAGS: ['iframe'],
              ADD_ATTR: [
                'allow',
                'allowfullscreen',
                'frameborder',
                'scrolling',
              ],
            }),
          }}
        />
      </>
    );
  };

  return (
    <>
      {gscopLoading ? (
        <JournalLoading />
      ) : gscopError ? (
        <JournalError />
      ) : (
        <>
          {renderHeader(gscop)}
          <Container className={'ln-u-soft-top-xl ln-u-soft-bottom-xl'}>
            <GridWrapper horizontalAlign='center'>
              <GridItem
                size={{ default: '1/1', lg: '12/12' }}
                className='ln-u-bg-color-white '
              >
                <GridWrapper
                  horizontalAlign='center'
                  className='ln-u-soft-bottom-xl ln-u-soft-top-xl'
                >
                  <GridItem size={{ default: '11/12' }}>
                    {gscop?.sections.map(
                      (section: IGSCOPSection, index: number) =>
                        renderSections(section, index),
                    )}
                  </GridItem>
                </GridWrapper>
              </GridItem>
            </GridWrapper>
          </Container>
        </>
      )}
    </>
  );
};
