import { FC } from 'react';
import { TopOfPage } from '@sainsburys-tech/suppex-top-of-page';
import { generateBreadcrumbs } from 'src/utils/getBreadcrumbs';
import { Link } from 'react-router-dom';
import { useGetSupplierArticleGroupings } from 'src/operations/queries/getSupplierArticleGroupings';
import { ArticleTeaser } from '@sainsburys-tech/suppex-article-teaser';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/constants/routes';
import { journalClickHandler } from 'src/utils/journalClickHandler';
import { JournalLoading } from 'src/components/JournalLoading';
import { JournalError } from 'src/components/JournalError';
import { useAppSelector } from 'src/redux/hooks';
import { getIsLoggedIn } from 'src/redux/selectors';
import { LoginRegisterPanel } from 'src/components/LoginRegisterPanel/LoginRegisterPanel';
import { useGetSupplierNews } from 'src/operations/queries/getSupplierNews';
import { SupplierNews } from './components/SupplierNews';
import { JournalBlock } from './components/journalBlock';
import { PlanBetterBlock } from './components/planBetterBlock';

export const NewsAndArticlesPage: FC = () => {
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const navigate = useNavigate();
  const {
    data: { news: latestNews } = {},
    error: newsError,
    loading: newsLoading,
  } = useGetSupplierNews(1, 4);
  const {
    data: { articles: journalData } = {},
    error: journalError,
    loading: journalLoading,
  } = useGetSupplierArticleGroupings('Journal');
  const {
    data: { articles: planBetterData } = {},
    error: planBetterError,
    loading: planBetterLoading,
  } = useGetSupplierArticleGroupings('Plan 4 Better');
  const {
    data: { articles: agricultureData } = {},
    error: agricultureError,
    loading: agricultureLoading,
  } = useGetSupplierArticleGroupings('Agriculture');

  return (
    <>
      <TopOfPage
        title='News &amp; articles'
        breadcrumbs={generateBreadcrumbs({})}
        LinkType={Link}
      />

      {isLoggedIn ? (
        <SupplierNews
          latestNews={latestNews}
          newsError={newsError}
          newsLoading={newsLoading}
        />
      ) : (
        <LoginRegisterPanel />
      )}

      {journalLoading || planBetterLoading || agricultureLoading ? (
        <JournalLoading />
      ) : journalError || planBetterError || agricultureError ? (
        <JournalError />
      ) : (
        <>
          {journalData?.length > 0 && (
            <JournalBlock journalData={journalData[0]} />
          )}

          {planBetterData?.length > 0 && (
            <PlanBetterBlock data={planBetterData[0]} />
          )}

          {agricultureData[0]?.children?.length > 0 && (
            <div
              data-cy='agricultureSectionHeader'
              className='ln-u-padding-top*4'
            >
              <ArticleTeaser
                title='Agriculture'
                teaserTitle={`What's in ${agricultureData[0]?.title}...`}
                imageTileProps={{
                  onClickHandler: () => {
                    navigate(`${routes.agriculture}`);
                  },
                  title: agricultureData[0]?.title,
                  summary: agricultureData[0]?.summary,
                  image: {
                    url: agricultureData[0]?.teaser_image,
                    alt: '',
                    metaDesc: '',
                  },
                  style: 'grey',
                }}
                list={agricultureData[0]?.children
                  ?.slice(0, 5)
                  .map((child: any) => ({
                    title: child?.title,
                    subtitle: child?.summary,
                    onClickHandler: () =>
                      journalClickHandler(child, navigate, agricultureData[0]),
                  }))}
                primaryButtonText={`Find out more`}
                primaryButtonHandler={() => navigate(`${routes.agriculture}`)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
